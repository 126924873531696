import React, { Fragment, Component } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import $ from "jquery";
import { Checkbox } from "semantic-ui-react";
import Regulations from "../other/Regulations";

class EditAgreements extends Component {
    state = {
        legalAge: this.props.accrData[0].part_declar_legal_age === "Y" ? true : false,
        regulations: this.props.accrData[0].part_declar_tac_agreement === "Y" ? true : false,
        showRegulations: false,
        changedValues: false,
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { onShowAlert, t } = this.props;

        onShowAlert("");
        let errorMsg = [];

        if (!this.state.legalAge) {
            errorMsg.push(t("error.legalAgeNotChecked"));
            $("#legalAge").addClass("is-invalid");
        }
        if (!this.state.regulations) {
            errorMsg.push(t("error.regulationsNotChecked"));
            $("#regulations").addClass("is-invalid");
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            this.handleEdit();
        }
    };

    handleEdit = async () => {
        const { fetchURL, onShowAlert, t, onRefreshNotification, accrData } = this.props;
        onShowAlert({ variant: "info", message: t("info.editingData"), fading: false });
        const passData = {
            legalAge: this.state.legalAge,
            regulations: this.state.regulations,
            partID: accrData[0].part_id,
        };
        //console.log(passData);
        try {
            const response = await fetch(`${fetchURL}/update-agreements`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    } else {
                        onShowAlert({ variant: "success", message: t(`success.dataUpdatedSuccessfully`), fading: 2000 });
                        onRefreshNotification();
                        this.props.onCloseAndRefresh();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    render() {
        const { regulations, changedValues, legalAge, showRegulations } = this.state;
        const { show, onClose, t, fetchURL, onShowAlert } = this.props;
        return (
            <Fragment>
                <Modal show={show} onHide={onClose} backdrop="static" keyboard={false} size="lg">
                    <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                        <Modal.Header closeButton>
                            <Modal.Title>{t("myAccount.accreditation.editAgreements.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="no-gutters">
                                <Col xs={12}>
                                    <p className="mb-3 h6" style={{ lineHeight: "1.2" }}>
                                        {t("myAccount.accreditation.editAgreements.subtitle")}
                                    </p>
                                </Col>

                                <Col xs={12}>
                                    <Checkbox
                                        label={t("myAccount.accreditation.form.regulations")}
                                        onChange={(event, data) => {
                                            $(`#regulations`).removeClass("is-invalid");
                                            this.setState({ regulations: data.checked, changedValues: true });
                                        }}
                                        id="regulations"
                                        checked={regulations ? true : false}
                                    />
                                    <Button variant="link" className="ml-2 align-baseline" onClick={() => this.setState({ showRegulations: true })}>
                                        {t("myAccount.accreditation.form.regulationsBtn")}
                                    </Button>
                                </Col>
                                {showRegulations && (
                                    <Col xs={12} className="mt-1">
                                        <Regulations t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} />
                                    </Col>
                                )}
                                <Col xs={12} className="mt-1">
                                    <Checkbox
                                        label={t("myAccount.accreditation.form.legalAge")}
                                        onChange={(event, data) => {
                                            $(`#legalAge`).removeClass("is-invalid");
                                            this.setState({ legalAge: data.checked, changedValues: true });
                                        }}
                                        id="legalAge"
                                        checked={legalAge ? true : false}
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={onClose}>
                                {t("info.cancel")}
                            </Button>
                            <Button variant="primary" disabled={!changedValues ? true : false} type="submit">
                                {t("info.saveChanges")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default EditAgreements;

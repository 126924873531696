import React, { Fragment, Component } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import SmallBanner from "../../other/SmallBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPager, faUser, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import Footer from "../../other/Footer";

var wh;

function setAccrCardsHeight() {
    let cardHeight = 0;
    $("#accrSection2 .accreditation-card").each(function () {
        $(this).removeAttr("style");
        if ($(this).outerHeight() > cardHeight) cardHeight = $(this).outerHeight();
    });
    $("#accrSection2 .accreditation-card").css({ height: `${cardHeight}px` });
}

class Accreditation extends Component {
    componentDidMount() {
        this.props.onHideSpinner(200);

        if ($(".loading-spoon").hasClass("show")) $("#accrSection1").delay(1200).fadeTo(400, 1);
        else $("#accrSection1").delay(400).fadeTo(400, 1);
        setAccrCardsHeight();

        wh = window.innerHeight < window.outerHeight ? window.innerHeight : window.outerHeight;

        window.addEventListener("resize", this.resize);
        window.addEventListener("scroll", this.scroll);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("scroll", this.scroll);
    }

    resize = () => {
        wh = window.innerHeight < window.outerHeight ? window.innerHeight : window.outerHeight;
        setAccrCardsHeight();
    };

    scroll = () => {
        if (document.getElementById("accreditation")) {
            const sec2 = $("#accrSection2").offset();
            const distance = window.scrollY;
            if (distance + wh - 200 >= sec2.top) $("#accrSection2").fadeTo(400, 1);
        }
    };

    handleRedirect = (e, url) => {
        e.preventDefault();
        this.props.onShowSpoonLoader();
        setTimeout(() => this.props.history.push(url), 700);
    };

    render() {
        const { t, frontURL, onShowSpoonLoader } = this.props;
        return (
            <Fragment>
                <Row className="no-gutters">
                    <Col xs={12} className="mb-lg-3">
                        <SmallBanner
                            frontURL={frontURL}
                            bgBottom={`${frontURL}/images/small-banners/b2-bottom.webp`}
                            bgDesktop={`${frontURL}/images/small-banners/b2.webp`}
                            title={t("accreditation.title")}
                            subtitle={t("accreditation.subtitle")}
                        />
                    </Col>
                </Row>
                <Container className="my-4" id="accreditation">
                    <Row id="accrSection1" style={{ opacity: 0 }}>
                        <Col xs={12} lg={5}>
                            <Row>
                                <Col xs={12} className="my-5 text-center">
                                    <h1 className="h1">{t("accreditation.fee")}</h1>
                                </Col>
                                <Col xs={12}>
                                    <div className="accreditation-card mb-4">
                                        <p className="h5 text-uppercase my-3">{t("accreditation.feeAmount1")}</p>
                                        <p className="h5 text-uppercase mb-3">{t("accreditation.feeAmount2")}</p>
                                        <p className="h5 text-uppercase mb-3">{t("accreditation.feeAmount3")}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} lg={7} className="my-5 text-center">
                            <p className="h1 text-uppercase">{t("accreditation.requirements.title")}</p>
                            <p className="h4 mt-5">{t("accreditation.requirements.subtitle")}</p>
                            <Button
                                variant="primary"
                                onClick={(e) => {
                                    this.handleRedirect(e, `${t("url.link.accreditationReq")}`);
                                }}
                                className="my-3"
                                size="lg"
                            >
                                {t("accreditation.requirements.goToReqBtn")}
                            </Button>
                        </Col>
                    </Row>

                    <Row id="accrSection2" style={{ opacity: 0 }}>
                        <Col xs={12} className="my-5 text-center">
                            <h1 className="h1">{t("accreditation.shortInfo")}</h1>
                        </Col>
                        <Col xs={12} lg={4}>
                            <div className="accreditation-card mb-4">
                                <div className="text-center my-3">
                                    <FontAwesomeIcon icon={faUserPlus} className="my-3" />
                                </div>
                                <p className="h4 text-uppercase mb-3">{t("accreditation.createAccount.title")}</p>
                                <p className="mb-3">{t("accreditation.createAccount.text")}</p>
                                <Button
                                    variant="primary"
                                    onClick={(e) => {
                                        this.handleRedirect(e, `/${t("url.link.register")}`);
                                    }}
                                >
                                    {t("accreditation.createAccount.link")}
                                </Button>
                            </div>
                        </Col>
                        <Col xs={12} lg={4}>
                            <div className="accreditation-card mb-4">
                                <div className="text-center my-3">
                                    <FontAwesomeIcon icon={faPager} className="my-3" />
                                </div>
                                <p className="h4 text-uppercase mb-3">{t("accreditation.participation.title")}</p>
                                <p className="mb-3">{t("accreditation.participation.text")}</p>
                                <Button
                                    variant="primary"
                                    onClick={(e) => {
                                        this.handleRedirect(e, `${t("url.link.myAccount")}/${t("url.link.accreditation")}/${t("url.link.accrForm")}`);
                                    }}
                                >
                                    {t("accreditation.participation.link")}
                                </Button>
                            </div>
                        </Col>
                        <Col xs={12} lg={4}>
                            <div className="accreditation-card mb-4">
                                <div className="text-center my-3">
                                    <FontAwesomeIcon icon={faUser} className="my-3" />
                                </div>
                                <p className="h4 text-uppercase mb-3">{t("accreditation.submitCharacter.title")}</p>
                                <p className="mb-3">{t("accreditation.submitCharacter.text")}</p>
                                <Button
                                    variant="primary"
                                    onClick={(e) => {
                                        this.handleRedirect(e, `${t("url.link.myAccount")}/${t("url.link.characters")}/${t("url.link.characterForm")}`);
                                    }}
                                >
                                    {t("accreditation.submitCharacter.link")}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withRouter(Accreditation);

import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Button, Form, Alert } from "react-bootstrap";
import TextForm from "../../../forms/text-inputs/TextForm";
import TextareaForm from "../../../forms/TextareaForm";
import { withRouter } from "react-router-dom";
import ParticipationRadios from "../../../forms/check-radio-boxes/ParticipationRadios";
//import ChooseCharacterOnLarp from "../../../forms/dropdowns/ChooseCharacterOnLarp";
import { Checkbox } from "semantic-ui-react";
import $ from "jquery";
import RegulationsModal from "../../../modals/RegulationsModal";
import ChooseCharacterInAccrForm from "./ChooseCharacterInAccrForm";
import AddNewCharacterModal from "../../../modals/AddNewCharacterModal";

var regExpWhiteSpace = /^\s*$/;

class AccrForm extends Component {
    state = {
        changedValues: false,
        showRegulationsModal: false,
        addedReq: false,
        showAddNewCharacterModal: false,
        downloadAgainCharList: false,
    };

    handleChangeTextFormValues = (e) => {
        const { accrData, onUpdateAccrData } = this.props;
        const name = e.target.name;
        const list = [...accrData];
        list[0][name] = e.target.value;
        onUpdateAccrData(list);
        this.setState({ changedValues: true });
    };

    handleChangeFormValues = (value, name) => {
        const { accrData, onUpdateAccrData } = this.props;
        const list = [...accrData];
        list[0][name] = value;
        onUpdateAccrData(list);
        this.setState({ changedValues: true });
    };

    handleChangeApplicationValues = (value, index) => {
        const { partApplications, onUpdateAccrApp } = this.props;
        const list = [...partApplications];
        list[index].value = value;
        onUpdateAccrApp(list);
        this.setState({ changedValues: true });
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { accrData } = this.props;
        const { onShowAlert, t } = this.props;

        onShowAlert("");
        let errorMsg = [];

        if (!accrData[0].participation) {
            $("#participation").addClass("is-invalid");
            errorMsg.push(t("error.emptyPartRadio"));
        } else if (accrData[0].participation === "ABSENT") {
            if (!accrData[0].partIdentNickname) {
                $("#partIdentNickname").addClass("is-invalid");
                errorMsg.push(t("error.emptyIdentNickname"));
            } else if (regExpWhiteSpace.test(accrData[0].partIdentNickname) && accrData[0].partIdentNickname.length > 0) {
                $("#partIdentNickname").addClass("is-invalid");
                errorMsg.push(t("error.invalidNicknameIdent"));
            } else if (accrData[0].partIdentNickname && accrData[0].partIdentNickname.length > 50) {
                $("#partIdentNickname").addClass("is-invalid");
                errorMsg.push(t("error.nicknameIdentToLong"));
            }

            if (!accrData[0].regulations) {
                $("#regulations").addClass("is-invalid");
                errorMsg.push(t("error.regulationsNotChecked"));
            }
            if (!accrData[0].legalAge) {
                $("#legalAge").addClass("is-invalid");
                errorMsg.push(t("error.legalAgeNotChecked"));
            }
            if (!accrData[0].moneyTransferDeclaration) {
                $("#moneyTransferDeclaration").addClass("is-invalid");
                errorMsg.push(t("error.moneyTransferDeclarationNotChecked"));
            }

            if (accrData[0].partCommentForm)
                if (accrData[0].partCommentForm.length > 255) {
                    $("#partCommentForm").addClass("is-invalid");
                    errorMsg.push(t("error.commentFormToLong"));
                } else if (regExpWhiteSpace.test(accrData[0].partCommentForm)) {
                    $("#partCommentForm").addClass("is-invalid");
                    errorMsg.push(t("error.invalidComment"));
                }
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            //console.log(accrData[0]);
            this.props.onDataProcessing();
        }
    };

    componentDidUpdate() {
        if (this.props.accrData[0].participation === "ABSENT" && !this.state.addedReq) {
            $("#regulations + label").append('<span class="required ml-1">*</span>');
            $("#legalAge + label").append('<span class="required ml-1">*</span>');
            $("#moneyTransferDeclaration + label").append('<span class="required ml-1">*</span>');

            this.setState({ addedReq: true });
        }
    }

    render() {
        const { changedValues, showRegulationsModal, showAddNewCharacterModal, downloadAgainCharList } = this.state;
        const { accrData, t, onShowAlert, fetchURL, disabledForm, allowSelectCharacter, activeEvent, myAccountLink } = this.props;
        return (
            <Fragment>
                <Row className="no-gutters">
                    <Col xl={{ span: 8, offset: 2 }} xs={12} className="fill-form mt-3 mb-5 px-4 py-3">
                        <Form onSubmit={this.handleCheckValidation}>
                            <Row className="no-gutters">
                                <Col xs={12}>
                                    <p className="h4 text-uppercase text-center my-3">{t("myAccount.accreditation.form.title")}</p>
                                </Col>
                                <Col xs={12} className="mt-4">
                                    <Form.Group>
                                        <Form.Label>
                                            {t("myAccount.accreditation.form.participation.label")} <span className="required">*</span>
                                        </Form.Label>
                                        <ParticipationRadios
                                            disabled={disabledForm}
                                            onChangeValue={(value, name) => {
                                                this.handleChangeFormValues(value, name);
                                                $("#participation").removeClass("is-invalid");
                                                if (value === "ABORTED") {
                                                    this.setState({
                                                        addedReq: false,
                                                        accrData: [
                                                            {
                                                                participation: "ABORTED",
                                                                partIdentNickname: "",
                                                                larpCharacter: null,
                                                                partCommentForm: "",
                                                                regulations: false,
                                                                legalAge: false,
                                                                moneyTransferDeclaration: false,
                                                            },
                                                        ],
                                                    });
                                                }
                                            }}
                                            t={t}
                                            radioValue={accrData[0].participation}
                                            id="participation"
                                        />
                                    </Form.Group>
                                </Col>
                                {accrData[0].participation === "ABSENT" ? (
                                    <Fragment>
                                        <Col xs={12} className="mt-3">
                                            <TextForm
                                                value={accrData[0].partIdentNickname}
                                                onChangeValue={this.handleChangeTextFormValues}
                                                req
                                                labelTxt={t("myAccount.accreditation.form.nickname.label")}
                                                placeholderTxt={t("myAccount.accreditation.editNicknameIdent.placeholder")}
                                                name="partIdentNickname"
                                                id="partIdentNickname"
                                                subtext={t("myAccount.accreditation.form.nickname.subtext")}
                                                disabled={disabledForm}
                                            />
                                        </Col>
                                        {allowSelectCharacter && (
                                            <Col xs={12} className="mt-3">
                                                <ChooseCharacterInAccrForm
                                                    onSelectChar={this.handleChangeFormValues}
                                                    t={t}
                                                    onShowAlert={onShowAlert}
                                                    fetchURL={fetchURL}
                                                    disabled={disabledForm}
                                                    activeEvent={activeEvent}
                                                    onShowAddNewCharacterModal={(v) => this.setState({ showAddNewCharacterModal: v })}
                                                    downloadAgainCharList={downloadAgainCharList}
                                                    onDownloadAgainCharList={() => this.setState({ downloadAgainCharList: false })}
                                                />
                                            </Col>
                                        )}

                                        <Col xs={12} className="mt-3">
                                            <TextareaForm
                                                value={accrData[0].partCommentForm}
                                                onChangeValue={this.handleChangeTextFormValues}
                                                labelTxt={t("myAccount.accreditation.form.comment.label")}
                                                placeholderTxt={t("myAccount.accreditation.form.comment.placeholder")}
                                                name="partCommentForm"
                                                id="partCommentForm"
                                                disabled={disabledForm}
                                                subtext={t("myAccount.accreditation.form.comment.subtext")}
                                            />
                                        </Col>
                                        <Col xs={12} className="mt-3">
                                            <Checkbox
                                                label={t("myAccount.accreditation.form.regulations")}
                                                onChange={(event, data) => {
                                                    $(`#regulations`).removeClass("is-invalid");
                                                    this.handleChangeFormValues(data.checked, "regulations");
                                                }}
                                                id="regulations"
                                                checked={accrData[0].regulations ? true : false}
                                                disabled={disabledForm}
                                            />
                                            <Button variant="link" className="ml-2 align-baseline" onClick={() => this.setState({ showRegulationsModal: true })}>
                                                {t("myAccount.accreditation.form.regulationsBtn")}
                                            </Button>
                                        </Col>
                                        <Col xs={12} className="mt-1">
                                            <Checkbox
                                                label={t("myAccount.accreditation.form.legalAge")}
                                                onChange={(event, data) => {
                                                    $(`#legalAge`).removeClass("is-invalid");
                                                    this.handleChangeFormValues(data.checked, "legalAge");
                                                }}
                                                id="legalAge"
                                                checked={accrData[0].legalAge ? true : false}
                                                disabled={disabledForm}
                                            />
                                        </Col>
                                        <Col xs={12} className="mt-1">
                                            <Checkbox
                                                label={t("myAccount.accreditation.form.moneyTransferDeclaration")}
                                                onChange={(event, data) => {
                                                    $(`#moneyTransferDeclaration`).removeClass("is-invalid");
                                                    this.handleChangeFormValues(data.checked, "moneyTransferDeclaration");
                                                }}
                                                id="moneyTransferDeclaration"
                                                checked={accrData[0].moneyTransferDeclaration ? true : false}
                                                disabled={disabledForm}
                                            />
                                        </Col>
                                        <Col xs={12} className="mt-1">
                                            <Checkbox label={t("myAccount.accreditation.form.haveGoodFun")} onChange={() => this.setState({ changedValues: true })} disabled={disabledForm} />
                                        </Col>
                                        <Col xs={12} className="mt-3 text-center">
                                            <Alert variant="primary">
                                                <b>{t("myAccount.accreditation.form.submitAlert")}</b>
                                            </Alert>
                                            <Button className="mt-3 forms-btn" variant="primary" type="submit" disabled={!changedValues || disabledForm} size="lg">
                                                {disabledForm ? <FontAwesomeIcon icon={faSpinner} className="btn-spinner" /> : t("myAccount.formSubmitBtn")}
                                            </Button>
                                        </Col>
                                    </Fragment>
                                ) : (
                                    <Col xs={12} className="mt-3 text-center">
                                        <Button className="forms-btn" variant="primary" type="submit" disabled={!changedValues || disabledForm} size="lg">
                                            {disabledForm ? <FontAwesomeIcon icon={faSpinner} className="btn-spinner" /> : t("myAccount.formSubmitBtn")}
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                        </Form>
                    </Col>
                </Row>
                {showRegulationsModal && (
                    <RegulationsModal show={showRegulationsModal} t={t} onClose={() => this.setState({ showRegulationsModal: false })} onShowAlert={onShowAlert} fetchURL={fetchURL} />
                )}
                {showAddNewCharacterModal ? (
                    <AddNewCharacterModal
                        t={t}
                        show={showAddNewCharacterModal}
                        onShowAlert={onShowAlert}
                        fetchURL={fetchURL}
                        activeEvent={activeEvent}
                        charLink={`${myAccountLink}${t("url.link.characters")}`}
                        onClose={() => this.setState({ showAddNewCharacterModal: false })}
                        onAddedNewChar={() => {
                            console.log("added new char");
                            this.setState({ showAddNewCharacterModal: false, downloadAgainCharList: true });
                        }}
                    />
                ) : null}
            </Fragment>
        );
    }
}

export default withRouter(AccrForm);

import React, { Suspense } from "react";
import App from "./App";

export default function Main() {
    return (
        <Suspense fallback={<></>}>
            <App />
        </Suspense>
    );
}

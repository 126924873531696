import React, { Component, Fragment } from "react";
import $ from "jquery";
import { Col, Container, Row, Table } from "react-bootstrap";
import Footer from "../../other/Footer";
//import ProgramTable from "./ProgramTable";
import SmallBanner from "../../other/SmallBanner";

class Program extends Component {
    state = {
        selectedTab: this.props.t("program.table"),
    };
    componentDidMount() {
        this.props.onHideSpinner(200);

        if ($(window).outerHeight() > $("footer").outerHeight()) $("#programBox").css({ minHeight: `${$(window).outerHeight() - $("footer").outerHeight()}px` });
        else $("#programBox").css({ height: `${$(window).outerHeight()}px` });

        if ($(".loading-spoon").hasClass("show")) $("#programBox").delay(1300).fadeTo(400, 1);
        else $("#programBox").delay(300).fadeTo(400, 1);
    }

    render() {
        //const { selectedTab } = this.state;
        const { t, frontURL, onShowSpoonLoader } = this.props;
        return (
            <Fragment>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <SmallBanner
                            frontURL={frontURL}
                            bgBottom={`${frontURL}/images/small-banners/b1-bottom.webp`}
                            bgDesktop={`${frontURL}/images/small-banners/b3.webp`}
                            title={t("program.title")}
                            subtitle={t("program.subtitle")}
                        />
                    </Col>
                </Row>
                <div id="programBox" style={{ opacity: 0 }}>
                    <div id="program">
                        <Container className="mt-5">
                            {/* <Row className="no-gutters">
                                <Col className="text-center mt-5">
                                    <p className="h3 mb-4">{t("program.underConstruction.subtitle")}</p>
                                    <p className="h4 mb-3">{t("program.programForYear")}2022</p>
                                    <Image src={`${frontURL}/images/harmonogram.jpg`} alt="program-for-lyzkon-2022" className="img-fluid mb-5" />
                                </Col>
                            </Row> */}
                            <Row className="no-gutters">
                                <Col className="text-center mt-5">
                                    <Table responsive bordered>
                                        <tbody>
                                            <tr>
                                                <td className="bg"></td>
                                                <td className="bg">{t("program.wednesday")} 10.07</td>
                                                <td className="bg">{t("program.thursday")} 11.07</td>
                                                <td className="bg">{t("program.friday")} 12.07</td>
                                                <td className="bg">{t("program.saturday")} 13.07</td>
                                                <td className="bg">{t("program.sunday")} 14.07</td>
                                            </tr>
                                            <tr>
                                                <td>09:00</td>
                                                <td></td>
                                                <td></td>
                                                <td rowSpan={15} className="turq">
                                                    09:00 - 00:00 {t("program.larpName")} - {t("program.day")} 2
                                                </td>
                                                <td rowSpan={10} className="turq">
                                                    09:00 - 19:00 {t("program.larpName")} - {t("program.day")} 3
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>10:00</td>
                                                <td></td>
                                                <td rowSpan={2} className="cyan">
                                                    10:00-11:30 {t("program.larpAccr")} - {t("program.round2")}
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>11:00</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>12:00</td>
                                                <td className="blue">{t("program.start")}</td>
                                                <td className="larp">12:00-13:00 {t("program.workshopsMechanics")}</td>
                                                <td className="blue">{t("program.end")}</td>
                                            </tr>
                                            <tr>
                                                <td>13:00</td>
                                                <td></td>
                                                <td className="larp">13:00-14:00 {t("program.workshopsASG")}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>14:00</td>
                                                <td></td>
                                                <td rowSpan={10} className="turq">
                                                    14:00-00:00 {t("program.larpName")} - {t("program.day")} 1
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>15:00</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>16:00</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>17:00</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>18:00</td>
                                                <td rowSpan={4} className="cyan">
                                                    18:00-22:00 {t("program.larpAccr")} - {t("program.round1")}
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>19:00</td>
                                                <td rowSpan={3} className="lightBlue">
                                                    19:00-22:00 Prize Fighters
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>20:00</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>21:00</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>22:00</td>
                                                <td rowSpan={5} className="purple">
                                                    22:00-02:00 {t("program.intrParty")}
                                                </td>
                                                <td rowSpan={5} className="purple">
                                                    22:00-????? {t("program.endParty")}
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>23:00</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>00:00</td>
                                                <td rowSpan={3} className="green">
                                                    00:00-09:00 {t("program.nightBreak")}
                                                </td>
                                                <td rowSpan={3} className="green">
                                                    00:00-09:00 {t("program.nightBreak")}
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>01:00</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>02:00</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default Program;

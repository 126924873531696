import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import i18n from "../../../../i18n";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

class PreviousEditionsPlot extends Component {
    state = {
        plot: null,
        year: null,
        loading: true,
        alert: null,
    };

    getPrevEditionPlot = async (year) => {
        const { fetchURL, t, onShowAlert } = this.props;
        const passData = { year: year, lang: i18n.language };
        try {
            const response = await fetch(`${fetchURL}/previous-edition-plot`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) {
                        if (fetchedData.code === "prevEditionHasNoPlot") this.setState({ alert: fetchedData.code });
                        else onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                        this.setState({ loading: false });
                    } else this.setState({ plot: fetchedData.langs, loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        const { location, t } = this.props;
        const pathname = location.pathname;
        const year = pathname.replace(`/${t("url.link.previousEditions")}/${t("url.link.plot")}/`, "");
        this.setState({ year: year }, () => this.getPrevEditionPlot(year));
    }

    render() {
        const { plot, loading } = this.state;
        const { t } = this.props;

        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col xs={12} className="my-3">
                            <Link to={`/${t("url.link.previousEditions")}`}>
                                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                {t("previousEditions.backToSummary")}
                            </Link>
                        </Col>
                        <Col xs={12}>
                            {loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <Fragment>
                                    {!plot || plot.length === 0 ? (
                                        <div className="text-center mt-5">
                                            <p className="h3 opacity-4 my-5">{t("previousEditions.notExistingPlot")}</p>
                                            <Link to={`/${t("url.link.previousEditions")}`} className="btn btn-outline-primary mb-5">
                                                {t("previousEditions.backToSummary")}
                                            </Link>
                                        </div>
                                    ) : (
                                        <Fragment>
                                            {plot.map((e, i) => (
                                                <Fragment key={i}>
                                                    {e.lang_code === i18n.language && (
                                                        <div className="mb-5" style={{ fontSize: "120%", lineHeight: "1.5" }} dangerouslySetInnerHTML={{ __html: e.plot }}></div>
                                                    )}
                                                </Fragment>
                                            ))}
                                            <div className="text-center">
                                                <Link to={`/${t("url.link.previousEditions")}`} className="btn btn-outline-primary mb-5">
                                                    {t("previousEditions.backToSummary")}
                                                </Link>
                                            </div>
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default PreviousEditionsPlot;

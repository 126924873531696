import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, Component } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import ApplicationTable from "./ApplicationTable";
import EditConstrFormModal from "../../../modals/EditConstrFormModal";
import EditBarFormModal from "../../../modals/EditBarFormModal";
import EditAccrFormModal from "../../../modals/EditAccrFormModal";
import EditNpcFormModal from "../../../modals/EditNpcFormModal";

class ApplicationType extends Component {
    state = { formModal: null };
    render() {
        const { formModal } = this.state;
        const { eventApplication, t, appTypeName, selectedEvent, onShowAlert, fetchURL, activeEvent, skillsStart, toolsStart, volLink, onRefreshData } = this.props;

        return (
            <Fragment>
                <p className="h5 my-4 text-uppercase">{t(`myAccount.volunteering.${appTypeName}.title`)}</p>
                {eventApplication === "avalOptionsNotExisting" ? (
                    <Row>
                        <Col>
                            {selectedEvent.event_active === "Y" ? (
                                <Alert variant="warning">
                                    <FontAwesomeIcon icon={faExclamation} className="mr-2" />
                                    {t("warning.avalOptionsNotExisting")}
                                </Alert>
                            ) : (
                                <p className="text-muted text-center my-2">{t("myAccount.volunteering.optionWasNotFilled")}</p>
                            )}
                        </Col>
                    </Row>
                ) : eventApplication === "noFilledAvalOptions" ? (
                    <Row>
                        <Col>
                            <Alert variant="info">{t("myAccount.volunteering.noFilledAvalOptions")}</Alert>
                            {/* selectedEvent.event_active === "Y" && (
                        <Link to={`${volLink}/${t(`url.link.${appTypeName}Form`)}`} className="btn btn-primary">
                            {t("myAccount.volunteering.notFilledFormBtn")}
                        </Link>
                    ) */}
                        </Col>
                    </Row>
                ) : eventApplication === "noFormFilled" || !eventApplication ? (
                    <Row>
                        <Col>
                            <p className="text-muted text-center my-2">{t("myAccount.volunteering.optionWasNotFilled")}</p>

                            {selectedEvent.event_active === "Y" && selectedEvent.allow_users_applications === "Y" && (
                                <Button variant="outline-primary" className="mb-3" onClick={() => this.setState({ formModal: { type: appTypeName.toUpperCase(), option: "add" } })}>
                                    {t("myAccount.dashboard.fillAccrDataBtn")}
                                </Button>
                            )}
                        </Col>
                    </Row>
                ) : (
                    <ApplicationTable
                        appTypeData={eventApplication}
                        t={t}
                        onShowAlert={onShowAlert}
                        fetchURL={fetchURL}
                        activeEvent={activeEvent}
                        skillsStart={skillsStart}
                        toolsStart={toolsStart}
                        volLink={volLink}
                        onRefreshData={onRefreshData}
                        onEditFormModal={() => this.setState({ formModal: { type: eventApplication.part_app_type_code, option: "edit" } })}
                    />
                )}
                {formModal && (
                    <Fragment>
                        {formModal.type === "CONSTR" && (
                            <EditConstrFormModal
                                t={t}
                                show={true}
                                onClose={() => this.setState({ formModal: null })}
                                onShowAlert={onShowAlert}
                                fetchURL={fetchURL}
                                activeEvent={activeEvent}
                                skillsStart={skillsStart}
                                toolsStart={toolsStart}
                                onRefreshData={() => this.setState({ formModal: null }, () => onRefreshData())}
                                option={formModal.option}
                            />
                        )}

                        {formModal.type === "BAR" && (
                            <EditBarFormModal
                                t={t}
                                show={true}
                                onClose={() => this.setState({ formModal: null })}
                                onShowAlert={onShowAlert}
                                fetchURL={fetchURL}
                                activeEvent={activeEvent}
                                onRefreshData={() => this.setState({ formModal: null }, () => onRefreshData())}
                                option={formModal.option}
                            />
                        )}
                        {formModal.type === "ACCR" && (
                            <EditAccrFormModal
                                t={t}
                                show={true}
                                onClose={() => this.setState({ formModal: null })}
                                onShowAlert={onShowAlert}
                                fetchURL={fetchURL}
                                activeEvent={activeEvent}
                                onRefreshData={() => this.setState({ formModal: null }, () => onRefreshData())}
                                option={formModal.option}
                            />
                        )}
                        {formModal.type === "NPC" && (
                            <EditNpcFormModal
                                t={t}
                                show={true}
                                onClose={() => this.setState({ formModal: null })}
                                onShowAlert={onShowAlert}
                                fetchURL={fetchURL}
                                activeEvent={activeEvent}
                                onRefreshData={() => this.setState({ formModal: null }, () => onRefreshData())}
                                option={formModal.option}
                            />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default ApplicationType;

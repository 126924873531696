import React, { Fragment, Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import ChooseCharacterOnLarp from "../../../forms/dropdowns/ChooseCharacterOnLarp";

class ChooseCharacterInAccrForm extends Component {
    state = {
        selectedCharUniqueID: null,
        versionsDropdown: null,
    };

    getCharactersVersions = async () => {
        const { selectedCharUniqueID } = this.state;
        const { fetchURL, t, onShowAlert, onSelectChar } = this.props;
        try {
            const response = await fetch(`${fetchURL}/get-char-versions`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ charID: selectedCharUniqueID }),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        const versions = fetchedData.charsList;
                        if (versions.length === 1) {
                            onSelectChar(selectedCharUniqueID, "larpCharacter");
                            this.setState({ versionsDropdown: null });
                        } else {
                            let versionsDropdown = [];
                            versions.forEach((e, i) => {
                                versionsDropdown.push({
                                    key: i + 1,
                                    value: e.char_id,
                                    text: `${t("myAccount.characters.characterHistory.version")} ${versions.length - i} ${i === 0 ? t("myAccount.characters.chooseCharacterToPlay.newest") : ""}`,
                                });
                            });
                            this.setState({ versionsDropdown: versionsDropdown });
                        }
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    render() {
        const { versionsDropdown } = this.state;
        const { t, onSelectChar, onShowAlert, fetchURL, activeEvent, onShowAddNewCharacterModal, downloadAgainCharList, onDownloadAgainCharList } = this.props;
        return (
            <Fragment>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <ChooseCharacterOnLarp
                            subtext={t("myAccount.accreditation.form.character.subtext")}
                            onShowAlert={onShowAlert}
                            fetchURL={fetchURL}
                            onShowAddNewCharacterModal={onShowAddNewCharacterModal}
                            t={t}
                            onChangeValue={(value) =>
                                this.setState({ selectedCharUniqueID: value, changedValues: true }, () => {
                                    console.log(value);
                                    if (value === "new_character") onShowAddNewCharacterModal(true);
                                    else if (value === "no_character") onSelectChar(null, "larpCharacter");
                                    else this.getCharactersVersions();
                                })
                            }
                            activeEvent={activeEvent}
                            downloadAgainCharList={downloadAgainCharList}
                            onDownloadAgainCharList={onDownloadAgainCharList}
                            showAddNewCharacterOption
                        />
                    </Col>
                    {versionsDropdown && (
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>
                                    {t("myAccount.characters.chooseCharacterToPlay.chooseVersionLabel")} {<span className="required">*</span>}
                                </Form.Label>
                                <Dropdown
                                    placeholder={t("myAccount.characters.chooseCharacterToPlay.chooseVersionPlaceholder")}
                                    fluid
                                    selection
                                    options={versionsDropdown}
                                    onChange={(event, data) => {
                                        document.getElementById("chooseCharVersion").classList.remove("is-invalid");
                                        onSelectChar(data.value, "larpCharacter");
                                    }}
                                    id="chooseCharVersion"
                                />
                            </Form.Group>
                        </Col>
                    )}
                </Row>
            </Fragment>
        );
    }
}

export default ChooseCharacterInAccrForm;

import React, { Component, Fragment } from "react";
import $ from "jquery";
import { Row, Col, Form, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class LoginForm extends Component {
    state = {
        login: "",
        password: "",
    };

    validateEmail = (email) => {
        const { t } = this.props;
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            $("#login").addClass("danger");
            return t("error.invalidEmail");
        } else return "";
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { login, password } = this.state;
        const { onShowAlert, t } = this.props;

        onShowAlert("");
        let errorMsg = [];
        if (!login) {
            $("#login").addClass("is-invalid");
            errorMsg.push(t("error.emptyEmail"));
        } else {
            const validate = this.validateEmail(login);
            if (validate) {
                $("#login").addClass("is-invalid");
                errorMsg.push(validate);
            }
        }

        if (!password) {
            errorMsg.push(t("error.emptyPassword"));
            $("#password").addClass("is-invalid");
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            this.handleLogin();
        }
    };

    handleLogin = async () => {
        const { fetchURL, onShowAlert, onPassLoggedUserData, moduleName, t, onGetEventData } = this.props;
        onShowAlert({ variant: "info", message: t("info.logging"), fading: false });
        const passData = {
            loginData: {
                email: this.state.login,
                password: this.state.password,
                qrCode: null,
            },
            moduleName: moduleName,
        };
        try {
            const response = await fetch(`${fetchURL}/login`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.code === "inactiveProfile" || fetchedData.code === "resetPass") {
                        onShowAlert({
                            variant: "danger",
                            message: t(`error.${fetchedData.code}`),
                            contactBtn: {
                                mailAddress: "",
                                mailTitle: t(`mails.mailTitle.${fetchedData.code}`),
                                mailSender: "",
                            },
                        });
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    } else {
                        onGetEventData();
                        onPassLoggedUserData(fetchedData.userPrivilages, fetchedData.expDate);
                        if (this.props.reloadCheckLogin) this.props.onCheckIfLogged();
                        else this.props.history.push(`/${t("url.link.myAccount")}`);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    handleRedirect = (e, url) => {
        e.preventDefault();
        this.props.onShowSpoonLoader();
        //console.log(url);
        setTimeout(() => this.props.history.push(url), 700);
    };

    render() {
        const { login, password } = this.state;
        const { t } = this.props;
        return (
            <Fragment>
                <h1 className="h3 my-3 text-center">{t("login.title")}</h1>
                <Row className="no-gutters">
                    <Col lg={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1 }}>
                        <Form onSubmit={this.handleCheckValidation} className="my-3" noValidate>
                            <Form.Group>
                                <Form.Label>{t("login.loginInput.label")}</Form.Label>
                                <Form.Control
                                    placeholder={t("login.loginInput.placeholder")}
                                    type="email"
                                    name="login"
                                    id="login"
                                    value={login}
                                    onChange={(e) => {
                                        $("#login").removeClass("is-invalid");
                                        this.setState({ [e.target.name]: e.target.value });
                                    }}
                                    autoComplete="username"
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{t("login.passInput.label")}</Form.Label>
                                <Form.Control
                                    placeholder={t("login.passInput.placeholder")}
                                    type="password"
                                    name="password"
                                    id="password"
                                    value={password}
                                    onChange={(e) => {
                                        $("#password").removeClass("is-invalid");
                                        this.setState({ [e.target.name]: e.target.value });
                                    }}
                                    autoComplete="current-password"
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group className="text-center">
                                <Button type="submit" variant="primary" size="lg" className="my-3">
                                    {t("login.loginBtn")}
                                </Button>
                            </Form.Group>
                        </Form>
                        <div className="text-center">
                            <Button className="my-3" variant="link" onClick={(e) => this.handleRedirect(e, `/${t("url.link.forgotPassword")}`)}>
                                {t("url.text.forgotPassword")}
                            </Button>
                        </div>
                        <div className="text-center">
                            <Button variant="link" className="mb-3" onClick={(e) => this.handleRedirect(e, t("url.link.sendActivationLink"))}>
                                {t("url.text.sendActivationLink")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withRouter(LoginForm);

import React from "react";
import { Button, Col, Row } from "react-bootstrap";

const Register = (props) => {
    const { t, frontURL, onChangeRoute } = props;
    return (
        <Row id="register" className="no-gutters">
            <div
                className="bg-img"
                style={{
                    backgroundImage: `url(${frontURL}/images/DSC_7906.webp)`,
                }}
                id="registerImg"
            ></div>
            <Col xs={12}>
                <p className="p-title" id="registerTitle">
                    {t("home.p4.title")}
                </p>
            </Col>
            <Col xs={{ span: 12, offset: 0 }} xl={{ span: 8, offset: 2 }}>
                <div className="p-text" style={{ opacity: 0 }} id="registerText">
                    <p dangerouslySetInnerHTML={{ __html: t("home.p4.text1") }}></p>
                    <p dangerouslySetInnerHTML={{ __html: t("home.p4.text2Alt") }}></p>
                    <div className="text-center">
                        <Button variant="primary" size="lg" onClick={(e) => onChangeRoute(e, `/${t("url.link.myAccount")}/${t("url.link.accreditation")}/${t("url.link.accrForm")}`)}>
                            {t("home.p4.btnFillForm")}
                        </Button>
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: t("home.p4.text3") }}></p>
                </div>
            </Col>
            <div id="registerBg"></div>
        </Row>
    );
};

export default Register;

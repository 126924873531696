import React, { Component, Fragment } from "react";
import { Row, Col, Table, Button, Nav, OverlayTrigger, Tooltip, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import NoPermission from "../../../other/NoPermission";
import { faCheckCircle, faCopy, faEdit, faExclamation, faInfoCircle, faPlusSquare, faQuestionCircle, faRetweet, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
//import EditParticipation from "../../../modals/EditParticipation";
import EditNicknameIdent from "../../../modals/EditNicknameIdent";
import EditPartComment from "../../../modals/EditPartComment";
import EditMoneyTransferDeclaration from "../../../modals/EditMoneyTransferDeclaration";
import EditAgreements from "../../../modals/EditAgreements";
import $ from "jquery";
import ChooseNewCharacter from "../../../modals/ChooseNewCharacter";
import EditChosenCharacter from "../../../modals/EditChosenCharacter";

class AccreditationMain extends Component {
    state = {
        loading: true,
        noPermission: false,
        accrData: null,
        showModal: {
            modalName: null,
            partID: null,
        },
        activeTabIndex: 0,
    };

    getAccrData = async () => {
        const { fetchURL, t, onShowAlert } = this.props;
        const passData = {
            eventsList: this.props.eventsList,
        };
        try {
            const response = await fetch(`${fetchURL}/accreditation-data`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onNotLogged();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status === "noData") this.setState({ loading: false });
                    else {
                        const accrData = fetchedData.accrData,
                            eventsList = fetchedData.eventsList;

                        eventsList.forEach((e, i) => {
                            if (e.event_active === "Y") {
                                this.setState({ activeTabIndex: i });
                            }
                        });

                        this.setState({
                            accrData: accrData.length > 0 ? accrData : "noParticipationInAnyEvent",
                            eventsList: eventsList,
                            loading: false,
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleCloseAndRefresh = () => {
        this.setState({ showModal: { modalName: null, partID: null }, loading: true }, () => this.getAccrData(this.props.eventsList));
    };

    handleRedirect = (e, url) => {
        e.preventDefault();
        this.props.history.push(url);
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getAccrData(this.props.eventsList);
    }

    render() {
        const { loading, noPermission, accrData, activeTabIndex, showModal, eventsList } = this.state;
        const { t, fetchURL, onShowAlert, activeEvent, myAccountLink, onRefreshNotification, accrLink } = this.props;

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : (
                    <Fragment>
                        <Row className="no-gutters">
                            <Col className="text-center">
                                <p className="h3 my-4 text-uppercase">{t("myAccount.accreditation.title")}</p>
                            </Col>
                        </Row>
                        {loading ? (
                            <LoadingProgressBar t={t} />
                        ) : (
                            <div className="my-account-show">
                                <Row className="no-gutters">
                                    <Col xs={12} className="text-center mt-4">
                                        {accrData === "noParticipationInAnyEvent" ? (
                                            <Alert variant="info">
                                                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                                {t("myAccount.accreditation.noParticipationInAnyEvent")}
                                            </Alert>
                                        ) : (
                                            <Fragment>
                                                <Nav
                                                    variant="pills"
                                                    defaultActiveKey={activeEvent && activeEvent !== "noActiveEvent" ? activeEvent.event_code : eventsList[0].event_code}
                                                    id="accrPills"
                                                    className="convention-pills"
                                                >
                                                    {eventsList.map((e, i) => (
                                                        <Fragment key={i}>
                                                            {accrData[i] !== "noParticipation" && (
                                                                <Nav.Item>
                                                                    <Nav.Link
                                                                        eventKey={e.event_code}
                                                                        onClick={() => {
                                                                            $(".account-data-container").fadeOut(200);
                                                                            setTimeout(() => {
                                                                                this.setState({ activeTabIndex: i }, () => {
                                                                                    $(".account-data-container").fadeIn(200);
                                                                                });
                                                                            }, 190);
                                                                        }}
                                                                    >
                                                                        {e.event_name}
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            )}
                                                        </Fragment>
                                                    ))}
                                                </Nav>
                                                <div className="account-data-table">
                                                    {typeof accrData[activeTabIndex] === "string" ? (
                                                        <div className="account-data-container">
                                                            <Alert variant="info" className="mb-0">
                                                                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                                                {t(`myAccount.accreditation.${accrData[activeTabIndex]}`)}
                                                            </Alert>
                                                            {accrData[activeTabIndex] === "notFilledAccrForm" && (
                                                                <Link to={`${this.props.accrLink}/${t("url.link.accrForm")}`} className="btn btn-primary mt-2">
                                                                    {t("myAccount.accreditation.notFilledFormBtn")}
                                                                </Link>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="account-data-container">
                                                            <Table responsive>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>{t("myAccount.accreditation.conventionArrival.title")}</td>
                                                                        <td className="editable-data">
                                                                            {accrData[activeTabIndex].data.part_stat_code === "ABORTED" ? (
                                                                                <Fragment>
                                                                                    <span className="text-capitalize">{t("info.no")}</span>
                                                                                </Fragment>
                                                                            ) : (
                                                                                <Fragment>
                                                                                    <span className="text-capitalize">{t("info.yes")}</span>
                                                                                    <OverlayTrigger
                                                                                        placement="right"
                                                                                        overlay={(props) => <Tooltip {...props}>{t("myAccount.accreditation.conventionArrival.arrivalText")}</Tooltip>}
                                                                                    >
                                                                                        <Button variant="link" size="sm">
                                                                                            <FontAwesomeIcon icon={faCheckCircle} className="ml-2 text-success" />
                                                                                        </Button>
                                                                                    </OverlayTrigger>
                                                                                </Fragment>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {accrData[activeTabIndex].allowEdit && (
                                                                                <Fragment>
                                                                                    {accrData[activeTabIndex].data.part_stat_code === "ABORTED" ||
                                                                                    accrData[activeTabIndex].data.part_stat_code === "ABSENT" ? (
                                                                                        <Button
                                                                                            className="align-baseline p-2 action-btn"
                                                                                            variant="outline-primary"
                                                                                            size="sm"
                                                                                            onClick={(e) => this.handleRedirect(e, `${accrLink}/${t("url.link.editParticipation")}`)}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                        </Button>
                                                                                    ) : (
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            overlay={(props) => <Tooltip {...props}>{t("myAccount.characters.notAbsent")}</Tooltip>}
                                                                                        >
                                                                                            <span className="d-inline-block">
                                                                                                <Button className="align-baseline p-2 action-btn" variant="outline-primary" size="sm" disabled>
                                                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                                                </Button>
                                                                                            </span>
                                                                                        </OverlayTrigger>
                                                                                    )}
                                                                                </Fragment>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    {/*  <tr>
                                                                <td>{t("myAccount.accreditation.partStatus")}</td>
                                                                <td className="">{t(`partStatName.${accrData[activeTabIndex].data.part_stat_code}`)}</td>
                                                                <td></td>
                                                            </tr> */}
                                                                    <tr>
                                                                        <td>{t("myAccount.accreditation.identNumber")}</td>
                                                                        <td className="editable-data">
                                                                            {accrData[activeTabIndex].data.part_ident_number ? (
                                                                                <input type="text" id="partIdent" value={accrData[activeTabIndex].data.part_ident_number} readOnly />
                                                                            ) : (
                                                                                <span className="opacity-4">{t("info.none")}</span>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {accrData[activeTabIndex].allowEdit && (
                                                                                <Button
                                                                                    className="align-baseline p-2 action-btn"
                                                                                    variant="outline-primary"
                                                                                    size="sm"
                                                                                    onClick={() => {
                                                                                        var copyText = document.getElementById("partIdent");
                                                                                        copyText.select();
                                                                                        copyText.setSelectionRange(0, 99999);
                                                                                        navigator.clipboard.writeText(copyText.value);
                                                                                        this.props.onShowAlert({ variant: "info", message: t("info.identCopied"), fading: 1500 });
                                                                                    }}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faCopy} />
                                                                                </Button>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    {accrData[activeTabIndex].data.part_stat_code !== "ABORTED" && (
                                                                        <Fragment>
                                                                            <tr>
                                                                                <td>
                                                                                    {t("myAccount.accreditation.nickname")}
                                                                                    {!accrData[activeTabIndex].data.part_ident_nickname &&
                                                                                        accrData[activeTabIndex].data.part_stat_code !== "ABORTED" &&
                                                                                        activeEvent &&
                                                                                        activeEvent !== "noActiveEvent" &&
                                                                                        accrData[activeTabIndex].data.event_code === activeEvent.event_code &&
                                                                                        eventsList[activeTabIndex].allow_users_apply === "Y" &&
                                                                                        eventsList[activeTabIndex].allow_users_apply_after_deadline && (
                                                                                            <OverlayTrigger
                                                                                                placement="right"
                                                                                                overlay={(props) => <Tooltip {...props}>{t("myAccount.accreditation.noIdentNickname")}</Tooltip>}
                                                                                            >
                                                                                                <Button variant="link" size="sm" className="align-baseline">
                                                                                                    <span className="badge badge-pill badge-danger ml-2 align-text-bottom">
                                                                                                        <FontAwesomeIcon icon={faExclamation} />
                                                                                                    </span>
                                                                                                </Button>
                                                                                            </OverlayTrigger>
                                                                                        )}
                                                                                </td>
                                                                                <td className="editable-data">
                                                                                    {accrData[activeTabIndex].data.part_ident_nickname ? (
                                                                                        accrData[activeTabIndex].data.part_ident_nickname
                                                                                    ) : (
                                                                                        <span className="opacity-4">{t("info.none")}</span>
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    {accrData[activeTabIndex].allowEdit && (
                                                                                        <Button
                                                                                            variant="outline-primary"
                                                                                            size="sm"
                                                                                            className="align-baseline p-2 action-btn"
                                                                                            onClick={() =>
                                                                                                this.setState({
                                                                                                    showModal: {
                                                                                                        modalName: "nickname",
                                                                                                        partID: accrData[activeTabIndex].data.part_id,
                                                                                                    },
                                                                                                })
                                                                                            }
                                                                                        >
                                                                                            <FontAwesomeIcon icon={accrData[activeTabIndex].data.part_ident_nickname ? faEdit : faPlusSquare} />
                                                                                        </Button>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    {t("myAccount.accreditation.chosenCharacter")}
                                                                                    {accrData[activeTabIndex].selectedChar && accrData[activeTabIndex].allowEdit && (
                                                                                        <Fragment>
                                                                                            {accrData[activeTabIndex].selectedChar.char_stat_code === "REJECTED" && (
                                                                                                <OverlayTrigger
                                                                                                    placement="right"
                                                                                                    overlay={(props) => <Tooltip {...props}>{t("myAccount.accreditation.rejectedCharacter")}</Tooltip>}
                                                                                                >
                                                                                                    <Button variant="link" size="sm" className="align-baseline">
                                                                                                        <span className="badge badge-pill badge-danger ml-2 align-text-bottom">
                                                                                                            <FontAwesomeIcon icon={faExclamation} />
                                                                                                        </span>
                                                                                                    </Button>
                                                                                                </OverlayTrigger>
                                                                                            )}
                                                                                            {accrData[activeTabIndex].selectedChar.char_stat_code === "INPROGRESS" && (
                                                                                                <OverlayTrigger
                                                                                                    placement="right"
                                                                                                    overlay={(props) => (
                                                                                                        <Tooltip {...props}>{t("myAccount.accreditation.inProgressCharacter")}</Tooltip>
                                                                                                    )}
                                                                                                >
                                                                                                    <Button variant="link" size="sm" className="align-baseline">
                                                                                                        <span className="badge badge-pill badge-danger ml-2 align-text-bottom">
                                                                                                            <FontAwesomeIcon icon={faExclamation} />
                                                                                                        </span>
                                                                                                    </Button>
                                                                                                </OverlayTrigger>
                                                                                            )}
                                                                                        </Fragment>
                                                                                    )}
                                                                                </td>
                                                                                <td className="editable-data">
                                                                                    {accrData[activeTabIndex].selectedChar ? (
                                                                                        <>
                                                                                            {accrData[activeTabIndex].selectedChar.char_name} -{" "}
                                                                                            {t(`myAccount.characters.selectedCharStat.${accrData[activeTabIndex].selectedChar.char_stat_code}`)}
                                                                                            {accrData[activeTabIndex].selectedChar.comment && (
                                                                                                <OverlayTrigger
                                                                                                    placement="right"
                                                                                                    overlay={(props) => (
                                                                                                        <Tooltip {...props}>
                                                                                                            {
                                                                                                                <p>
                                                                                                                    {t("myAccount.characters.characterComment")}
                                                                                                                    <br /> <br />
                                                                                                                    <i>{accrData[activeTabIndex].selectedChar.comment}</i>
                                                                                                                </p>
                                                                                                            }
                                                                                                        </Tooltip>
                                                                                                    )}
                                                                                                >
                                                                                                    <Button variant="link" size="sm" className="align-baseline">
                                                                                                        <FontAwesomeIcon icon={faQuestionCircle} className="ml-2 text-light" />
                                                                                                    </Button>
                                                                                                </OverlayTrigger>
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <span className="opacity-4">{t("info.none")}</span>
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    {accrData[activeTabIndex].allowEdit && (
                                                                                        <>
                                                                                            {eventsList[activeTabIndex].allow_users_characters === "Y" ? (
                                                                                                <>
                                                                                                    {accrData[activeTabIndex].selectedChar ? (
                                                                                                        <>
                                                                                                            {(accrData[activeTabIndex].selectedChar.char_stat_code === "REJECTED" ||
                                                                                                                accrData[activeTabIndex].selectedChar.char_stat_code === "PENDING") && (
                                                                                                                <OverlayTrigger
                                                                                                                    placement="top"
                                                                                                                    overlay={(props) => (
                                                                                                                        <Tooltip {...props}>{t("myAccount.characters.changeCharacter")}</Tooltip>
                                                                                                                    )}
                                                                                                                >
                                                                                                                    <Button
                                                                                                                        variant="outline-primary"
                                                                                                                        size="sm"
                                                                                                                        className="align-baseline p-2 action-btn"
                                                                                                                        onClick={() =>
                                                                                                                            this.setState({
                                                                                                                                showModal: {
                                                                                                                                    modalName: "chooseAnotherChar",
                                                                                                                                    partID: accrData[activeTabIndex].data.part_id,
                                                                                                                                    charComment: accrData[activeTabIndex].selectedChar.comment,
                                                                                                                                    fetchWithoutChar:
                                                                                                                                        accrData[activeTabIndex].selectedChar.char_unique_id,
                                                                                                                                },
                                                                                                                            })
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <FontAwesomeIcon icon={faRetweet} />
                                                                                                                    </Button>
                                                                                                                </OverlayTrigger>
                                                                                                            )}
                                                                                                            {accrData[activeTabIndex].selectedChar.char_stat_code !== "REJECTED" &&
                                                                                                                accrData[activeTabIndex].selectedChar.char_stat_code !== "ACCEPTED" && (
                                                                                                                    <OverlayTrigger
                                                                                                                        placement="top"
                                                                                                                        overlay={(props) => (
                                                                                                                            <Tooltip {...props}>{t("myAccount.characters.editCharacter")}</Tooltip>
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <Button
                                                                                                                            variant="outline-primary"
                                                                                                                            size="sm"
                                                                                                                            className="align-baseline p-2 action-btn ml-2"
                                                                                                                            onClick={() =>
                                                                                                                                this.setState({
                                                                                                                                    showModal: {
                                                                                                                                        modalName: "editChar",
                                                                                                                                        partID: accrData[activeTabIndex].data.part_id,
                                                                                                                                        charID: accrData[activeTabIndex].selectedChar.char_id,
                                                                                                                                        charComment: accrData[activeTabIndex].selectedChar.comment,
                                                                                                                                    },
                                                                                                                                })
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                                        </Button>
                                                                                                                    </OverlayTrigger>
                                                                                                                )}
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <Button
                                                                                                            variant="outline-primary"
                                                                                                            size="sm"
                                                                                                            className="align-baseline p-2 action-btn"
                                                                                                            onClick={() =>
                                                                                                                this.setState({
                                                                                                                    showModal: {
                                                                                                                        modalName: "chooseNewChar",
                                                                                                                        partID: accrData[activeTabIndex].data.part_id,
                                                                                                                        fetchWithoutChar: false,
                                                                                                                    },
                                                                                                                })
                                                                                                            }
                                                                                                        >
                                                                                                            <FontAwesomeIcon icon={faPlusSquare} />
                                                                                                        </Button>
                                                                                                    )}
                                                                                                </>
                                                                                            ) : (
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={(props) => <Tooltip {...props}>{t("myAccount.characters.optionNotAvailable")}</Tooltip>}
                                                                                                >
                                                                                                    <span className="d-inline-block">
                                                                                                        <Button
                                                                                                            variant="outline-primary"
                                                                                                            size="sm"
                                                                                                            className="align-baseline p-2 action-btn"
                                                                                                            disabled
                                                                                                            style={{ pointerEvents: "none" }}
                                                                                                        >
                                                                                                            <FontAwesomeIcon icon={accrData[activeTabIndex].selectedChar ? faEdit : faPlusSquare} />
                                                                                                        </Button>
                                                                                                    </span>
                                                                                                </OverlayTrigger>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                            {/*
                                                                    <tr>
                                                                        <td>{t("myAccount.accreditation.group")}</td>
                                                                        <td className="">
                                                                            {accrData[activeTabIndex].data.part_group_name ? (
                                                                                <Fragment>
                                                                                    {accrData[activeTabIndex].data.part_group_name}
                                                                                    <OverlayTrigger
                                                                                        placement="right"
                                                                                        overlay={(props) => (
                                                                                            <Tooltip {...props}>
                                                                                                {accrData[activeTabIndex].data.part_affiliation_verified === "Y"
                                                                                                    ? t("myAccount.accreditation.affilationVerified")
                                                                                                    : t("myAccount.accreditation.affilationNotVerified")}
                                                                                            </Tooltip>
                                                                                        )}
                                                                                    >
                                                                                        <Button variant="link" className="align-baseline">
                                                                                            {accrData[activeTabIndex].data.part_affiliation_verified === "Y" ? (
                                                                                                <FontAwesomeIcon icon={faCheckCircle} className="ml-1 text-success" />
                                                                                            ) : (
                                                                                                <FontAwesomeIcon icon={faTimesCircle} className="ml-1 text-danger" />
                                                                                            )}
                                                                                        </Button>
                                                                                    </OverlayTrigger>
                                                                                </Fragment>
                                                                            ) : (
                                                                                <span className="opacity-4 text-lowercase">{t("info.none")}</span>
                                                                            )}
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                     <tr>
                                                                        <td>{t("myAccount.accreditation.assignedLabels")}</td>
                                                                        <td className="">
                                                                            {accrData[activeTabIndex].data.part_labels_list ? (
                                                                                accrData[activeTabIndex].data.part_labels_list
                                                                            ) : (
                                                                                <span className="opacity-4 text-lowercase">{t("info.none")}</span>
                                                                            )}
                                                                        </td>
                                                                        <td></td>
                                                                    </tr> */}

                                                                            <tr>
                                                                                <td>{t("myAccount.accreditation.accrPriceWithDiscount")}</td>
                                                                                <td className="">
                                                                                    {accrData[activeTabIndex].data.part_verified_amount ? accrData[activeTabIndex].data.part_verified_amount : 0}{" "}
                                                                                    {t("pln")}{" "}
                                                                                    <span className="account-data-table-discount">
                                                                                        (
                                                                                        {accrData[activeTabIndex].data.part_verified_discount
                                                                                            ? accrData[activeTabIndex].data.part_verified_discount
                                                                                            : 0}
                                                                                        %)
                                                                                    </span>
                                                                                    <OverlayTrigger
                                                                                        placement="right"
                                                                                        overlay={(props) => <Tooltip {...props}>{t("myAccount.accreditation.discountText")}</Tooltip>}
                                                                                    >
                                                                                        <Button variant="link" size="sm" className="align-baseline">
                                                                                            <FontAwesomeIcon icon={faQuestionCircle} className="ml-2 text-light" />
                                                                                        </Button>
                                                                                    </OverlayTrigger>
                                                                                </td>
                                                                                <td></td>
                                                                            </tr>
                                                                            {accrData[activeTabIndex].data.part_verified_amount > 0 && (
                                                                                <tr>
                                                                                    <td>
                                                                                        {t("myAccount.accreditation.moneyTransferDeclaration")}
                                                                                        {accrData[activeTabIndex].data.part_declare_money_transfer === "N" &&
                                                                                            accrData[activeTabIndex].data.part_verified_discount < 100 &&
                                                                                            activeEvent &&
                                                                                            activeEvent !== "noActiveEvent" &&
                                                                                            accrData[activeTabIndex].data.event_code === activeEvent.event_code &&
                                                                                            eventsList[activeTabIndex].allow_users_apply_after_deadline &&
                                                                                            accrData[activeTabIndex].data.part_stat_code === "ABSENT" && (
                                                                                                <OverlayTrigger
                                                                                                    placement="right"
                                                                                                    overlay={(props) => (
                                                                                                        <Tooltip {...props}>{t("myAccount.accreditation.noMoneyTransferTooltip")}</Tooltip>
                                                                                                    )}
                                                                                                >
                                                                                                    <Button variant="link" size="sm">
                                                                                                        <span className="badge badge-pill badge-danger ml-2 align-text-bottom">
                                                                                                            <FontAwesomeIcon icon={faExclamation} />
                                                                                                        </span>
                                                                                                    </Button>
                                                                                                </OverlayTrigger>
                                                                                            )}
                                                                                    </td>
                                                                                    <td className="editable-data">
                                                                                        {accrData[activeTabIndex].data.part_declare_money_transfer === "Y" ? (
                                                                                            <span className="text-capitalize">
                                                                                                {t("info.yes")}: {accrData[activeTabIndex].data.part_declare_money_transfer_date}
                                                                                            </span>
                                                                                        ) : (
                                                                                            <span className="text-capitalize">{t("info.no")}</span>
                                                                                        )}

                                                                                        {accrData[activeTabIndex].data.part_verified_amount &&
                                                                                        accrData[activeTabIndex].data.part_verified_amount !== 0 ? (
                                                                                            accrData[activeTabIndex].data.payment_amount_accr >= accrData[activeTabIndex].data.part_verified_amount ? (
                                                                                                <OverlayTrigger
                                                                                                    placement="right"
                                                                                                    overlay={(props) => <Tooltip {...props}>{t("myAccount.accreditation.moneyTransferDone")}</Tooltip>}
                                                                                                >
                                                                                                    <Button variant="link" size="sm" className="align-baseline">
                                                                                                        <FontAwesomeIcon icon={faCheckCircle} className="ml-2 text-success" />
                                                                                                    </Button>
                                                                                                </OverlayTrigger>
                                                                                            ) : (
                                                                                                <OverlayTrigger
                                                                                                    placement="right"
                                                                                                    overlay={(props) => <Tooltip {...props}>{t("myAccount.accreditation.noMoneyTransfer")}</Tooltip>}
                                                                                                >
                                                                                                    <Button variant="link" size="sm" className="align-baseline">
                                                                                                        <FontAwesomeIcon icon={faTimesCircle} className="ml-2 text-danger" />
                                                                                                    </Button>
                                                                                                </OverlayTrigger>
                                                                                            )
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        {accrData[activeTabIndex].allowEdit && (
                                                                                            <Fragment>
                                                                                                {accrData[activeTabIndex].data.part_declare_money_transfer !== "Y" &&
                                                                                                    accrData[activeTabIndex].data.part_verified_amount > 0 && (
                                                                                                        <Button
                                                                                                            className="align-baseline p-2 action-btn"
                                                                                                            variant="outline-primary"
                                                                                                            size="sm"
                                                                                                            onClick={() =>
                                                                                                                this.setState({
                                                                                                                    showModal: {
                                                                                                                        modalName: "moneyTransferDeclaration",
                                                                                                                        partID: accrData[activeTabIndex].data.part_id,
                                                                                                                    },
                                                                                                                })
                                                                                                            }
                                                                                                        >
                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                        </Button>
                                                                                                    )}
                                                                                            </Fragment>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            )}

                                                                            <tr>
                                                                                <td>
                                                                                    {t("myAccount.accreditation.agreements")}
                                                                                    {(accrData[activeTabIndex].data.part_declar_tac_agreement !== "Y" ||
                                                                                        accrData[activeTabIndex].data.part_declar_legal_age !== "Y") &&
                                                                                        activeEvent &&
                                                                                        activeEvent !== "noActiveEvent" &&
                                                                                        accrData[activeTabIndex].data.event_code === activeEvent.event_code &&
                                                                                        eventsList[activeTabIndex].allow_users_apply_after_deadline &&
                                                                                        accrData[activeTabIndex].data.part_stat_code === "ABSENT" && (
                                                                                            <OverlayTrigger
                                                                                                placement="right"
                                                                                                overlay={(props) => <Tooltip {...props}>{t("myAccount.accreditation.noAgreements")}</Tooltip>}
                                                                                            >
                                                                                                <Button variant="link" size="sm" className="align-baseline">
                                                                                                    <span className="badge badge-pill badge-danger ml-2 align-text-bottom">
                                                                                                        <FontAwesomeIcon icon={faExclamation} />
                                                                                                    </span>
                                                                                                </Button>
                                                                                            </OverlayTrigger>
                                                                                        )}
                                                                                </td>
                                                                                <td className="editable-data">
                                                                                    {accrData[activeTabIndex].data.part_declar_tac_agreement === "Y" &&
                                                                                    accrData[activeTabIndex].data.part_declar_legal_age === "Y" ? (
                                                                                        t("allAccepted")
                                                                                    ) : (
                                                                                        <>
                                                                                            <p className="mb-2">
                                                                                                {t("myAccount.accreditation.legalAge")}:{" "}
                                                                                                {accrData[activeTabIndex].data.part_declar_legal_age === "Y" ? (
                                                                                                    <>
                                                                                                        {t("info.yes")}
                                                                                                        <FontAwesomeIcon icon={faCheckCircle} className="ml-2 text-success" />
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {t("info.no")}
                                                                                                        <FontAwesomeIcon icon={faTimesCircle} className="ml-2 text-danger" />
                                                                                                    </>
                                                                                                )}
                                                                                            </p>
                                                                                            <p className="mb-0">
                                                                                                {t("myAccount.accreditation.regulations")}:{" "}
                                                                                                {accrData[activeTabIndex].data.part_declar_tac_agreement === "Y" ? (
                                                                                                    <>
                                                                                                        {t("info.yes")}
                                                                                                        <FontAwesomeIcon icon={faCheckCircle} className="ml-2 text-success" />
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {t("info.no")}
                                                                                                        <FontAwesomeIcon icon={faTimesCircle} className="ml-2 text-danger" />
                                                                                                    </>
                                                                                                )}
                                                                                            </p>
                                                                                        </>
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    {(accrData[activeTabIndex].data.part_declar_tac_agreement !== "Y" ||
                                                                                        accrData[activeTabIndex].data.part_declar_legal_age !== "Y") &&
                                                                                        accrData[activeTabIndex].allowEdit && (
                                                                                            <Button
                                                                                                className="align-baseline p-2 action-btn"
                                                                                                variant="outline-primary"
                                                                                                size="sm"
                                                                                                onClick={() =>
                                                                                                    this.setState({
                                                                                                        showModal: {
                                                                                                            modalName: "agreements",
                                                                                                            partID: accrData[activeTabIndex].data.part_id,
                                                                                                        },
                                                                                                    })
                                                                                                }
                                                                                            >
                                                                                                <FontAwesomeIcon icon={faEdit} />
                                                                                            </Button>
                                                                                        )}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>{t("myAccount.accreditation.comments")}</td>
                                                                                <td className="editable-data">
                                                                                    {accrData[activeTabIndex].data.part_comment_form ? (
                                                                                        accrData[activeTabIndex].data.part_comment_form
                                                                                    ) : (
                                                                                        <span className="opacity-4">{t("info.none")}</span>
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    {accrData[activeTabIndex].allowEdit && (
                                                                                        <Button
                                                                                            variant="outline-primary"
                                                                                            size="sm"
                                                                                            className="align-baseline p-2 action-btn"
                                                                                            onClick={() =>
                                                                                                this.setState({
                                                                                                    showModal: {
                                                                                                        modalName: "comment",
                                                                                                        partID: accrData[activeTabIndex].data.part_id,
                                                                                                    },
                                                                                                })
                                                                                            }
                                                                                        >
                                                                                            <FontAwesomeIcon icon={accrData[activeTabIndex].data.part_comment_form ? faEdit : faPlusSquare} />
                                                                                        </Button>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        </Fragment>
                                                                    )}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    )}
                                                </div>
                                            </Fragment>
                                        )}
                                    </Col>
                                </Row>
                                {/* showModal.modalName === "editParticipation" && (
                                    <EditParticipation
                                        fetchURL={fetchURL}
                                        t={t}
                                        onShowAlert={onShowAlert}
                                        show={showModal.modalName === "editParticipation" ? true : false}
                                        onClose={() => this.setState({ showModal: { modalName: null, partID: null } })}
                                        accrData={accrData[activeTabIndex].data}
                                        onCloseAndRefresh={this.handleCloseAndRefresh}
                                        activeEvent={activeEvent}
                                        onRefreshNotification={onRefreshNotification}
                                    />
                                ) */}
                                {showModal.modalName === "nickname" && (
                                    <EditNicknameIdent
                                        fetchURL={fetchURL}
                                        t={t}
                                        onShowAlert={onShowAlert}
                                        show={showModal.modalName === "nickname" ? true : false}
                                        onClose={() => this.setState({ showModal: { modalName: null, partID: null } })}
                                        accrData={[accrData[activeTabIndex].data]}
                                        onCloseAndRefresh={this.handleCloseAndRefresh}
                                        activeEvent={activeEvent}
                                    />
                                )}
                                {showModal.modalName === "comment" && (
                                    <EditPartComment
                                        fetchURL={fetchURL}
                                        t={t}
                                        onShowAlert={onShowAlert}
                                        show={showModal.modalName === "comment" ? true : false}
                                        onClose={() => this.setState({ showModal: { modalName: null, partID: null } })}
                                        accrData={accrData[activeTabIndex].data}
                                        onCloseAndRefresh={this.handleCloseAndRefresh}
                                        activeEvent={activeEvent}
                                    />
                                )}
                                {showModal.modalName === "moneyTransferDeclaration" && (
                                    <EditMoneyTransferDeclaration
                                        fetchURL={fetchURL}
                                        t={t}
                                        onShowAlert={onShowAlert}
                                        show={showModal.modalName === "moneyTransferDeclaration" ? true : false}
                                        onClose={() => this.setState({ showModal: { modalName: null, partID: null } })}
                                        accrData={accrData[activeTabIndex].data}
                                        onCloseAndRefresh={this.handleCloseAndRefresh}
                                        activeEvent={activeEvent}
                                        onRefreshNotification={onRefreshNotification}
                                    />
                                )}
                                {showModal.modalName === "agreements" && (
                                    <EditAgreements
                                        fetchURL={fetchURL}
                                        t={t}
                                        onShowAlert={onShowAlert}
                                        show={showModal.modalName === "agreements" ? true : false}
                                        onClose={() => this.setState({ showModal: { modalName: null, partID: null } })}
                                        accrData={[accrData[activeTabIndex].data]}
                                        onCloseAndRefresh={this.handleCloseAndRefresh}
                                        activeEvent={activeEvent}
                                        onRefreshNotification={onRefreshNotification}
                                    />
                                )}
                                {(showModal.modalName === "chooseNewChar" || showModal.modalName === "chooseAnotherChar") && (
                                    <ChooseNewCharacter
                                        fetchURL={fetchURL}
                                        t={t}
                                        onShowAlert={onShowAlert}
                                        show={true}
                                        onClose={() => this.setState({ showModal: { modalName: null, partID: null } })}
                                        partID={showModal.partID}
                                        onCloseAndRefresh={this.handleCloseAndRefresh}
                                        activeEvent={activeEvent}
                                        onRefreshNotification={onRefreshNotification}
                                        myAccountLink={myAccountLink}
                                        fetchWithoutChar={showModal.fetchWithoutChar}
                                    />
                                )}
                                {showModal.modalName === "editChar" && (
                                    <EditChosenCharacter
                                        fetchURL={fetchURL}
                                        t={t}
                                        onShowAlert={onShowAlert}
                                        show={true}
                                        onClose={() => this.setState({ showModal: { modalName: null, partID: null } })}
                                        partID={showModal.partID}
                                        charID={showModal.charID}
                                        charComment={showModal.charComment}
                                        onCloseAndRefresh={this.handleCloseAndRefresh}
                                        activeEvent={activeEvent}
                                        onRefreshNotification={onRefreshNotification}
                                        myAccountLink={myAccountLink}
                                    />
                                )}
                            </div>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default withRouter(AccreditationMain);

import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import i18n from "../../../../i18n";

class NewsPost extends Component {
    state = {
        post: null,
    };

    getNewsPost = async () => {
        const { fetchURL, t, onShowAlert, location } = this.props;

        const link = location.pathname.replace(`/${t("url.link.news")}/`, "");
        const passData = {
            link: link,
            lang: i18n.language,
        };

        try {
            const response = await fetch(`${fetchURL}/news-post`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status === "errNewsNotExisting") {
                        this.setState({ post: fetchedData.status });
                    } else {
                        let post = fetchedData.news;

                        if (post.blob_data) {
                            const blob = post.blob_data;
                            var buffer = Buffer.from(blob);
                            var bufferBase64 = buffer.toString("base64");
                        }

                        post = {
                            image: post.blob_data
                                ? {
                                      data: bufferBase64,
                                      type: post.blob_file_type,
                                      name: post.blob_name,
                                  }
                                : null,
                            langs: post.langs,
                            date: post.news_date,
                            content: post.news_content,
                        };

                        this.setState({ post: post });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        this.getNewsPost();
    }

    render() {
        const { t, frontURL } = this.props;
        const { post } = this.state;
        return (
            <Fragment>
                {!post ? (
                    <LoadingProgressBar t={t} />
                ) : (
                    <Container>
                        <Row>
                            <Col>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb news">
                                        <li className="breadcrumb-item">
                                            <Link to={`/${t("url.link.news")}`}>{t("url.text.news")}</Link>
                                        </li>
                                        {post !== "errNewsNotExisting" &&
                                            post.langs.map((el, j) => (
                                                <Fragment key={j}>
                                                    {el.lang_code === i18n.language && (
                                                        <li className="breadcrumb-item active" aria-current="page">
                                                            {el.news_title}
                                                        </li>
                                                    )}
                                                </Fragment>
                                            ))}
                                    </ol>
                                </nav>
                            </Col>
                        </Row>
                        <div className="news-post">
                            <Row>
                                <Fragment>
                                    {post === "errNewsNotExisting" ? (
                                        <Col>
                                            <p className="text-center h2 mb-5">{t("news.newsNotExisting")}</p>
                                        </Col>
                                    ) : (
                                        <Fragment>
                                            <Col xs={12} className="text-center">
                                                <div className="news-image mt-3 mb-5">
                                                    <Image
                                                        src={post.image ? `data:${post.image.type};base64,${post.image.data}` : `${frontURL}/images/placeholder_img.webp`}
                                                        alt={post.image ? post.image.name : "placeholder-img"}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={12} className="news-titles">
                                                {post.langs.map((el, j) => (
                                                    <Fragment key={j}>
                                                        {el.lang_code === i18n.language && (
                                                            <>
                                                                <h1 className="h1 mb-5 text-center font-weight-bold">{el.news_title}</h1>
                                                                <p className="h3 mb-4">{el.news_subtitle}</p>
                                                            </>
                                                        )}
                                                    </Fragment>
                                                ))}
                                            </Col>
                                            <Col xs={12} className="news-date my-2 opacity-4">
                                                {post.date}
                                            </Col>
                                            <Col xs={12} className="news-text mb-4 mt-3">
                                                {post.langs.map((el, j) => (
                                                    <Fragment key={j}>
                                                        {el.lang_code === i18n.language && <div dangerouslySetInnerHTML={{ __html: el.news_content }}></div>}
                                                    </Fragment>
                                                ))}
                                            </Col>
                                        </Fragment>
                                    )}
                                </Fragment>
                            </Row>
                        </div>
                    </Container>
                )}
            </Fragment>
        );
    }
}

export default NewsPost;

import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import AccreditationMain from "./AccreditationMain";
import SubmitForm from "./SubmitForm";
import NoMatchMyAccount from "./../../../other/NoMatchMyAccount";
import EditParticipation from "./EditParticipation";

const Accreditation = (props) => {
    const { fetchURL, onShowAlert, t, accrLink, location, activeEvent, eventsList, onRefreshNotification, onFetchAgainEventsList, myAccountLink, onShowSpoonLoader, onNotLogged } = props;

    return (
        <Fragment>
            {location.pathname !== `${accrLink}/${t("url.link.accrForm")}` && location.pathname !== `${accrLink}/${t("url.link.editParticipation")}` && location.pathname !== `${accrLink}` && (
                <Route render={() => <NoMatchMyAccount t={t} />} />
            )}
            <Route
                exact
                path={`${accrLink}`}
                render={() => (
                    <AccreditationMain
                        t={t}
                        fetchURL={fetchURL}
                        onShowAlert={onShowAlert}
                        accrLink={accrLink}
                        eventsList={eventsList}
                        activeEvent={activeEvent}
                        onRefreshNotification={onRefreshNotification}
                        myAccountLink={myAccountLink}
                        onShowSpoonLoader={onShowSpoonLoader}
                        onNotLogged={onNotLogged}
                    />
                )}
            />
            <Route
                exact
                path={`${accrLink}/${t("url.link.accrForm")}`}
                render={() => (
                    <SubmitForm
                        t={t}
                        fetchURL={fetchURL}
                        onShowAlert={onShowAlert}
                        accrLink={accrLink}
                        activeEvent={activeEvent}
                        onRefreshNotification={onRefreshNotification}
                        onFetchAgainEventsList={onFetchAgainEventsList}
                        myAccountLink={myAccountLink}
                    />
                )}
            />
            <Route
                exact
                path={`${accrLink}/${t("url.link.editParticipation")}`}
                render={() => (
                    <EditParticipation
                        t={t}
                        fetchURL={fetchURL}
                        onShowAlert={onShowAlert}
                        accrLink={accrLink}
                        activeEvent={activeEvent}
                        onRefreshNotification={onRefreshNotification}
                        onFetchAgainEventsList={onFetchAgainEventsList}
                    />
                )}
            />
        </Fragment>
    );
};

export default Accreditation;

import React, { Fragment, Component } from "react";
import { Modal, Button, Row, Col, Form, Alert } from "react-bootstrap";
import Phone from "./../forms/text-inputs/Phone";
import $ from "jquery";

class EditPhone extends Component {
    state = {
        newUserPhone: this.props.userData[0].user_phone ? this.props.userData[0].user_phone : "",
        changedValues: false,
    };

    validatePhone = (number) => {
        const regExp = /^\(?\+?[0-9]{1,3}\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{3,4}( ?-?[0-9]{3})? ?(\w{1,10}\s?\d{1,6})?$/;
        if (regExp.test(number)) return true;
        else return false;
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { onShowAlert, t } = this.props;

        onShowAlert("");
        let errorMsg = [];

        if (!this.state.newUserPhone) {
            errorMsg.push(t("error.emptyUserPhone"));
            $("#userPhone").addClass("is-invalid");
        } else {
            if (!this.validatePhone(this.state.newUserPhone)) {
                errorMsg.push(t("error.invalidPhoneNumber"));
                $("#userPhone").addClass("is-invalid");
            }
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            this.handleEdit();
        }
    };

    handleEdit = async () => {
        const { fetchURL, onShowAlert, t, onRefreshNotification } = this.props;
        onShowAlert({ variant: "info", message: t("info.editingData"), fading: false });
        const passData = {
            oldPhone: this.props.userData[0].user_phone,
            newPhone: this.state.newUserPhone,
        };
        try {
            const response = await fetch(`${fetchURL}/update-phone`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    } else {
                        onShowAlert({ variant: "success", message: t(`success.dataUpdatedSuccessfully`), fading: 2000 });
                        onRefreshNotification();
                        this.props.onCloseAndRefresh();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    render() {
        const { newUserPhone, changedValues } = this.state;
        const { show, onClose, t } = this.props;
        return (
            <Fragment>
                <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
                    <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                        <Modal.Header closeButton>
                            <Modal.Title>{t("myAccount.dataAccount.editPhone.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="no-gutters">
                                <Col xs={12}>
                                    <p className="mb-4 h6" style={{ lineHeight: "1.2" }}>
                                        {t("myAccount.dataAccount.editPhone.subtitle")}
                                    </p>
                                </Col>

                                <Col xs={12}>
                                    <Phone
                                        value={newUserPhone}
                                        onChangeValue={(e) => {
                                            this.setState({ newUserPhone: e.target.value, changedValues: true });
                                            if (e.target.value === "") $(`#newUserPhone`).addClass("is-invalid");
                                            else if (!this.validatePhone(e.target.value)) $(`#newUserPhone`).addClass("is-invalid");
                                            else $(`#newUserPhone`).removeClass("is-invalid");
                                        }}
                                        name="newUserPhone"
                                        id="newUserPhone"
                                        req
                                        t={t}
                                    />
                                    {!newUserPhone ? (
                                        <Alert variant="danger" className="alert-small">
                                            {t("error.emptyUserPhone")}
                                        </Alert>
                                    ) : (
                                        !this.validatePhone(newUserPhone) && (
                                            <Alert variant="danger" className="alert-small">
                                                {t("error.invalidPhoneNumber")}
                                            </Alert>
                                        )
                                    )}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={onClose}>
                                {t("info.cancel")}
                            </Button>
                            <Button variant="primary" disabled={!changedValues || !newUserPhone || !this.validatePhone(newUserPhone) ? true : false} type="submit">
                                {t("info.saveChanges")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default EditPhone;

import { faInfoCircle, faQuestionCircle, faEye, faMoneyCheck, faTimesCircle, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Row, Col, Alert, Nav, Table, OverlayTrigger, Button, Tooltip } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import NoPermission from "../../../other/NoPermission";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import $ from "jquery";
import BarOrdersListModal from "../../../modals/BarOrdersListModal";
import PayBarCreditModal from "../../../modals/PayBarCreditModal";

class BarClientMain extends Component {
    state = {
        loading: true,
        noPermission: false,
        infoAlert: null,
        barClient: null,
        showModal: null,
        activeTabIndex: 0,
        eventsList: this.props.eventsList,
    };

    checkShowBarClient = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = { eventsList: this.props.eventsList };
        try {
            const response = await fetch(`${fetchURL}/show-bar-client`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onNotLogged();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        const barClient = fetchedData.barClient,
                            eventsList = fetchedData.eventsList;

                        this.setState({ loading: false, barClient: barClient.length > 0 ? barClient : "noParticipationInAnyEvent", eventsList: eventsList });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    handleRefreshData = () => {
        this.setState({ loading: true }, () => {
            window.scrollTo(0, 0);
            this.checkShowBarClient();
        });
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.checkShowBarClient();
    }

    render() {
        const { loading, noPermission, infoAlert, barClient, activeTabIndex, eventsList, showModal } = this.state;
        const { fetchURL, onShowAlert, t, activeEvent } = this.props;

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : (
                    <Fragment>
                        <Row className="no-gutters">
                            <Col className="text-center">
                                <p className="h3 my-4 text-uppercase">{t("myAccount.barClient.title")}</p>
                            </Col>
                        </Row>
                        {loading ? (
                            <LoadingProgressBar t={t} />
                        ) : infoAlert ? (
                            <div className="text-center">
                                <Alert variant="info">
                                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                    {infoAlert}
                                </Alert>
                            </div>
                        ) : (
                            <div className="my-account-show">
                                <Row className="no-gutters">
                                    <Col xs={12} className="text-center mt-4">
                                        {barClient === "noParticipationInAnyEvent" ? (
                                            <Alert variant="info">
                                                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                                {t("myAccount.accreditation.noParticipationInAnyEvent")}
                                            </Alert>
                                        ) : (
                                            <Fragment>
                                                <Nav
                                                    variant="pills"
                                                    defaultActiveKey={activeEvent && activeEvent !== "noActiveEvent" ? activeEvent.event_code : eventsList[0].event_code}
                                                    id="accrPills"
                                                    className="convention-pills"
                                                >
                                                    {eventsList.map((e, i) => (
                                                        <Fragment key={i}>
                                                            {barClient[i] !== "noParticipation" && (
                                                                <Nav.Item>
                                                                    <Nav.Link
                                                                        eventKey={e.event_code}
                                                                        onClick={() => {
                                                                            $(".account-data-container").fadeOut(200);
                                                                            setTimeout(() => {
                                                                                this.setState({ activeTabIndex: i }, () => {
                                                                                    $(".account-data-container").fadeIn(200);
                                                                                });
                                                                            }, 190);
                                                                        }}
                                                                    >
                                                                        {e.event_name}
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            )}
                                                        </Fragment>
                                                    ))}
                                                </Nav>

                                                <div className="account-data-table">
                                                    {typeof barClient[activeTabIndex] === "string" ? (
                                                        <div className="account-data-container">
                                                            <Alert variant="info" className="mb-0">
                                                                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                                                {t(`myAccount.accreditation.${barClient[activeTabIndex]}`)}
                                                            </Alert>
                                                            {barClient[activeTabIndex] === "notFilledAccrForm" && (
                                                                <Link
                                                                    to={`${this.props.accrLink}/${t("url.link.accrForm")}`}
                                                                    className="btn btn-primary mt-2"
                                                                    onClick={() => this.props.onChangeActiveKey(t("url.link.accreditation"))}
                                                                >
                                                                    {t("myAccount.accreditation.notFilledFormBtn")}
                                                                </Link>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="account-data-container">
                                                            <Table responsive>
                                                                <tbody>
                                                                    {barClient[activeTabIndex].client_type === "INNER" && (
                                                                        <tr>
                                                                            <td>{t("myAccount.barClient.clientType")}</td>
                                                                            <td>{t(`myAccount.barClient.clientTypes.${barClient[activeTabIndex].client_type}`)}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                    )}
                                                                    <tr>
                                                                        <td>{t("myAccount.barClient.clientStatus")}</td>
                                                                        <td>{t(`myAccount.barClient.clientStatuses.${barClient[activeTabIndex].client_status}`)}</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{t("myAccount.barClient.ordersSum")}</td>
                                                                        <td>
                                                                            {barClient[activeTabIndex].sum_orders_value === 0 ? (
                                                                                <span>{t("myAccount.barClient.noOrders")}</span>
                                                                            ) : (
                                                                                <span>
                                                                                    {barClient[activeTabIndex].sum_orders_value} {t("currency")}
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {barClient[activeTabIndex].sum_orders_value !== 0 && (
                                                                                <Button
                                                                                    variant="outline-primary"
                                                                                    size="sm"
                                                                                    className="align-baseline p-2 action-btn"
                                                                                    onClick={() => this.setState({ showModal: { option: "ordersList" } })}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faEye} />
                                                                                </Button>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    {barClient[activeTabIndex].client_type === "INNER" && (
                                                                        <Fragment>
                                                                            <tr>
                                                                                <td>{t("myAccount.barClient.amountLimit")}</td>
                                                                                <td>
                                                                                    {barClient[activeTabIndex].amount_limit ? (
                                                                                        <span>
                                                                                            {barClient[activeTabIndex].amount_limit} {t("currency")}
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span>{t("myAccount.barClient.noLimit")}</span>
                                                                                    )}
                                                                                    <OverlayTrigger
                                                                                        placement="right"
                                                                                        overlay={(props) => (
                                                                                            <Tooltip {...props}>{t("myAccount.barClient.amountLimitHelper")}</Tooltip>
                                                                                        )}
                                                                                    >
                                                                                        <Button variant="link" size="sm" className="align-baseline">
                                                                                            <FontAwesomeIcon icon={faQuestionCircle} className="ml-2 text-light" />
                                                                                        </Button>
                                                                                    </OverlayTrigger>
                                                                                </td>
                                                                                <td></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>{t("myAccount.barClient.amountFree")}</td>
                                                                                <td>
                                                                                    {barClient[activeTabIndex].amount_free} {t("currency")}
                                                                                    <OverlayTrigger
                                                                                        placement="right"
                                                                                        overlay={(props) => (
                                                                                            <Tooltip {...props}>{t("myAccount.barClient.amountFreeHelper")}</Tooltip>
                                                                                        )}
                                                                                    >
                                                                                        <Button variant="link" size="sm" className="align-baseline">
                                                                                            <FontAwesomeIcon icon={faQuestionCircle} className="ml-2 text-light" />
                                                                                        </Button>
                                                                                    </OverlayTrigger>
                                                                                </td>
                                                                                <td></td>
                                                                            </tr>
                                                                        </Fragment>
                                                                    )}
                                                                    <tr>
                                                                        <td>{t("myAccount.barClient.barPayments")}</td>
                                                                        <td>
                                                                            {barClient[activeTabIndex].sum_bar_payments} {t("currency")}
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            {t("myAccount.barClient.balance")}
                                                                            {barClient[activeTabIndex].client_balance < 0 && (
                                                                                <OverlayTrigger
                                                                                    placement="right"
                                                                                    overlay={(props) => <Tooltip {...props}>{t("myAccount.barClient.balanceBelowZero")}</Tooltip>}
                                                                                >
                                                                                    <Button variant="link" size="sm" className="align-baseline">
                                                                                        <span className="badge badge-pill badge-danger ml-2 align-text-bottom">
                                                                                            <FontAwesomeIcon icon={faExclamation} />
                                                                                        </span>
                                                                                    </Button>
                                                                                </OverlayTrigger>
                                                                            )}
                                                                        </td>
                                                                        <td className="editable-data">
                                                                            {barClient[activeTabIndex].client_balance} {t("currency")}
                                                                            {barClient[activeTabIndex].client_balance !== 0 && (
                                                                                <OverlayTrigger
                                                                                    placement="right"
                                                                                    overlay={(props) => (
                                                                                        <Tooltip {...props}>
                                                                                            {t(
                                                                                                `myAccount.barClient.${
                                                                                                    barClient[activeTabIndex].client_balance < 0 ? "underpaied" : "overpaied"
                                                                                                }`
                                                                                            )}
                                                                                        </Tooltip>
                                                                                    )}
                                                                                >
                                                                                    <Button variant="link" size="sm" className="align-baseline">
                                                                                        <FontAwesomeIcon
                                                                                            className={
                                                                                                barClient[activeTabIndex].client_balance < 0 ? "text-danger ml-2" : "text-info ml-2"
                                                                                            }
                                                                                            icon={barClient[activeTabIndex].client_balance < 0 ? faTimesCircle : faInfoCircle}
                                                                                        />
                                                                                    </Button>
                                                                                </OverlayTrigger>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {barClient[activeTabIndex].client_balance < 0 && (
                                                                                <Button
                                                                                    variant="outline-primary"
                                                                                    size="sm"
                                                                                    className="align-baseline p-2 action-btn"
                                                                                    onClick={() => this.setState({ showModal: { option: "payBarCredit" } })}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faMoneyCheck} />
                                                                                </Button>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    )}
                                                </div>
                                            </Fragment>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Fragment>
                )}

                {showModal && (
                    <Fragment>
                        {showModal.option === "ordersList" && (
                            <BarOrdersListModal
                                t={t}
                                onClose={() => this.setState({ showModal: null })}
                                barOrdersList={barClient[activeTabIndex].barOrders}
                                clientType={barClient[activeTabIndex].client_type}
                            />
                        )}
                        {showModal.option === "payBarCredit" && (
                            <PayBarCreditModal
                                fetchURL={fetchURL}
                                onShowAlert={onShowAlert}
                                t={t}
                                onClose={() => this.setState({ showModal: null })}
                                amount={-1 * barClient[activeTabIndex].client_balance}
                                transferTitle={`${eventsList[activeTabIndex].event_name} – ${t("myAccount.barClient.payBarCredit.transfer")}`}
                            />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default withRouter(BarClientMain);

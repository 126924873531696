import React, { Component, Fragment } from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import SmallBanner from "../../other/SmallBanner";
import { withRouter } from "react-router-dom";
import Footer from "../../other/Footer";
import $ from "jquery";
/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBus, faCar, faTrain } from "@fortawesome/free-solid-svg-icons"; */

/* function fadeToObjects(object) {
    const objectOffset = $(`${object}`).offset();
    $(window).on("scroll", function () {
        const distance = window.scrollY;
        const wh = $(window).outerHeight();
        if (distance + wh - 200 >= objectOffset.top) $(`${object}`).fadeTo(600, 1);
    });
} */

class AccessAndParking extends Component {
    componentDidMount() {
        this.props.onHideSpinner(200);
        if ($(".loading-spoon").hasClass("show")) $("#centerRooms").delay(1200).fadeTo(400, 1);
        else $("#centerRooms").delay(400).fadeTo(400, 1);
    }

    /* componentDidUpdate() {
        fadeToObjects("#car");
        fadeToObjects("#bus");
        fadeToObjects("#train");
        fadeToObjects("#another");
        fadeToObjects("#accessImg");
        fadeToObjects("#centerBar");
        fadeToObjects("#centerGarage");
    } */

    handleRedirect = (e, url) => {
        e.preventDefault();
        this.props.onShowSpoonLoader();
        setTimeout(() => this.props.history.push(url), 700);
    };

    render() {
        const { t, frontURL, onShowSpoonLoader } = this.props;
        return (
            <Fragment>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <SmallBanner
                            frontURL={frontURL}
                            bgBottom={`${frontURL}/images/small-banners/b1-bottom.webp`}
                            bgDesktop={`${frontURL}/images/small-banners/b3.webp`}
                            title={t("accessAndParking.title")}
                            subtitle={t("accessAndParking.subtitle")}
                        />
                    </Col>
                </Row>
                <div id="accessAndParking">
                    <Container>
                        <Row className="my-5">
                            <Col xs={12} lg={6}>
                                <p className="h2 text-uppercase mb-4">{t("accessAndParking.title1")}</p>
                                <div dangerouslySetInnerHTML={{ __html: t("accessAndParking.text1") }}></div>
                                <div dangerouslySetInnerHTML={{ __html: t("accessAndParking.text2") }}></div>
                            </Col>
                            <Col xs={12} lg={6}>
                                <div id="areaAndAccessGoogleMap">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19772.471314346174!2d14.7033639!3d51.7228118!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47087f1ce812d5b3%3A0xd8fec30280e3d0fd!2sBattleground%20Paintball!5e0!3m2!1spl!2spl!4v1719850682926!5m2!1spl!2spl"
                                        width="100%"
                                        height="100%"
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                        title="areaAndAccess"
                                    ></iframe>
                                </div>
                            </Col>
                            <Col xs={12} lg={6}>
                                <p className="h2 text-uppercase my-4">{t("accessAndParking.title3")}</p>
                                <div dangerouslySetInnerHTML={{ __html: t("accessAndParking.text3") }}></div>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Image fluid src="/images/lyzkon_offgame_map_2024.png" alt="terrain map 2024" className="my-3" />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withRouter(AccessAndParking);

import { faExclamationTriangle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Form, Alert, Button } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertNoPermission from "../../alerts/AlertNoPermission";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

class ChooseCharacterOnLarp extends Component {
    state = {
        charactersList: null,
        noPermission: false,
        loading: true,
    };

    getCharactersList = async () => {
        const { fetchURL, t, onShowAlert, fetchWithoutChar, showAddNewCharacterOption } = this.props;

        try {
            const response = await fetch(`${fetchURL}/get-user-chars-list`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        //create dropdown
                        let charsList = fetchedData.charsList,
                            charsListDropdown = [];
                        charsList.forEach((e, i) => {
                            if (!fetchWithoutChar || fetchWithoutChar !== e.char_unique_id) {
                                charsListDropdown.push({
                                    key: i + 1,
                                    value: e.char_unique_id,
                                    text: e.char_name,
                                });
                            }
                        });
                        //new character option
                        if (fetchedData.charsList.length > 0) {
                            charsListDropdown.unshift({
                                key: 0,
                                value: "no_character",
                                text: t("info.none"),
                            });
                            if (showAddNewCharacterOption)
                                charsListDropdown.push({
                                    key: charsListDropdown.length + 1,
                                    value: "new_character",
                                    text: (
                                        <>
                                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                            {t("myAccount.characters.addNewCharacter")}
                                        </>
                                    ),
                                });
                        }
                        this.setState({ charactersList: charsListDropdown, loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        this.getCharactersList();
    }

    componentDidUpdate() {
        const { downloadAgainCharList, onDownloadAgainCharList } = this.props;
        if (downloadAgainCharList) {
            this.setState({ loading: true }, () => {
                this.getCharactersList();
                onDownloadAgainCharList();
            });
        }
    }

    render() {
        const { charactersList, noPermission, loading } = this.state;
        const { t, subtext, onChangeValue, disabled, req, onShowAddNewCharacterModal } = this.props;
        return (
            <Fragment>
                <Form.Group>
                    {noPermission ? (
                        <AlertNoPermission />
                    ) : !charactersList || loading ? (
                        <LoadingProgressBar t={t} />
                    ) : (
                        <Fragment>
                            <Form.Label>
                                {t("myAccount.accreditation.form.character.label")} {req && <span className="required">*</span>}
                            </Form.Label>
                            {subtext && <p className="text-small">{subtext}</p>}
                            {charactersList.length > 0 ? (
                                <Fragment>
                                    <Dropdown
                                        placeholder={t("myAccount.characters.chooseCharacterToPlay.choose")}
                                        fluid
                                        selection
                                        options={charactersList}
                                        onChange={(event, data) => {
                                            document.getElementById("chooseCharacterToPlay").classList.remove("is-invalid");
                                            onChangeValue(data.value);
                                        }}
                                        disabled={disabled}
                                        id="chooseCharacterToPlay"
                                    />
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Alert variant="warning" className="alert-small w-auto">
                                        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" /> {t("myAccount.characters.noCharactersToChoose")}
                                    </Alert>
                                    <Button variant="primary" size="sm" onClick={() => onShowAddNewCharacterModal(true)}>
                                        {t("myAccount.characters.addNewCharacter")}
                                    </Button>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </Form.Group>
            </Fragment>
        );
    }
}

export default ChooseCharacterOnLarp;

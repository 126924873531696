import React from "react";
import { Image } from "react-bootstrap";

const LoadingSpinner = (props) => {
    const { frontURL } = props;
    return (
        <div className="loading-spoon">
            <div className="spoon">
                <Image src={`${frontURL}/images/lyzkon_icon_white.svg`} alt="loading-spoon-icon" />
            </div>
        </div>
    );
};

export default LoadingSpinner;

import React, { Fragment, Component } from "react";
import { Button, Table } from "react-bootstrap";
import AvailabilityDetailsModal from "../../../modals/AvailabilityDetailsModal";
//import EditNpcFormModal from "../../../modals/EditNpcFormModal";

class ApplicationTable extends Component {
    state = {
        showAvalModal: false,
    };
    render() {
        const { showAvalModal } = this.state;
        const { appTypeData, t, onShowAlert, fetchURL, activeEvent, onEditFormModal } = this.props;
        return (
            <Fragment>
                <div className="border p-2 text-left">
                    <Table responsive size="sm">
                        <tbody>
                            <tr>
                                <td>{t("myAccount.volunteering.appStat")}</td>
                                <td className="">{t(`myAccount.volunteering.appStatCode.${appTypeData.part_app_stat_code}`)}</td>
                                <td></td>
                            </tr>
                            {appTypeData.part_app_type_code === "CONSTR" && (
                                <Fragment>
                                    <tr>
                                        <td>{t("myAccount.volunteering.constr.skills")}</td>
                                        <td className="">{appTypeData.pac_skills ? appTypeData.pac_skills : <span className="opacity-4">{t("info.none")}</span>}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>{t("myAccount.volunteering.constr.tools")}</td>
                                        <td className="">{appTypeData.pac_tools ? appTypeData.pac_tools : <span className="opacity-4">{t("info.none")}</span>}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>{t("myAccount.volunteering.constr.arrivalDate")}</td>
                                        <td className="">{appTypeData.pac_arrival_date ? appTypeData.pac_arrival_date : <span className="opacity-4">{t("info.none")}</span>}</td>
                                        <td></td>
                                    </tr>
                                </Fragment>
                            )}
                            {(appTypeData.part_app_type_code === "BAR" || appTypeData.part_app_type_code === "ACCR") && (
                                <Fragment>
                                    <tr>
                                        <td>{t("myAccount.volunteering.declaredNumberOfDuties")}</td>
                                        <td className="">{appTypeData.number_of_duties}</td>
                                        <td></td>
                                    </tr>
                                    {/*  <tr>
                                        <td>{t("myAccount.volunteering.assignedNumberOfDuties")}</td>
                                        <td className=""></td>
                                        <td></td>
                                    </tr> */}
                                </Fragment>
                            )}
                            {(appTypeData.part_app_type_code === "CONSTR" || appTypeData.part_app_type_code === "BAR" || appTypeData.part_app_type_code === "ACCR") && (
                                <tr>
                                    <td>{t("myAccount.volunteering.avalDetails")}</td>
                                    <td className="">
                                        <Button variant="link" onClick={() => this.setState({ showAvalModal: true })}>
                                            {t("myAccount.volunteering.seeAvalDetails")}
                                        </Button>
                                    </td>
                                    <td></td>
                                </tr>
                            )}
                            <tr>
                                <td>{t("myAccount.volunteering.appComment")}</td>
                                <td className="">{appTypeData.app_comment ? appTypeData.app_comment : <span className="opacity-4">{t("info.none")}</span>}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{t("myAccount.volunteering.createDate")}</td>
                                <td className="">{appTypeData.create_date}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                    <Button variant="outline-primary" size="sm" className="mt-3" onClick={onEditFormModal}>
                        {t("myAccount.volunteering.editForm")}
                    </Button>
                </div>

                {showAvalModal && (
                    <AvailabilityDetailsModal
                        t={t}
                        show={showAvalModal}
                        onClose={() => this.setState({ showAvalModal: false })}
                        partAppType={appTypeData.part_app_type_code}
                        onShowAlert={onShowAlert}
                        fetchURL={fetchURL}
                        activeEvent={activeEvent}
                    />
                )}
            </Fragment>
        );
    }
}

export default ApplicationTable;

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { OverlayTrigger, Button, Popover } from "react-bootstrap";

const DescPopover = (props) => {
    const { popoverTxt, popoverTitle, placement } = props;
    return (
        <Fragment>
            <OverlayTrigger
                key={placement ? placement : "right"}
                placement={placement ? placement : "right"}
                overlay={
                    <Popover id={`popover-positioned-${placement ? placement : "right"}`}>
                        <Popover.Title as="h3">{popoverTitle}</Popover.Title>
                        <Popover.Content>{popoverTxt}</Popover.Content>
                    </Popover>
                }
            >
                <Button variant="link" size="sm" className="p-0 mx-1">
                    <FontAwesomeIcon icon={faInfoCircle} />
                </Button>
            </OverlayTrigger>
        </Fragment>
    );
};

export default DescPopover;

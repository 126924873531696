import React, { Fragment, Component } from "react";
import SunEditor from "suneditor-react";
import { withRouter } from "react-router-dom";
import { Alert, Col, Form, Row, Button } from "react-bootstrap";
import NoPermission from "../../../../other/NoPermission";
import TextForm from "../../../../forms/text-inputs/TextForm";
import { faArrowLeft, faExclamation, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import CalendarDateAndTime from "../../../../forms/datetimes/CalendarDateAndTime";
import NewsPreviewModal from "../../../../modals/NewsPreviewModal";
import LoadingProgressBar from "../../../../spinners/LoadingProgressBar";

class AddNews extends Component {
    state = {
        noPermission: false,
        newsDate: new Date(),
        imageFile: null,
        alertUploadImage: "",
        imageFileURL: null,
        changedValues: false,
        showNewsPreview: false,
        newsPreviewLang: null,
        newsStat: null,
        langs: [],
        loading: true,
        blobID: null,
    };

    showAddNewsView = async () => {
        const { fetchURL, t, onShowAlert, frontURL } = this.props;
        onShowAlert("");
        try {
            const response = await fetch(`${fetchURL}/show-add-news-view`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status === "notLogged") this.props.history.push(`${frontURL}`);
                    else this.setState({ langs: fetchedData.getLangs, loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.errLogout")} (${err}).` });
        }
    };

    publishNews = async (imageID) => {
        const { langs, newsStat, newsDate } = this.state;
        const { fetchURL, onShowAlert, t, newsLink } = this.props;
        const passData = { langs: langs, newsStat: newsStat, imageID: imageID, newsDate: newsDate };
        onShowAlert({ variant: "info", message: newsStat === "PUBLISHED" ? t("info.publishingNews") : t("info.savingNews"), fading: false });
        try {
            const response = await fetch(`${fetchURL}/add-news`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false)
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        onShowAlert({ variant: "success", message: t(`success.${this.state.newsStat === "PUBLISHED" ? "addedNews" : "savedNews"}`), fading: 2000 });
                        this.props.history.push(newsLink);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    insertBlobItem = async (imageData) => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.addingNewImg"), fading: false });

        try {
            const response = await fetch(`${fetchURL}/insert-news-image`, {
                credentials: "include",
                method: "POST",
                body: imageData,
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === "noPermission" || fetchedData.status === false)
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        const imageID = fetchedData.imageID;
                        this.publishNews(imageID);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
            this.setState({ loading: false });
        }
    };

    handleChangeValues = (e, i) => {
        const { langs } = this.state;
        const list = [...langs];
        list[i][e.target.name] = e.target.value;
        this.setState({ changedValues: true, langs: list });
    };

    handleEditorChange = (name, value, i) => {
        const { langs } = this.state;
        const list = [...langs];
        list[i][name] = value;
        this.setState({ changedValues: true, langs: list });
    };

    handleCheckValidation = (option) => {
        const { langs, imageFile, newsDate } = this.state;
        const { t, onShowAlert } = this.props;

        onShowAlert("");
        let errorMsg = [];

        langs.forEach((e) => {
            if (!e.news_title) {
                $(`#newsTitle${e.lang_code.toUpperCase()}`).addClass("is-invalid");
                errorMsg.push(t("error.invalidNewsTitle"));
            } else if (e.news_title.length > 255) {
                $(`#newsTitle${e.lang_code.toUpperCase()}`).addClass("is-invalid");
                errorMsg.push(t("error.tooLongNewsTitle"));
            }

            if (option === "PUBLISH") {
                if (!e.news_subtitle) {
                    $(`#newsSubtitle${e.lang_code.toUpperCase()}`).addClass("is-invalid");
                    errorMsg.push(t("error.invalidNewsSubtitle"));
                } else if (e.news_subtitle.length > 255) {
                    $(`#newsSubtitle${e.lang_code.toUpperCase()}`).addClass("is-invalid");
                    errorMsg.push(t("error.tooLongNewsSubtitle"));
                }
                if (!e.news_content) {
                    $(`#newsContent${e.lang_code.toUpperCase()}`).addClass("is-invalid");
                    errorMsg.push(t("error.invalidNewsContent"));
                } else if (e.news_content.length > 4000) {
                    $(`#newsContent${e.lang_code.toUpperCase()}`).addClass("is-invalid");
                    errorMsg.push(t("error.tooLongNewsContent"));
                }
            }
        });

        if (!newsDate) {
            $("#newsDate").addClass("is-invalid");
            errorMsg.push(t("error.invalidNewsDate"));
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            if (imageFile) {
                const imageData = new FormData();
                imageData.append("file", imageFile);
                this.insertBlobItem(imageData);
            } else {
                this.publishNews(null);
            }
        }
    };

    handleRemoveImage = () => {
        this.setState({ imageFile: null, alertUploadImage: "", imageFileURL: null });
        document.getElementById("uploadFile").value = "";
        $("#removeImageFile").hide();
    };

    handleUploadFile = (e) => {
        const { t } = this.props;
        const image = e.target.files[0];
        const imgType = image.type;
        $("#removeImageFile").show();
        /* 1024x1024x16 = 16MB */
        let errorMsg = "";
        if (image.size > 16777216) errorMsg += t("error.toMuchWeight");
        if (!imgType.includes("image")) errorMsg += t("error.invalidFileFormat");

        if (errorMsg) this.setState({ changedValues: true, alertUploadImage: { variant: "danger", message: errorMsg } });
        else {
            //jeżeli wcześniej był przypisany jakiś obrazek do produktu i jest próba załadowania nowego - wyczyść id obrazka
            this.setState({ changedValues: true, alertUploadImage: "", imageFile: image, imageFileURL: URL.createObjectURL(image), blobID: null });
        }
    };

    componentDidMount() {
        this.showAddNewsView();
    }

    render() {
        const { noPermission, newsDate, imageFile, imageFileURL, alertUploadImage, showNewsPreview, changedValues, loading, langs } = this.state;
        const { t, frontURL, newsLink } = this.props;
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : loading ? (
                    <Row>
                        <Col className="text-center mt-5">
                            <LoadingProgressBar t={t} />
                        </Col>
                    </Row>
                ) : (
                    <Fragment>
                        <Row>
                            <Col xs={3}>
                                <Button variant="link" onClick={() => this.props.history.push(newsLink)} className="mt-4">
                                    <span className="d-lg-inline d-none">{t("myAccount.adminPanel.news.goBack")}</span>
                                    <span className="d-lg-none">
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </span>
                                </Button>
                            </Col>
                            <Col xs={6}>
                                <p className="my-4 h4 text-uppercase text-center">{t("myAccount.adminPanel.news.addNews")}</p>
                            </Col>
                            <Col xs={3}></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form>
                                    {langs.map((e, i) => (
                                        <Row key={i}>
                                            <Col xs={12} lg={4}>
                                                <TextForm
                                                    value={e.news_title}
                                                    onChangeValue={(event) => this.handleChangeValues(event, i)}
                                                    req
                                                    labelTxt={t(`myAccount.adminPanel.news.newsTitle${e.lang_code.toUpperCase()}`)}
                                                    placeholderTxt={t("myAccount.adminPanel.news.typeTitle")}
                                                    name="news_title"
                                                    id={`newsTitle${e.lang_code.toUpperCase()}`}
                                                />
                                            </Col>
                                            <Col xs={12} lg={8}>
                                                <TextForm
                                                    value={e.news_subtitle}
                                                    onChangeValue={(event) => this.handleChangeValues(event, i)}
                                                    req
                                                    labelTxt={t(`myAccount.adminPanel.news.newsSubtitle${e.lang_code.toUpperCase()}`)}
                                                    placeholderTxt={t("myAccount.adminPanel.news.typeSubtitle")}
                                                    name="news_subtitle"
                                                    id={`newsSubtitle${e.lang_code.toUpperCase()}`}
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                    <Row>
                                        <Col xs={12} lg={4} className="mb-3">
                                            <Form.Label>{t("myAccount.adminPanel.news.newsImg")}</Form.Label>
                                            <input id="uploadFile" className="d-block" type="file" name="picture" onChange={this.handleUploadFile} />
                                            <Button
                                                variant="outline-secondary"
                                                id="removeImageFile"
                                                style={{ display: imageFileURL ? "inline-block" : "none" }}
                                                size="sm"
                                                onClick={this.handleRemoveImage}
                                            >
                                                <FontAwesomeIcon icon={faTimes} />
                                            </Button>
                                            {imageFile &&
                                                (imageFileURL ? (
                                                    <img src={imageFileURL} alt="add-img" className="d-block add-product-img mt-2" />
                                                ) : (
                                                    <img src={`data:${imageFile.type};base64,${imageFile.data}`} className="d-block add-product-img mt-2" alt={imageFile.name} />
                                                ))}
                                            {alertUploadImage && (
                                                <Alert variant={alertUploadImage.variant} className="alert-small my-2">
                                                    {alertUploadImage.variant === "danger" && <FontAwesomeIcon icon={faExclamation} className="mr-2" />}
                                                    {alertUploadImage.message}
                                                </Alert>
                                            )}
                                        </Col>
                                        <Col xs={12} lg={4} className="mb-3">
                                            <CalendarDateAndTime
                                                t={t}
                                                txtLabel={t("myAccount.adminPanel.news.publishingDate")}
                                                value={newsDate}
                                                onChangeCalendarDate={(date) => {
                                                    this.setState({ newsDate: date, changedValues: true });
                                                }}
                                                id="publishingDate"
                                                req
                                            />
                                        </Col>
                                    </Row>
                                    {langs.map((e, i) => (
                                        <Row key={i}>
                                            <Col xs={12} className="mb-3">
                                                <Form.Label>
                                                    {t(`myAccount.adminPanel.news.newsContent${e.lang_code.toUpperCase()}`)} <span className="required">*</span>
                                                </Form.Label>
                                                <SunEditor
                                                    lang={e.lang_code}
                                                    onChange={(value) => this.handleEditorChange("news_content", value, i)}
                                                    setOptions={{
                                                        buttonList: [["fontSize", "formatBlock"], ["bold", "underline", "italic", "fontColor"], ["link"], ["removeFormat"], ["align", "list"]],
                                                        formats: ["p", "blockquote", "h1", "h2", "h3", "h4", "h5", "h6"],
                                                    }}
                                                    setDefaultStyle="height: 10rem; overflow-y:scroll; font-size: 1rem"
                                                    name="news_content"
                                                    id={`newsContent${e.lang_code.toUpperCase()}`}
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                    <Row>
                                        <Col xs={6} className="my-3">
                                            {langs.map((e, i) => (
                                                <Fragment key={i}>
                                                    <Button
                                                        variant="outline-primary"
                                                        disabled={changedValues ? false : true}
                                                        onClick={() => this.setState({ showNewsPreview: true, newsPreviewLang: e.lang_code })}
                                                        className="mr-2"
                                                    >
                                                        {t(`myAccount.adminPanel.news.preview${e.lang_code.toUpperCase()}`)}
                                                    </Button>
                                                </Fragment>
                                            ))}

                                            <Button
                                                variant="outline-primary"
                                                disabled={changedValues ? false : true}
                                                onClick={() => {
                                                    this.setState({ newsStat: "DRAFT" }, () => this.handleCheckValidation("DRAFT"));
                                                }}
                                            >
                                                {t("myAccount.adminPanel.news.saveAsDraft")}
                                            </Button>
                                        </Col>
                                        <Col xs={6} className="text-right my-3">
                                            <Button
                                                variant="primary"
                                                disabled={changedValues ? false : true}
                                                onClick={() => {
                                                    this.setState({ newsStat: "PUBLISHED" }, () => this.handleCheckValidation("PUBLISH"));
                                                }}
                                            >
                                                {t("myAccount.adminPanel.news.publish")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Fragment>
                )}

                {showNewsPreview && (
                    <NewsPreviewModal
                        show={showNewsPreview}
                        onClose={() => this.setState({ showNewsPreview: false, newsPreviewLang: null })}
                        langs={langs}
                        imageFile={imageFile}
                        imageFileURL={imageFileURL}
                        t={t}
                        frontURL={frontURL}
                        selectedLang={this.state.newsPreviewLang}
                        newsDate={newsDate}
                    />
                )}
            </Fragment>
        );
    }
}

export default withRouter(AddNews);

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Row, Col, Alert, Nav, Table } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import NoPermission from "../../../other/NoPermission";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import $ from "jquery";
import ApplicationType from "./ApplicationType";

class VolunteeringMain extends Component {
    state = {
        loading: true,
        noPermission: false,
        infoAlert: null,
        applications: null,
        partStatuses: null,
        activeTabIndex: 0,
        eventsList: this.props.eventsList,
    };

    checkShowVolunteering = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = { eventsList: this.props.eventsList };
        try {
            const response = await fetch(`${fetchURL}/show-volunteering`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onNotLogged();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        const applications = fetchedData.applications,
                            eventsList = fetchedData.eventsList;
                        eventsList.forEach((e, i) => {
                            if (e.event_active === "Y") {
                                this.setState({ activeTabIndex: i });
                            }
                        });
                        this.setState({
                            loading: false,
                            applications: applications.length > 0 ? applications : "noParticipationInAnyEvent",
                            eventsList: eventsList,
                            partStatuses: fetchedData.partStatuses,
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    handleRefreshData = () => {
        this.setState({ loading: true }, () => {
            window.scrollTo(0, 0);
            this.checkShowVolunteering();
        });
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.checkShowVolunteering();
    }

    render() {
        const { loading, noPermission, infoAlert, applications, activeTabIndex, partStatuses, eventsList } = this.state;
        const { t, activeEvent, volLink, onShowAlert, fetchURL, skillsStart, toolsStart } = this.props;

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : (
                    <Fragment>
                        {loading ? (
                            <LoadingProgressBar t={t} />
                        ) : infoAlert ? (
                            <div className="text-center">
                                <Alert variant="info">
                                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                    {infoAlert}
                                </Alert>
                            </div>
                        ) : (
                            <div className="my-account-show">
                                <Row className="no-gutters">
                                    <Col xs={12} className="text-center mt-4">
                                        {applications === "noParticipationInAnyEvent" ? (
                                            <Alert variant="info">
                                                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                                {t("myAccount.accreditation.noParticipationInAnyEvent")}
                                            </Alert>
                                        ) : (
                                            <Fragment>
                                                <Nav
                                                    variant="pills"
                                                    defaultActiveKey={activeEvent && activeEvent !== "noActiveEvent" ? activeEvent.event_code : eventsList[0].event_code}
                                                    id="accrPills"
                                                    className="convention-pills"
                                                >
                                                    {eventsList.map((e, i) => (
                                                        <Fragment key={i}>
                                                            {applications[i] !== "noParticipation" && (
                                                                <Nav.Item>
                                                                    <Nav.Link
                                                                        eventKey={e.event_code}
                                                                        onClick={() => {
                                                                            $(".account-data-container").fadeOut(200);
                                                                            setTimeout(() => {
                                                                                this.setState({ activeTabIndex: i }, () => {
                                                                                    $(".account-data-container").fadeIn(200);
                                                                                });
                                                                            }, 190);
                                                                        }}
                                                                    >
                                                                        {e.event_name}
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            )}
                                                        </Fragment>
                                                    ))}
                                                </Nav>

                                                <div className="account-data-table">
                                                    {typeof applications[activeTabIndex] === "string" ? (
                                                        <div className="account-data-container">
                                                            {partStatuses[activeTabIndex] === "ABORTED" ? (
                                                                <Alert variant="info" className="mb-0">
                                                                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                                                    {t(`myAccount.volunteering.${eventsList[activeTabIndex].event_active === "N" ? "noApplication" : "abortedParticipant"}`)}
                                                                </Alert>
                                                            ) : (
                                                                <>
                                                                    <Alert variant="info" className="mb-0">
                                                                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                                                        {t(`myAccount.accreditation.${applications[activeTabIndex]}`)}
                                                                    </Alert>
                                                                    {applications[activeTabIndex] === "notFilledAccrForm" && (
                                                                        <Link
                                                                            to={`${this.props.accrLink}/${t("url.link.accrForm")}`}
                                                                            className="btn btn-primary mt-2"
                                                                            onClick={() => this.props.onChangeActiveKey(t("url.link.accreditation"))}
                                                                        >
                                                                            {t("myAccount.accreditation.notFilledFormBtn")}
                                                                        </Link>
                                                                    )}
                                                                    {applications[activeTabIndex] === "anyFormNotFilled" && eventsList[activeTabIndex].event_active === "Y" && (
                                                                        <Link to={`${this.props.volLink}/${t("url.link.volForm")}`} className="btn btn-primary mt-2">
                                                                            {t("myAccount.accreditation.anyFormNotFilledBtn")}
                                                                        </Link>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="account-data-container">
                                                            <p className="h5 my-4 text-uppercase">{t(`myAccount.volunteering.functions.title`)}</p>
                                                            {applications[activeTabIndex][0] === "noFormFilled" ? (
                                                                <p className="text-muted text-center my-2">{t("myAccount.volunteering.optionWasNotFilled")}</p>
                                                            ) : (
                                                                <Table responsive className="border my-3">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{t("myAccount.volunteering.functions.selectedFunctions")}</td>
                                                                            <td>
                                                                                {applications[activeTabIndex][0].applications.map((e, i) => (
                                                                                    <Fragment key={i}>
                                                                                        <p className="my-1">
                                                                                            {t(`myAccount.volunteering.functions.${e.part_app_type_code}`)} (
                                                                                            {t("myAccount.volunteering.appStatCode.status")}:{" "}
                                                                                            {t(`myAccount.volunteering.appStatCode.${e.part_app_stat_code}`)})
                                                                                        </p>
                                                                                    </Fragment>
                                                                                ))}
                                                                            </td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{t("myAccount.volunteering.functions.comment")}</td>
                                                                            <td>
                                                                                {applications[activeTabIndex][0].part_app_comment ? (
                                                                                    applications[activeTabIndex][0].part_app_comment
                                                                                ) : (
                                                                                    <span className="opacity-4">{t("info.none")}</span>
                                                                                )}
                                                                            </td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{t("myAccount.volunteering.functions.createDate")}</td>
                                                                            <td>{applications[activeTabIndex][0].create_date}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            )}
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <ApplicationType
                                                                        t={t}
                                                                        eventApplication={applications[activeTabIndex][1]}
                                                                        appTypeName="constr"
                                                                        volLink={volLink}
                                                                        selectedEvent={eventsList[activeTabIndex]}
                                                                        activeEvent={activeEvent}
                                                                        onShowAlert={onShowAlert}
                                                                        fetchURL={fetchURL}
                                                                        skillsStart={skillsStart}
                                                                        toolsStart={toolsStart}
                                                                        onRefreshData={this.handleRefreshData}
                                                                    />
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <ApplicationType
                                                                        t={t}
                                                                        eventApplication={applications[activeTabIndex][2]}
                                                                        appTypeName="bar"
                                                                        volLink={volLink}
                                                                        selectedEvent={eventsList[activeTabIndex]}
                                                                        activeEvent={activeEvent}
                                                                        onShowAlert={onShowAlert}
                                                                        fetchURL={fetchURL}
                                                                        onRefreshData={this.handleRefreshData}
                                                                    />
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <ApplicationType
                                                                        t={t}
                                                                        eventApplication={applications[activeTabIndex][3]}
                                                                        appTypeName="accr"
                                                                        volLink={volLink}
                                                                        selectedEvent={eventsList[activeTabIndex]}
                                                                        activeEvent={activeEvent}
                                                                        onShowAlert={onShowAlert}
                                                                        fetchURL={fetchURL}
                                                                        onRefreshData={this.handleRefreshData}
                                                                    />
                                                                </Col>
                                                                {/* <Col xs={12}>
                                                                    <ApplicationType
                                                                        t={t}
                                                                        eventApplication={applications[activeTabIndex][4]}
                                                                        appTypeName="npc"
                                                                        volLink={volLink}
                                                                        selectedEvent={eventsList[activeTabIndex]}
                                                                        activeEvent={activeEvent}
                                                                        onShowAlert={onShowAlert}
                                                                        fetchURL={fetchURL}
                                                                        onRefreshData={this.handleRefreshData}
                                                                    />
                                                                </Col> */}
                                                            </Row>
                                                        </div>
                                                    )}
                                                </div>
                                            </Fragment>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default withRouter(VolunteeringMain);

import React, { Fragment, Component } from "react";
import queryString from "query-string";
import { Container, Row, Col, Button, Alert, Form } from "react-bootstrap";
import ContactForm from "../../forms/ContactForm";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import Password from "../../forms/Password";
import i18n from "../../../../i18n";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ActivateAccount extends Component {
    state = {
        showContactForm: false,
        loading: true,
        infoAlert: false,
        dataURL: null,
        password: "",
        repeatPassword: "",
        email: "",
    };

    activateAccount = async () => {
        const { password, repeatPassword, dataURL } = this.state;
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            password: password,
            repeatPassword: repeatPassword,
            dataURL: dataURL,
        };
        onShowAlert({ variant: "info", message: t("info.activatingAccount") });
        try {
            const response = await fetch(`${fetchURL}/activate-account`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    } else if (fetchedData.status !== true) this.setState({ loading: false, infoAlert: true });
                    else {
                        onShowAlert({ variant: "success", message: t("success.accountActivated"), fading: 3000 });
                        this.props.onShowSpinner();
                        setTimeout(() => {
                            this.props.history.push(`/${t("url.link.login")}`);
                        }, 200);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    confirmAccount = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert("");
        const passData = this.state.dataURL;
        //console.log(passData);
        try {
            const response = await fetch(`${fetchURL}/check-confirm-link`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                        this.setState({ showContactForm: true, loading: false });
                        this.props.onHideSpinner(200);
                    } else if (fetchedData.status !== true) {
                        //link jest nieważny lub niepoprawny - wyświetl alert
                        this.setState({
                            loading: false,
                            showContactForm: true,
                            email: fetchedData.userEmail,
                            infoAlert: {
                                variant: fetchedData.status,
                                message: t(`${fetchedData.status}.${fetchedData.code}`),
                                btn: fetchedData.code === "activateAccountLinkExpired" ? true : false,
                            },
                        });
                        this.props.onHideSpinner(200);
                    } else {
                        if (fetchedData.setPassword) {
                            //jest hasło - przekieruj od razu na stronę logowania, wyświetl alert
                            onShowAlert({ variant: "success", message: t("activateAccount.activateWithoutSettingPass"), fading: 3000 });
                            this.props.onShowSpinner();
                            setTimeout(() => {
                                this.props.history.push(`/${t("url.link.login")}`);
                            }, 200);
                        } else {
                            this.setState({ loading: false, email: fetchedData.userEmail });
                            this.props.onHideSpinner(200);
                        }
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    sendActivationLink = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.sendingEmail"), fading: false });
        const passData = {
            email: this.state.email,
            lang: i18n.language === "en" ? "en" : "pl",
        };
        try {
            const response = await fetch(`${fetchURL}/send-activation-link`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status !== true) onShowAlert({ variant: fetchedData.status, message: t(`${fetchedData.status}.${fetchedData.code}`), fading: false });
                    else onShowAlert({ variant: "success", message: t(`success.sendEmail`), fading: 2000 });
                });
            }
        } catch (err) {
            this.onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { password, repeatPassword } = this.state;
        const { t } = this.props;
        let errorMsg = false;
        if (!password || !repeatPassword) {
            this.setState({ password: "", repeatPassword: "" });
            errorMsg = t("error.reqFields");
        } else if (password !== repeatPassword) {
            this.setState({ password: "", repeatPassword: "" });
            errorMsg = t("error.passNotIdentical");
        }

        if (errorMsg) this.props.onShowAlert({ variant: "danger", message: errorMsg });
        else this.activateAccount();
    };

    validatePassword = (pass) => {
        const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~!@#$%^&*()_\-{}[\]:;'"<> ?`/]).{8,}$/;
        //console.log(pass, re.test(pass));
        if (!re.test(pass)) {
            return false;
        } else return true;
    };

    handleLogout = async () => {
        const { fetchURL, t, onShowAlert, onLogout } = this.props;
        try {
            const response = await fetch(`${fetchURL}/logout`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                onShowAlert("");
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    onLogout();
                    this.confirmAccount();
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.errLogout")} (${err}).`, fading: false });
        }
    };

    componentDidUpdate() {
        $(document).ready(function () {
            $("#confirmAccount").css("min-height", `${$(window).outerHeight() - $("#footer").outerHeight()}px`);
        });
    }

    componentDidMount() {
        const { location, t } = this.props;
        const parsedLocationSearch = queryString.parse(location.search);
        if (location.search === "" || parsedLocationSearch.url === null) {
            this.setState({ showContactForm: true, infoAlert: { variant: "info", message: t("info.invalidActivationLink") } });
            this.props.onHideSpinner(200);
        } else {
            this.setState(
                {
                    dataURL: {
                        pathname: location.pathname,
                        urlValue: parsedLocationSearch.url,
                    },
                },
                () => {
                    this.handleLogout();
                }
            );
        }
    }
    render() {
        const { showContactForm, loading, infoAlert, repeatPassword, password, email } = this.state;
        const { onShowAlert, t, fetchURL } = this.props;

        return (
            <Fragment>
                {!loading && (
                    <Container id="confirmAccount" className="pt-lg-5">
                        <Row className="no-gutters">
                            {infoAlert ? (
                                <Col lg={{ span: 8, offset: 2 }} xs={12} className="mt-lg-5 mt-3 text-center">
                                    <Alert variant={infoAlert.variant}>
                                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                        {infoAlert.message}
                                    </Alert>
                                    {infoAlert.btn && (
                                        <Button variant="primary" onClick={() => this.sendActivationLink()}>
                                            {t("activateAccount.sendActivationMail")}
                                        </Button>
                                    )}
                                </Col>
                            ) : (
                                <Fragment>
                                    <Container className="pt-lg-5">
                                        <Row className="no-gutters">
                                            <Col className="border shadow mt-lg-5" lg={{ span: 8, offset: 2 }} xs={12}>
                                                <Row className="no-gutters">
                                                    <Col className="p-3 bg-darker">
                                                        <div className="text-center">
                                                            <h2 className="h3 my-5">{t("activateAccount.title")}</h2>
                                                            <p className="h6 mb-4">{t("activateAccount.activateWithSettingPass")}</p>
                                                        </div>
                                                        <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                                                            <Form.Group>
                                                                <Form.Control type="hidden" value={email} />
                                                                <Password
                                                                    t={t}
                                                                    value1={password}
                                                                    value2={repeatPassword}
                                                                    onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })}
                                                                    option="double"
                                                                    onValidatePassword={this.validatePassword}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="text-center">
                                                                <Button variant="primary" type="submit">
                                                                    {t("activateAccount.setPassword")}
                                                                </Button>
                                                            </Form.Group>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Fragment>
                            )}
                            {showContactForm && (
                                <Col lg={{ span: 6, offset: 3 }} xs={12} className="pt-lg-5">
                                    <p className="h4 text-center">{t("activateAccount.contactMailTitle")}</p>
                                    <ContactForm t={t} onShowAlert={onShowAlert} fetchURL={fetchURL} />
                                </Col>
                            )}
                        </Row>
                    </Container>
                )}
            </Fragment>
        );
    }
}

export default withRouter(ActivateAccount);

import React, { Fragment } from "react";
import TextForm from "../text-inputs/TextForm";
import { Checkbox } from "semantic-ui-react";

const SkillsCheckboxes = (props) => {
    const { t, skills, skillsOther, onCheckSkill, onChangeOtherSkills } = props;
    return (
        <Fragment>
            {skills.map((e, i) => (
                <Fragment key={i}>
                    <Checkbox label={e.label} checked={e.value} onChange={(e, d) => onCheckSkill(i, d.checked)} className="d-block" />
                    {i === skills.length - 1 && e.value && (
                        <TextForm
                            value={skillsOther}
                            onChangeValue={onChangeOtherSkills}
                            placeholderTxt={t("myAccount.volunteering.constr.form.skills.otherSkillPlaceholder")}
                            name="otherSkills"
                            id="otherSkills"
                        />
                    )}
                </Fragment>
            ))}
        </Fragment>
    );
};

export default SkillsCheckboxes;

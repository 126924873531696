import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import $ from "jquery";

const Email = (props) => {
    const { value, onChangeValue, id, name, classN, t } = props;
    return (
        <Fragment>
            <Form.Group>
                <Form.Label>
                    E-mail<span className="required"> *</span>
                </Form.Label>
                <Form.Control
                    id={id}
                    type="email"
                    placeholder={t("myAccount.dataAccount.emailPlaceholder")}
                    name={name}
                    value={value}
                    onChange={(e) => {
                        onChangeValue(e);
                        $(`#${id}`).removeClass("is-invalid");
                    }}
                    className={classN ? `${classN} req` : "req"}
                    autoComplete="on"
                />
            </Form.Group>
        </Fragment>
    );
};

export default Email;

import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
import Regulations from "../other/Regulations";

const RegulationsModal = (props) => {
    const { show, onClose, t, onShowAlert, fetchURL } = props;

    return (
        <Fragment>
            <Modal show={show} onHide={onClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{t("regulations.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Regulations t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={onClose} type="button">
                        {t("regulations.closeBtn")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default RegulationsModal;

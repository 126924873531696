import React, { Component, Fragment } from "react";
import { Row, Col, Container, Button, Image } from "react-bootstrap";
import SmallBanner from "../../other/SmallBanner";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import Footer from "../../other/Footer";
/* import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; */
import i18next from "i18next";
import i18n from "../../../../i18n";

var wh;

function basicEffects() {
    $(".end-card").css({ height: `auto` });
    let cardHeight = 0;
    $(".end-card").each(function () {
        if ($(this).outerHeight() > cardHeight) cardHeight = $(this).outerHeight();
    });
    $(".end-card").css({ height: `${cardHeight}px` });
    if ($(".loading-spoon").hasClass("show")) {
        $("#intro").delay(1000).fadeTo(400, 1);
    } else {
        $("#intro").delay(500).fadeTo(400, 1);
    }
}

class Intro extends Component {
    componentDidMount() {
        this.props.onHideSpinner(200);
        wh = window.innerHeight < window.outerHeight ? window.innerHeight : window.outerHeight;
        basicEffects();

        window.addEventListener("scroll", this.scroll);
        window.addEventListener("resize", this.resize);
    }

    scroll = () => {
        if (document.getElementById("intro")) {
            const objectOffset = $(`#introCards`).offset();
            const distance = window.scrollY;
            if (distance + wh - 200 >= objectOffset.top) $(`#introCards`).fadeTo(600, 1);
        }
    };

    resize = () => {
        basicEffects();
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("scroll", this.scroll);
    }

    handleRedirect = (e, url) => {
        e.preventDefault();
        this.props.onShowSpoonLoader();
        setTimeout(() => this.props.history.push(url), 700);
    };

    render() {
        const { frontURL, t, onShowSpoonLoader } = this.props;
        return (
            <Fragment>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <SmallBanner
                            frontURL={frontURL}
                            bgBottom={`${frontURL}/images/small-banners/b2-bottom.webp`}
                            bgDesktop={`${frontURL}/images/small-banners/b2.webp`}
                            title={t("intro.title")}
                            subtitle={t("intro.subtitle")}
                        />
                    </Col>
                </Row>
                <Container id="intro" style={{ opacity: 0 }}>
                    {/* <Row>
                        <Col>
                            <Alert variant="info" className="mt-5">
                                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                {t("intro.designDocInProgress")}
                            </Alert>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col xs={12} lg={{ span: 4, offset: 4 }} className="pl-4 py-5">
                            <div className="end-card text-center p-4">
                                <p className="end-card-title py-3 text-center">
                                    {t("url.text.designDoc")} 2024
                                    <br />"{t("designDocTitle")}"
                                </p>
                                <a href={`/files/Design_Doc_lyzkon_2024_${i18n.language}.pdf`} className="d-block mb-2 btn btn-outline-primary btn-lg">
                                    {t("download")}
                                </a>
                            </div>
                        </Col>
                    </Row>
                    {/*<Row>
                        <Col className="text-center my-5 white border py-3 bg-white-opacity">
                            <h1 className="h1">{t("intro.inProgress")}</h1>
                            <Row>
                                <Col xs={12} lg={{ span: 8, offset: 2 }}>
                                    <p className="h4 mb-0">{t("intro.inProgressTxt")}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col xs={12} className="text-justify my-5">
                            {i18next.language === "pl" ? (
                                <div>
                                    <h1 className="h2 text-center my-5">Pułapka mgły pochłonęła w 2122 roku wiele istnień.</h1>
                                    <p>
                                        <Image src="/images/grid_0_51.png" fluid />
                                    </p>
                                    <p>
                                        Nie wszystkie karawany wróciły do domu, ale udało nam się wydostać. Przedwojenny wynalazek został na szczęście znaleziony i mogliśmy opuścić
                                        ośrodek "Sosna". Standardowo, nie zabrakło przy tym konfliktów, kombinatorstwa i ryzyka, ale wielkie rzeczy rzadko robią się za darmo i z
                                        dobrego serca. Zwłaszcza że okoliczności były tym razem więcej niż tylko niesprzyjające.
                                    </p>
                                    <p>
                                        Teraz, wiele miesięcy później po całym kraju rozchodzą się plotki o tym, że do nieistniejącej dotychczas na mapach wioski przy starej bazie
                                        wracają ludzie. Wraz z nimi wrócili przewodnicy. Kiedy Mgła się rozwiała, okazało się, że w ośrodku jest wiele więcej przedwojennych
                                        laboratoriów -skrywających wszelkiego gatunku szpej, ale i tajemnice.
                                    </p>
                                    <p>
                                        Treść papierów odnalezionych w okolicy wskazuje, że miejsce to mogło stanowić swego rodzaju centrum badawcze, o niejasnym charakterze. Część
                                        to zwyczajne notatki, lecz niektóre zawierają precyzyjne schematy rozmaitych urządzeń. Niestety, nie będziemy w stanie pojąć przeznaczenia
                                        laboratoriów, jeśli nie skompletujemy większej bazy podobnych źródeł.
                                    </p>
                                    <h1 className="h2 text-center my-5">Kto wie, co oprócz Mgły opracowywano w ośrodku?</h1>
                                    <p>
                                        <Image src="/images/grid_0_52.png" fluid />
                                    </p>
                                    <p>
                                        Nową broń, leki, chemię? A może nową wersję FEV? Wszystko wskazuje, że to miejsce może okazać się poważniejszym znaleziskiem, niż początkowo
                                        zakładaliśmy. Pytanie tylko jaka jego część przyniesie zysk, a jaka męczeńską śmierć. Niewielu jednak przejmuje się tym drugim, gdy widmo
                                        góry kapsli przysłania perspektywę. Jedno jest pewne - odkrycie tajemnic ośrodka może odmienić ten świat, a wiedza jest naszym najlepszym
                                        sojusznikiem.
                                    </p>
                                    <p>
                                        Nie wszyscy jeszcze to czują, ale wyścig już się zaczął. Kto pierwszy otworzy stare krypty i wyciągnie z nich wiedzę sprzed lat, będzie miał
                                        dużo argumentów, żeby dyktować ceny albo przejąć władzę. Podobno zniszczenie generatora Mgły wymagało przeciążenia reaktora pod ośrodkiem,
                                        ale kto by się przejmował odrobiną promieniowania?
                                    </p>
                                    <p>
                                        Karawany już nadciągają i będzie ich więcej niż w zeszłym roku. Przyjmując kolejny rzut handlarzy, sprzedawców, badaczy i obdartych z ubrań
                                        i godności podróżników, miasteczko ma w końcu szansę rozkwitnąć na nowo. Możliwe jednak, że to wszystko dzieje się zbyt szybko. Że nie
                                        wiemy, na co się porywamy. I okażemy się zbyt słabi w nierównym starciu z sekretami ośrodka Sosna.
                                    </p>
                                    <p>
                                        <Image src="/images/grid_0_53.png" fluid />
                                    </p>
                                </div>
                            ) : i18next.language === "en" ? (
                                <div>
                                    <h1 className="h2 text-center my-5">Trap of the Mist claimed in 2122 year many lives.</h1>
                                    <p>
                                        <Image src="/images/grid_0_51.png" fluid />
                                    </p>
                                    <p>
                                        Not all the caravans made it home, but we managed to get out. Fortunately, the pre-war invention was found and we were able to leave the
                                        'Sosna' resort. As usual, there were also conflicts, scheming and risk, but great things are rarely done for free and with a good heart.
                                        Especially since the circumstances were more than just unfavorable this time.
                                    </p>
                                    <p>
                                        Now, many months later, rumors are spreading all over the country that people are returning to the previously non-existent village near the
                                        old base. The guides came with them. When the Mist cleared, it turned out that there are many more pre-war laboratories in the center -
                                        hiding all kinds of equipment, but also secrets.
                                    </p>
                                    <p>
                                        The content of papers found in the area indicates that this place could have been a kind of research center, of an unclear nature. Some are
                                        simple notes, but some contain precise diagrams of various devices. Unfortunately, we won't be able to understand the purpose of the labs
                                        unless we gather a larger database of similar sources.
                                    </p>
                                    <h1 className="h2 text-center my-5">Who knows what the center was developing besides Mist?</h1>
                                    <p>
                                        <Image src="/images/grid_0_52.png" fluid />
                                    </p>
                                    <p>
                                        New weapons, drugs, chemistry? Or maybe a new version of FEV? Everything indicates that this place may turn out to be a more serious find
                                        than we initially assumed. The only question is what part of it will bring profit and what part will be a martyr's death. Few, however, care
                                        about the latter when the specter of a mountain of bottle caps obscures the perspective. One thing is certain - unraveling the secrets of
                                        the facility can change this world, and knowledge is our best ally.
                                    </p>
                                    <p>
                                        Not everyone feels it yet, but the race has already begun. Whoever is the first to open the old crypts and draw knowledge from them from
                                        years ago will have many arguments to dictate prices or take power. Supposedly, destroying the Mist Generator required overloading the
                                        reactor underneath the site, but who cares about a little radiation?
                                    </p>
                                    <p>
                                        The caravans are already on their way, and there will be more of them than last year. Taking on another wave of traders, vendors,
                                        researchers and travelers stripped of their clothes and dignity, the town finally has a chance to flourish again. But it's possible that
                                        it's all happening too fast. That we don't know what we're getting into. And we will prove too weak in an uneven clash with the secrets of
                                        the Sosna resort.
                                    </p>{" "}
                                    <p>
                                        <Image src="/images/grid_0_53.png" fluid />
                                    </p>
                                </div>
                            ) : (
                                ""
                            )}
                        </Col>
                    </Row>
                    <Row className="no-gutters" id="introCards" style={{ opacity: 0 }}>
                        <Col xs={12} className="text-center my-5">
                            <p className="h2 text-uppercase">{t("intro.wannaKnowMore.title")}</p>
                        </Col>
                        <Col xs={12} lg={4} className="pr-4 py-5">
                            <div className="end-card text-center p-4">
                                <p className="end-card-title py-3">{t("intro.wannaKnowMore.mechanics")}</p>
                                <Button onClick={(e) => this.handleRedirect(e, `${t("url.link.basicMechanics")}`)} variant="outline-primary" size="lg">
                                    {t("intro.wannaKnowMore.mechanicsBtn")}
                                </Button>
                            </div>
                        </Col>
                        <Col xs={12} lg={4} className="px-4 py-5">
                            <div className="end-card text-center p-4">
                                <p className="end-card-title py-3">{t("about.wannaKnowMore.accr")}</p>
                                <Button
                                    onClick={(e) => this.handleRedirect(e, `${t("url.link.myAccount")}/${t("url.link.accreditation")}/${t("url.link.accrForm")}`)}
                                    variant="outline-primary"
                                    size="lg"
                                >
                                    {t("about.wannaKnowMore.accrBtn")}
                                </Button>
                            </div>
                        </Col>
                        <Col xs={12} lg={4} className="pl-4 py-5">
                            <div className="end-card text-center p-4">
                                <p className="end-card-title py-3">{t("about.wannaKnowMore.program")}</p>
                                <Button onClick={(e) => this.handleRedirect(e, `${t("url.link.program")}`)} variant="outline-primary" size="lg">
                                    {t("about.wannaKnowMore.programBtn")}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withRouter(Intro);

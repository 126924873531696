import React, { Fragment, Component } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import ChooseCharacterOnLarp from "../forms/dropdowns/ChooseCharacterOnLarp";

class ChooseNewCharacter extends Component {
    state = {
        selectedCharUniqueID: null,
        changedValues: false,
        selectedCharVersionID: null,
        versionsDropdown: null,
    };

    getCharactersVersions = async () => {
        const { fetchURL, t, onShowAlert } = this.props;
        try {
            const response = await fetch(`${fetchURL}/get-char-versions`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ charID: this.state.selectedCharUniqueID }),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        const versions = fetchedData.charsList;
                        if (versions.length === 1) this.setState({ selectedCharVersionID: versions[0].char_id, versionsDropdown: null });
                        else {
                            let versionsDropdown = [];
                            versions.forEach((e, i) => {
                                versionsDropdown.push({
                                    key: i + 1,
                                    value: e.char_id,
                                    text: `${t("myAccount.characters.characterHistory.version")} ${versions.length - i} ${
                                        i === 0 ? t("myAccount.characters.chooseCharacterToPlay.newest") : ""
                                    }`,
                                });
                            });
                            this.setState({ versionsDropdown: versionsDropdown });
                        }
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    selectCharacterToPlay = async () => {
        const { selectedCharVersionID } = this.state;
        const { fetchURL, t, onShowAlert, partID, onCloseAndRefresh } = this.props;
        try {
            const response = await fetch(`${fetchURL}/select-char-to-play`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ charID: selectedCharVersionID, partID: partID }),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 3000 });
                        onCloseAndRefresh();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { versionsDropdown, selectedCharUniqueID, selectedCharVersionID } = this.state;
        const { onShowAlert, t } = this.props;

        onShowAlert("");
        let errorMsg = [];

        /* if (!selectedCharUniqueID) {
            document.getElementById("chooseCharacterToPlay").classList.add("is-invalid");
            errorMsg.push(t("error.notSelectedCharacterToPlay"));
        } */
        if (selectedCharUniqueID && versionsDropdown && !selectedCharVersionID) {
            document.getElementById("chooseCharVersion").classList.add("is-invalid");
            errorMsg.push(t("error.notSelectedCharacterVersion"));
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            //console.log(`selectedCharID: ${this.state.selectedCharVersionID}, partID: ${this.props.partID}`);
            this.selectCharacterToPlay();
        }
    };

    componentDidMount() {
        this.props.onShowAlert("");
    }

    render() {
        const { changedValues, versionsDropdown } = this.state;
        const { t, show, onClose, fetchURL, onShowAlert, myAccountLink, fetchWithoutChar, activeEvent } = this.props;

        return (
            <Fragment>
                <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
                    <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                        <Modal.Header closeButton>
                            <Modal.Title>{t("myAccount.characters.chooseCharacterToPlay.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="no-gutters">
                                <Col xs={12}>
                                    <p className="mb-4 h6">
                                        {t("myAccount.characters.chooseCharacterToPlay.subtitle")}
                                        <a href={`${myAccountLink}${t("url.link.characters")}`} target="_blank" rel="noopener noreferrer">
                                            {t("here")}
                                        </a>
                                        .
                                        <br />
                                        {t("myAccount.characters.chooseCharacterToPlay.subtitle2")}{" "}
                                        <Link to={`${myAccountLink}${t("url.link.characters")}/${t(`url.link.addNewCharacter`)}`}>
                                            {t("myAccount.characters.chooseCharacterToPlay.createNew")}
                                        </Link>
                                        .
                                    </p>
                                </Col>
                                <Col xs={12}>
                                    <ChooseCharacterOnLarp
                                        req
                                        onShowAlert={onShowAlert}
                                        fetchURL={fetchURL}
                                        t={t}
                                        onChangeValue={(value) =>
                                            this.setState({ selectedCharUniqueID: value, changedValues: true }, () => {
                                                if (value !== "no_character") this.getCharactersVersions();
                                            })
                                        }
                                        fetchWithoutChar={fetchWithoutChar}
                                        myAccountLink={myAccountLink}
                                        activeEvent={activeEvent}
                                    />
                                </Col>
                                {versionsDropdown && (
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>
                                                {t("myAccount.characters.chooseCharacterToPlay.chooseVersionLabel")} {<span className="required">*</span>}
                                            </Form.Label>
                                            <Dropdown
                                                placeholder={t("myAccount.characters.chooseCharacterToPlay.chooseVersionPlaceholder")}
                                                fluid
                                                selection
                                                options={versionsDropdown}
                                                onChange={(event, data) => {
                                                    document.getElementById("chooseCharVersion").classList.remove("is-invalid");
                                                    this.setState({ selectedCharVersionID: data.value, changedValues: true });
                                                }}
                                                id="chooseCharVersion"
                                            />
                                        </Form.Group>
                                    </Col>
                                )}
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={onClose}>
                                {t("close")}
                            </Button>
                            <Button variant="primary" disabled={!changedValues} type="submit">
                                {t("info.saveChanges")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default ChooseNewCharacter;

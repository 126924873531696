import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { Form } from "react-bootstrap";
import pl from "date-fns/locale/pl";
import $ from "jquery";
registerLocale("pl", pl);

const CalendarDateAndTime = (props) => {
    const { txtLabel, value, onChangeCalendarDate, id, req, subtext, t } = props;
    $(`#${id}`).on("click", function () {
        $(this).removeClass("danger");
    });
    return (
        <Form.Group>
            <Form.Label>
                {txtLabel} {req && <span className="required">*</span>}
            </Form.Label>
            {subtext && <p className="text-small">{subtext}</p>}
            <DatePicker
                placeholderText={t("typeOrChoose")}
                selected={value}
                onChange={(date) => onChangeCalendarDate(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                locale="pl"
                className="datepicker"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={5}
                id={id}
                timeCaption={t("hour")}
            />
        </Form.Group>
    );
};

export default CalendarDateAndTime;

import React, { Fragment, Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import LoginForm from "./../forms/LoginForm";
import $ from "jquery";
import Footer from "../other/Footer";

class Login extends Component {
    checkIfLogged = async () => {
        const { fetchURL, t, onShowAlert } = this.props;
        try {
            const response = await fetch(`${fetchURL}/check-if-logged`, {
                credentials: "include",
                method: "POST",
                headers: { "Content-Type": "application/json" },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === true && fetchedData.status !== "notLogged") this.props.history.push(`/${t("url.link.myAccount")}`);
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        this.checkIfLogged();

        $("#loginContainer").css("min-height", `${$(window).outerHeight() - $("#footer").outerHeight()}px`);
        $("#loginContainer").css("padding-top", `${$("#mainNavbar").outerHeight()}px`);

        this.props.onHideSpinner(200);
    }

    handleRedirect = (e, url) => {
        e.preventDefault();
        this.props.onShowSpoonLoader();
        setTimeout(() => this.props.history.push(url), 700);
    };

    render() {
        const { t, fetchURL, onShowAlert, onPassLoggedUserData, moduleName, onGetEventData, onShowSpoonLoader, frontURL } = this.props;
        return (
            <Fragment>
                <Container id="loginContainer">
                    <Row className="no-gutters mb-3 mb-lg-0">
                        <Col className="border shadow my-lg-5">
                            <Row className="no-gutters">
                                <Col lg={6} xs={12} className="p-4">
                                    <LoginForm
                                        fetchURL={fetchURL}
                                        onShowAlert={onShowAlert}
                                        t={t}
                                        onPassLoggedUserData={onPassLoggedUserData}
                                        moduleName={moduleName}
                                        onGetEventData={onGetEventData}
                                        onShowSpoonLoader={onShowSpoonLoader}
                                        frontURL={frontURL}
                                    />
                                </Col>
                                <Col lg={6} xs={12} className="p-3 bg-darker">
                                    <div className="text-center">
                                        <p className="h6 mt-3">{t("login.register.subtitle1")}</p>
                                        <h2 className="h3 my-5">{t("login.register.title")}</h2>
                                    </div>
                                    <div>
                                        <p className="mb-2">{t("login.register.prosTitle")}</p>
                                        <ul>
                                            <li>{t("login.register.prosPoint1")}</li>
                                            <li>{t("login.register.prosPoint2")}</li>
                                            <li>{t("register.login.prosPoint3")}</li>
                                        </ul>
                                    </div>
                                    <div className="text-center">
                                        <Button variant="outline-primary" size="lg" className="my-3" onClick={(e) => this.handleRedirect(e, t("url.link.register"))}>
                                            {t("url.text.register")}
                                        </Button>

                                        <p className="h6 mt-3 mb-2">{t("login.register.subtitle2")}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withRouter(Login);

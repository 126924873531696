import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBookReader,
    faClipboard,
    faDollarSign,
    faExclamation,
    faGlassMartiniAlt,
    faHatWizard,
    faHome,
    faKey,
    faNewspaper,
    faQuestionCircle,
    faShoppingCart,
    faSignOutAlt,
    faTools,
    faUser,
    //faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { Nav, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

const MyAccountMenuTabs = (props) => {
    const { t, activeKey, reqDataNotification, link, onLogout, showAdminPanel, onCollapse } = props;

    function showNotification(name) {
        if (!reqDataNotification) return false;
        else {
            const array = reqDataNotification.filter((e) => e === name);
            if (array.length > 0) return true;
            else return false;
        }
    }
    return (
        <Fragment>
            <Nav defaultActiveKey={activeKey} className="flex-column pb-4" id="myAccountMenu">
                {showAdminPanel === "Y" && (
                    <Fragment>
                        <p className="p-3 h5 mb-0">{t("url.text.adminPanel")}</p>
                        <Nav.Link as={Link} onClick={onCollapse} to={`${link}${t("url.link.adminPanel")}-${t("url.link.news")}`} eventKey={`${t("url.link.adminPanel")}-${t("url.link.news")}`}>
                            <FontAwesomeIcon className="mr-2" icon={faNewspaper} />
                            {t("url.text.news")}
                        </Nav.Link>
                    </Fragment>
                )}
                <p className="p-3 h5 mb-0">{t("url.text.conventionData")}</p>
                <Nav.Link as={Link} onClick={onCollapse} to={`/${t("url.link.myAccount")}`} eventKey={`${t("url.link.myAccount")}`}>
                    <FontAwesomeIcon className="mr-2" icon={faHome} />
                    {t("url.text.dashboard")}
                </Nav.Link>
                <Nav.Link as={Link} onClick={onCollapse} to={`${link}${t("url.link.accountData")}`} eventKey={t("url.link.accountData")}>
                    <FontAwesomeIcon className="mr-2" icon={faUser} />
                    {t("url.text.accountData")}
                    {showNotification("account") && (
                        <OverlayTrigger placement="right" overlay={(props) => <Tooltip {...props}>{t("myAccount.notification")}</Tooltip>}>
                            <Button variant="link">
                                <span className="badge badge-pill badge-danger ml-2 align-text-bottom">
                                    <FontAwesomeIcon icon={faExclamation} />
                                </span>
                            </Button>
                        </OverlayTrigger>
                    )}
                </Nav.Link>
                <Nav.Link as={Link} onClick={onCollapse} to={`${link}${t("url.link.accreditation")}`} eventKey={t("url.link.accreditation")}>
                    <FontAwesomeIcon className="mr-2" icon={faBookReader} />
                    {t("url.text.accreditation")}
                    {(showNotification("accreditation") ||
                        showNotification("rejectedChar") ||
                        showNotification("inProgressChar") ||
                        showNotification("identNickname") ||
                        showNotification("legalAge") ||
                        showNotification("tac") ||
                        showNotification("notPaied")) && (
                        <OverlayTrigger placement="right" overlay={(props) => <Tooltip {...props}>{t("myAccount.notification")}</Tooltip>}>
                            <Button variant="link">
                                <span className="badge badge-pill badge-danger ml-2 align-text-bottom">
                                    <FontAwesomeIcon icon={faExclamation} />
                                </span>
                            </Button>
                        </OverlayTrigger>
                    )}
                </Nav.Link>
                {/* Czasowo wyłączone z użycia */}
                {/*<Nav.Link as={Link} onClick={onCollapse} to={`${link}${t("url.link.group")}`} eventKey={t("url.link.group")}>
                    <FontAwesomeIcon className="mr-2" icon={faUserFriends} />
                    {t("url.text.group")}
                </Nav.Link>
                 <Nav.Link as={Link} onClick={onCollapse} to={`${link}${t("url.link.accomodation")}`} eventKey={t("url.link.accomodation")}>
                    <FontAwesomeIcon className="mr-2" icon={faBed} />
                    {t("url.text.accomodation")}
                </Nav.Link> */}
                <Nav.Link as={Link} onClick={onCollapse} to={`${link}${t("url.link.characters")}`} eventKey={t("url.link.characters")}>
                    <FontAwesomeIcon className="mr-2" icon={faHatWizard} />
                    {t("url.text.characters")}
                </Nav.Link>
                <Nav.Link as={Link} onClick={onCollapse} to={`${link}${t("url.link.volunteering")}`} eventKey={t("url.link.volunteering")}>
                    <FontAwesomeIcon className="mr-2" icon={faTools} />
                    {t("url.text.volunteering")}
                </Nav.Link>
                <Nav.Link as={Link} onClick={onCollapse} to={`${link}${t("url.link.shop")}`} eventKey={t("url.link.shop")}>
                    <FontAwesomeIcon className="mr-2" icon={faShoppingCart} />
                    {t("url.text.shop")}
                </Nav.Link>
                <Nav.Link as={Link} onClick={onCollapse} to={`${link}${t("url.link.shopOrders")}`} eventKey={t("url.link.shopOrders")}>
                    <FontAwesomeIcon className="mr-2" icon={faClipboard} />
                    {t("myAccount.shop.itemsList.orders")}
                </Nav.Link>
                <Nav.Link as={Link} onClick={onCollapse} to={`${link}${t("url.link.barClient")}`} eventKey={t("url.link.barClient")}>
                    <FontAwesomeIcon className="mr-2" icon={faGlassMartiniAlt} />
                    {t("myAccount.barClient.title")}{" "}
                    {showNotification("barClientBalance") && (
                        <OverlayTrigger placement="right" overlay={(props) => <Tooltip {...props}>{t("myAccount.notification")}</Tooltip>}>
                            <Button variant="link">
                                <span className="badge badge-pill badge-danger ml-2 align-text-bottom">
                                    <FontAwesomeIcon icon={faExclamation} />
                                </span>
                            </Button>
                        </OverlayTrigger>
                    )}
                </Nav.Link>
                <Nav.Link as={Link} onClick={onCollapse} to={`${link}${t("url.link.transactions")}`} eventKey={t("url.link.transactions")}>
                    <FontAwesomeIcon className="mr-2" icon={faDollarSign} />
                    {t("myAccount.transactions.title")}
                </Nav.Link>
                <p className="p-3 h5 mb-0">{t("url.text.accountDataText")}</p>
                <Nav.Link as={Link} onClick={onCollapse} to={`${link}${t("url.link.changePass")}`} eventKey={t("url.link.changePass")}>
                    <FontAwesomeIcon className="mr-2" icon={faKey} />
                    {t("url.text.changePass")}
                </Nav.Link>
                <Nav.Link as={Link} onClick={onCollapse} to={`${link}${t("url.link.help")}`} eventKey={t("url.link.help")}>
                    <FontAwesomeIcon className="mr-2" icon={faQuestionCircle} />
                    {t("url.text.help")}
                </Nav.Link>
                <Button className="nav-link" variant="link" onClick={onLogout}>
                    <FontAwesomeIcon className="mr-2" icon={faSignOutAlt} />
                    {t("url.text.logout")}
                </Button>
            </Nav>
        </Fragment>
    );
};

export default MyAccountMenuTabs;

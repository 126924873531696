import React, { Component, Fragment } from "react";
import SmallBanner from "../../other/SmallBanner";
import { Row, Col } from "react-bootstrap";
import $ from "jquery";
import NewsList from "./NewsList";
import NewsPost from "./NewsPost";
import Footer from "../../other/Footer";

class News extends Component {
    componentDidMount() {
        this.props.onHideSpinner(200);
        $("#news").css({ minHeight: `${$(window).outerHeight() - $("#footer").outerHeight()}px` });
        $(".news-texts").css("width", `${$(".news").width() - 210}px`);
    }

    render() {
        const { t, frontURL, fetchURL, onShowAlert, location, onShowSpoonLoader } = this.props;
        return (
            <Fragment>
                <div id="news">
                    <Row className="no-gutters">
                        <Col>
                            <SmallBanner
                                frontURL={frontURL}
                                bgBottom={`${frontURL}/images/small-banners/b1-bottom.webp`}
                                bgDesktop={`${frontURL}/images/small-banners/b1.webp`}
                                title={t("news.title")}
                                subtitle={t("news.subtitle")}
                            />
                        </Col>
                    </Row>
                    {location.pathname === `/${t("url.link.news")}` ? (
                        <NewsList t={t} fetchURL={fetchURL} frontURL={frontURL} onShowAlert={onShowAlert} />
                    ) : (
                        <NewsPost t={t} fetchURL={fetchURL} frontURL={frontURL} onShowAlert={onShowAlert} location={location} />
                    )}
                </div>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default News;

import React from "react";
import { Fragment } from "react";
import { Alert } from "react-bootstrap";

const AlertNoPermission = () => {
    return (
        <Fragment>
            <Alert variant="danger" className="w-100 alert-small">
                Brak uprawnień do wyświetlenia danych.
            </Alert>
        </Fragment>
    );
};

export default AlertNoPermission;

import React, { Fragment } from "react";
import { Col, Row, Table, Button } from "react-bootstrap";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import { withRouter } from "react-router-dom";
import i18n from "../../../../../i18n";

const ShopItemsCart = (props) => {
    const { t, loading, cart, shopLink } = props;
    //console.log(cart);
    return (
        <Fragment>
            <Row>
                <Col xs={12}>
                    <p className="my-3 h4 text-uppercase text-center">{t("myAccount.shop.itemsList.cart")}</p>
                </Col>
            </Row>
            {loading ? (
                <Row>
                    <Col>
                        <LoadingProgressBar t={t} />
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col>
                        {cart ? (
                            <Fragment>
                                <Table size="sm">
                                    <tbody>
                                        {cart.map((e, i) => (
                                            <tr key={i}>
                                                {e.langs ? (
                                                    <td>
                                                        {e.langs.map((l, j) => (
                                                            <Fragment key={j}>{l.lang_code === i18n.language && l.shit_name}</Fragment>
                                                        ))}
                                                        {e.shit_variant_name && `, ${e.shit_variant_name}`} x{e.shit_quantity}
                                                    </td>
                                                ) : (
                                                    <td></td>
                                                )}
                                                <td className="bold text-right">
                                                    {(e.shit_price * e.shit_quantity).toFixed(2)} {t("currency")}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <Button variant="outline-primary" size="sm" className="w-100" onClick={() => props.history.push(`${shopLink}/${t("url.link.shopCart")}`)}>
                                    {t("myAccount.shop.goToSummary")}
                                </Button>
                            </Fragment>
                        ) : (
                            <p className="opacity-4 text-center">{t("myAccount.shop.emptyCart")}</p>
                        )}
                    </Col>
                </Row>
            )}
        </Fragment>
    );
};

export default withRouter(ShopItemsCart);

import React, { Component, Fragment } from "react";
import $ from "jquery";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import Email from "../forms/Email";
import Footer from "../other/Footer";
import i18n from "../../../i18n";
import ReCAPTCHA from "react-google-recaptcha";

class ResetPassword extends Component {
    state = { email: "", token: null };

    validateEmail = (email) => {
        const { t } = this.props;
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            $("#userEmail").addClass("danger");
            return t("error.invalidEmail");
        } else return "";
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { email, token } = this.state;
        const { onShowAlert, t } = this.props;

        onShowAlert("");
        let errorMsg = [];
        if (!email) {
            $("#userEmail").addClass("is-invalid");
            errorMsg.push(t("error.emptyEmail"));
        } else {
            const validate = this.validateEmail(email);
            if (validate) {
                $("#userEmail").addClass("is-invalid");
                errorMsg.push(validate);
            }
        }

        if (!token) errorMsg.push(t("error.recaptchaEmpty"));

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            $("#userEmail").removeClass("is-invalid");
            this.resetPassword();
        }
    };

    resetPassword = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.resetingPass"), fading: false });
        const passData = {
            email: this.state.email,
            lang: i18n.language === "en" ? "en" : "pl",
            token: this.state.token,
        };
        try {
            const response = await fetch(`${fetchURL}/reset-password`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    } else {
                        onShowAlert({ variant: "success", message: t(`success.resetPasswordSuccess`), fading: 3000 });
                        this.setState({ email: "", token: null });
                    }
                });
            }
        } catch (err) {
            this.onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        $(document).ready(function () {
            $("#remindPassContainer").css("min-height", `${$(window).outerHeight() - $("#footer").outerHeight()}px`);
        });
        this.props.onHideSpinner(200);
    }

    render() {
        const { email } = this.state;
        const { t, onShowSpoonLoader, frontURL } = this.props;
        return (
            <Fragment>
                <Container className="pt-lg-5" id="remindPassContainer">
                    <Row className="no-gutters">
                        <Col className="border shadow mt-lg-5" lg={{ span: 6, offset: 3 }} xs={12}>
                            <Row className="no-gutters">
                                <Col className="p-3 bg-darker">
                                    <div className="text-center">
                                        <h2 className="h3 my-5">{t("remindPass.title")}</h2>
                                        <p className="h6 mb-4">{t("remindPass.subtitle")}</p>
                                    </div>
                                    <Form onSubmit={this.handleCheckValidation} autoComplete="off" noValidate>
                                        <Row>
                                            <Col xs={12}>
                                                <Email value={email} onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })} id="userEmail" name="email" t={t} />
                                            </Col>
                                            <Col xs={12} className="text-center my-3" id="recaptcha">
                                                <ReCAPTCHA
                                                    sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
                                                    onChange={(value) => this.setState({ token: value })}
                                                    theme="dark"
                                                />
                                            </Col>
                                            <Col xs={12} className="text-center">
                                                <Button type="submit">{t("remindPass.submitBtn")}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default ResetPassword;

import React, { Component } from "react";
import { Form, Row, Col, Alert } from "react-bootstrap";
import $ from "jquery";

class Password extends Component {
    state = { alertValue1: "", alertValue2: "" };

    render() {
        const { alertValue1, alertValue2 } = this.state;
        const { value1, value2, onChangeValue, option, arrangement, t, onValidatePassword, label1, label2 } = this.props;

        return (
            <Row>
                <Col md={arrangement === "block" || !option || option === "single" ? 12 : 6} xs={12}>
                    <Form.Label>
                        {label1 ? label1 : t("register.passInput.label")} <span className="required"> *</span>
                    </Form.Label>
                    <Form.Control
                        type="password"
                        id="password"
                        placeholder={t("register.passInput.placeholder")}
                        name="password"
                        value={value1}
                        onChange={(e) => {
                            onChangeValue(e);

                            if (!onValidatePassword(e.target.value)) {
                                this.setState({ alertValue1: t("error.passRegExp") });
                                $(`#password`).addClass("is-invalid");
                            } else {
                                this.setState({ alertValue1: "" });
                                $(`#password`).removeClass("is-invalid");
                            }
                        }}
                        autoComplete={option === "double" ? "new-password" : "on"}
                        className="mb-3 req"
                    />
                    {alertValue1 && (
                        <Alert variant="danger" className="alert-small mt-1">
                            {alertValue1}
                        </Alert>
                    )}
                </Col>
                {option === "double" && (
                    <Col md={arrangement === "block" ? 12 : 6} xs={12}>
                        <Form.Label>
                            {label2 ? label2 : t("register.pass2Input.label")} <span className="required"> *</span>
                        </Form.Label>
                        <Form.Control
                            type="password"
                            id="repeatPassword"
                            placeholder={t("register.pass2Input.placeholder")}
                            name="repeatPassword"
                            value={value2}
                            onChange={(e) => {
                                onChangeValue(e);

                                if (!onValidatePassword(e.target.value)) {
                                    this.setState({ alertValue2: t("error.passRegExp") });
                                    $(`#repeatPassword`).addClass("is-invalid");
                                } else {
                                    this.setState({ alertValue2: "" });
                                    $(`#repeatPassword`).removeClass("is-invalid");
                                }
                            }}
                            autoComplete="new-password"
                            className="mb-3 req"
                        />
                        {alertValue2 && (
                            <Alert variant="danger" className="alert-small mt-1">
                                {alertValue2}
                            </Alert>
                        )}
                    </Col>
                )}
            </Row>
        );
    }
}

export default Password;

import React, { Fragment, Component } from "react";
import { Modal, Button, Row, Col, Form, Image, Alert } from "react-bootstrap";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

class EditAvatar extends Component {
    state = {
        avatar: this.props.avatar && this.props.avatar !== "noFile" ? this.props.avatar : null,
        changedValues: false,
        invalidFile: false,
    };

    uploadAvatar = async (data) => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.editingData"), fading: false });

        try {
            const response = await fetch(`${fetchURL}/assign-avatar-to-user`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                body: data,
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        onShowAlert({ variant: "success", message: t(`success.dataUpdatedSuccessfully`), fading: 2000 });
                        this.props.onCloseAndRefresh();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    handleUploadNewFile = (event) => {
        const newFile = event.target.files[0];

        if (newFile) {
            /* 16777216 - 16 MB, 2097152 - 2MB */
            if (newFile.type !== "image/png" && newFile.type !== "image/jpeg") this.setState({ invalidFile: "invalidFileFormat" });
            else if (newFile.size > 2097152) this.setState({ invalidFile: "tooBigFile" });
            else {
                this.setState({ invalidFile: false });
                var reader = new FileReader();
                reader.onload = function (e) {
                    $(`#avatar`).attr("src", e.target.result);
                    $(`#avatar`).attr("alt", "newAvatar");
                };
                reader.readAsDataURL(newFile);
                this.setState({ avatar: newFile, changedValues: true });
            }
        }
    };

    handleUploadAvatar = (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append("file", this.state.avatar);
        this.uploadAvatar(data);
    };

    render() {
        const { avatar, changedValues, invalidFile } = this.state;
        const { show, onClose, t } = this.props;

        return (
            <Fragment>
                <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
                    <Form onSubmit={this.handleUploadAvatar} autoComplete="off">
                        <Modal.Header closeButton>
                            <Modal.Title>{t("myAccount.dataAccount.editAvatar.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="no-gutters">
                                <Col xs={12}>
                                    <p className="mb-4 h6" style={{ lineHeight: "1.2" }}>
                                        {t("myAccount.dataAccount.editAvatar.subtitle")}
                                    </p>
                                </Col>

                                <Col xs={12} className="text-center">
                                    <div className="avatar mx-auto d-inline-block">
                                        {!avatar || avatar === "noFile" ? (
                                            <FontAwesomeIcon icon={faUser} />
                                        ) : changedValues ? (
                                            <Image src="" alt="" id="avatar" />
                                        ) : (
                                            <img src={`data:${avatar.type};base64,${avatar.data}`} alt={avatar.name} id="avatar" />
                                        )}
                                    </div>
                                </Col>
                                <Col xs={12} className="text-center">
                                    <div className="input-group my-3">
                                        <div className="choose-file m-auto">
                                            <input
                                                type="file"
                                                className="choose-file-input btn-outline-secondary"
                                                id="chooseFile"
                                                name="avatar_file"
                                                onChange={this.handleUploadNewFile}
                                                accept="image/x-png,image/jpeg"
                                            />
                                            <label className="choose-file-label btn btn-outline-secondary" htmlFor="chooseFile" aria-describedby="chooseFileAddon">
                                                {t("myAccount.dataAccount.editAvatar.chooseNewAvatar")}
                                            </label>
                                        </div>
                                    </div>
                                </Col>
                                {invalidFile && (
                                    <Col xs={12} className="text-center">
                                        <Alert variant="danger" className="alert-small">
                                            {t(`error.${invalidFile}`)}
                                        </Alert>
                                    </Col>
                                )}
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={onClose}>
                                {t("info.cancel")}
                            </Button>
                            <Button variant="primary" disabled={!changedValues ? true : false} type="submit">
                                {t("info.saveChanges")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default EditAvatar;

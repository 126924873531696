import React, { Fragment, Component } from "react";
import { Alert, Button, Form, Modal, Row, Col } from "react-bootstrap";
import AlertNoPermission from "../alerts/AlertNoPermission";
import LoadingProgressBar from "../spinners/LoadingProgressBar";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import BarForm from "../pages/myAccount/volunteering/BarForm";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class EditBarFormModal extends Component {
    state = { noPermission: false, loading: true, alert: null, changedValues: false, barFormData: null };

    getBarData = async () => {
        const { fetchURL, t, onShowAlert, activeEvent } = this.props;
        const passData = {
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/get-bar-data`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status !== true)
                        this.setState({ alert: { variant: fetchedData.status, message: t(`${fetchedData.status}.${fetchedData.code}`) }, loading: false });
                    else {
                        const basicData = fetchedData.basicData[0];
                        this.setState({
                            barFormData: [
                                {
                                    avalOptions: fetchedData.avalOptions,
                                    numberOfDuties: basicData.pab_number_of_duties,
                                    barComment: basicData.pab_comment ? basicData.pab_comment : "",
                                },
                            ],
                            loading: false,
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    insertOrUpdateForm = async () => {
        const { fetchURL, t, onShowAlert, activeEvent, onRefreshData } = this.props;
        const passData = {
            activeEvent: activeEvent,
            barFormData: this.state.barFormData,
        };
        onShowAlert({ variant: "info", message: t(this.props.option === "edit" ? "info.editingData" : "info.submittingForm"), fading: false });
        try {
            const response = await fetch(`${fetchURL}/${this.props.option === "edit" ? "update-bar-data" : "insert-bar-data"}`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status !== true)
                        this.setState({ alert: { variant: fetchedData.status, message: t(`${fetchedData.status}.${fetchedData.code}`) }, loading: false });
                    else {
                        onShowAlert({
                            variant: "success",
                            message: t(`success.${this.props.option === "edit" ? "formEditedSuccessfully" : "formSubmittedSuccessfully"}`),
                            fading: 2000,
                        });
                        onRefreshData();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleChangeBarData = (value, name, enableBtn) => {
        const { barFormData } = this.state;
        const list = [...barFormData];
        list[0][name] = value;
        this.setState({ barFormData: list, changedValues: enableBtn });
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { barFormData } = this.state;
        const { onShowAlert, t } = this.props;
        onShowAlert("");
        let errorMsg = [];

        if (!barFormData[0].numberOfDuties) {
            $("#BARnumberOfDuties").addClass("is-invalid");
            errorMsg.push(t("error.emptyNumberOfDuties"));
        }

        let avalError = false;
        let allAvalsAreNo = true;
        barFormData[0].avalOptions.forEach((e, i) => {
            if (!e.answer) {
                $(`#BARavalAnswer${i}`).addClass("is-invalid");
                avalError = t("error.avalAnswersNotFilled");
            } else if (e.answer !== "N") allAvalsAreNo = false;
        });

        if (avalError) errorMsg.push(avalError);
        if (allAvalsAreNo) errorMsg.push(t("error.allAvalsAreCheckedForNo"));

        var regExpWhiteSpace = /^\s*$/;

        if (barFormData[0].barComment && regExpWhiteSpace.test(barFormData[0].barComment)) {
            $("#barComment").addClass("is-invalid");
            errorMsg.push(t("error.commentFormInvalid"));
        } else if (barFormData[0].barComment.length > 255) {
            $("#barComment").addClass("is-invalid");
            errorMsg.push(t("error.commentFormToLong"));
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            this.insertOrUpdateForm();
        }
    };

    handleChangeBarData = (value, name, enableBtn) => {
        const { barFormData } = this.state;
        const list = [...barFormData];
        list[0][name] = value;
        this.setState({ barFormData: list, changedValues: enableBtn });
    };

    componentDidMount() {
        if (this.props.activeEvent && this.props.activeEvent !== "noActiveEvent")
            if (this.props.option === "edit") this.getBarData();
            else
                this.setState({
                    barFormData: [{ avalOptions: null, numberOfDuties: null, barComment: "" }],
                    loading: false,
                });
        else this.setState({ showView: "noActiveEvent" });
    }

    render() {
        const { noPermission, loading, alert, changedValues, barFormData } = this.state;
        const { t, show, onClose, fetchURL, onShowAlert, activeEvent } = this.props;
        return (
            <Fragment>
                <Modal show={show} onHide={onClose} size="lg" backdrop="static" keyboard={false}>
                    <Form onSubmit={this.handleCheckValidation}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t(`myAccount.volunteering.${this.props.option === "edit" ? "editForm" : "fillForm"}`)}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {noPermission ? (
                                <AlertNoPermission t={t} />
                            ) : alert ? (
                                <Alert className="my-5 text-center" variant={alert.variant}>
                                    <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /> {alert.message}
                                </Alert>
                            ) : loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <div className="my-account-show" id="volunteeringForm">
                                    <Row className="no-gutters">
                                        <Col xs={12} className="fill-form mt-3 mb-5 px-4 py-3">
                                            <BarForm
                                                appType="BAR"
                                                t={t}
                                                fetchURL={fetchURL}
                                                onShowAlert={onShowAlert}
                                                activeEvent={activeEvent}
                                                onChangeView={(view) => this.setState({ showView: view })}
                                                changedValues={changedValues}
                                                onChangeBarData={(value, name, enableBtn) => this.handleChangeBarData(value, name, enableBtn)}
                                                barFormData={barFormData}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary" type="button" onClick={onClose}>
                                {t("info.cancel")}
                            </Button>
                            <Button variant="primary" type="submit" disabled={!changedValues}>
                                {t(this.props.option === "edit" ? "info.save" : "myAccount.formSubmitBtn")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default withRouter(EditBarFormModal);

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Row, Col, Alert, Nav, Table } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import NoPermission from "../../../other/NoPermission";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import $ from "jquery";
import BarOrdersListModal from "../../../modals/BarOrdersListModal";
import PayBarCreditModal from "../../../modals/PayBarCreditModal";

class TransactionsMain extends Component {
    state = {
        loading: true,
        noPermission: false,
        infoAlert: null,
        transactions: null,
        showModal: null,
        activeTabIndex: 0,
        eventsList: this.props.eventsList,
    };

    checkShowTransactions = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = { eventsList: this.props.eventsList };
        try {
            const response = await fetch(`${fetchURL}/show-transactions`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onNotLogged();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        const transactions = fetchedData.transactions,
                            eventsList = fetchedData.eventsList;

                        this.setState({ loading: false, transactions: transactions.length > 0 ? transactions : "noParticipationInAnyEvent", eventsList: eventsList });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    handleRefreshData = () => {
        this.setState({ loading: true }, () => {
            window.scrollTo(0, 0);
            this.checkShowTransactions();
        });
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.checkShowTransactions();
    }

    render() {
        const { loading, noPermission, infoAlert, transactions, activeTabIndex, eventsList, showModal } = this.state;
        const { fetchURL, onShowAlert, t, activeEvent } = this.props;

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : (
                    <Fragment>
                        <Row className="no-gutters">
                            <Col className="text-center">
                                <p className="h3 my-4 text-uppercase">{t("myAccount.transactions.title")}</p>
                            </Col>
                        </Row>
                        {loading ? (
                            <LoadingProgressBar t={t} />
                        ) : infoAlert ? (
                            <div className="text-center">
                                <Alert variant="info">
                                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                    {infoAlert}
                                </Alert>
                            </div>
                        ) : (
                            <div className="my-account-show">
                                <Row className="no-gutters">
                                    <Col xs={12} className="text-center mt-4">
                                        {transactions === "noParticipationInAnyEvent" ? (
                                            <Alert variant="info">
                                                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                                {t("myAccount.accreditation.noParticipationInAnyEvent")}
                                            </Alert>
                                        ) : (
                                            <Fragment>
                                                <Nav
                                                    variant="pills"
                                                    defaultActiveKey={activeEvent && activeEvent !== "noActiveEvent" ? activeEvent.event_code : eventsList[0].event_code}
                                                    id="accrPills"
                                                    className="convention-pills"
                                                >
                                                    {eventsList.map((e, i) => (
                                                        <Fragment key={i}>
                                                            {transactions[i] !== "noParticipation" && (
                                                                <Nav.Item>
                                                                    <Nav.Link
                                                                        eventKey={e.event_code}
                                                                        onClick={() => {
                                                                            $(".account-data-container").fadeOut(200);
                                                                            setTimeout(() => {
                                                                                this.setState({ activeTabIndex: i }, () => {
                                                                                    $(".account-data-container").fadeIn(200);
                                                                                });
                                                                            }, 190);
                                                                        }}
                                                                    >
                                                                        {e.event_name}
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            )}
                                                        </Fragment>
                                                    ))}
                                                </Nav>

                                                <div className="account-data-table">
                                                    {typeof transactions[activeTabIndex] === "string" ? (
                                                        <div className="account-data-container">
                                                            <Alert variant="info" className="mb-0">
                                                                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                                                {t(`myAccount.accreditation.${transactions[activeTabIndex]}`)}
                                                            </Alert>
                                                            {transactions[activeTabIndex] === "notFilledAccrForm" && (
                                                                <Link
                                                                    to={`${this.props.accrLink}/${t("url.link.accrForm")}`}
                                                                    className="btn btn-primary mt-2"
                                                                    onClick={() => this.props.onChangeActiveKey(t("url.link.accreditation"))}
                                                                >
                                                                    {t("myAccount.accreditation.notFilledFormBtn")}
                                                                </Link>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="account-data-container">
                                                            <p className="h5 my-4 text-uppercase">{t(`myAccount.transactions.balance`)}</p>
                                                            <Table responsive className="border">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>{t("myAccount.transactions.accreditation")}</td>
                                                                        <td>
                                                                            {transactions[activeTabIndex].payments.payment_amount_accr
                                                                                ? transactions[activeTabIndex].payments.payment_amount_accr
                                                                                : 0}{" "}
                                                                            {t("currency")}
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{t("myAccount.transactions.bar")}</td>
                                                                        <td>
                                                                            {transactions[activeTabIndex].payments.payment_amount_bar
                                                                                ? transactions[activeTabIndex].payments.payment_amount_bar
                                                                                : 0}{" "}
                                                                            {t("currency")}
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{t("myAccount.transactions.shop")}</td>
                                                                        <td>
                                                                            {transactions[activeTabIndex].payments.payment_amount_shop
                                                                                ? transactions[activeTabIndex].payments.payment_amount_shop
                                                                                : 0}{" "}
                                                                            {t("currency")}
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                            <p className="h5 my-4 text-uppercase">{t(`myAccount.transactions.transactionsList`)}</p>
                                                            {transactions[activeTabIndex].transactions.length > 0 ? (
                                                                <Table responsive hover id="transactionsTable" className="border">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>{t("no.")}</th>
                                                                            <th>{t("myAccount.transactions.paymentType")}</th>
                                                                            <th>{t("myAccount.transactions.paymentAmount")}</th>
                                                                            <th>{t("myAccount.transactions.paymentReceiver")}</th>
                                                                            <th>{t("myAccount.transactions.paymentDate")}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {transactions[activeTabIndex].transactions.map((e, i) => (
                                                                            <tr key={i}>
                                                                                <td>{i + 1}</td>
                                                                                <td>{t(`myAccount.transactions.paymentTypes.${e.payment_type_code}`)}</td>
                                                                                <td>
                                                                                    {e.payment_amount > 0 && "+"}
                                                                                    {e.payment_amount} {t("currency")}
                                                                                </td>
                                                                                <td>{e.payment_receiver ? e.payment_receiver : <span className="text-muted">{t("na.")}</span>}</td>
                                                                                <td>{e.payment_date}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            ) : (
                                                                <p className="text-center my-3 text-muted">{t("myAccount.transactions.noTransactions")}</p>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </Fragment>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Fragment>
                )}

                {showModal && (
                    <Fragment>
                        {showModal.option === "ordersList" && (
                            <BarOrdersListModal
                                t={t}
                                onClose={() => this.setState({ showModal: null })}
                                barOrdersList={transactions[activeTabIndex].barOrders}
                                clientType={transactions[activeTabIndex].client_type}
                            />
                        )}
                        {showModal.option === "payBarCredit" && (
                            <PayBarCreditModal
                                fetchURL={fetchURL}
                                onShowAlert={onShowAlert}
                                t={t}
                                onClose={() => this.setState({ showModal: null })}
                                amount={-1 * transactions[activeTabIndex].client_balance}
                                transferTitle={`${eventsList[activeTabIndex].event_name} – ${t("myAccount.transactions.payBarCredit.transfer")}`}
                            />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default withRouter(TransactionsMain);

import React, { Fragment, Component } from "react";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import MyAccountMenuTabs from "../../navigation/MyAccountMenuTabs";
import $ from "jquery";

class MyAccountMenu extends Component {
    state = {
        showAdminPanel: "N",
    };
    handleLogout = async () => {
        const { fetchURL, t, onShowAlert, onLogout, onCheckIfLogged, onShowSpoonLoader } = this.props;
        onShowSpoonLoader();
        try {
            onShowAlert({ variant: "info", message: t("info.loggingOut"), fading: false });
            const response = await fetch(`${fetchURL}/logout`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === true) {
                        onLogout();

                        setTimeout(() => {
                            onShowAlert({ variant: "success", message: t("success.logoutSuccessfully"), fading: 2000 });
                            this.props.history.push(`/${t("url.link.login")}`);
                        }, 500);
                    } else {
                        onCheckIfLogged(); //mechanizm do przekierowania na zalogowanie się
                        onShowAlert({ variant: "info", message: t("info.logoutInfo"), fading: 3000 });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.errLogout")} (${err}).`, fading: false });
        }
    };

    showAdminPanel = async () => {
        const { fetchURL, t, onShowAlert } = this.props;
        try {
            const response = await fetch(`${fetchURL}/show-admin-panel`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    this.setState({ showAdminPanel: fetchedData.status === true ? "Y" : "N" });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.errLogout")} (${err}).`, fading: false });
        }
    };

    componentDidMount() {
        this.showAdminPanel();
    }

    handleCollapse = () => {
        /* $("#myAccountMenuAccordion .card-header + div").removeClass("collapse show");
        $("#myAccountMenuAccordion .card-header + div").addClass("collapsing");
        $("#myAccountMenuAccordion .card-header + div").removeClass("collapsing");
        $("#myAccountMenuAccordion .card-header + div").addClass("collapse"); */
        $("#myAccountMenuAccordion .card-header + div").removeClass("show");
        this.btnCollapseText();
    };

    btnCollapseText = () => {
        const { t } = this.props;
        $("#myAccountMenuAccordion #myAccountMenu").removeClass("show-menu");
        $("#myAccountMenuBtn").text(t("myAccount.menu.show"));
    };

    handleRedirect = (e, url) => {
        e.preventDefault();
        //console.log(url);
        this.props.history.push(url);
    };

    render() {
        const { showAdminPanel } = this.state;
        const { t, link, reqDataNotification, activeKey, onChangeActiveKey } = this.props;

        return (
            <Fragment>
                <div id="myAccountMenuAccordion" className="d-lg-none d-block">
                    <Button
                        variant="outline-primary"
                        className="w-100"
                        id="myAccountMenuBtn"
                        onClick={() => {
                            if (!$("#myAccountMenuAccordion #myAccountMenu").hasClass("show-menu")) {
                                $("#myAccountMenuAccordion #myAccountMenu").addClass("show-menu");
                                $("#myAccountMenuBtn").text(t("myAccount.menu.hide"));
                            } else {
                                $("#myAccountMenuAccordion #myAccountMenu").removeClass("show-menu");
                                $("#myAccountMenuBtn").text(t("myAccount.menu.show"));
                            }
                        }}
                    >
                        {t("myAccount.menu.show")}
                    </Button>
                    <MyAccountMenuTabs
                        t={t}
                        activeKey={activeKey}
                        link={link}
                        reqDataNotification={reqDataNotification}
                        onLogout={() => this.handleLogout()}
                        showAdminPanel={showAdminPanel}
                        onCollapse={this.handleCollapse}
                        onChangeActiveKey={onChangeActiveKey}
                        onRedirect={this.handleRedirect}
                    />
                </div>
                <div className="d-none d-lg-inline">
                    <MyAccountMenuTabs t={t} activeKey={activeKey} link={link} reqDataNotification={reqDataNotification} onLogout={() => this.handleLogout()} showAdminPanel={showAdminPanel} />
                </div>
            </Fragment>
        );
    }
}

export default withRouter(MyAccountMenu);

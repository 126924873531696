import React, { Fragment } from "react";
import { faSadTear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "react-bootstrap";

const NoPermission = (props) => {
    const { t } = props;
    return (
        <Fragment>
            <Row>
                <Col className="text-center mt-5">
                    <img src="https://media2.giphy.com/media/2sdLxid6Y31kNFVPvr/giphy.gif" alt="Itysl 104 GIF by Vulture.com" />
                    <p className="my-4 h2 text-uppercase">{t("noPermission.title")}</p>

                    <p className="mt-4">
                        {t("noPermission.text1")} <FontAwesomeIcon icon={faSadTear} />
                        <br />
                        {t("noPermission.text2")}
                    </p>

                    <Button size="lg" className="mt-3" variant="primary" onClick={() => window.location.reload()}>
                        {t("noPermission.reloadBtn")}
                    </Button>
                </Col>
            </Row>
        </Fragment>
    );
};

export default NoPermission;

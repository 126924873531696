import React, { Component, Fragment } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import TextareaForm from "../../forms/TextareaForm";
import TextForm from "../../forms/text-inputs/TextForm";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import $ from "jquery";

class Help extends Component {
    state = { email: "", name: "", title: "", message: "", loading: true };

    sendMailByContactForm = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("activateAccount.sendingMail"), fading: false });

        const passData = {
            email: this.state.email,
            name: this.state.name,
            title: this.state.title,
            message: this.state.message,
        };
        try {
            const response = await fetch(`${fetchURL}/send-email`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.errCannotSendMail`), fading: false });
                    else if (fetchedData.status === "notLogged") this.props.onNotLogged();
                    else {
                        onShowAlert({ variant: "success", message: t(`success.mailSentSuccessfully`), fading: 2000 });
                        this.setState({ title: "", message: "" });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { email, title, message, name } = this.state;
        const { t, onShowAlert } = this.props,
            regExpWhiteSpace = /^\s*$/;

        onShowAlert("");
        let errorMsg = [];
        if (!email) {
            errorMsg.push(t("error.emptyEmail"));
        }

        if (!title) {
            $("#mailTitle").addClass("is-invalid");
            errorMsg.push(t("error.emptyTitleMessage"));
        } else if (regExpWhiteSpace.test(title)) {
            $("#mailTitle").addClass("is-invalid");
            errorMsg.push(t("error.titleMessageInvalid"));
        } else if (title.length > 50) {
            $("#mailTitle").addClass("is-invalid");
            errorMsg.push(t("error.titleMessageTooLong"));
        }

        if (!message) {
            $("#mailMessage").addClass("is-invalid");
            errorMsg.push(t("error.emptyMailMessage"));
        } else if (regExpWhiteSpace.test(message)) {
            $("#mailMessage").addClass("is-invalid");
            errorMsg.push(t("error.mailMessageInvalid"));
        } else if (message.length > 4000) {
            $("#mailMessage").addClass("is-invalid");
            errorMsg.push(t("error.mailMessageTooLong"));
        }

        if (!name) {
            errorMsg.push(t("error.emptyMailName"));
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            this.sendMailByContactForm();
        }
    };

    getUserData = async () => {
        const { fetchURL, onShowAlert, t } = this.props;

        try {
            const response = await fetch(`${fetchURL}/get-email-and-name`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        this.setState({ email: fetchedData.email, name: fetchedData.name, loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        this.getUserData();
    }

    render() {
        const { title, message, loading } = this.state;
        const { t } = this.props;
        return (
            <Fragment>
                <Row className="no-gutters">
                    <Col className="text-center">
                        <p className="h3 my-4 text-uppercase">{t("myAccount.help.title")}</p>
                    </Col>
                </Row>
                <div className="my-account-show">
                    <Row className="no-gutters">
                        <Col xs={12} xl={{ span: 6, offset: 3 }}>
                            <p className="h5 mt-4 mb-3 text-uppercase text-center">{t("myAccount.help.contactForm.title")}</p>
                            <p className="mt-4">{t("myAccount.help.contactForm.subtitle")}</p>
                            {loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <Form onSubmit={this.handleCheckValidation} autoComplete="off" className="mt-5">
                                    <TextForm
                                        value={title}
                                        onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })}
                                        labelTxt={t("contactForm.title.label")}
                                        placeholderTxt={t("contactForm.title.placeholder")}
                                        name="title"
                                        id="mailTitle"
                                        req
                                    />
                                    <TextareaForm
                                        value={message}
                                        onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })}
                                        labelTxt={t("contactForm.message.label")}
                                        name="message"
                                        placeholderTxt={t("contactForm.message.placeholder")}
                                        id="mailMessage"
                                        req
                                    />

                                    <Form.Group className="text-center">
                                        <Button variant="primary" size="lg" type="submit" disabled={!title || !message}>
                                            {t("contactForm.submit")}
                                        </Button>
                                    </Form.Group>
                                </Form>
                            )}
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(Help);

import React, { Fragment, Component } from "react";
import { Modal, Button, Row, Col, Form, Alert } from "react-bootstrap";
import TextForm from "./../forms/text-inputs/TextForm";
import $ from "jquery";

class EditFbLink extends Component {
    state = {
        newUserFbLink: this.props.userData[0].user_facebook_link ? this.props.userData[0].user_facebook_link : "",
        changedValues: false,
    };

    validateFbLink = (fb) => {
        const regExp = /^https:\/\/www.facebook.com\/.+/;
        const regExp2 = /^https:\/\/facebook.com\/.+/;

        if (regExp.test(fb) || regExp2.test(fb) || !fb) {
            $("#userFbLink").removeClass("is-invalid");
            return true;
        } else {
            $("#userFbLink").addClass("is-invalid");
            return false;
        }
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { onShowAlert, t } = this.props;

        onShowAlert("");
        let errorMsg = [];

        if (!this.validateFbLink(this.state.newUserFbLink)) {
            errorMsg.push(t("error.invalidFbLink"));
            $("#userFbLink").addClass("is-invalid");
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            this.handleEdit();
        }
    };

    handleEdit = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.editingData"), fading: false });
        const passData = {
            oldFbLink: this.props.userData[0].user_facebook_link,
            newFbLink: this.state.newUserFbLink,
        };
        try {
            const response = await fetch(`${fetchURL}/update-facebook-link`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    } else {
                        onShowAlert({ variant: "success", message: t(`success.dataUpdatedSuccessfully`), fading: 2000 });
                        this.props.onCloseAndRefresh();
                    }
                });
            }
        } catch (err) {
            this.onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    render() {
        const { newUserFbLink, changedValues } = this.state;
        const { show, onClose, t } = this.props;
        return (
            <Fragment>
                <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
                    <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                        <Modal.Header closeButton>
                            <Modal.Title>{t("myAccount.dataAccount.editFbLink.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="no-gutters">
                                <Col xs={12}>
                                    <p className="mb-4 h6" style={{ lineHeight: "1.2" }}>
                                        {t("myAccount.dataAccount.editFbLink.subtitle")}
                                    </p>
                                </Col>

                                <Col xs={12}>
                                    <TextForm
                                        value={newUserFbLink}
                                        onChangeValue={(e) => {
                                            this.setState({ newUserFbLink: e.target.value, changedValues: true });
                                            if (e.target.value === "") $(`#newUserFbLink`).addClass("is-invalid");
                                            else if (!this.validateFbLink(e.target.value)) $(`#newUserFbLink`).addClass("is-invalid");
                                            else $(`#newUserFbLink`).removeClass("is-invalid");
                                        }}
                                        name="newUserFbLink"
                                        id="newUserFbLink"
                                        req
                                        t={t}
                                    />
                                    {!this.validateFbLink(newUserFbLink) && (
                                        <Alert variant="danger" className="alert-small">
                                            {t("error.invalidFbLink")}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={onClose}>
                                {t("info.cancel")}
                            </Button>
                            <Button variant="primary" disabled={!changedValues || !newUserFbLink || !this.validateFbLink(newUserFbLink) ? true : false} type="submit">
                                {t("info.saveChanges")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default EditFbLink;

import React, { Fragment } from "react";
import { Button, Col, Row } from "react-bootstrap";
import i18n from "../../../../../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const MoneyTransferData = (props) => {
    const { t, transferTitle, onShowAlert, transferAmount } = props;
    return (
        <Fragment>
            <Row id="moneyTransferData">
                <Col className="mb-2" xs={12}>
                    {t("myAccount.accreditation.transfer.receiver")}: <span className="text-nowrap">{process.env.REACT_APP_ACCOUNT_NAME}</span>
                </Col>
                {i18n.language === "pl" ? (
                    <>
                        <Col className="mb-2 d-inline-block" xs={12} sm={7} md={7} lg={8}>
                            {t("myAccount.accreditation.transfer.accountNum")}:<br />
                            {process.env.REACT_APP_ACCOUNT_NUMBER}
                            <input type="text" id="accountNumber" value={process.env.REACT_APP_ACCOUNT_NUMBER} readOnly className="d-none" />
                        </Col>
                        <Col xs={12} sm={5} md={5} lg={4} id="moneyTransferDataBtn">
                            <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={() => {
                                    var copyText = document.getElementById("accountNumber");
                                    copyText.select();
                                    copyText.setSelectionRange(0, 99999);
                                    navigator.clipboard.writeText(copyText.value);

                                    onShowAlert({ variant: "info", message: t("info.accountNumberCopied"), fading: 1500 });
                                }}
                            >
                                <FontAwesomeIcon icon={faCopy} className="d-inline-block d-lg-none" />
                                <span className="d-none d-lg-inline">{t("myAccount.accreditation.transfer.copy")}</span>
                            </Button>
                        </Col>
                    </>
                ) : (
                    <>
                        <Col className="mb-2 d-inline-block" xs={12} sm={7} md={7} lg={8}>
                            <span className="d-block mb-lg-2">
                                {t("myAccount.accreditation.transfer.accountNum")}:<br />
                                {process.env.REACT_APP_ACCOUNT_NUMBER_EN}
                                <input type="text" id="accountNumber" value={process.env.REACT_APP_ACCOUNT_NUMBER_EN} readOnly className="d-none" />
                            </span>
                        </Col>
                        <Col xs={12} sm={5} md={5} lg={4} id="moneyTransferDataBtn">
                            <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={() => {
                                    var copyText = document.getElementById("accountNumber");
                                    copyText.select();
                                    copyText.setSelectionRange(0, 99999);
                                    navigator.clipboard.writeText(copyText.value);
                                    onShowAlert({ variant: "info", message: t("info.accountNumberCopied"), fading: 1500 });
                                }}
                            >
                                <FontAwesomeIcon icon={faCopy} className="d-inline-block d-lg-none" />
                                <span className="d-none d-lg-inline">{t("myAccount.accreditation.transfer.copy")}</span>
                            </Button>
                        </Col>
                        <Col xs={12} className="mb-lg-2">
                            {t("myAccount.accreditation.transfer.swiftCode")}: <span className="d-inline-block">{process.env.REACT_APP_SWIFT_CODE}</span>
                        </Col>
                    </>
                )}
                <Col className="mb-2" xs={12}>
                    {t("myAccount.accreditation.transfer.transferTitle")}: <span className="text-nowrap">{transferTitle}</span>
                </Col>
                <Col className="mb-2" xs={12}>
                    {t("myAccount.accreditation.transfer.transferAmount")}:{" "}
                    <span className="text-uppercase bold">
                        {transferAmount} {t("currency")}
                    </span>
                </Col>
            </Row>
        </Fragment>
    );
};

export default MoneyTransferData;

import { faCheckCircle, faTimesCircle, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const AccreditationData = (props) => {
    const { t, activeEvent, accrData, link } = props;
    console.log(accrData);
    return (
        <div className="dashboard-box mx-2" id="accrDataBox">
            <div className="p-4">
                <p className="dashboard-box-title">{t("myAccount.accreditation.title")}</p>
                <Row className="no-gutters">
                    <Col xs={8}>
                        <p className="dashboard-box-text">
                            {t(
                                `myAccount.dashboard.accrDataText.${
                                    activeEvent === "noActiveEvent"
                                        ? "noActiveEvent"
                                        : accrData === false || accrData === "noAccrData"
                                        ? "notFilled"
                                        : accrData === "fillingFormNotAllowed"
                                        ? "fillingFormNotAllowed"
                                        : accrData.part_stat_code === "ABORTED"
                                        ? "filledNotComming"
                                        : "filledComming"
                                }`
                            )}
                        </p>
                    </Col>
                    <Col xs={4} className="text-center" id="dashboardBoxIcon">
                        {activeEvent === "noActiveEvent" ? (
                            <FontAwesomeIcon icon={faUser} className="text-light" />
                        ) : accrData && accrData === "fillingFormNotAllowed" ? (
                            <FontAwesomeIcon icon={faUser} className="text-light" />
                        ) : accrData && accrData !== "noAccrData" ? (
                            <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                        ) : (
                            <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
                        )}
                    </Col>
                    {(!accrData || accrData === "noAccrData") && (
                        <Col xs={12}>
                            <Link className="btn btn-primary w-100 mt-4" to={`/${link}/${t("url.link.accreditation")}/${t("url.link.accrForm")}`} id={t("url.link.accreditation")}>
                                {t("myAccount.dashboard.fillAccrDataBtn")}
                            </Link>
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    );
};

export default AccreditationData;

import React, { Component, Fragment } from "react";
import $ from "jquery";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import Password from "../forms/Password";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import Footer from "../other/Footer";
import LoadingProgressBar from "../spinners/LoadingProgressBar";

class SetPassword extends Component {
    state = { password: "", repeatPassword: "", showAlert: "", postDataURL: null };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { password, repeatPassword } = this.state;
        const { onShowAlert, t } = this.props;

        onShowAlert("");
        let errorMsg = [];
        if (!password || !repeatPassword) {
            errorMsg.push(t("error.emptyPassword"));
            if (!password) $("#password").addClass("is-invalid");
            if (!repeatPassword) $("#repeatPassword").addClass("is-invalid");
        } else if (password !== repeatPassword) {
            errorMsg.push(t("error.diffPassword"));
            $("#password").addClass("is-invalid");
            $("#repeatPassword").addClass("is-invalid");
        }

        if (!this.validatePassword(password) || !this.validatePassword(repeatPassword)) errorMsg.push(t("error.errIncorrectPass"));
        if (!this.validatePassword(password)) {
            $("#password").addClass("is-invalid");
        }
        if (!this.validatePassword(repeatPassword)) {
            $("#repeatPassword").addClass("is-invalid");
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            this.setNewPassword();
        }
    };

    setNewPassword = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.settingNewPass"), fading: false });
        const passData = {
            password: this.state.password,
            repeatPassword: this.state.repeatPassword,
            postDataURL: this.state.postDataURL,
        };
        try {
            const response = await fetch(`${fetchURL}/set-password`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status === "expired_link") onShowAlert({ variant: "warning", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        onShowAlert({ variant: "success", message: t(`success.settingNewPassSuccess`), fading: 3000 });
                        this.props.history.push(`/${t("url.link.login")}`);
                    }
                });
            }
        } catch (err) {
            this.onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    validatePassword = (pass) => {
        const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~!@#$%^&*()_\-{}[\]:;'"<> ?`/]).{8,}$/;
        if (!re.test(pass)) {
            return false;
        } else return true;
    };

    handleLogout = async () => {
        const { fetchURL, t, onShowAlert, onLogout } = this.props;
        try {
            const response = await fetch(`${fetchURL}/logout`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                onShowAlert("");
                await response.json().then(() => {
                    //console.log(fetchedData)

                    onLogout();
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.errLogout")} (${err}).`, fading: false });
        }
    };

    checkLinkValid = async () => {
        const { fetchURL, t, onShowAlert } = this.props;
        const dataPass = this.state.postDataURL;
        onShowAlert("");
        try {
            const response = await fetch(`${fetchURL}/check-reset-pass-link-valid`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dataPass),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                        this.setState({ showAlert: true, loading: false });
                    } else if (fetchedData.status === "expired_link") {
                        onShowAlert({ variant: "warning", message: t(`error.${fetchedData.code}`), fading: false });
                        this.setState({ showAlert: true, loading: false });
                    } else this.setState({ loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz. (${err})` });
            this.setState({ loading: false });
            setTimeout(this.checkLinkValid(dataPass), 4000);
        }
    };

    componentDidUpdate() {
        $(document).ready(function () {
            $("#resetPassContainer").css("min-height", `${$(window).outerHeight() - $("#footer").outerHeight()}px`);
            $(".loading-spinner").delay(200).fadeOut(500);
        });
    }

    componentDidMount() {
        this.handleLogout();
        const { location, t, onShowAlert } = this.props;
        const parsedLocationSearch = queryString.parse(location.search);

        if (location.search === "" || parsedLocationSearch.url === null) {
            onShowAlert({
                variant: "warning",
                message: t("error.invalidResetPassLink"),
            });
            this.setState({ showAlert: true, loading: false });
        } else {
            const postDataURL = {
                url: `${location.pathname}${location.search}`,
                pathname: `${location.pathname}`,
                generatedString: parsedLocationSearch.url,
            };
            this.setState({ postDataURL: postDataURL }, () => this.checkLinkValid());
        }
        this.props.onHideSpinner(200);
    }

    handleRedirect = (e, url) => {
        e.preventDefault();
        this.props.onShowSpoonLoader();
        setTimeout(() => this.props.history.push(url), 700);
    };

    render() {
        const { password, repeatPassword, showAlert, loading } = this.state;
        const { t, frontURL, onShowSpoonLoader } = this.props;
        return (
            <Fragment>
                <Container className="pt-lg-5" id="resetPassContainer">
                    {loading ? (
                        <Row>
                            <Col xs={12} className="text-center mt-5">
                                <LoadingProgressBar t={t} />
                            </Col>
                        </Row>
                    ) : showAlert ? (
                        <Row>
                            <Col xs={12} lg={6} className="text-center text-lg-right mt-5">
                                <Button variant="outline-primary" onClick={(e) => this.handleRedirect(e, t("url.link.forgotPassword"))} className="mt-5">
                                    {t("remindPass.title")}
                                </Button>
                            </Col>
                            <Col xs={12} lg={6} className="text-center text-lg-left mt-5">
                                <Button variant="outline-primary" onClick={(e) => this.handleRedirect(e, t("url.link.contact"))} className="mt-5">
                                    {t("url.link.contact")}
                                </Button>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col className="border shadow my-lg-5" lg={{ span: 6, offset: 3 }} xs={12}>
                                <Row>
                                    <Col className="p-3 bg-darker">
                                        <div className="text-center">
                                            <h2 className="h3 my-5">{t("resetPass.title")}</h2>
                                            <p className="h6 mb-4">{t("resetPass.subtitle2")}</p>
                                        </div>
                                        <Form onSubmit={this.handleCheckValidation}>
                                            {/* only for disabling warning: "Password forms should have (optionally hidden) username fields for accessibility:"*/}
                                            <input type="text" name="email" autoComplete="username email" style={{ display: "none" }} />
                                            <Password
                                                value1={password}
                                                value2={repeatPassword}
                                                onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })}
                                                t={t}
                                                onValidatePassword={this.validatePassword}
                                                option="double"
                                            />
                                            <div className="text-center">
                                                <Button type="submit" className="mb-3">
                                                    {t("resetPass.submitBtn")}
                                                </Button>
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                </Container>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withRouter(SetPassword);

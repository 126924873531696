import React, { Fragment, Component } from "react";
import { Button, Col, Image, Row, Table } from "react-bootstrap";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import i18n from "../../../../i18n";

class NewsTiles extends Component {
    state = {
        news: null,
    };

    getNewsList = async () => {
        const { fetchURL, t, onShowAlert, onHideSpinner } = this.props;
        const passData = { lang: i18n.language, limit: 3, offset: 0 };
        try {
            const response = await fetch(`${fetchURL}/news-list`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        const newsList = fetchedData.newsList;
                        let news = [];
                        newsList.forEach((e) => {
                            if (e.blob_data) {
                                const blob = e.blob_data;
                                var buffer = Buffer.from(blob);
                                var bufferBase64 = buffer.toString("base64");
                            }

                            news.push({
                                image: e.blob_data
                                    ? {
                                          data: bufferBase64,
                                          type: e.blob_file_type,
                                          name: e.blob_name,
                                      }
                                    : null,
                                langs: e.langs,
                                date: e.news_date,
                                link: e.news_link,
                            });
                        });
                        this.setState({ news: news }, () => onHideSpinner());
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        this.getNewsList();
    }

    render() {
        const { news } = this.state;
        const { t, frontURL, onChangeRoute } = this.props;
        return (
            <Row id="news" className="no-gutters">
                <p className="p-title">{t("home.newsTitle")}</p>

                <div id="newsListBg"></div>
                <Col>
                    <div id="newsList" style={{ opacity: 0 }}>
                        <Row className="no-gutters">
                            <Col xs={12} id="newsShow">
                                {!news ? (
                                    <Row>
                                        <Col xs={12} className="text-center">
                                            <LoadingProgressBar t={t} />
                                        </Col>
                                    </Row>
                                ) : (
                                    <Fragment>
                                        {news.length === 0 ? (
                                            <p className="p-text no-news">{t("home.noNews")}</p>
                                        ) : (
                                            <div>
                                                <Table className="news-list">
                                                    <tbody>
                                                        {news.map((e, i) => (
                                                            <tr key={i} onClick={(event) => onChangeRoute(event, `/${t("url.link.news")}/${e.link}`)}>
                                                                <td>
                                                                    <Image
                                                                        src={e.image ? `data:${e.image.type};base64,${e.image.data}` : `${frontURL}/images/placeholder_img.webp`}
                                                                        alt={e.image ? e.image.name : "placeholder-img"}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {e.langs.map((el, j) => (
                                                                        <Fragment key={j}>
                                                                            <p className="news-title px-lg-2">{el.lang_code === i18n.language && el.news_title}</p>
                                                                            <p className="news-text px-lg-2 ">{el.lang_code === i18n.language && el.news_subtitle}</p>
                                                                        </Fragment>
                                                                    ))}
                                                                    <p className="news-date opacity-4 ">{e.date}</p>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                <Row className="no-gutters">
                                                    <Col xs={12} className="text-center">
                                                        {news.length < 3 ? (
                                                            <p className="h6 opacity-4 mt-5">{t("home.allNews")}</p>
                                                        ) : (
                                                            <Button
                                                                variant="outline-primary"
                                                                size="lg"
                                                                onClick={(e) => onChangeRoute(e, `/${t("url.link.news")}`)}
                                                                className="my-3"
                                                            >
                                                                {t("home.moreNews")}
                                                            </Button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </Fragment>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Col>
                <div id="newsBg"></div>
            </Row>
        );
    }
}

export default NewsTiles;

import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { Route } from "react-router-dom";
import NoMatchMyAccount from "./../../../other/NoMatchMyAccount";
import CharactersMain from "./CharactersMain";
import AddNewCharacter from "./AddNewCharacter";

const Characters = (props) => {
    const { fetchURL, onShowAlert, t, charLink, location, activeEvent, eventsList, onRefreshNotification, onFetchAgainEventsList, accrLink, onNotLogged } = props;

    return (
        <Fragment>
            <Row className="no-gutters">
                <Col className="text-center">
                    <p className="h3 my-4 text-uppercase">{props.t("myAccount.characters.title")}</p>
                </Col>
            </Row>

            {location.pathname !== `${charLink}/${t("url.link.addNewCharacter")}` && location.pathname !== `${charLink}` && <Route render={() => <NoMatchMyAccount t={t} />} />}
            <Route
                exact
                path={`${charLink}`}
                render={() => (
                    <CharactersMain
                        t={t}
                        fetchURL={fetchURL}
                        onShowAlert={onShowAlert}
                        charLink={charLink}
                        eventsList={eventsList}
                        activeEvent={activeEvent}
                        onRefreshNotification={onRefreshNotification}
                        accrLink={accrLink}
                        onNotLogged={onNotLogged}
                    />
                )}
            />
            <Route
                exact
                path={`${charLink}/${t("url.link.addNewCharacter")}`}
                render={() => (
                    <AddNewCharacter
                        t={t}
                        fetchURL={fetchURL}
                        onShowAlert={onShowAlert}
                        charLink={charLink}
                        activeEvent={activeEvent}
                        onRefreshNotification={onRefreshNotification}
                        onFetchAgainEventsList={onFetchAgainEventsList}
                    />
                )}
            />
        </Fragment>
    );
};

export default Characters;

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { withRouter } from "react-router";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import $ from "jquery";
import AvailabilityOptions from "./AvailabilityOptions";
import NumberOfDutiesRadios from "../../../forms/check-radio-boxes/NumberOfDutiesRadios";
import TextareaForm from "../../../forms/TextareaForm";

class AccrForm extends Component {
    state = {
        loading: true,
    };

    getAvalOptionsForForm = async () => {
        const { fetchURL, t, onShowAlert, activeEvent, onChangeAccrData } = this.props;
        const passData = {
            appType: "ACCR",
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/get-aval-options-for-form`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status !== true) this.setState({ showView: { variant: fetchedData.status, message: fetchedData.code } });
                    else
                        this.setState({
                            loading: false,
                        });
                    onChangeAccrData(fetchedData.avalOptions, "avalOptions", false);
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { onShowAlert, t, appTypes, accrFormData, onChangeView } = this.props;
        onShowAlert("");
        let errorMsg = [];

        if (!accrFormData[0].numberOfDuties) {
            $("#ACCRnumberOfDuties").addClass("is-invalid");
            errorMsg.push(t("error.emptyNumberOfDuties"));
        }

        let avalError = false;
        let allAvalsAreNo = true;
        accrFormData[0].avalOptions.forEach((e, i) => {
            if (!e.answer) {
                $(`#ACCRavalAnswer${i}`).addClass("is-invalid");
                avalError = t("error.avalAnswersNotFilled");
            } else if (e.answer !== "N") allAvalsAreNo = false;
        });

        if (avalError) errorMsg.push(avalError);
        if (allAvalsAreNo) errorMsg.push(t("error.allAvalsAreCheckedForNo"));

        var regExpWhiteSpace = /^\s*$/;

        if (accrFormData[0].accrComment && regExpWhiteSpace.test(accrFormData[0].accrComment)) {
            $("#accrComment").addClass("is-invalid");
            errorMsg.push(t("error.commentFormInvalid"));
        } else if (accrFormData[0].accrComment.length > 255) {
            $("#accrComment").addClass("is-invalid");
            errorMsg.push(t("error.commentFormToLong"));
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            let showView = "summary";
            const npc = appTypes.filter((e) => e.name === "NPC");

            if (npc[0].value) showView = npc[0].name;

            // console.log(showView);
            window.scrollTo(0, 0);
            onChangeView(showView);
        }
    };

    handleSelectAnswer = (i, answer) => {
        const list = [...this.props.accrFormData[0].avalOptions];
        list[i].answer = answer;
        $(`#ACCRavalAnswer${i}`).removeClass("is-invalid");
        this.props.onChangeAccrData(list, "avalOptions", true);
    };

    componentDidMount() {
        if (!this.props.accrFormData[0].avalOptions) this.getAvalOptionsForForm();
        else this.setState({ loading: false });
    }

    render() {
        const { loading } = this.state;
        const { t, appTypes, changedValues, accrFormData, onChangeAccrData, onChangeView } = this.props;
        return (
            <Fragment>
                <Col xs={12}>
                    <p className="h4 text-uppercase text-center my-3">{t(`myAccount.volunteering.accr.form.title`)}</p>
                </Col>
                {loading ? (
                    <Col xs={12} className="text-center">
                        <LoadingProgressBar t={t} />
                    </Col>
                ) : (
                    <Fragment>
                        {this.props.addView && (
                            <Row className="no-gutters">
                                <Col xs={12} className="my-3">
                                    <Alert variant="primary" className="mt-3">
                                        <span dangerouslySetInnerHTML={{ __html: t(`myAccount.volunteering.accr.form.text`) }}></span>
                                    </Alert>
                                    <Alert variant="primary" className="text-center mt-3">
                                        <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                                        <p className="d-inline" dangerouslySetInnerHTML={{ __html: t(`myAccount.volunteering.accr.form.alert`) }}></p>
                                    </Alert>
                                </Col>
                            </Row>
                        )}
                        <Row className="no-gutters">
                            <Col xs={12} className="mt-4">
                                <Form.Group>
                                    <Form.Label>
                                        {t(`myAccount.volunteering.accr.form.availabilityTitle`)} <span className="required">*</span>
                                    </Form.Label>
                                    <p className="text-small">{t(`myAccount.volunteering.accr.form.availabilitySubtitle`)}</p>
                                    <Row>
                                        <AvailabilityOptions t={t} appType="ACCR" avalOptions={accrFormData[0].avalOptions} onSelectAnswer={this.handleSelectAnswer} />
                                    </Row>
                                </Form.Group>
                            </Col>

                            <Col xs={12} className="mt-4">
                                <Form.Group>
                                    <Form.Label>
                                        {t(`myAccount.volunteering.accr.form.numberOfDutiesTitle`)} <span className="required">*</span>
                                    </Form.Label>
                                    <p className="text-small">{t(`myAccount.volunteering.accr.form.numberOfDutiesSubtitle`)}</p>
                                    <NumberOfDutiesRadios
                                        numberOfDuties={accrFormData[0].numberOfDuties}
                                        onCheckNumberOfDuties={(value) => {
                                            $("#ACCRnumberOfDuties").removeClass("is-invalid");
                                            onChangeAccrData(value, "numberOfDuties", true);
                                        }}
                                        radioValue={accrFormData[0].numberOfDuties}
                                        id="ACCRnumberOfDuties"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} className="mt-4">
                                <TextareaForm
                                    value={accrFormData[0].accrComment}
                                    onChangeValue={(e) => {
                                        $("#accrComment").removeClass("is-invalid");
                                        onChangeAccrData(e.target.value, "accrComment", true);
                                    }}
                                    labelTxt={t("myAccount.volunteering.accr.form.comment")}
                                    name="accrComment"
                                    placeholderTxt={t("myAccount.volunteering.accr.form.commentPlaceholder")}
                                    id="accrComment"
                                    subtext={t("myAccount.volunteering.accr.form.commentSubtext")}
                                />
                            </Col>
                            {this.props.addView && (
                                <Col xs={12} className="mt-3 text-center">
                                    <Button
                                        className="forms-btn mr-1"
                                        variant="outline-primary"
                                        type="button"
                                        onClick={() => {
                                            let showView = "appFunctions";
                                            const bar = appTypes.filter((e) => e.name === "BAR"),
                                                accr = appTypes.filter((e) => e.name === "CONSTR");
                                            if (bar[0].value) showView = bar[0].name;
                                            else if (accr[0].value) showView = accr[0].name;
                                            window.scrollTo(0, 0);
                                            onChangeView(showView);
                                        }}
                                    >
                                        {t("myAccount.prevBtn")}
                                    </Button>
                                    <Button className="forms-btn ml-1" variant="primary" type="button" onClick={this.handleCheckValidation} disabled={!changedValues}>
                                        {t("myAccount.nextBtn")}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default withRouter(AccrForm);

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { withRouter } from "react-router";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import AvailabilityOptions from "./AvailabilityOptions";
import $ from "jquery";
import SkillsCheckboxes from "../../../forms/check-radio-boxes/SkillsCheckboxes";
import CalendarDateAndTime from "../../../forms/datetimes/CalendarDateAndTime";
import ToolsCheckboxes from "../../../forms/check-radio-boxes/ToolsCheckboxes";
import TextareaForm from "../../../forms/TextareaForm";

class ConstructionForm extends Component {
    state = {
        loading: true,
    };

    getAvalOptionsForForm = async () => {
        const { fetchURL, t, onShowAlert, activeEvent, onChangeConstrData } = this.props;
        const passData = {
            appType: "CONSTR",
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/get-aval-options-for-form`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status !== true) this.setState({ showView: { variant: fetchedData.status, message: fetchedData.code } });
                    else {
                        this.getMinDataForArrival();
                    }

                    onChangeConstrData(fetchedData.avalOptions, "avalOptions", false);
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    getMinDataForArrival = async () => {
        const { fetchURL, t, onShowAlert, activeEvent, onChangeConstrData } = this.props;
        const passData = {
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/get-min-data-for-arrival`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status !== true) this.setState({ showView: { variant: fetchedData.status, message: fetchedData.code } });
                    else {
                        this.setState({ loading: false });
                        onChangeConstrData(new Date(fetchedData.minDate), "arrivalDate", true);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    /* applyHelpForm = async () => {
        const { fetchURL, t, onShowAlert, activeEvent, volLink } = this.props;
        const passData = {
            activeEvent: activeEvent,
            skills: this.state.skills,
            tools: this.state.tools,
            skillsOther: this.state.skillsOther,
            toolsOther: this.state.toolsOther,
            appComment: this.state.appComment,
            arrivalDate: this.state.arrivalDate,
            avalOptions: this.state.avalOptions,
            userFbLink: this.state.userFbLink,
            userPhone: this.state.userPhone,
        };
        //console.log(passData);
        try {
            const response = await fetch(`${fetchURL}/apply-constr-form`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                        this.setState({ disabledForm: false });
                    } else if (fetchedData.status !== true) {
                        onShowAlert({ variant: fetchedData.status, message: t(`${fetchedData.status}.${fetchedData.code}`) });
                        this.setState({ disabledForm: false });
                    } else {
                        onShowAlert({ variant: "success", message: t("success.helpFormApplied"), fading: 2000 });
                        this.props.history.push(volLink);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    }; */

    handleSelectAnswer = (i, answer) => {
        const list = [...this.props.constrFormData[0].avalOptions];
        list[i].answer = answer;
        $(`#CONSTRavalAnswer${i}`).removeClass("is-invalid");
        this.props.onChangeConstrData(list, "avalOptions", true);
    };

    handleCheckSkill = (i, value) => {
        const list = [...this.props.constrFormData[0].skills];
        list[i].value = value;
        const other = list[list.length - 1].value ? this.props.constrFormData[0].skillsOther : "";
        this.props.onChangeConstrData(list, "skills");
        this.props.onChangeConstrData(other, "skillsOther", true);
    };

    handleCheckTool = (i, value) => {
        const list = [...this.props.constrFormData[0].tools];
        list[i].value = value;
        const other = list[list.length - 1].value ? this.props.constrFormData[0].toolsOther : "";
        this.props.onChangeConstrData(list, "tools");
        this.props.onChangeConstrData(other, "toolsOther", true);
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { onShowAlert, t, constrFormData } = this.props;
        onShowAlert("");
        let errorMsg = [];

        if (!constrFormData[0].arrivalDate) {
            $("#arrivalDate").addClass("is-invalid");
            errorMsg.push(t("error.emptyArrivalDate"));
        }

        let avalError = false;
        let allAvalsAreNo = true;
        constrFormData[0].avalOptions.forEach((e, i) => {
            if (!e.answer) {
                $(`#CONSTRavalAnswer${i}`).addClass("is-invalid");
                avalError = t("error.avalAnswersNotFilled");
            } else if (e.answer !== "N") allAvalsAreNo = false;
        });

        if (avalError) errorMsg.push(avalError);
        if (allAvalsAreNo) errorMsg.push(t("error.allAvalsAreCheckedForNo"));

        const regExpWhiteSpace = /^\s*$/;

        if (constrFormData[0].constrComment && regExpWhiteSpace.test(constrFormData[0].constrComment)) {
            $("#constrComment").addClass("is-invalid");
            errorMsg.push(t("error.commentFormInvalid"));
        } else if (constrFormData[0].constrComment.length > 255) {
            $("#constrComment").addClass("is-invalid");
            errorMsg.push(t("error.commentFormToLong"));
        }

        if (constrFormData[0].skillsOther.length > 0 && constrFormData[0].skillsOther.length > 50) {
            $("#otherSkills").addClass("is-invalid");
            errorMsg.push(t("error.skillsCommentTooLong"));
        } else if (constrFormData[0].skillsOther.length > 0 && regExpWhiteSpace.test(constrFormData[0].skillsOther)) {
            $("#otherSkills").addClass("is-invalid");
            errorMsg.push(t("error.skillsCommentInvalid"));
        }
        if (constrFormData[0].toolsOther.length > 0 && constrFormData[0].toolsOther.length > 50) {
            $("#otherSkills").addClass("is-invalid");
            errorMsg.push(t("error.toolsCommentTooLong"));
        } else if (constrFormData[0].toolsOther.length > 0 && regExpWhiteSpace.test(constrFormData[0].toolsOther)) {
            $("#otherSkills").addClass("is-invalid");
            errorMsg.push(t("error.toolsCommentInvalid"));
        }

        if (avalError) errorMsg.push(avalError);

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            const { appTypes, onChangeView } = this.props;
            let showView = "summary";
            const bar = appTypes.filter((e) => e.name === "BAR"),
                npc = appTypes.filter((e) => e.name === "NPC"),
                constr = appTypes.filter((e) => e.name === "ACCR");

            if (bar[0].value) showView = bar[0].name;
            else if (constr[0].value) showView = constr[0].name;
            else if (npc[0].value) showView = npc[0].name;

            //console.log(showView);
            window.scrollTo(0, 0);
            onChangeView(showView);
        }
    };

    componentDidMount() {
        if (!this.props.constrFormData[0].avalOptions) this.getAvalOptionsForForm();
        else this.setState({ loading: false });
    }

    render() {
        const { loading } = this.state;
        const { t, onChangeView, changedValues, constrFormData, onChangeConstrData } = this.props;
        //console.log(constrFormData);
        return (
            <Fragment>
                <Col xs={12} className="text-center">
                    <p className="h4 text-uppercase text-center my-3">{t("myAccount.volunteering.constr.form.title")}</p>
                </Col>
                {loading ? (
                    <Col xs={12} className="text-center">
                        <LoadingProgressBar t={t} />
                    </Col>
                ) : (
                    <Fragment>
                        {this.props.addView && (
                            <Row className="no-gutters">
                                <Col className="my-3">
                                    <Alert variant="primary" className="mt-3">
                                        <span dangerouslySetInnerHTML={{ __html: t("myAccount.volunteering.constr.form.text1") }}></span>
                                    </Alert>
                                    <Alert variant="primary" className="text-center mt-3">
                                        <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                                        <p className="d-inline" dangerouslySetInnerHTML={{ __html: t("myAccount.volunteering.constr.form.alert1") }}></p>
                                    </Alert>
                                </Col>
                            </Row>
                        )}
                        <Row className="no-gutters">
                            <Col xs={12} className="mt-4">
                                <Form.Group>
                                    <Form.Label>
                                        {t("myAccount.volunteering.arrival")} <span className="required">*</span>
                                    </Form.Label>
                                    <p className="text-small">{t("myAccount.volunteering.arrivalSubtext")}</p>
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <CalendarDateAndTime
                                    t={t}
                                    value={constrFormData[0].arrivalDate}
                                    onChangeCalendarDate={(v) => onChangeConstrData(v, "arrivalDate", true)}
                                    id="arrivalDate"
                                />
                            </Col>
                            <Col xs={12} className="mt-4">
                                <Form.Group>
                                    <Form.Label>
                                        {t("myAccount.volunteering.availability.title")} <span className="required">*</span>
                                    </Form.Label>
                                    <p className="text-small">{t("myAccount.volunteering.availability.subtitle")}</p>
                                    <Row>
                                        <AvailabilityOptions t={t} appType="CONSTR" avalOptions={constrFormData[0].avalOptions} onSelectAnswer={this.handleSelectAnswer} />
                                    </Row>
                                </Form.Group>
                            </Col>

                            <Col xs={12} className="mt-4">
                                <Form.Group>
                                    <Form.Label>{t("myAccount.volunteering.constr.form.skills.label")}</Form.Label>
                                    <p className="text-small">{t("myAccount.volunteering.constr.form.skills.subtext")}</p>
                                    <SkillsCheckboxes
                                        t={t}
                                        skills={constrFormData[0].skills}
                                        skillsOther={constrFormData[0].skillsOther}
                                        onChangeOtherSkills={(e) => onChangeConstrData(e.target.value, "skillsOther", true)}
                                        onCheckSkill={this.handleCheckSkill}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} className="mt-4">
                                <Form.Group>
                                    <Form.Label>{t("myAccount.volunteering.constr.form.tools.label")}</Form.Label>
                                    <p className="text-small">{t("myAccount.volunteering.constr.form.tools.subtext")}</p>
                                    <ToolsCheckboxes
                                        t={t}
                                        tools={constrFormData[0].tools}
                                        toolsOther={constrFormData[0].toolsOther}
                                        onChangeOtherTools={(e) => onChangeConstrData(e.target.value, "toolsOther", true)}
                                        onCheckTool={this.handleCheckTool}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} className="mt-4">
                                <TextareaForm
                                    value={constrFormData[0].constrComment}
                                    onChangeValue={(e) => {
                                        $("#constrComment").removeClass("is-invalid");
                                        onChangeConstrData(e.target.value, "constrComment", true);
                                    }}
                                    labelTxt={t("myAccount.volunteering.constr.form.comment")}
                                    name="constrComment"
                                    placeholderTxt={t("myAccount.volunteering.constr.form.commentPlaceholder")}
                                    id="constrComment"
                                    subtext={t("myAccount.volunteering.constr.form.commentSubtext")}
                                />
                            </Col>
                            {this.props.addView && (
                                <Col xs={12} className="mt-3 text-center">
                                    <Button
                                        className="forms-btn mr-1"
                                        variant="outline-primary"
                                        type="button"
                                        onClick={() => {
                                            //console.log(constrFormData);
                                            window.scrollTo(0, 0);
                                            onChangeView("appFunctions");
                                        }}
                                    >
                                        {t("myAccount.prevBtn")}
                                    </Button>
                                    <Button className="forms-btn ml-1" variant="primary" type="button" onClick={this.handleCheckValidation} disabled={!changedValues}>
                                        {t("myAccount.nextBtn")}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default withRouter(ConstructionForm);

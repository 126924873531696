import React, { Component, Fragment } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { withRouter } from "react-router";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import $ from "jquery";
import TextareaForm from "../../../forms/TextareaForm";

class NPCForm extends Component {
    state = {
        npcComment: "",
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { onShowAlert, t, npcComment, onChangeView } = this.props;
        onShowAlert("");
        let errorMsg = [];
        const regExpWhiteSpace = /^\s*$/;

        if (!npcComment) {
            $("#npcComment").addClass("is-invalid");
            errorMsg.push(t("error.npcCommentEmpty"));
        } else if (npcComment.length > 255) {
            $("#npcComment").addClass("is-invalid");
            errorMsg.push(t("error.npcCommentTooLong"));
        } else if (npcComment && regExpWhiteSpace.test(npcComment)) {
            $("#npcComment").addClass("is-invalid");
            errorMsg.push(t("error.npcCommentInvalid"));
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            onChangeView("summary");
        }
    };

    render() {
        const { loading } = this.state;
        const { t, appTypes, changedValues, onChangeView, npcComment, onChangeValue } = this.props;
        return (
            <Fragment>
                <Col xs={12}>
                    <p className="h4 text-uppercase text-center my-3">{t(`myAccount.volunteering.npc.form.title`)}</p>
                </Col>
                {loading ? (
                    <Col xs={12} className="text-center">
                        <LoadingProgressBar t={t} />
                    </Col>
                ) : (
                    <Fragment>
                        <Row className="no-gutters">
                            <Col xs={12} className="mt-4">
                                <TextareaForm
                                    value={npcComment}
                                    onChangeValue={onChangeValue}
                                    labelTxt={t("myAccount.volunteering.npc.form.npcComment")}
                                    name="npcComment"
                                    placeholderTxt={t("myAccount.volunteering.npc.form.npcCommentPlaceholder")}
                                    id="npcComment"
                                    subtext={t("myAccount.volunteering.npc.form.subtext")}
                                    req
                                />
                            </Col>
                            {this.props.addView && (
                                <Col xs={12} className="mt-3 text-center">
                                    <Button
                                        className="forms-btn mr-1"
                                        variant="outline-primary"
                                        type="button"
                                        onClick={() => {
                                            let showView = "appFunctions";
                                            const bar = appTypes.filter((e) => e.name === "BAR"),
                                                constr = appTypes.filter((e) => e.name === "CONSTR"),
                                                accr = appTypes.filter((e) => e.name === "ACCR");
                                            if (accr[0].value) showView = accr[0].name;
                                            else if (bar[0].value) showView = bar[0].name;
                                            else if (constr[0].value) showView = constr[0].name;
                                            window.scrollTo(0, 0);
                                            onChangeView(showView);
                                        }}
                                    >
                                        {t("myAccount.prevBtn")}
                                    </Button>
                                    <Button className="forms-btn ml-1" variant="primary" type="button" onClick={this.handleCheckValidation} disabled={!changedValues}>
                                        {t("myAccount.nextBtn")}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default withRouter(NPCForm);

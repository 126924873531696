import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import { Row, Col } from "react-bootstrap";
import MainBanner from "./homeContent/MainBanner";
import WhatIsLyzkon from "./homeContent/WhatIsLyzkon";
import Attractions from "./homeContent/Attractions";
import BestLARP from "./homeContent/BestLARP";
import NewsTiles from "./homeContent/NewsTiles";
import Register from "./homeContent/Register";
import * as basicScroll from "basicscroll";
import RestContent from "./homeContent/RestContent";

var ww, wh, instance;

function spreadWalls(wh, distance) {
    if (distance + 0.3 * wh >= document.getElementById("barBanner").offsetTop) {
        document.getElementById("barWallLeft").style.transform = `translateX(${document.getElementById("barBanner").offsetTop - distance - 0.3 * wh}px)`;
        document.getElementById("barWallRight").style.transform = `translateX(${(document.getElementById("barBanner").offsetTop - distance - 0.3 * wh) * -1}px)`;
    } else {
        document.getElementById("barWallLeft").style.transform = `translateX(0)`;
        document.getElementById("barWallRight").style.transform = `translateX(0)`;
    }
}

function removeStyleAttr() {
    document.getElementById("mainBannerLogo").removeAttribute("style");
    document.getElementById("mainBannerTitle").removeAttribute("style");
    document.getElementById("mainBannerSubtitle").removeAttribute("style");
    document.getElementById("mainBannerBtn").removeAttribute("style");
}

function resetAnimation() {
    document.getElementById("mainBannerLogo").style.animation = "none";
    document.getElementById("mainBannerTitle").style.animation = "none";
    document.getElementById("mainBannerSubtitle").style.animation = "none";
}

function checkIfMobile() {
    if (window.matchMedia("(pointer: coarse)").matches) return true;
    else return false;
}

function showAndHide(object, distance, wh) {
    const el = document.getElementById(object);
    const objectOffsetTop = el.offsetTop;
    const distanceOffset = distance + wh - wh / 5;
    if (distanceOffset >= objectOffsetTop && !el.classList.contains("fade-in")) {
        el.classList.add("fade-in");
    }
}

function setXLSize(object, direction) {
    const el = object;
    if (window.innerWidth >= 1914 && window.innerWidth > window.innerHeight && !checkIfMobile()) {
        if (direction === "left") el.classList.add("col-xl-5", "offset-xl-1");
        else el.classList.add("col-xl-5", "offset-xl-6");
        el.classList.remove("col-xl-8", "offset-xl-2");
    } else {
        el.classList.remove("col-xl-5", "offset-xl-1", "offset-xl-6");
        el.classList.add("col-xl-8", "offset-xl-2");
    }
}

function moveText(object, direction, distance, ww) {
    const el = document.getElementById(object);
    const objectOffset = el.offsetTop;
    if (direction === "right") {
        el.style.transform = `translate(${(distance - objectOffset + ww / 4) * 0.1}px,0)`;
    } else {
        el.style.transform = `translate(${(distance - objectOffset + ww / 4) * -0.1}px,0)`;
    }
}

function setPositions() {
    const barOffsetTop = document.getElementById("barBanner").offsetTop,
        newsOffsetTop = document.getElementById("newsList").offsetTop;

    let distance = window.scrollY;

    $("#mainBannerBtn").fadeIn(0);

    if (distance >= barOffsetTop) document.getElementById("wastelandImg").style.zIndex = 21;
    else document.getElementById("wastelandImg").style.zIndex = 19;
    if (distance >= newsOffsetTop) document.getElementById("registerImg").style.zIndex = 22;
    else document.getElementById("registerImg").style.zIndex = 19;

    showAndHide("whatIsLyzkonText", distance, wh);
    showAndHide("attractionsTxtContent", distance, wh);
    showAndHide("bestLARPText", distance, wh);
    showAndHide("newsList", distance, wh);
    showAndHide("registerText", distance, wh);

    if (distance + wh - wh / 4 >= document.getElementById("restContent").offsetTop) {
        document.getElementById("collage").classList.add("fade-in");
    }

    const mainBannerLogo = document.getElementById("mainBannerLogo");
    const mainBannerTitle = document.getElementById("mainBannerTitle");
    const mainBannerSubtitle = document.getElementById("mainBannerSubtitle");
    const mainBannerBtn = document.getElementById("mainBannerBtn");

    if (ww >= 1200 && ww > wh) {
        document.getElementById("newsList").classList.remove("container-fluid");
        document.getElementById("newsList").classList.add("container");
        resetAnimation(ww, wh, false);
    } else {
        document.getElementById("newsList").classList.remove("container");
        document.getElementById("newsList").classList.add("container-fluid");
    }

    if (ww >= 1914) {
        moveText("whatIsLyzkonText", "right", distance, ww);
        moveText("attractionsTxtContent", "left", distance, ww);
        moveText("bestLARPText", "right", distance, ww);
    } else {
        document.getElementById("whatIsLyzkonText").style.transform = `translate(0,0)`;
        document.getElementById("attractionsTxtContent").style.transform = `translate(0,0)`;
        document.getElementById("bestLARPText").style.transform = `translate(0,0)`;
    }

    //lg - on scroll
    if (ww >= 992 && ww <= 1199) {
        mainBannerBtn.style.top = `calc(${distance * -0.6}px + 75vh)`;
        if (wh <= 600) {
            mainBannerTitle.style.transform = `translate(0,calc(${distance * -0.4}px + 47vh))`;
            mainBannerLogo.style.transform = `translate(0,calc(${distance * -0.1}px + 2vh))`;
        } else {
            mainBannerLogo.style.transform = `translate(0px, calc(${distance * -0.1}px + 7vh))`;
            mainBannerTitle.style.transform = `translate(0,calc(${distance * -0.4}px + 53vh))`;
        }
    }
    //xl - on scroll
    else if (ww >= 1200 && ww <= 1439) {
        if (checkIfMobile()) distance = 0;
        if (wh <= 913) {
            mainBannerLogo.style.transform = `translate(10vw, calc(${distance * -0.3}px + 20vh))`;
            mainBannerTitle.style.transform = `translate(10vw, calc(${distance * -0.6}px + 50vh))`;
            mainBannerSubtitle.style.transform = `translate(calc(100vw - 500px - 4vw), calc(${distance * -0.3}px + 20vh))`;
            mainBannerBtn.style.top = `calc(${distance * -1}px + 60vh)`;
        } else {
            mainBannerLogo.style.transform = `translate(0, calc(${distance * -0.1}px + 10vh))`;
            mainBannerTitle.style.transform = `translate(0,calc(${distance * -0.6}px + 50vh))`;
            mainBannerSubtitle.style.transform = `translate(0,calc(${distance * -0.8}px + 35vh))`;
            mainBannerBtn.style.top = `calc(${distance * -1}px + 80vh)`;
        }
    }
    //2xl - on scroll
    else if (ww >= 1440 && ww < 1914) {
        if (checkIfMobile()) distance = 0;

        mainBannerLogo.style.transform = `translate(calc(${distance * -0.4}px + 12vw), calc(${distance * -0.3}px + 20vh))`;
        mainBannerTitle.style.transform = `translate(calc(${distance * -0.4}px + 15vw), calc(${distance * -0.6}px + 50vh))`;
        mainBannerSubtitle.style.transform = `translate(calc(${distance * 0.6}px + calc(100vw - 500px - 150px)), calc(${distance * -0.3}px + 20vh))`;
        mainBannerBtn.style.top = `calc(${distance * -0.7}px + 60vh)`;
        if (!checkIfMobile()) {
            mainBannerBtn.style.right = `calc(${distance * -0.6}px + 17vw`;
            spreadWalls(wh, distance);
        }
    }
    //3xl - on scroll
    else if (ww >= 1914 && ww < 2554) {
        if (checkIfMobile()) distance = 0;
        mainBannerLogo.style.transform = `translate(calc(${distance * -0.4}px + 10vw),calc(${distance * -0.3}px + 20vh))`;
        mainBannerTitle.style.transform = `translate(calc(${distance * -0.4}px + 15vw), calc(${distance * -0.1}px + 50vh))`;
        mainBannerSubtitle.style.transform = `translate(calc(${distance * 0.6}px + calc(((100vw - 700px) * 90) / 100)),calc(${distance * -0.3}px + 20vh))`;
        mainBannerBtn.style.top = `calc(${distance * -0.1}px + 60vh)`;
        mainBannerBtn.style.right = `calc(${distance * -0.6}px + 15vw)`;

        if (!checkIfMobile()) spreadWalls(wh, distance);
    }
    // > 4xl - on scroll
    else if (ww >= 2554) {
        if (checkIfMobile()) distance = 0;
        mainBannerLogo.style.transform = `translate(calc(${distance * -0.4}px + 12vw), calc(${distance * -0.3}px + 15vh))`;
        mainBannerTitle.style.transform = `translate(calc(${distance * -0.4}px + 17vw), calc(${distance * -0.1}px + 55vh))`;
        mainBannerSubtitle.style.transform = `translate(calc(${distance * 0.6}px + calc((100vw - 800px) * 89 / 100)), calc(${distance * -0.3}px + 20vh))`;
        mainBannerBtn.style.top = `calc(${distance * -0.1}px + 70vh)`;
        mainBannerBtn.style.right = `calc(${distance * -0.6}px + 15vw)`;

        if (!checkIfMobile()) spreadWalls(wh, distance);
    }
}
function disableScroll() {
    window.oldScrollPos = $(window).scrollTop();

    $(window).on("scroll.scrolldisabler", function (e) {
        $(window).scrollTop(window.oldScrollPos);
        e.preventDefault();
    });
}

function enableScroll() {
    $(window).off("scroll.scrolldisabler");
}

const countDirSize = () => {
    return window.innerWidth <= 575
        ? "xs"
        : window.innerWidth <= 767 && window.innerHeight < window.innerWidth
        ? "sm_horizontal"
        : window.innerWidth <= 767 && window.innerHeight >= window.innerWidth
        ? "sm_vertical"
        : window.innerWidth <= 991 && window.innerHeight < window.innerWidth
        ? "md_horizontal"
        : window.innerWidth <= 991 && window.innerHeight >= window.innerWidth
        ? "md_vertical"
        : window.innerWidth <= 1199
        ? "lg"
        : window.innerWidth <= 1439 && window.innerHeight < window.innerWidth
        ? "xl_horizontal"
        : window.innerWidth <= 1439 && window.innerHeight >= window.innerWidth
        ? "xl_vertical"
        : window.innerWidth <= 1919 && window.innerHeight < window.innerWidth
        ? "2xl_horizontal"
        : window.innerWidth <= 1919 && window.innerHeight >= window.innerWidth
        ? "2xl_vertical"
        : window.innerWidth <= 2559
        ? "3xl"
        : "4xl";
};

function scrolling() {
    if (document.getElementById("homeHeight")) {
        $("#scrollDown").delay(200).fadeOut(400);
        resetAnimation();
        removeStyleAttr();
        setPositions();
    }
}

class Home extends Component {
    state = {
        dirSize: countDirSize(),
    };

    handleChangeRoute = (e, url) => {
        //console.log(url);
        e.preventDefault();
        this.props.onShowSpoonLoader(1000);
        setTimeout(() => {
            this.props.history.push(url);
        }, 700);
    };

    resize = () => {
        const { dirSize } = this.state;
        if (document.getElementById("homeHeight")) {
            ww = window.innerWidth < window.outerWidth ? window.innerWidth : window.outerWidth;
            wh = window.innerHeight < window.outerHeight ? window.innerHeight : window.outerHeight;

            if (dirSize.includes("lg") || (dirSize.includes("xl") && instance)) instance.destroy();
            this.setState(
                {
                    dirSize: countDirSize(),
                },
                () => {
                    setXLSize(document.getElementById("whatIsLyzkonCol"), "left");
                    setXLSize(document.getElementById("attractionsCol"), "right");
                    setXLSize(document.getElementById("bestLARPCol"), "left");
                    resetAnimation();
                    removeStyleAttr();
                    setPositions();
                }
            );
        }
    };

    componentDidMount() {
        //const mainBannerBtn = document.getElementById("mainBannerBtn");
        //mainBannerBtn.classList.remove("bounce");
        ww = window.innerWidth < window.outerWidth ? window.innerWidth : window.outerWidth;
        wh = window.innerHeight < window.outerHeight ? window.innerHeight : window.outerHeight;
        $("body").addClass("overflow-x-hidden");

        if (this.state.dirSize.includes("lg") || this.state.dirSize.includes("xl")) {
            disableScroll();
            setTimeout(() => {
                //mainBannerBtn.classList.add("bounce");
                enableScroll();
                window.addEventListener("scroll", scrolling);

                $("#scrollDown").fadeIn(400);
            }, 2000);
            setTimeout(() => {
                $("#scrollDownBall").addClass("move");
            }, 2400);
        } else {
            window.addEventListener("scroll", scrolling);
            //mainBannerBtn.classList.add("bounce");
            $("#scrollDown").fadeIn(400);
            $("#scrollDownBall").delay(400).addClass("move");
        }
        /*  document.getElementById("testh3").textContent += "inner: " + window.innerWidth + "px, " + window.innerHeight + "px, outer: " + window.outerWidth + "px, " + window.outerHeight + "px"; */

        setXLSize(document.getElementById("whatIsLyzkonCol"), "left");
        setXLSize(document.getElementById("attractionsCol"), "right");
        setXLSize(document.getElementById("bestLARPCol"), "left");

        window.addEventListener("resize", this.resize);

        //scrolling bar banner
        document.querySelectorAll(".barimg").forEach((elem, i) => {
            const modifier = elem.getAttribute("data-modifier");
            instance = basicScroll.create({
                elem: elem,
                from: "top-bottom",
                to: "top-top",
                direct: true,
                props: {
                    "--translateY": {
                        from: "0",
                        to: `-${10 * modifier}px`,
                    },
                },
            });

            instance.start();
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("scroll", scrolling);
    }

    render() {
        const { dirSize } = this.state;
        const { t, frontURL, onShowSpoonLoader, fetchURL, onHideSpinner } = this.props;
        return (
            <Row className="no-gutters">
                <Col id="homeHeight">
                    <MainBanner t={t} frontURL={frontURL} onChangeRoute={(e, link) => this.handleChangeRoute(e, link)} dirSize={dirSize} />
                    <WhatIsLyzkon t={t} frontURL={frontURL} onChangeRoute={(e, link) => this.handleChangeRoute(e, link)} />
                    <Attractions t={t} frontURL={frontURL} onChangeRoute={(e, link) => this.handleChangeRoute(e, link)} dirSize={dirSize} />
                    <BestLARP t={t} frontURL={frontURL} onChangeRoute={(e, link) => this.handleChangeRoute(e, link)} />
                    <NewsTiles t={t} frontURL={frontURL} onChangeRoute={(e, link) => this.handleChangeRoute(e, link)} fetchURL={fetchURL} onHideSpinner={onHideSpinner} />
                    <Register t={t} frontURL={frontURL} onChangeRoute={(e, link) => this.handleChangeRoute(e, link)} />
                    <RestContent t={t} frontURL={frontURL} onChangeRoute={(e, link) => this.handleChangeRoute(e, link)} onShowSpoonLoader={onShowSpoonLoader} dirSize={dirSize} />
                </Col>
            </Row>
        );
    }
}

export default withRouter(Home);

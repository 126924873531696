import React, { Component, Fragment } from "react";
import { Row, Col, Container, Image, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import i18n from "../../../../i18n";

var limit = 5;

class NewsList extends Component {
    state = {
        news: [],
        offset: 0,
        showLoadMoreBtn: true,
        loading: true,
    };

    getNewsList = async () => {
        const { offset, news } = this.state;
        const { fetchURL, t, onShowAlert } = this.props;
        const passData = { lang: i18n.language, limit: limit, offset: offset };
        try {
            const response = await fetch(`${fetchURL}/news-list`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        const fetchedNewsList = fetchedData.newsList;
                        let newsList = [];
                        fetchedNewsList.forEach((e) => {
                            if (e.blob_data) {
                                const blob = e.blob_data;
                                var buffer = Buffer.from(blob);
                                var bufferBase64 = buffer.toString("base64");
                            }

                            newsList.push({
                                image: e.blob_data
                                    ? {
                                          data: bufferBase64,
                                          type: e.blob_file_type,
                                          name: e.blob_name,
                                      }
                                    : null,
                                langs: e.langs,
                                date: e.news_date,
                                link: e.news_link,
                            });
                        });

                        newsList = news.concat(newsList);

                        this.setState({
                            news: newsList,
                            offset: offset + limit,
                            showLoadMoreBtn: fetchedNewsList.length < limit ? false : true,
                        });

                        setTimeout(() => {
                            this.setState({ loading: false });
                        }, 1000);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        this.getNewsList();
    }

    componentWillUnmount() {
        this.setState({ news: null });
    }

    render() {
        const { news, showLoadMoreBtn, loading } = this.state;
        const { frontURL, t } = this.props;

        return (
            <div id="fetchedNewsList">
                <Container className="my-5">
                    <Row>
                        {loading ? (
                            <Col className="text-center">
                                <LoadingProgressBar t={t} />
                            </Col>
                        ) : (
                            <Fragment>
                                {news.length === 0 ? (
                                    <Col>
                                        <p className="text-center my-5 h3 opacity-7">{t("news.noNewsTxt")}</p>
                                    </Col>
                                ) : (
                                    <Fragment>
                                        {news.map((e, i) => (
                                            <Col xs={12} key={i}>
                                                <div
                                                    className="news-list"
                                                    onClick={() => {
                                                        this.props.history.push(`/${t("url.link.news")}/${e.link}`);
                                                    }}
                                                >
                                                    <Row>
                                                        <Col xs={12} lg={3} className="py-lg-3">
                                                            <div className="news-image ml-lg-3 mx-auto mr-lg-0 mt-3 mt-lg-0">
                                                                <Image
                                                                    src={e.image ? `data:${e.image.type};base64,${e.image.data}` : `${frontURL}/images/placeholder_img.webp`}
                                                                    alt={e.image ? e.image.name : "placeholder-img"}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} lg={9} className="pr-lg-5">
                                                            {e.langs.map((el, j) => (
                                                                <Fragment key={j}>
                                                                    <p className="news-title px-2">{el.lang_code === i18n.language && el.news_title}</p>
                                                                    <p className="news-text px-2 ">{el.lang_code === i18n.language && el.news_subtitle}</p>
                                                                </Fragment>
                                                            ))}

                                                            <p className="news-date opacity-4 px-2 pb-3 pb-lg-0">{e.date}</p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        ))}
                                        <Col xs={12} className="text-center my-4">
                                            {!showLoadMoreBtn ? (
                                                <p className="h4 opacity-4">{t("news.thatsAll")}</p>
                                            ) : (
                                                <Button variant="outline-primary" onClick={() => this.getNewsList()}>
                                                    {t("news.loadMoreBtn")}
                                                </Button>
                                            )}
                                        </Col>
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(NewsList);

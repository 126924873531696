import React, { Fragment } from "react";
import { Alert, Button } from "react-bootstrap";

const AlertUsingCookies = (props) => {
    const { onAcceptCookies, t } = props;
    return (
        <Fragment>
            <Alert className="fixed-bottom mb-0 small" id="alertCookie">
                {t("alertCookie")}

                <Button className="ml-2 btn-sm" variant="primary" onClick={onAcceptCookies}>
                    OK
                </Button>
            </Alert>
        </Fragment>
    );
};

export default AlertUsingCookies;

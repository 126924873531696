import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import AddNewCharacter from "../pages/myAccount/characters/AddNewCharacter";

const AddNewCharacterModal = (props) => {
    const { show, onClose, t, onShowAlert, fetchURL, activeEvent, charLink, onAddedNewChar } = props;

    return (
        <Fragment>
            <Modal show={show} onHide={onClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{t("myAccount.characters.addNewCharacter")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddNewCharacter onShowAlert={onShowAlert} t={t} fetchURL={fetchURL} activeEvent={activeEvent} charLink={charLink} modalView={true} onAddedNewChar={onAddedNewChar} />
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};
export default AddNewCharacterModal;

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Button, Col, Modal, Row, Table, OverlayTrigger, Tooltip } from "react-bootstrap";

const BarOrdersListModal = (props) => {
    const { onClose, t, barOrdersList, clientType } = props;
    return (
        <Fragment>
            <Modal show={true} onHide={onClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{t("myAccount.barClient.barOrdersList")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Table responsive className="border my-3" id="tableOrders" hover>
                                <thead>
                                    <tr>
                                        <th>{t("no.")}</th>
                                        <th>{t("myAccount.barClient.orders.product")}</th>
                                        <th>{t("myAccount.barClient.orders.price")}</th>
                                        <th>{t("myAccount.barClient.orders.status")}</th>
                                        <th>{t("myAccount.barClient.orders.date")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {barOrdersList.map((e, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>
                                                {e.amount}x {e.bar_item_name}
                                            </td>
                                            <td>
                                                {clientType === "INNER" ? e.inner_price : e.price} {t("currency")}{" "}
                                                {e.ingame_currency_price > 0 && (
                                                    <span>
                                                        {e.ingame_currency_price} {t("ingameCurrency")}
                                                    </span>
                                                )}
                                            </td>
                                            <td>
                                                <OverlayTrigger placement="top" overlay={(props) => <Tooltip {...props}>{t(`myAccount.barClient.orders.statuses.${e.bar_order_stat_code}`)}</Tooltip>}>
                                                    <FontAwesomeIcon
                                                        icon={e.bar_order_stat_code === "CANCELED" ? faTimes : faCheck}
                                                        className={e.bar_order_stat_code === "CANCELED" ? "text-danger" : "text-success"}
                                                    />
                                                </OverlayTrigger>
                                            </td>
                                            <td>{e.create_date}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" type="button" onClick={onClose}>
                        {t("close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default BarOrdersListModal;

import { faArrowLeft, faCheck, faExclamationCircle, faMinus, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, Component } from "react";
import { Alert, Button, Col, Form, InputGroup, Modal, Row, Image } from "react-bootstrap";
import NoPermission from "../../../other/NoPermission";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import { withRouter } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import i18n from "../../../../../i18n";
import $ from "jquery";

class ShopItemDetails extends Component {
    state = { shopItemDetails: null, shitVariants: null, loading: true, warning: null, info: null, selectedVariant: null, selectedQuantity: 1, showTableSizesModal: false };

    getShopItemDetails = async () => {
        const { fetchURL, t, onShowAlert, location, shopLink, activeEvent } = this.props;

        const shitCode = location.pathname.replace(`${shopLink}/${t("url.link.shopItem")}/`, "");
        const passData = {
            shitCode: shitCode,
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/shop-item-details`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status !== true) {
                        this.setState({ warning: t(`warning.${fetchedData.status}`), loading: false });
                        this.props.onRefreshCartData();
                    } else {
                        const shopItemDetails = fetchedData.shopItemDetails;
                        if (shopItemDetails.img) {
                            let blob = shopItemDetails.img.data;
                            let buffer = Buffer.from(blob);
                            let bufferBase64 = buffer.toString("base64");
                            shopItemDetails.img.data = bufferBase64;
                        }

                        const shitVariantsDropdown = fetchedData.shitVariants;
                        shitVariantsDropdown.forEach((e) => {
                            if (e.quantity <= 0 && e.quantity !== null) {
                                e.disabled = true;
                                e.text += ` (${t("myAccount.shop.variantDisabled")})`;
                            }
                        });
                        // console.log(shitVariantsDropdown);
                        this.setState({ shopItemDetails: shopItemDetails, shitVariants: shitVariantsDropdown, loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    addToCart = async () => {
        const { shopItemDetails, selectedVariant, selectedQuantity } = this.state;
        const { fetchURL, t, onShowAlert, activeEvent } = this.props;
        onShowAlert({ variant: "info", message: t("info.addingToCart") });
        const passData = {
            shopItemCode: shopItemDetails.code,
            selectedVariant: selectedVariant,
            selectedQuantity: parseInt(selectedQuantity, 10),
            activeEvent: activeEvent,
            shopItemPrice: shopItemDetails.price,
        };

        try {
            const response = await fetch(`${fetchURL}/add-to-cart`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status === "warning") onShowAlert({ variant: "warning", message: t(`warning.${fetchedData.code}`), fading: false });
                    else {
                        onShowAlert({ variant: "success", message: t("success.addedToCart"), fading: 2000 });
                        this.setState({ selectedQuantity: 1 });
                    }
                    this.props.onRefreshCartData();
                    this.setState({ loading: true }, () => this.getShopItemDetails());
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleRedirectTo = (e, url) => {
        e.preventDefault();
        this.props.history.push(url);
    };

    handleCheckValidation = (e) => {
        const { shitVariants, selectedQuantity, selectedVariant, shopItemDetails } = this.state;
        const { t, onShowAlert } = this.props;
        e.preventDefault();
        onShowAlert("");
        let errorMsg = [];
        const quantity = parseInt(selectedQuantity);
        if (shitVariants.length > 0) {
            if (!selectedVariant) {
                $("#itemVariant").addClass("is-invalid");
                errorMsg.push(t("error.incorrectItemVariant"));
            }

            shitVariants.forEach((e, i) => {
                if (selectedVariant === e.value) {
                    if (e.quantity <= 0 && e.quantity !== null) {
                        $("#itemVariant").addClass("is-invalid");
                        errorMsg.push(t("error.shitVariantNotAvailable"));
                    } else if (e.quantity !== null && (quantity > e.quantity || quantity < 0 || quantity > 99)) {
                        $(`#itemQuantity${i}`).addClass("is-invalid");
                        errorMsg.push(t("error.incorrectItemQuantity"));
                    }
                }
            });
        } else {
            //console.log(shopItemDetails);
            //console.log(quantity);
            if (!quantity || quantity < 1 || quantity > 99) {
                $("#itemQuantity").addClass("is-invalid");
                errorMsg.push(t("error.incorrectItemQuantity"));
            } else if (shopItemDetails.quantity && quantity > shopItemDetails.quantity) {
                $("#itemQuantity").addClass("is-invalid");
                errorMsg.push(t("error.cannotAddMoreProduct"));
            }
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            this.addToCart();
        }
    };

    handleChangeQuantity = (operation) => {
        const { selectedQuantity } = this.state;
        let quantity = selectedQuantity;
        if (operation === "add") quantity += 1;
        else quantity -= 1;
        this.setState({ selectedQuantity: quantity });
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getShopItemDetails();
    }
    render() {
        const { noPermission, shopItemDetails, loading, warning, shitVariants, selectedVariant, selectedQuantity, showTableSizesModal } = this.state;
        const { t, shopLink, frontURL } = this.props;

        return (
            <Fragment>
                {noPermission ? (
                    <Col xs={12} className="text-center">
                        <NoPermission t={t} />
                    </Col>
                ) : (
                    <Fragment>
                        <Col xs={12}>
                            <p className="my-4 h4 text-uppercase text-center">{t("myAccount.shop.itemDetails.title")}</p>
                        </Col>
                        <Col xs={12}>
                            <Button variant="link" onClick={(e) => this.handleRedirectTo(e, `${shopLink}`)}>
                                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                {t("myAccount.shop.goBackToItemsList")}
                            </Button>
                        </Col>
                        {loading ? (
                            <Col xs={12} className="text-center">
                                <LoadingProgressBar t={t} />
                            </Col>
                        ) : warning ? (
                            <Col xs={12}>
                                <Alert variant="warning" className="w-100 text-center my-3">
                                    <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                                    {warning}
                                </Alert>
                            </Col>
                        ) : (
                            <Form onSubmit={this.handleCheckValidation}>
                                {shopItemDetails && (
                                    <Col id="shopItemDetails">
                                        <Row>
                                            <Col lg={6} xs={12}>
                                                {shopItemDetails.img ? (
                                                    <img
                                                        src={`data:${shopItemDetails.img.type};base64,${shopItemDetails.img.data}`}
                                                        className="w-100 mt-3"
                                                        alt={shopItemDetails.img.name}
                                                    />
                                                ) : (
                                                    <img src={`${frontURL}/images/placeholder_img_dark.webp`} alt="placeholder_img" className="w-100  mt-3" />
                                                )}
                                                <p className="text-justify my-3">
                                                    {shopItemDetails.langs.map((e, i) => (
                                                        <Fragment key={i}>{e.lang_code === i18n.language && e.shit_description}</Fragment>
                                                    ))}
                                                </p>
                                            </Col>
                                            <Col lg={6} xs={12}>
                                                <p className="shop-item-details-name">
                                                    {shopItemDetails.langs.map((e, i) => (
                                                        <Fragment key={i}>{e.lang_code === i18n.language && e.shit_name}</Fragment>
                                                    ))}
                                                </p>
                                                <Row>
                                                    <Col lg={8} xs={12}>
                                                        <p className="shop-item-details-availability">
                                                            {shopItemDetails.available === "Y" && (shopItemDetails.quantity > 0 || shopItemDetails.quantity === null) ? (
                                                                <>
                                                                    {t("myAccount.shop.itemAvailable")}
                                                                    <FontAwesomeIcon icon={faCheck} className="text-success ml-2" />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {t("myAccount.shop.itemNotAvailable")}
                                                                    <FontAwesomeIcon icon={faTimes} className="text-danger ml-2" />
                                                                </>
                                                            )}
                                                        </p>
                                                    </Col>
                                                    {shopItemDetails.category === "TSHIRT" && (
                                                        <Col lg={4} xs={12} className="text-lg-right mt-3 mt-lg-0">
                                                            <Button variant="link" onClick={() => this.setState({ showTableSizesModal: true })}>
                                                                {t("myAccount.shop.sizes")}
                                                            </Button>
                                                        </Col>
                                                    )}

                                                    <Col xs={12}>
                                                        <p className="shop-item-details-price my-3">
                                                            <span className="price mr-2">
                                                                {shopItemDetails.price} {t("currency")}
                                                            </span>
                                                            / {t("myAccount.shop.pcs")}
                                                        </p>
                                                    </Col>

                                                    {shopItemDetails.available === "Y" && (shopItemDetails.quantity > 0 || shopItemDetails.quantity === null) && (
                                                        <Fragment>
                                                            {shitVariants.length > 0 ? (
                                                                <Fragment>
                                                                    <Col xs={12} className="my-3">
                                                                        <Form.Label>{t("myAccount.shop.variantLabel")}:</Form.Label>
                                                                        <Dropdown
                                                                            placeholder={t("myAccount.shop.chooseItemVariant")}
                                                                            fluid
                                                                            selection
                                                                            options={shitVariants}
                                                                            onChange={(event, data) => {
                                                                                $("#itemVariant").removeClass("is-invalid");
                                                                                this.setState({ selectedVariant: data.value });
                                                                            }}
                                                                            id="itemVariant"
                                                                        />
                                                                    </Col>
                                                                    {selectedVariant &&
                                                                        shitVariants.map((e, i) => (
                                                                            <Fragment key={i}>
                                                                                {e.value === selectedVariant && (
                                                                                    <Fragment>
                                                                                        {e.quantity > 0 || e.quantity === null ? (
                                                                                            shopItemDetails.limited === "N" && (
                                                                                                <>
                                                                                                    {e.quantity !== null && (
                                                                                                        <Col xs={12} className="my-3">
                                                                                                            <p>
                                                                                                                {t("myAccount.shop.availableAmount")}: {e.quantity}{" "}
                                                                                                                {t("myAccount.shop.pcs")}
                                                                                                            </p>
                                                                                                        </Col>
                                                                                                    )}
                                                                                                    <Col xs={12}>
                                                                                                        <InputGroup className="mb-3">
                                                                                                            <Button
                                                                                                                variant="outline-secondary"
                                                                                                                id="itemQuantityMinus"
                                                                                                                disabled={selectedQuantity <= 1 ? true : false}
                                                                                                                onClick={() => {
                                                                                                                    $(`#itemQuantity${i}`).removeClass("is-invalid");
                                                                                                                    this.handleChangeQuantity("subtract");
                                                                                                                }}
                                                                                                            >
                                                                                                                <FontAwesomeIcon icon={faMinus} />
                                                                                                            </Button>
                                                                                                            <Form.Control
                                                                                                                aria-label={`itemQuantity${i}`}
                                                                                                                aria-describedby={`itemQuantityAddon${i}`}
                                                                                                                type="number"
                                                                                                                name={`itemQuantity${i}`}
                                                                                                                id={`itemQuantity${i}`}
                                                                                                                value={selectedQuantity}
                                                                                                                className="item-quantity"
                                                                                                                style={{ width: "80px" }}
                                                                                                                readOnly
                                                                                                            />

                                                                                                            <Button
                                                                                                                variant="outline-secondary"
                                                                                                                id="itemQuantityPlus"
                                                                                                                disabled={
                                                                                                                    (e.quantity && selectedQuantity >= e.quantity) ||
                                                                                                                    selectedQuantity >= 99
                                                                                                                        ? true
                                                                                                                        : false
                                                                                                                }
                                                                                                                onClick={() => {
                                                                                                                    $(`#itemQuantity${i}`).removeClass("is-invalid");
                                                                                                                    this.handleChangeQuantity("add");
                                                                                                                }}
                                                                                                            >
                                                                                                                <FontAwesomeIcon icon={faPlus} />
                                                                                                            </Button>
                                                                                                        </InputGroup>
                                                                                                    </Col>
                                                                                                </>
                                                                                            )
                                                                                        ) : (
                                                                                            <Col xs={12} className="my-3">
                                                                                                {t("myAccount.shop.noProductVariant")}
                                                                                            </Col>
                                                                                        )}
                                                                                    </Fragment>
                                                                                )}
                                                                            </Fragment>
                                                                        ))}
                                                                </Fragment>
                                                            ) : (
                                                                <Fragment>
                                                                    <Col xs={12}>
                                                                        {shopItemDetails.quantity && (
                                                                            <p>
                                                                                {t("myAccount.shop.availableAmount")}: {shopItemDetails.quantity} {t("myAccount.shop.pcs")}
                                                                            </p>
                                                                        )}
                                                                        <p className="mt-2 mr-1 d-inline-block">{t("myAccount.shop.quantity")}:</p>
                                                                        {shopItemDetails.limited === "N" && (
                                                                            <InputGroup className="mb-3">
                                                                                <Button
                                                                                    variant="outline-secondary"
                                                                                    id="itemQuantityMinus"
                                                                                    disabled={selectedQuantity <= 1 ? true : false}
                                                                                    onClick={() => {
                                                                                        $(`#itemQuantity`).removeClass("is-invalid");
                                                                                        this.handleChangeQuantity("subtract");
                                                                                    }}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faMinus} />
                                                                                </Button>
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    name="itemQuantity"
                                                                                    id="itemQuantity"
                                                                                    value={selectedQuantity}
                                                                                    className="item-quantity"
                                                                                    style={{ width: "80px" }}
                                                                                    readOnly
                                                                                />

                                                                                <Button
                                                                                    variant="outline-secondary"
                                                                                    id="itemQuantityPlus"
                                                                                    disabled={
                                                                                        (shopItemDetails.quantity && selectedQuantity >= shopItemDetails.quantity) ||
                                                                                        selectedQuantity >= 99
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    onClick={() => {
                                                                                        $(`#itemQuantity`).removeClass("is-invalid");
                                                                                        this.handleChangeQuantity("add");
                                                                                    }}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faPlus} />
                                                                                </Button>
                                                                            </InputGroup>
                                                                        )}
                                                                    </Col>
                                                                </Fragment>
                                                            )}
                                                        </Fragment>
                                                    )}

                                                    {shopItemDetails.available === "Y" && (shopItemDetails.quantity > 0 || shopItemDetails.quantity === null) && (
                                                        <Col xs={12}>
                                                            <Button type="submit" variant="primary" className="w-100 my-3">
                                                                {t("myAccount.shop.addToCart")}
                                                            </Button>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                            </Form>
                        )}
                    </Fragment>
                )}
                {showTableSizesModal && (
                    <Modal show={showTableSizesModal} onHide={() => this.setState({ showTableSizesModal: false })} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>{t("myAccount.shop.sizes")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="no-gutters">
                                <Col xs={12} className="text-center">
                                    <Image src={`${frontURL}/images/table-sizes-${i18n.language === "pl" ? "pl" : "en"}.jpg`} alt="tshirt sizes in table" className="img-fluid" />
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default withRouter(ShopItemDetails);

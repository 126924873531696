import React, { Component, Fragment } from "react";
import SmallBanner from "../../other/SmallBanner";
import { Row, Col } from "react-bootstrap";
import PreviousEditionsList from "./PreviousEditionsList";
import PreviousEditionsSummary from "./PreviousEditionsSummary";
import PreviousEditionsPlot from "./PreviousEditionsPlot";
import { Route } from "react-router-dom";
import $ from "jquery";
import Footer from "../../other/Footer";

class PreviousEditions extends Component {
    componentDidMount() {
        this.props.onHideSpinner(200);
        $("#previousEditions").css({ minHeight: `${$(window).outerHeight() - $("#footer").outerHeight()}px` });
    }

    render() {
        const { t, frontURL, fetchURL, onShowAlert, onShowSpoonLoader } = this.props;
        const mainLink = `/${t("url.link.previousEditions")}/`;
        return (
            <Fragment>
                <div id="previousEditions">
                    <Row className="no-gutters">
                        <Col>
                            <SmallBanner
                                frontURL={frontURL}
                                bgBottom={`${frontURL}/images/small-banners/b5-bottom.webp`}
                                bgDesktop={`${frontURL}/images/small-banners/b5.webp`}
                                title={t("previousEditions.title")}
                                subtitle={t("previousEditions.subtitle")}
                            />
                        </Col>
                    </Row>
                    <Route exact path={mainLink} render={() => <PreviousEditionsList t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} frontURL={frontURL} />} />
                    <Route
                        path={`${mainLink}${t("url.link.summary")}`}
                        render={(routeProps) => <PreviousEditionsSummary t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} frontURL={frontURL} {...routeProps} />}
                    />
                    <Route
                        path={`${mainLink}${t("url.link.plot")}`}
                        render={(routeProps) => <PreviousEditionsPlot t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} frontURL={frontURL} {...routeProps} />}
                    />
                </div>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default PreviousEditions;

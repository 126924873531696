import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";

const DeleteNewsModal = (props) => {
    const { show, onClose, onDeleteNews, t } = props;
    return (
        <Fragment>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("myAccount.adminPanel.news.newsDelete")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("myAccount.adminPanel.news.newsDeleteModalBody")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={onClose}>
                        {t("myAccount.adminPanel.news.newsDeleteModalNo")}
                    </Button>
                    <Button variant="outline-primary" type="button" onClick={onDeleteNews}>
                        {t("myAccount.adminPanel.news.newsDeleteModalYes")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default DeleteNewsModal;

import React, { Component, Fragment } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import ShopCart from "./ShopCart";
import ShopItem from "./ShopItem";
import { Route } from "react-router";
import NoMatchMyAccount from "../../../other/NoMatchMyAccount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

class Shop extends Component {
    state = {
        loading: true,
        cart: null,
    };

    getCartData = async () => {
        const { fetchURL, t, onShowAlert } = this.props;
        try {
            const response = await fetch(`${fetchURL}/get-cart-data`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status === "notLogged") this.props.onNotLogged();
                    else {
                        this.setState({ loading: false, cart: fetchedData.cart });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleResetCart = () => {
        this.setState({ cart: null });
    };

    componentDidMount() {
        this.getCartData();
    }
    render() {
        const { loading, cart } = this.state;
        const { t, fetchURL, onShowAlert, activeEvent, shopLink, location, frontURL } = this.props;
        return (
            <Fragment>
                <div className="my-account-show">
                    {activeEvent === "noActiveEvent" ? (
                        <Row className="no-gutters">
                            <Col xs={12} lg={{ span: 8, offset: 2 }}>
                                <Alert variant="info" className="w-100 text-center mt-5">
                                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                    {t("info.noActiveEventInfo")}
                                </Alert>
                            </Col>
                        </Row>
                    ) : (
                        <div id="shop" className="mb-4">
                            {location.pathname !== `${shopLink}/${t("url.link.shopOrders")}` &&
                                location.pathname !== `${shopLink}/${t("url.link.shopCart")}` &&
                                !location.pathname.includes(`${shopLink}/${t("url.link.shopItem")}`) &&
                                location.pathname !== `${shopLink}` && <Route render={() => <NoMatchMyAccount t={t} />} />}
                            {(location.pathname === `${shopLink}` || location.pathname.includes(`${shopLink}/${t("url.link.shopItem")}`)) && (
                                <ShopItem
                                    t={t}
                                    fetchURL={fetchURL}
                                    onShowAlert={onShowAlert}
                                    shopLink={shopLink}
                                    activeEvent={activeEvent}
                                    location={location}
                                    frontURL={frontURL}
                                    loading={loading}
                                    cart={cart}
                                    onRefreshCartData={this.getCartData}
                                />
                            )}
                            <Route
                                exact
                                path={`${shopLink}/${t("url.link.shopCart")}`}
                                render={() => (
                                    <ShopCart
                                        t={t}
                                        fetchURL={fetchURL}
                                        onShowAlert={onShowAlert}
                                        shopLink={shopLink}
                                        activeEvent={activeEvent}
                                        frontURL={frontURL}
                                        onGetCartData={this.getCartData}
                                        onResetCart={this.handleResetCart}
                                    />
                                )}
                            />
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}

export default Shop;

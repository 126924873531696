import React, { Component, Fragment } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import i18n from "../../../../i18n";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

class PreviousEditionsList extends Component {
    state = {
        editions: null,
    };

    getEditionsList = async () => {
        const { fetchURL, t, onShowAlert } = this.props;

        try {
            const response = await fetch(`${fetchURL}/previous-editions-list`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        this.setState({ editions: fetchedData.previousEditionsList });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getEditionsList();
    }

    render() {
        const { frontURL, t } = this.props;
        const { editions } = this.state;

        return (
            <Fragment>
                <Container id="previousEditionsList" className="py-5">
                    <Row>
                        {!editions ? (
                            <Col>
                                <LoadingProgressBar t={t} />
                            </Col>
                        ) : (
                            <Fragment>
                                {editions.length === 0 ? (
                                    <Col>
                                        <p className="text-center h3 opacity-7">{t("previousEditions.noEditions")}</p>
                                    </Col>
                                ) : (
                                    <Fragment>
                                        {editions.map((e, i) => (
                                            <Col xs={12} lg={4} className="text-center my-4" key={i}>
                                                <Image src={`${frontURL}/images/previous-editions/logo_${e.code}.webp`} alt="placeholder-img" fluid />
                                                <p className="previous-edition-title">Łyżkon {e.code}</p>
                                                {i18n.language === "pl" && (
                                                    <Button
                                                        variant="outline-primary"
                                                        className="d-block w-100 my-3"
                                                        onClick={() => {
                                                            this.props.history.push(`/${t("url.link.previousEditions")}/${t("url.link.summary")}/${e.code}`);
                                                        }}
                                                    >
                                                        {t("previousEditions.summaryBtn")}
                                                    </Button>
                                                )}
                                                {e.langs.length > 0 && (
                                                    <Fragment>
                                                        {e.langs.map((el, j) => (
                                                            <Fragment key={j}>
                                                                {el.lang_code === i18n.language && (
                                                                    <Button
                                                                        variant="outline-primary"
                                                                        className="d-block w-100 my-3"
                                                                        onClick={() => {
                                                                            this.props.history.push(`/${t("url.link.previousEditions")}/${t("url.link.plot")}/${e.code}`);
                                                                        }}
                                                                    >
                                                                        {t("previousEditions.plotBtn")}
                                                                    </Button>
                                                                )}
                                                            </Fragment>
                                                        ))}
                                                    </Fragment>
                                                )}
                                            </Col>
                                        ))}
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default withRouter(PreviousEditionsList);

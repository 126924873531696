import React from "react";
import { Button, Col, Row } from "react-bootstrap";

const WhatIsLyzkon = (props) => {
    const { t, frontURL, onChangeRoute } = props;

    return (
        <Row id="whatIsLyzkon" className="no-gutters">
            <div
                id="barImg"
                className="bg-img"
                style={{
                    backgroundImage: `url(${`${frontURL}/images/22555546_1537139566366384_9144545403879232720_o.webp`})`,
                }}
            ></div>
            <Col xs={12}>
                <p className="p-title">{t("home.p1.title")}</p>
            </Col>
            <Col xs={12} lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }} id="whatIsLyzkonCol">
                <div className="p-text" id="whatIsLyzkonText" style={{ opacity: 0 }}>
                    <p dangerouslySetInnerHTML={{ __html: t("home.p1.text") }}></p>
                    <div className="text-center">
                        <Button variant="primary" size="lg" onClick={(e) => onChangeRoute(e, `/${t("url.link.about")}`)}>
                            {t("url.text.readMore")}
                        </Button>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default WhatIsLyzkon;

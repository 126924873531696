import React, { Fragment } from "react";
import { Alert, Row, Col, Button } from "react-bootstrap";
import $ from "jquery";
import { withRouter } from "react-router-dom";

const AlertBasic = (props) => {
    const { variant, mssg, onCloseAlert, fading, contactBtn, t, onShowSpoonLoader, onShowAlert } = props;
    $(document).ready(function () {
        if (fading) {
            $(".alert-basic").delay(fading).fadeOut(400);
            setTimeout(() => {
                onCloseAlert();
            }, fading + 400);
        }
    });

    function handleCloseAlert() {
        $(".alert-basic").fadeOut(200);
        setTimeout(() => {
            onCloseAlert();
        }, 250);
    }

    function handleRedirect(e, url) {
        e.preventDefault();
        onShowSpoonLoader();
        //console.log(url);
        setTimeout(() => props.history.push(url), 700);
    }

    return (
        <Fragment>
            <Row className="no-gutters">
                <Col>
                    <Alert className="alert-basic" variant={variant} onClose={() => handleCloseAlert()} dismissible>
                        {mssg}
                        {contactBtn && (
                            <Button
                                variant="outline-danger"
                                className={`d-block mt-2 btn btn-outline-${variant}`}
                                onClick={(e) => {
                                    onShowAlert("");
                                    handleRedirect(e, `/${t("url.link.contact")}`);
                                }}
                            >
                                {t("error.contactWithAdministration")}
                            </Button>
                        )}
                    </Alert>
                </Col>
            </Row>
        </Fragment>
    );
};

export default withRouter(AlertBasic);

import React, { Component } from "react";
import { Row, Col, Image, Button, Container } from "react-bootstrap";
import SmallBanner from "../../other/SmallBanner";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import Footer from "../../other/Footer";

var ww, wh;

function fadeToObjects(object) {
    const objectOffset = $(`${object}`).offset();

    const distance = window.scrollY;
    if (distance + wh - 200 >= objectOffset.top) $(`${object}`).fadeTo(600, 1);
}

function setPositions() {
    const distance = window.scrollY,
        jokeText = $("#joke p").offset();
    if (distance + wh + 100 >= jokeText.top) $("#joke p").fadeTo(400, 1);
    if (ww >= 992) {
        $("#joke h1 span:nth-child(1)").css({ transform: `translateX(${distance * 0.3}px)`, animation: "none" });
        $("#joke h1 span:nth-child(2)").css({ transform: `translateX(${distance * 0.5}px)`, animation: "none" });
        $("#joke h1 span:nth-child(3)").css({ transform: `translateX(${distance * 0.7}px)`, animation: "none" });

        $("#joke p").css({ transform: `translateX(${distance * -0.1}px)`, animation: "none" });
    } else {
        $("#joke h1 span:nth-child(1)").css({ transform: `translateX(0px)`, animation: "none" });
        $("#joke h1 span:nth-child(2)").css({ transform: `translateX(0px)`, animation: "none" });
        $("#joke h1 span:nth-child(3)").css({ transform: `translateX(0px)`, animation: "none" });
        $("#joke p").css({ transform: `translateX(0px)`, animation: "none" });
    }

    fadeToObjects(`#aboutDetailsTxt`);
    fadeToObjects(`#concepts #conceptPostapo`);
    fadeToObjects(`#concepts #conceptLarp`);
    fadeToObjects(`#concepts #conceptPupa`);
    fadeToObjects(`#aboutCards`);

    if (ww >= 1200 && ww > wh) {
        const el = document.getElementById("aboutDetailsImg");
        const objectOffset = document.getElementById("aboutDetailsFrame").offsetTop;
        if (distance + wh - 100 >= objectOffset) {
            el.style.transform = `translateY(${distance * 0.15 - 100}px)`;
        }
    }
}

const cardsHeight = () => {
    $(".end-card").css({ height: `auto` });
    let cardHeight = 0;
    $(".end-card").each(function () {
        if ($(this).outerHeight() > cardHeight) cardHeight = $(this).outerHeight();
    });
    $(".end-card").css({ height: `${cardHeight}px` });
};

class About extends Component {
    scrolling = () => {
        if (document.getElementById("aboutPage")) {
            setPositions();
        }
    };
    resize = () => {
        if (document.getElementById("aboutPage")) {
            document.getElementById("aboutDetailsImg").removeAttribute("style");
            ww = window.innerWidth < window.outerWidth ? window.innerWidth : window.outerWidth;
            wh = window.innerHeight < window.outerHeight ? window.innerHeight : window.outerHeight;
            cardsHeight();
            setPositions();
        }
    };

    componentDidMount() {
        this.props.onHideSpinner(200);
        setTimeout(
            () => {
                setTimeout(() => $("#joke h1 span:nth-child(1)").addClass("slide-in-left"), 200);
                setTimeout(() => $("#joke h1 span:nth-child(2)").addClass("slide-in-left"), 600);
                setTimeout(() => $("#joke h1 span:nth-child(3)").addClass("slide-in-left"), 1000);
            },
            $(".loading-spoon").hasClass("show") ? 1000 : 0
        );
        ww = window.innerWidth < window.outerWidth ? window.innerWidth : window.outerWidth;
        wh = window.innerHeight < window.outerHeight ? window.innerHeight : window.outerHeight;

        cardsHeight();

        window.addEventListener("scroll", this.scrolling);
        window.addEventListener("resize", this.resize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("scroll", this.scrolling);
    }

    handleRedirect = (e, url) => {
        e.preventDefault();
        this.props.onShowSpoonLoader();
        setTimeout(() => this.props.history.push(url), 700);
    };

    render() {
        const { t, frontURL, onShowSpoonLoader } = this.props;
        return (
            <div id="aboutPage">
                <Row className="no-gutters">
                    <Col xs={12}>
                        <SmallBanner
                            frontURL={frontURL}
                            bgBottom={`${frontURL}/images/small-banners/b2-bottom.webp`}
                            bgDesktop={`${frontURL}/images/small-banners/b3.webp`}
                            title={t("about.title")}
                            subtitle={t("about.subtitle")}
                        />
                    </Col>
                </Row>
                <Container fluid id="about">
                    <Row id="joke">
                        <Col xs={12} className="mt-xl-5">
                            <h1>
                                <span>{t("about.jokeTitle1")}</span>
                                <span>{t("about.jokeTitle2")}</span>
                                <span>{t("about.jokeTitle3")}</span>
                            </h1>
                        </Col>
                        <Col xs={12} xl={{ span: 5, offset: 5 }}>
                            <p className="mb-5" style={{ opacity: 0 }}>
                                {t("about.jokeText")}
                            </p>
                        </Col>
                    </Row>
                    <Row id="aboutDetails">
                        <Col xs={12}>
                            <p className="text-uppercase w-100 text-center my-5" id="aboutDetailsTitle">
                                {t("about.details.title")}
                            </p>
                        </Col>
                        <Col xl={6} xs={12} className="p-3" id="aboutDetailsFrame">
                            <div id="aboutDetailsImg">
                                <Image src={`${frontURL}/images/72816493_2494090067337991_4173708553778888704_n.webp`} fluid />
                                <p className="text-small text-center pt-2">
                                    Photo by{" "}
                                    <a href="https://www.facebook.com/ZlodziejeDusz" rel="noopener noreferrer" target="_blank">
                                        Złodzieje Dusz
                                    </a>
                                </p>
                            </div>
                        </Col>
                        <Col xl={6} xs={12} className="p-3">
                            <div id="aboutDetailsTxt" style={{ opacity: 0 }}>
                                <div dangerouslySetInnerHTML={{ __html: t("about.details.text") }}></div>
                            </div>
                        </Col>
                    </Row>
                    <Row id="concepts">
                        <Col xs={12}>
                            <h2 className="text-center px-4">{t("about.concepts.title")}</h2>
                        </Col>
                        <Col xs={12} xl={{ span: 8, offset: 2 }} className="px-4 concept" style={{ opacity: 0 }} id="conceptPostapo">
                            <p className="concept-title mt-5">{t("about.concepts.postapo")}</p>
                            <div className="concept-img">
                                <div></div>
                                <div></div>
                            </div>
                            <p className="concept-text">{t("about.concepts.postapoTxt")}</p>
                        </Col>
                        <Col xs={12} xl={{ span: 8, offset: 2 }} className="px-4 concept" style={{ opacity: 0 }} id="conceptLarp">
                            <p className="concept-title mt-5">{t("about.concepts.larp")}</p>
                            <div className="concept-img">
                                <div></div>
                                <div></div>
                            </div>
                            <p className="concept-text pb-0">{t("about.concepts.larpTxt")}</p>
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.onShowSpoonLoader();
                                    setTimeout(() => {
                                        this.props.history.push(`${t("url.link.intro")}`);
                                    }, 500);
                                }}
                                variant="outline-primary"
                                size="lg"
                                className="mb-5"
                            >
                                {t("about.concepts.larpBtn")}
                            </Button>
                        </Col>
                        <Col xs={12} xl={{ span: 8, offset: 2 }} className="px-4 concept" style={{ opacity: 0 }} id="conceptPupa">
                            <p className="concept-title mt-5">{t("about.concepts.pupa")}</p>
                            <div className="concept-img">
                                <div></div>
                                <div></div>
                            </div>
                            <p className="concept-text">{t("about.concepts.pupaTxt")}</p>
                        </Col>
                    </Row>
                    <Row id="aboutCards" style={{ opacity: 0 }}>
                        <Col xs={12} className="text-center my-5">
                            <p className="h2 text-uppercase">{t("about.wannaKnowMore.title")}</p>
                        </Col>
                        <Col xs={12} lg={4} className="p-5">
                            <div className="end-card text-center p-4">
                                <p className="end-card-title py-3">{t("about.wannaKnowMore.intro")}</p>
                                <Button onClick={(e) => this.handleRedirect(e, `${t("url.link.intro")}`)} variant="outline-primary" size="lg">
                                    {t("about.wannaKnowMore.introBtn")}
                                </Button>
                            </div>
                        </Col>
                        <Col xs={12} lg={4} className="p-5">
                            <div className="end-card text-center p-4">
                                <p className="end-card-title py-3">{t("about.wannaKnowMore.accr")}</p>
                                <Button
                                    onClick={(e) => this.handleRedirect(e, `${t("url.link.myAccount")}/${t("url.link.accreditation")}/${t("url.link.accrForm")}`)}
                                    variant="outline-primary"
                                    size="lg"
                                >
                                    {t("about.wannaKnowMore.accrBtn")}
                                </Button>
                            </div>
                        </Col>
                        <Col xs={12} lg={4} className="p-5">
                            <div className="end-card text-center p-4">
                                <p className="end-card-title py-3">{t("about.wannaKnowMore.program")}</p>
                                <Button onClick={(e) => this.handleRedirect(e, `${t("url.link.program")}`)} variant="outline-primary" size="lg">
                                    {t("about.wannaKnowMore.programBtn")}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(About);

import React, { Component, Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import i18n from "../../../../../i18n";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";

class ShopOrders extends Component {
    state = {
        userOrders: null,
        loading: true,
    };

    getShopOrdersList = async () => {
        const { fetchURL, t, onShowAlert } = this.props;
        try {
            const response = await fetch(`${fetchURL}/shop-orders-list`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status === "notLogged") this.props.onNotLogged();
                    else if (fetchedData.status === "noOrders") this.setState({ loading: false, userOrders: [] });
                    else this.setState({ loading: false, userOrders: fetchedData.userOrders });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        this.getShopOrdersList();
    }

    render() {
        const { loading, userOrders } = this.state;
        const { t } = this.props;

        return (
            <Fragment>
                <Row className="no-gutters">
                    <Col className="text-center">
                        <p className="h3 my-4 text-uppercase">{t("myAccount.shop.itemsList.orders")}</p>
                    </Col>
                </Row>

                {loading ? (
                    <Row>
                        <Col>
                            <LoadingProgressBar t={t} />
                        </Col>
                    </Row>
                ) : userOrders.length === 0 ? (
                    <Row id="ordersList">
                        <Col className="text-center">
                            <span className="opacity-4">{t("myAccount.shop.ordersList.noOrders")}</span>
                        </Col>
                    </Row>
                ) : (
                    <Row id="ordersList">
                        {userOrders.map((event, i) => (
                            <Fragment key={i}>
                                <Col xs={12} className="mb-3">
                                    <p className="text-uppercase h4 ml-3">{event.eventName}</p>
                                </Col>
                                <Col xs={12}>
                                    <div className="orders-list-box">
                                        {event.orders ? (
                                            event.orders.map((order, j) => (
                                                <div key={j} className="order-box">
                                                    <p className="mb-1">
                                                        {t("myAccount.shop.ordersList.orderNumber")}: {order.orderCode}
                                                    </p>
                                                    <p>
                                                        {t("myAccount.shop.ordersList.date")}: {order.orderDate}
                                                    </p>
                                                    <ul>
                                                        {order.items.map((item, k) => (
                                                            <li className="order-item mb-1" key={k}>
                                                                {item.langs.map((l, m) => (
                                                                    <Fragment key={m}>{l.lang_code === i18n.language && l.shit_name}</Fragment>
                                                                ))}{" "}
                                                                x{item.shordit_quantity} -{" "}
                                                                <span className="text-uppercase bold">
                                                                    {(item.shordit_price * item.shordit_quantity).toFixed(2)} {t("currency")}
                                                                </span>{" "}
                                                                ({t(`myAccount.shop.ordersList.status.${item.shordit_stat_code}`)})
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <p className="mt-3">
                                                        {t("myAccount.shop.ordersList.comment")}:{" "}
                                                        {order.orderComment ? <i>{order.orderComment}</i> : <span className="opacity-4">{t("info.none")}</span>}
                                                    </p>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="opacity-4 ml-3 mb-4">{t("myAccount.shop.ordersList.noOrders")}</p>
                                        )}
                                    </div>
                                </Col>
                            </Fragment>
                        ))}
                    </Row>
                )}
            </Fragment>
        );
    }
}

export default ShopOrders;

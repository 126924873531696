import React from "react";
import $ from "jquery";

const SmallBanner = (props) => {
    const { title, subtitle, bgDesktop, bgBottom } = props;

    $(".small-banner").css({ backgroundImage: `url(${bgDesktop})` });
    $(".small-banner-bottom").css({ backgroundImage: `url(${bgBottom})` });
    $(window).on("scroll", function () {
        const distance = window.scrollY;

        $(".small-banner-bottom").css({ transform: `translateY(${distance * -1.5}px)` });
    });

    return (
        <>
            <div className="small-banner">
                <p className="small-banner-title">
                    <span>{title}</span>
                </p>
                <p className="small-banner-subtitle text-uppercase">
                    <span>{subtitle}</span>
                </p>
            </div>
            <div className="small-banner-bottom"></div>
        </>
    );
};

export default SmallBanner;

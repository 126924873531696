import { faCheckCircle, faMinus, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Col, Row, Container, InputGroup, Button, FormControl, Form, Alert } from "react-bootstrap";
import { withRouter } from "react-router";
import TextareaForm from "../../../forms/TextareaForm";
import ConfirmOrderModal from "../../../modals/ConfirmOrderModal";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import $ from "jquery";
import MoneyTransferData from "../accreditation/MoneyTransferData";
import i18n from "../../../../../i18n";

class ShopCart extends Component {
    state = {
        cartItemsList: null,
        loading: true,
        orderDescr: "",
        orderSum: 0,
        showConfirmOrderModal: false,
        view: "cart",
        orderTitle: null,
        orderSumAmount: null,
        disableSubmitBtn: false,
    };

    getCartItemsList = async () => {
        const { fetchURL, t, onShowAlert } = this.props;

        try {
            const response = await fetch(`${fetchURL}/cart-items-list`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        const errList = fetchedData.errList;

                        if (errList.length > 0) {
                            onShowAlert({
                                variant: "warning",
                                message: t("warning.cartUpdate"),
                            });
                        }

                        const cartItemsList = fetchedData.cartItemsList;
                        let orderSum = 0;
                        cartItemsList.forEach((e) => {
                            if (e.blob_data) {
                                let blob = e.blob_data;
                                let buffer = Buffer.from(blob);
                                let bufferBase64 = buffer.toString("base64");
                                e.blob_data = bufferBase64;
                            }

                            orderSum += e.shit_quantity * e.shit_price;
                        });
                        this.props.onGetCartData();
                        this.setState({ loading: false, cartItemsList: cartItemsList, orderSum: orderSum });
                    }
                });
            }
        } catch (err) {
            //console.log(err);
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    updateCart = async () => {
        const { cartItemsList } = this.state;
        const { fetchURL, t, onShowAlert } = this.props;
        const passData = { cartItemsList: cartItemsList };

        try {
            const response = await fetch(`${fetchURL}/update-cart`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else this.props.onGetCartData();
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    submitOrder = async () => {
        const { fetchURL, t, onShowAlert, activeEvent, onResetCart } = this.props;
        const passData = { cartItemsList: this.state.cartItemsList, orderDescr: this.state.orderDescr, activeEvent: activeEvent };
        //console.log(this.state.cartItemsList);
        onShowAlert({ variant: "info", message: t("info.submittingOrder"), fading: false });
        try {
            const response = await fetch(`${fetchURL}/submit-order`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status === "productLimited") {
                        const langs = fetchedData.langs;
                        let shitName = null;
                        langs.forEach((l) => {
                            if (l.lang_code === "pl") shitName = l.shit_name;
                            else if (l.lang_code === "en") shitName = l.shit_name;
                        });

                        onShowAlert({
                            variant: "danger",
                            message: `${shitName} ${t(`error.productLimited`)}`,
                            fading: false,
                        });
                    } else if (fetchedData.status === "warning") {
                        onShowAlert({ variant: "warning", message: t(`warning.${fetchedData.code}`), fading: 3000 });
                        //w koszyku znalazły się niedostępne produkty - zaktualizuj koszyk
                        if (fetchedData.code === "updatedCart") this.setState({ cartItemsList: fetchedData.updateCart }, () => this.updateCart());
                    } else {
                        //wyświetl dane do przelewu
                        this.setState({ orderTitle: fetchedData.orderTitle, view: "payment", cartItemsList: null });
                        onResetCart();
                        onShowAlert("");
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleChangeQuantity = (operation, i) => {
        const { cartItemsList } = this.state;
        const list = [...cartItemsList];
        if (operation === "add") list[i].shit_quantity += 1;
        else list[i].shit_quantity -= 1;
        let orderSum = 0;
        list.forEach((e) => (orderSum += e.shit_quantity * e.shit_price));
        this.setState({ cartItemsList: list, orderSum: orderSum }, () => this.updateCart());
    };

    handleChangeFormValue = (name, value, i) => {
        const { cartItemsList } = this.state;
        const list = [...cartItemsList];
        let orderSum = 0;
        list[i][name] = value;
        list.forEach((e) => (orderSum += e.shit_quantity * e.shit_price));
        this.setState({ cartItemsList: list, orderSum: orderSum }, () => this.updateCart());
    };

    handleCheckValidation = (e) => {
        const { cartItemsList, orderDescr } = this.state;
        const { t, onShowAlert } = this.props;
        e.preventDefault();
        onShowAlert("");
        let errorMsg = [];

        cartItemsList.forEach((e, i) => {
            if (!e.shit_quantity || e.shit_quantity > 99 || e.shit_quantity < 1) {
                $(`#itemQuantity${i}`).addClass("is-invalid");
                errorMsg.push(t("error.incorrectItemQuantity"));
            }
        });

        if (orderDescr && orderDescr.length > 255) {
            $("#orderDescr").addClass("is-invalid");
            errorMsg.push(t("error.orderDescrIsTooLong"));
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            this.setState({ showConfirmOrderModal: true });
        }
    };

    componentDidMount() {
        this.getCartItemsList();
    }

    render() {
        const { loading, cartItemsList, orderDescr, orderSum, showConfirmOrderModal, view, disableSubmitBtn } = this.state;
        const { t, frontURL, shopLink, onShowAlert } = this.props;
        return (
            <Fragment>
                {view === "cart" && (
                    <Fragment>
                        <Row className="no-gutters">
                            <Col className="text-center">
                                <p className="h3 my-4 text-uppercase">{t("myAccount.shop.itemsList.cart")}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                {loading ? (
                                    <LoadingProgressBar t={t} />
                                ) : (
                                    <Form onSubmit={this.handleCheckValidation}>
                                        {cartItemsList.length > 0 ? (
                                            <Container>
                                                {cartItemsList.map((e, i) => (
                                                    <Row key={i}>
                                                        {i !== 0 && (
                                                            <Col xs={12} className="d-block d-lg-none">
                                                                <hr />
                                                            </Col>
                                                        )}
                                                        <Col xs={{ order: 1, span: 3 }} lg={{ order: 1, span: 1 }} className="mb-2 mb-lg-0">
                                                            {e.blob_data ? (
                                                                <img src={`data:${e.blob_file_type};base64,${e.blob_data}`} className="w-100" alt={e.blob_name} />
                                                            ) : (
                                                                <img src={`${frontURL}/images/placeholder_img_dark.webp`} alt="placeholder_img" className="w-100" />
                                                            )}
                                                        </Col>
                                                        <Col xs={{ order: 2, span: 7 }} lg={{ order: 2, span: 5 }}>
                                                            <p className="m-0 pt-lg-3 pt-0">
                                                                {e.langs.map((l, j) => (
                                                                    <Fragment key={j}>{l.lang_code === i18n.language && l.shit_name}</Fragment>
                                                                ))}
                                                                {e.shit_variant_name && `, ${e.shit_variant_name}`}
                                                            </p>
                                                        </Col>
                                                        <Col xs={{ order: 3, span: 2 }} lg={{ order: 5, span: 1 }} className="text-center mt-3">
                                                            <Button
                                                                variant="link"
                                                                onClick={() => {
                                                                    const { cartItemsList } = this.state;
                                                                    const list = cartItemsList.filter((e, j) => j !== i);

                                                                    let orderSum = 0;
                                                                    list.forEach((e) => (orderSum += e.shit_quantity * e.shit_price));
                                                                    this.setState({ cartItemsList: list, orderSum: orderSum }, () => this.updateCart());
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </Button>
                                                        </Col>
                                                        <Col xs={{ order: 4, span: 7 }} lg={{ order: 3, span: 3 }} className="mb-4 mt-2">
                                                            <InputGroup>
                                                                <Button
                                                                    variant="outline-secondary"
                                                                    id="itemQuantityMinus"
                                                                    disabled={e.shit_quantity <= 1 ? true : false}
                                                                    onClick={() => {
                                                                        $(`#itemQuantity${i}`).removeClass("is-invalid");
                                                                        this.handleChangeQuantity("subtract", i);
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon={faMinus} />
                                                                </Button>
                                                                <FormControl
                                                                    aria-label="item-quantity"
                                                                    id={`itemQuantity${i}`}
                                                                    name="item-quantity"
                                                                    type="number"
                                                                    value={e.shit_quantity}
                                                                    className="text-center item-quantity"
                                                                    onChange={(event) => {
                                                                        const regexp = /^\d+$/;
                                                                        if (!regexp.test(event.target.value) || !event.target.value || event.target.value >= e.available_quantity) {
                                                                            $(`#itemQuantity${i}`).addClass("is-invalid");
                                                                        } else {
                                                                            $(`#itemQuantity${i}`).removeClass("is-invalid");
                                                                            this.handleChangeFormValue("shit_quantity", event.target.value, i);
                                                                        }
                                                                    }}
                                                                    readOnly
                                                                />
                                                                <Button
                                                                    variant="outline-secondary"
                                                                    id="itemQuantityPlus"
                                                                    disabled={
                                                                        (e.shit_variant_quantity && e.shit_quantity >= e.shit_variant_quantity) ||
                                                                        e.shit_quantity >= 99 ||
                                                                        (e.available_quantity && e.shit_quantity >= e.available_quantity) ||
                                                                        e.shit_limited === "Y"
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onClick={() => {
                                                                        $(`#itemQuantity${i}`).removeClass("is-invalid");
                                                                        this.handleChangeQuantity("add", i);
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon={faPlus} />
                                                                </Button>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col xs={{ order: 5, span: 5 }} lg={{ order: 4, span: 2 }} className="text-right">
                                                            {e.shit_quantity > 1 && e.shit_price * e.shit_quantity > 0 ? (
                                                                <div className="mt-1">
                                                                    <span className="bold h5">
                                                                        {(e.shit_price * e.shit_quantity).toFixed(2)} {t("currency")}
                                                                    </span>
                                                                    <p className="opacity-7 text-small">
                                                                        {t("myAccount.shop.cart.perItem")}: {e.shit_price} {t("currency")}
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <span className="bold h5 mt-3 d-block">
                                                                    {(e.shit_price * e.shit_quantity).toFixed(2)} {t("currency")}
                                                                </span>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Row>
                                                    <Col xs={12} className="d-none d-lg-block">
                                                        <hr />
                                                    </Col>
                                                    <Col xs={12} lg={7}>
                                                        <TextareaForm
                                                            value={orderDescr}
                                                            onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })}
                                                            labelTxt={t("myAccount.shop.cart.commentToOrder")}
                                                            name="orderDescr"
                                                            placeholderTxt={t("myAccount.shop.cart.typeCommentToOrder")}
                                                            id="orderDescr"
                                                            rows={2}
                                                        />
                                                    </Col>
                                                    <Col xs={7} lg={3} className="text-uppercase">
                                                        {t("myAccount.shop.cart.orderValue")}:
                                                    </Col>
                                                    <Col xs={5} lg={2} className="text-right text-lg-left h4 bold">
                                                        {orderSum.toFixed(2)} {t("currency")}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={{ order: 2, span: 12 }} lg={{ order: 1, span: 6 }} className="text-center text-lg-left">
                                                        <Button variant="link" className="my-3" onClick={() => this.props.history.push(`${shopLink}`)}>
                                                            {t("myAccount.shop.cart.continueOrdering")}
                                                        </Button>
                                                    </Col>
                                                    <Col xs={{ order: 1, span: 12 }} lg={{ order: 2, span: 6 }} className="text-lg-right text-center">
                                                        <Button type="submit" variant="primary" size="lg" className="my-3" disabled={disableSubmitBtn}>
                                                            {t("myAccount.shop.cart.goToPayment")}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        ) : (
                                            <Fragment>
                                                <Row>
                                                    <Col xs={12} className="text-center">
                                                        <p className=" opacity-4">{t("myAccount.shop.emptyCart")}</p>

                                                        <Button variant="link" onClick={() => this.props.history.push(`${shopLink}`)}>
                                                            {t("myAccount.shop.goBack")}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Fragment>
                                        )}
                                    </Form>
                                )}
                            </Col>
                        </Row>
                    </Fragment>
                )}
                {view === "payment" && this.state.orderTitle && (
                    <Fragment>
                        <Row className="no-gutters">
                            <Col className="text-center">
                                <p className="h3 my-4 text-uppercase">{t("myAccount.shop.payment")}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} xl={{ span: 8, offset: 2 }} className="text-center">
                                <Alert variant="success">
                                    <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                                    {t("success.submitOrder")}
                                </Alert>
                            </Col>

                            <Col xs={12} xl={{ span: 8, offset: 2 }}>
                                <MoneyTransferData
                                    transferTitle={`${this.state.orderTitle} - ${t("myAccount.shop.gadgets")}`}
                                    t={t}
                                    transferAmount={this.state.orderSum.toFixed(2)}
                                    onShowAlert={onShowAlert}
                                />
                            </Col>
                            <Col xs={12} className="text-center">
                                <Button variant="link" onClick={() => this.props.history.push(`${shopLink}`)}>
                                    {t("myAccount.shop.goBack")}
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                )}
                {showConfirmOrderModal && (
                    <ConfirmOrderModal
                        show={showConfirmOrderModal}
                        t={t}
                        onClose={() => this.setState({ showConfirmOrderModal: false })}
                        onSubmitOrder={() => this.setState({ showConfirmOrderModal: false, disableSubmitBtn: true }, () => this.submitOrder())}
                    />
                )}
            </Fragment>
        );
    }
}

export default withRouter(ShopCart);

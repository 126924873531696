import React, { Component, Fragment } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import MyAccountMenu from "./MyAccountMenu";
import NoPermission from "../../other/NoPermission";
import LoginForm from "../../forms/LoginForm";
import $ from "jquery";
import Accreditation from "./accreditation/Accreditation";
//import Accomodation from "./accomodation/Accomodation";
import Dashboard from "./dashboard/Dashboard";
import DataAccount from "./DataAccount";
//import Groups from "./groups/Groups";
import Characters from "./characters/Characters";
import ChangePassword from "./ChangePassword";
import Help from "./Help";
import Shop from "./shop/Shop";
import Volunteering from "./volunteering/Volunteering";
import { Route } from "react-router-dom";
import Footer from "../../other/Footer";
import ShopOrders from "./shop/ShopOrders";
import News from "./adminPanel/news/News";
//import Editors from "./adminPanel/editors/Editors";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import BarClient from "./barClient/BarClient";
import Transactions from "./transactions/Transactions";

function adjustHeight() {
    if ($(window).outerWidth() > 992) {
        $("#myAccount").css("min-height", `${$(window).outerHeight() - $("#footer").outerHeight()}px`);
        $("#myAccount").css("padding-top", `${$("#mainNavbar").outerHeight()}px`);
        $("#myAccount > div").css("min-height", `${$(window).outerHeight()}px`);
    } else {
        $("#myAccount").removeAttr("style");
        $("#myAccount > div").removeAttr("style");
    }
}

class MyAccount extends Component {
    state = {
        userLogged: false,
        noPermission: false,
        eventsList: null,
        avatar: null,
        loading: true,
        activeKey: null,
    };

    checkIfLogged = async () => {
        const { fetchURL, t, onShowAlert } = this.props;
        try {
            const response = await fetch(`${fetchURL}/check-if-logged`, {
                credentials: "include",
                method: "POST",
                headers: { "Content-Type": "application/json" },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "notLogged") this.handleDisplayStuff();
                    else if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else {
                        this.getAllEventsList();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    //get all events
    getAllEventsList = async () => {
        const { fetchURL, t, onShowAlert } = this.props;
        try {
            const response = await fetch(`${fetchURL}/events-list`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else this.setState({ eventsList: fetchedData.eventsList }, () => this.readAvatar());
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    readAvatar = async () => {
        const { fetchURL, onShowAlert, t } = this.props;

        try {
            const response = await fetch(`${fetchURL}/read-avatar`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                //body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status && fetchedData.fileData === "noFile") {
                        this.setState({ avatar: fetchedData.fileData, userLogged: true }, () => this.handleDisplayStuff());
                    } else {
                        const blob = fetchedData.fileData.blob_data;
                        var buffer = Buffer.from(blob);
                        var bufferBase64 = buffer.toString("base64");
                        this.setState({ avatar: { data: bufferBase64, type: fetchedData.fileData.blob_file_type, name: fetchedData.fileData.blob_name }, userLogged: true }, () => {
                            this.handleDisplayStuff();
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleDisplayStuff = () => {
        this.setState({ loading: false }, () => {
            $("#myAccount").addClass("fade-in");
            this.props.onHideSpinner();
        });
    };

    componentDidMount() {
        this.checkIfLogged();
        adjustHeight();
        window.addEventListener("scroll", this.resize);

        const { location, t } = this.props;
        //console.log(location);
        let activeKey = location.pathname;
        if (location.pathname === `/${t("url.link.myAccount")}`) activeKey = t("url.link.myAccount");
        else {
            activeKey = activeKey.replace(`/${t("url.link.myAccount")}/`, "");
            activeKey = activeKey.replace(/\/(.*)/, "");
        }
        //console.log(activeKey);
        this.setState({ activeKey: activeKey });
    }

    handleChangeActiveKey = (keyName) => {
        //console.log("changeActiveKey", keyName);
        this.setState({ activeKey: keyName });
    };

    resize = () => {
        adjustHeight();
    };

    handleRedirect = (e, url) => {
        e.preventDefault();
        this.props.onShowSpoonLoader();
        setTimeout(() => this.props.history.push(url), 700);
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    handleNotLogged = () => {
        this.setState({ loading: true }, () => {
            setTimeout(() => {
                this.setState({ loading: false, userLogged: false });
            }, 400);
        });
        this.setState({ userLogged: false });
    };

    render() {
        const { noPermission, userLogged, eventsList, avatar, loading, activeKey } = this.state;
        const { onLogout, t, onShowAlert, fetchURL, onPassLoggedUserData, moduleName, location, activeEvent, onShowSpoonLoader, frontURL, onRefreshNotification } = this.props;

        const myAccountLink = `/${t("url.link.myAccount")}/`;

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : (
                    <div id="myAccount" style={{ opacity: 0 }}>
                        {loading ? (
                            <Row>
                                <Col className="pt-5">
                                    <LoadingProgressBar t={t} />
                                </Col>
                            </Row>
                        ) : userLogged ? (
                            <Row className="no-gutters">
                                <Col xs={12} lg={3}>
                                    <MyAccountMenu
                                        t={t}
                                        onLogout={onLogout}
                                        onShowAlert={onShowAlert}
                                        fetchURL={fetchURL}
                                        onCheckIfLogged={() => this.checkIfLogged()}
                                        link={myAccountLink}
                                        location={location}
                                        reqDataNotification={this.props.reqDataNotification}
                                        onRefreshNotification={this.props.onRefreshNotification}
                                        onShowSpoonLoader={this.props.onShowSpoonLoader}
                                        activeKey={activeKey}
                                        onChangeActiveKey={this.handleChangeActiveKey}
                                    />
                                </Col>
                                <Col xs={12} lg={9}>
                                    <div id="myAccountContainer" className="px-3">
                                        <Route
                                            exact
                                            path={myAccountLink}
                                            render={() => (
                                                <Dashboard
                                                    t={t}
                                                    fetchURL={fetchURL}
                                                    onShowAlert={onShowAlert}
                                                    avatar={avatar && avatar !== "noData" ? avatar : null}
                                                    activeEvent={activeEvent}
                                                    onNotLogged={this.handleNotLogged}
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path={`${myAccountLink}${t("url.link.accountData")}`}
                                            render={() => (
                                                <DataAccount
                                                    t={t}
                                                    fetchURL={fetchURL}
                                                    onShowAlert={onShowAlert}
                                                    avatar={avatar && avatar !== "noData" ? avatar : null}
                                                    onRefreshAvatar={() => this.readAvatar()}
                                                    onRefreshNotification={onRefreshNotification}
                                                    onNotLogged={this.handleNotLogged}
                                                />
                                            )}
                                        />
                                        <Route
                                            path={`${myAccountLink}${t("url.link.accreditation")}`}
                                            render={() => (
                                                <Accreditation
                                                    t={t}
                                                    fetchURL={fetchURL}
                                                    onShowAlert={onShowAlert}
                                                    frontURL={this.props.frontURL}
                                                    location={location}
                                                    accrLink={`${myAccountLink}${t("url.link.accreditation")}`}
                                                    eventsList={eventsList}
                                                    activeEvent={activeEvent}
                                                    onRefreshNotification={onRefreshNotification}
                                                    onFetchAgainEventsList={() => {
                                                        this.getAllEventsList();
                                                        onRefreshNotification();
                                                    }}
                                                    myAccountLink={myAccountLink}
                                                    onShowSpoonLoader={onShowSpoonLoader}
                                                    onNotLogged={this.handleNotLogged}
                                                />
                                            )}
                                        />
                                        {/* <Route
                                            path={`${myAccountLink}${t("url.link.group")}`}
                                            render={() => (
                                                <Groups
                                                    t={t}
                                                    fetchURL={fetchURL}
                                                    onShowAlert={onShowAlert}
                                                    frontURL={this.props.frontURL}
                                                    location={location}
                                                    groupLink={`${myAccountLink}${t("url.link.group")}`}
                                                    accrLink={`${myAccountLink}${t("url.link.accreditation")}`}
                                                    eventsList={eventsList}
                                                    activeEvent={activeEvent}
                                                    onRefreshNotification={onRefreshNotification}
                                                    onFetchAgainEventsList={() => {
                                                        this.getAllEventsList();
                                                        onRefreshNotification();
                                                    }}
                                                />
                                            )}
                                        /> */}
                                        <Route
                                            path={`${myAccountLink}${t("url.link.characters")}`}
                                            render={() => (
                                                <Characters
                                                    t={t}
                                                    fetchURL={fetchURL}
                                                    onShowAlert={onShowAlert}
                                                    frontURL={this.props.frontURL}
                                                    location={location}
                                                    charLink={`${myAccountLink}${t("url.link.characters")}`}
                                                    accrLink={`${myAccountLink}${t("url.link.accreditation")}`}
                                                    eventsList={eventsList}
                                                    activeEvent={activeEvent}
                                                    onRefreshNotification={onRefreshNotification}
                                                    onFetchAgainEventsList={() => {
                                                        this.getAllEventsList();
                                                        onRefreshNotification();
                                                    }}
                                                    onNotLogged={this.handleNotLogged}
                                                />
                                            )}
                                        />
                                        {/* Czasowo wyłączone z użycia */}
                                        {/* <Route
                                                path={`${myAccountLink}${t("url.link.accomodation")}`}
                                                render={() => (
                                                    <Accomodation
                                                        t={t}
                                                        fetchURL={fetchURL}
                                                        onShowAlert={onShowAlert}
                                                        frontURL={this.props.frontURL}
                                                        location={location}
                                                        accomodationLink={`${myAccountLink}${t("url.link.accomodation")}`}
                                                        accrLink={`${myAccountLink}${t("url.link.accreditation")}`}
                                                        eventsList={eventsList}
                                                        activeEvent={activeEvent}
                                                        onRefreshNotification={onRefreshNotification}
                                                        onFetchAgainEventsList={() => {
                                                            this.getAllEventsList();
                                                            onRefreshNotification();
                                                        }}
                                                    />
                                                )}
                                            /> */}
                                        <Route
                                            path={`${myAccountLink}${t("url.link.volunteering")}`}
                                            render={() => (
                                                <Volunteering
                                                    location={location}
                                                    t={t}
                                                    fetchURL={fetchURL}
                                                    onShowAlert={onShowAlert}
                                                    activeEvent={activeEvent}
                                                    eventsList={eventsList}
                                                    volLink={`${myAccountLink}${t("url.link.volunteering")}`}
                                                    accrLink={`${myAccountLink}${t("url.link.accreditation")}`}
                                                    onRefreshNotification={onRefreshNotification}
                                                    onChangeActiveKey={this.handleChangeActiveKey}
                                                    onNotLogged={this.handleNotLogged}
                                                />
                                            )}
                                        />
                                        <Route
                                            path={`${myAccountLink}${t("url.link.barClient")}`}
                                            render={() => (
                                                <BarClient
                                                    location={location}
                                                    t={t}
                                                    fetchURL={fetchURL}
                                                    onShowAlert={onShowAlert}
                                                    activeEvent={activeEvent}
                                                    eventsList={eventsList}
                                                    barLink={`${myAccountLink}${t("url.link.barClient")}`}
                                                    accrLink={`${myAccountLink}${t("url.link.accreditation")}`}
                                                    onRefreshNotification={onRefreshNotification}
                                                    onChangeActiveKey={this.handleChangeActiveKey}
                                                    onNotLogged={this.handleNotLogged}
                                                />
                                            )}
                                        />
                                        <Route
                                            path={`${myAccountLink}${t("url.link.transactions")}`}
                                            render={() => (
                                                <Transactions
                                                    location={location}
                                                    t={t}
                                                    fetchURL={fetchURL}
                                                    onShowAlert={onShowAlert}
                                                    activeEvent={activeEvent}
                                                    eventsList={eventsList}
                                                    transactionsLink={`${myAccountLink}${t("url.link.transactions")}`}
                                                    accrLink={`${myAccountLink}${t("url.link.accreditation")}`}
                                                    onRefreshNotification={onRefreshNotification}
                                                    onChangeActiveKey={this.handleChangeActiveKey}
                                                    onNotLogged={this.handleNotLogged}
                                                />
                                            )}
                                        />
                                        <Route
                                            path={`${myAccountLink}${t("url.link.shop")}`}
                                            render={() => (
                                                <Shop
                                                    t={t}
                                                    fetchURL={fetchURL}
                                                    onShowAlert={onShowAlert}
                                                    frontURL={this.props.frontURL}
                                                    shopLink={`${myAccountLink}${t("url.link.shop")}`}
                                                    activeEvent={activeEvent}
                                                    location={location}
                                                    onNotLogged={this.handleNotLogged}
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path={`/${t("url.link.myAccount")}/${t("url.link.shopOrders")}`}
                                            render={() => (
                                                <ShopOrders t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} activeEvent={activeEvent} onNotLogged={this.handleNotLogged} />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path={`${myAccountLink}${t("url.link.changePass")}`}
                                            render={() => <ChangePassword t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} onNotLogged={this.handleNotLogged} />}
                                        />
                                        <Route
                                            exact
                                            path={`${myAccountLink}${t("url.link.help")}`}
                                            render={() => <Help t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} onNotLogged={this.handleNotLogged} />}
                                        />
                                        <Route
                                            path={`${myAccountLink}${t("url.link.adminPanel")}-${t("url.link.news")}`}
                                            render={() => (
                                                <News
                                                    t={t}
                                                    fetchURL={fetchURL}
                                                    onShowAlert={onShowAlert}
                                                    location={location}
                                                    newsLink={`${myAccountLink}${t("url.link.adminPanel")}-${t("url.link.news")}`}
                                                    frontURL={frontURL}
                                                    onNotLogged={this.handleNotLogged}
                                                />
                                            )}
                                        />
                                        {/* <Route
                                            exact
                                            path={`${myAccountLink}${t("url.link.adminPanel")}-${t("url.link.editors")}`}
                                            render={() => (
                                                <Editors
                                                    t={t}
                                                    fetchURL={fetchURL}
                                                    onShowAlert={onShowAlert}
                                                    location={location}
                                                    editorsLink={`${myAccountLink}${t("url.link.adminPanel")}-${t("url.link.editors")}`}
                                                    onNotLogged={this.handleNotLogged}
                                                />
                                            )}
                                        /> */}
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <Container className="" id="loginContainer">
                                <Row className="no-gutters mb-3 mb-lg-0">
                                    <Col className="border shadow my-lg-5">
                                        <Row className="no-gutters">
                                            <Col lg={6} xs={12} className="p-4">
                                                <LoginForm
                                                    fetchURL={fetchURL}
                                                    onShowAlert={onShowAlert}
                                                    t={t}
                                                    onPassLoggedUserData={onPassLoggedUserData}
                                                    moduleName={moduleName}
                                                    reloadCheckLogin
                                                    onCheckIfLogged={() => {
                                                        this.setState({ loading: true }, () => {
                                                            adjustHeight();
                                                            this.checkIfLogged();
                                                        });
                                                    }}
                                                    onGetEventData={this.props.onGetEventData}
                                                    onShowSpoonLoader={onShowSpoonLoader}
                                                    frontURL={frontURL}
                                                />
                                            </Col>
                                            <Col lg={6} xs={12} className="p-3 bg-darker">
                                                <div className="text-center">
                                                    <p className="h6 mt-3">{t("login.register.subtitle1")}</p>
                                                    <h2 className="h3 my-5">{t("login.register.title")}</h2>
                                                </div>
                                                <div>
                                                    <p className="mb-2">{t("login.register.prosTitle")}</p>
                                                    <ul>
                                                        <li>{t("login.register.prosPoint1")}</li>
                                                        <li>{t("login.register.prosPoint2")}</li>
                                                        <li>{t("login.register.prosPoint3")}</li>
                                                    </ul>
                                                </div>
                                                <div className="text-center">
                                                    <Button
                                                        variant="outline-primary"
                                                        size="lg"
                                                        className="my-3"
                                                        onClick={(e) => this.handleRedirect(e, `/${t("url.link.register")}`)}
                                                    >
                                                        {t("url.text.register")}
                                                    </Button>

                                                    <p className="h6 mt-3 mb-2">{t("login.register.subtitle2")}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        )}
                    </div>
                )}
                <Row className="no-gutters">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withRouter(MyAccount);

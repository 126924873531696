import React, { Component, Fragment } from "react";
import { Row, Col, Alert, Form, Button } from "react-bootstrap";
import Email from "./../forms/Email";
import TextareaForm from "./../forms/TextareaForm";
import TextForm from "./../forms/text-inputs/TextForm";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";

class ContactForm extends Component {
    state = {
        email: this.props.email ? this.props.email : "",
        mailName: this.props.mailName ? this.props.mailName : "",
        title: this.props.title ? this.props.title : "",
        message: this.props.message ? this.props.message : "",
        messageInfoAlert: "",
        token: null,
    };

    validateEmail = (email) => {
        const { t } = this.props;
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            $("#userEmail").addClass("danger");
            return t("error.invalidEmail");
        } else return "";
    };

    validateName = (name) => {
        const regExp = /^([A-Z]|\u0141)([a-z]|\u0142|\u0144|\u00f3|\u0107|\u0105|\u017A|\u017C|\u0119|\u015B)+$/;
        if (regExp.test(name)) return true;
        else return false;
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { email, title, message, mailName } = this.state;
        const { t, onShowAlert } = this.props,
            regExpWhiteSpace = /^\s*$/;

        onShowAlert("");
        let errorMsg = [];
        if (!email) {
            $("#mailEmail").addClass("is-invalid");
            errorMsg.push(t("error.emptyEmail"));
        } else {
            const validate = this.validateEmail(email);
            if (validate) {
                $("#mailEmail").addClass("is-invalid");
                errorMsg.push(validate);
            } else if (email.length > 255) {
                $("#mailEmail").addClass("is-invalid");
                errorMsg.push(t("error.emailTooLong"));
            }
        }

        if (!title) {
            $("#mailTitle").addClass("is-invalid");
            errorMsg.push(t("error.emptyTitleMessage"));
        } else if (regExpWhiteSpace.test(title)) {
            $("#mailTitle").addClass("is-invalid");
            errorMsg.push(t("error.titleMessageInvalid"));
        } else if (title.length > 50) {
            $("#mailTitle").addClass("is-invalid");
            errorMsg.push(t("error.titleMessageTooLong"));
        }

        if (!message) {
            $("#mailMessage").addClass("is-invalid");
            errorMsg.push(t("error.emptyMailMessage"));
        } else if (regExpWhiteSpace.test(message)) {
            $("#mailMessage").addClass("is-invalid");
            errorMsg.push(t("error.mailMessageInvalid"));
        } else if (message.length > 4000) {
            $("#mailMessage").addClass("is-invalid");
            errorMsg.push(t("error.mailMessageTooLong"));
        }

        if (!mailName) {
            $("#mailName").addClass("is-invalid");
            errorMsg.push(t("error.emptyMailName"));
        } else if (regExpWhiteSpace.test(mailName)) {
            $("#mailName").addClass("is-invalid");
            errorMsg.push(t("error.userNameInvalid"));
        } else if (mailName.length > 50) {
            $("#mailName").addClass("is-invalid");
            errorMsg.push(t("error.userNameTooLong"));
        }

        if (!this.state.token) errorMsg.push(t("error.recaptchaEmpty"));

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            this.sendMailByContactForm();
        }
    };

    sendMailByContactForm = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("activateAccount.sendingMail"), fading: false });

        const passData = {
            email: this.state.email,
            name: this.state.mailName,
            title: this.state.title,
            message: this.state.message,
            token: this.state.token,
        };
        try {
            const response = await fetch(`${fetchURL}/send-email-by-contact-form`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.errCannotSendMail`), fading: false });
                    else {
                        onShowAlert({ variant: "success", message: t(`success.mailSentSuccessfully`), fading: 2000 });
                        this.setState({
                            email: this.props.email ? this.props.email : "",
                            mailName: this.props.mailName ? this.props.mailName : "",
                            title: this.props.title ? this.props.title : "",
                            message: this.props.message ? this.props.message : "",
                            messageInfoAlert: "",
                            token: null,
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    render() {
        const { email, title, message, mailName } = this.state;
        const { t } = this.props;
        return (
            <Fragment>
                <Form onSubmit={this.handleCheckValidation} autoComplete="off" noValidate>
                    <Row className="no-gutters">
                        <Col xs={12}>
                            <TextForm
                                value={mailName}
                                onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })}
                                labelTxt={t("contactForm.name.label")}
                                placeholderTxt={t("contactForm.name.placeholder")}
                                name="mailName"
                                id="mailName"
                                req
                            />
                        </Col>
                        <Col xs={12}>
                            <Email value={email} onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })} id="mailEmail" name="email" t={t} />
                        </Col>
                        <Col xs={12}>
                            <TextForm
                                value={title}
                                onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })}
                                labelTxt={t("contactForm.title.label")}
                                placeholderTxt={t("contactForm.title.placeholder")}
                                name="title"
                                id="mailTitle"
                                req
                            />
                        </Col>
                        <Col xs={12}>
                            <TextareaForm
                                value={message}
                                onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })}
                                labelTxt={t("contactForm.message.label")}
                                name="message"
                                placeholderTxt={t("contactForm.message.placeholder")}
                                id="mailMessage"
                                req
                            />
                        </Col>
                        {message.length > 0 && (
                            <Col xs={12}>
                                {message.length <= 256 ? (
                                    <Alert variant="info" className="alert-small mb-3">
                                        {t("contactForm.message.remained")}: {256 - message.length}
                                    </Alert>
                                ) : (
                                    <Alert variant="danger" className="alert-small mb-3">
                                        {t("contactForm.message.characterLimit")} ({message.length - 256})
                                    </Alert>
                                )}
                            </Col>
                        )}
                        <Col xs={12} className="text-center my-3" id="recaptcha">
                            <ReCAPTCHA sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY} onChange={(value) => this.setState({ token: value })} theme="dark" />
                        </Col>
                        <Col xs={12} className="text-center">
                            <Button variant="primary" type="submit">
                                {t("contactForm.submit")}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Fragment>
        );
    }
}

export default ContactForm;

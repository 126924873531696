import { faExclamationCircle, faSpinner, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, Component } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import ParticipationRadios from "../../../forms/check-radio-boxes/ParticipationRadios";
import AccreditationTransfer from "./AccreditationTransfer";
import $ from "jquery";
import { Checkbox } from "semantic-ui-react";
import RegulationsModal from "../../../modals/RegulationsModal";
import { withRouter } from "react-router-dom";
import TextForm from "../../../forms/text-inputs/TextForm";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";

class EditParticipation extends Component {
    state = {
        showView: null,
        partID: null,
        oldAccrData: null,
        accrData: null,
        disabledForm: false,
        changedValues: false,
        showRegulationsModal: false,
        addedReq: false,
    };

    updateParticipant = async () => {
        const { fetchURL, t, onShowAlert, onRefreshNotification } = this.props;
        const passData = {
            accrData: this.state.accrData[0],
        };
        //console.log(this.state.accrData);
        try {
            const response = await fetch(`${fetchURL}/update-accr-data`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                        this.setState({ disabledForm: false });
                    } else if (fetchedData.status !== true) {
                        onShowAlert({ variant: "warning", message: t(`warning.${fetchedData.status}`), fading: false });
                    } else {
                        onShowAlert({ variant: "success", message: t(`success.dataUpdatedSuccessfully`), fading: 2000 });
                        onRefreshNotification();
                        this.props.history.push(`/${t("url.link.myAccount")}/${t("url.link.accreditation")}`);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    getAccrData = async () => {
        const { fetchURL, t, onShowAlert } = this.props;

        try {
            const response = await fetch(`${fetchURL}/get-accreditation-data-for-edit`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status !== true) this.setState({ showView: { variant: fetchedData.status, message: fetchedData.code } });
                    else {
                        this.setState({
                            accrData: fetchedData.accrData,
                            oldAccrData: fetchedData.oldAccrData,
                            showView: "form",
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { accrData, oldAccrData } = this.state,
            { onShowAlert, t } = this.props,
            regExpWhiteSpace = /^\s*$/;
        onShowAlert("");
        let errorMsg = [];

        if (!accrData[0].part_stat_code) {
            $("#participation").addClass("is-invalid");
            errorMsg.push(t("error.emptyPartRadio"));
        } else if (accrData[0].part_stat_code === "ABSENT") {
            if (!accrData[0].part_ident_nickname) {
                $("#partIdentNickname").addClass("is-invalid");
                errorMsg.push(t("error.emptyIdentNickname"));
            } else if (regExpWhiteSpace.test(accrData[0].part_ident_nickname)) {
                $("#partIdentNickname").addClass("is-invalid");
                errorMsg.push(t("error.invalidNicknameIdent"));
            } else if (accrData[0].part_ident_nickname.length > 50) {
                $("#partIdentNickname").addClass("is-invalid");
                errorMsg.push(t("error.nicknameIdentToLong"));
            }

            if (!accrData[0].part_declar_tac_agreement) {
                $("#regulations").addClass("is-invalid");
                errorMsg.push(t("error.regulationsNotChecked"));
            }
            if (!accrData[0].part_declar_legal_age) {
                $("#legalAge").addClass("is-invalid");
                errorMsg.push(t("error.legalAgeNotChecked"));
            }
            if (!accrData[0].part_declar_money_transfer) {
                $("#moneyTransferDeclaration").addClass("is-invalid");
                errorMsg.push(t("error.moneyTransferDeclarationNotChecked"));
            }
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            //console.log(accrData[0], oldAccrData);
            if (accrData[0].part_stat_code === "ABORTED" || (accrData[0].part_stat_code === "ABSENT" && oldAccrData.part_declar_money_transfer && oldAccrData.part_declar_mt_date))
                this.updateParticipant();
            else this.setState({ showView: "transfer" });
        }
    };

    handleChangeFormValues = (value, name) => {
        const { accrData } = this.state;
        const list = [...accrData];
        list[0][name] = value;
        this.setState({ changedValues: true, accrData: list });
    };

    handleSendData = (mt, date) => {
        this.setState({ disabledForm: true }, () => {
            const { accrData } = this.state;
            const list = [...accrData];
            list[0].part_declar_money_transfer = mt;
            list[0].part_declar_mt_date = list[0].part_declar_money_transfer ? date : null;
            this.setState({ accrData: list }, () => this.updateParticipant());
        });
    };

    componentDidMount() {
        if (this.props.activeEvent && this.props.activeEvent !== "noActiveEvent") this.getAccrData();
        else this.setState({ showView: "noActiveEvent" });
    }

    componentDidUpdate() {
        const { accrData } = this.state;

        if (accrData && accrData[0].part_stat_code === "ABSENT" && !this.state.addedReq) {
            $("#regulations + label").append('<span class="required ml-1">*</span>');
            $("#legalAge + label").append('<span class="required ml-1">*</span>');
            $("#moneyTransferDeclaration + label").append('<span class="required ml-1">*</span>');

            this.setState({ addedReq: true });
        }
    }

    render() {
        const { showRegulationsModal, disabledForm, changedValues, accrData, showView, oldAccrData } = this.state;
        const { activeEvent, onShowAlert, fetchURL, t } = this.props;
        return (
            <Fragment>
                {!showView ? (
                    <LoadingProgressBar t={t} />
                ) : (
                    <div className="my-account-show">
                        <Row className="no-gutters">
                            <Col className="mt-5 mb-3">
                                <Button variant="link" onClick={() => this.props.history.goBack()}>
                                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                    {t("myAccount.goBack")}
                                </Button>
                            </Col>
                        </Row>
                        {showView === "transfer" ? (
                            <AccreditationTransfer
                                t={t}
                                activeEvent={activeEvent}
                                partID={accrData[0].part_id}
                                onShowAlert={onShowAlert}
                                fetchURL={fetchURL}
                                onSendData={this.handleSendData}
                                disabledForm={disabledForm}
                            />
                        ) : showView === "form" ? (
                            <Fragment>
                                <Row className="no-gutters">
                                    <Col xl={{ span: 8, offset: 2 }} xs={12} className="fill-form mt-3 mb-5 px-4 py-3">
                                        <Form onSubmit={this.handleCheckValidation}>
                                            <Row className="no-gutters">
                                                <Col xs={12}>
                                                    <p className="h4 text-uppercase text-center my-3">{t("myAccount.accreditation.form.title2")}</p>
                                                </Col>
                                                <Col xs={12} className="mt-4">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {t("myAccount.accreditation.form.participation.label")} <span className="required">*</span>
                                                        </Form.Label>
                                                        <ParticipationRadios
                                                            disabled={disabledForm}
                                                            onChangeValue={(value) => {
                                                                this.handleChangeFormValues(value, "part_stat_code");
                                                                $("#participation").removeClass("is-invalid");
                                                            }}
                                                            t={t}
                                                            radioValue={accrData[0].part_stat_code}
                                                            id="participation"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                {accrData[0].part_stat_code === "ABSENT" ? (
                                                    <Fragment>
                                                        {!oldAccrData.part_ident_nickname && (
                                                            <Col xs={12} className="mt-3">
                                                                <TextForm
                                                                    value={accrData[0].part_ident_nickname}
                                                                    onChangeValue={(e) => {
                                                                        this.handleChangeFormValues(e.target.value, e.target.name);
                                                                    }}
                                                                    req
                                                                    labelTxt={t("myAccount.accreditation.form.nickname.label")}
                                                                    placeholderTxt={t("myAccount.accreditation.editNicknameIdent.placeholder")}
                                                                    name="part_ident_nickname"
                                                                    id="partIdentNickname"
                                                                    subtext={t("myAccount.accreditation.form.nickname.subtext")}
                                                                    disabled={disabledForm}
                                                                />
                                                            </Col>
                                                        )}

                                                        {!oldAccrData.part_declar_tac_agreement && (
                                                            <Col xs={12} className="mt-3">
                                                                <Checkbox
                                                                    label={t("myAccount.accreditation.form.regulations")}
                                                                    onChange={(event, data) => {
                                                                        $(`#regulations`).removeClass("is-invalid");
                                                                        this.handleChangeFormValues(data.checked, "part_declar_tac_agreement");
                                                                    }}
                                                                    id="regulations"
                                                                    checked={accrData[0].part_declar_tac_agreement}
                                                                    disabled={disabledForm}
                                                                />
                                                                <Button
                                                                    variant="link"
                                                                    className="ml-2 align-baseline"
                                                                    onClick={() => this.setState({ showRegulationsModal: true })}
                                                                >
                                                                    {t("myAccount.accreditation.form.regulationsBtn")}
                                                                </Button>
                                                            </Col>
                                                        )}
                                                        {!oldAccrData.part_declar_legal_age && (
                                                            <Col xs={12} className="mt-1">
                                                                <Checkbox
                                                                    label={t("myAccount.accreditation.form.legalAge")}
                                                                    onChange={(event, data) => {
                                                                        $(`#legalAge`).removeClass("is-invalid");
                                                                        this.handleChangeFormValues(data.checked, "part_declar_legal_age");
                                                                    }}
                                                                    id="legalAge"
                                                                    checked={accrData[0].part_declar_legal_age}
                                                                    disabled={disabledForm}
                                                                />
                                                            </Col>
                                                        )}
                                                        {!oldAccrData.part_declar_money_transfer && (
                                                            <>
                                                                <Col xs={12} className="mt-1">
                                                                    <Checkbox
                                                                        label={t("myAccount.accreditation.form.moneyTransferDeclaration")}
                                                                        onChange={(event, data) => {
                                                                            $(`#moneyTransferDeclaration`).removeClass("is-invalid");
                                                                            this.handleChangeFormValues(data.checked, "part_declar_money_transfer");
                                                                        }}
                                                                        id="moneyTransferDeclaration"
                                                                        checked={accrData[0].part_declar_money_transfer}
                                                                        disabled={disabledForm}
                                                                    />
                                                                </Col>

                                                                <Col xs={12} className="mt-3 text-center">
                                                                    <Alert variant="primary">
                                                                        <b>{t("myAccount.accreditation.form.submitAlert")}</b>
                                                                    </Alert>
                                                                </Col>
                                                            </>
                                                        )}
                                                        <Col xs={12} className="mt-3 text-center">
                                                            <Button className="forms-btn" variant="primary" type="submit" disabled={!changedValues || disabledForm} size="lg">
                                                                {disabledForm ? <FontAwesomeIcon icon={faSpinner} className="btn-spinner" /> : t("myAccount.formSubmitBtn")}
                                                            </Button>
                                                        </Col>
                                                    </Fragment>
                                                ) : (
                                                    <Col xs={12} className="mt-3 text-center">
                                                        <Button className="forms-btn" variant="primary" type="submit" disabled={!changedValues || disabledForm} size="lg">
                                                            {disabledForm ? <FontAwesomeIcon icon={faSpinner} className="btn-spinner" /> : t("myAccount.formSubmitBtn")}
                                                        </Button>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                                {showRegulationsModal && (
                                    <RegulationsModal
                                        show={showRegulationsModal}
                                        t={t}
                                        onClose={() => this.setState({ showRegulationsModal: false })}
                                        onShowAlert={onShowAlert}
                                        fetchURL={fetchURL}
                                    />
                                )}
                            </Fragment>
                        ) : (
                            <Row className="no-gutters">
                                <Col lg={{ span: 8, offset: 2 }} xs={12}>
                                    <Alert className="my-5 text-center" variant={showView.variant}>
                                        <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /> {t(`info.${showView.message}`)}
                                    </Alert>
                                </Col>
                            </Row>
                        )}
                    </div>
                )}
            </Fragment>
        );
    }
}

export default withRouter(EditParticipation);

import React, { Component, Fragment } from "react";
import { Row, Col, Button, Alert, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faExclamationCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import TextForm from "../../../forms/text-inputs/TextForm";
import TextareaForm from "../../../forms/TextareaForm";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import CharacterContinuationRadios from "../../../forms/check-radio-boxes/CharacterContinuationRadios";
import PlayableCharacterRadios from "../../../forms/check-radio-boxes/PlayableCharacterRadios";
import CharacterCraftingRadios from "../../../forms/check-radio-boxes/CharacterCraftingRadios";

class AddNewCharacter extends Component {
    state = {
        showView: null,
        disabledForm: false,
        characterData: [
            {
                characterName: "",
                characterFraction: "",
                characterDescription: "",
                characterExpectiption: "",
                continuation: null,
                characterSkills: "",
                characterAssets: "",
                playableCharacter: null,
                crafting: null,
            },
        ],
        partHasActiveChars: false,
        showPlayableCharOption: false,
    };

    handleChangeTextFormValues = (e) => {
        const { characterData } = this.state;
        const name = e.target.name;
        const list = [...characterData];
        list[0][name] = e.target.value;
        this.setState({ characterData: list, changedValues: true });
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { characterData, showPlayableCharOption } = this.state;
        const { onShowAlert, t } = this.props;

        onShowAlert("");
        let errorMsg = [];

        if (!characterData[0].characterName) {
            $(`#characterName`).addClass("is-invalid");
            errorMsg.push(t("error.emptyCharacterName"));
        } else if (characterData[0].characterName.length > 50) {
            $(`#characterName`).addClass("is-invalid");
            errorMsg.push(t("error.tooLongCharacterName"));
        }

        if (!characterData[0].characterDescription) {
            $(`#characterDescription`).addClass("is-invalid");
            errorMsg.push(t("error.emptyCharacterDescription"));
        } else if (characterData[0].characterDescription.length > 4000) {
            $(`#characterDescription`).addClass("is-invalid");
            errorMsg.push(t("error.tooLongCharacterDescription"));
        }

        if (characterData[0].characterExpectiption.length > 4000) {
            $(`#characterExpectiption`).addClass("is-invalid");
            errorMsg.push(t("error.tooLongCharacterExpectiption"));
        }
        if (characterData[0].characterSkills.length > 4000) {
            $(`#characterSkills`).addClass("is-invalid");
            errorMsg.push(t("error.tooLongCharacterSkills"));
        }
        if (characterData[0].characterAssets.length > 4000) {
            $(`#characterAssets`).addClass("is-invalid");
            errorMsg.push(t("error.tooLongCharacterAssets"));
        }
        if (!characterData[0].continuation) {
            $(`#characterContinuation`).addClass("is-invalid");
            errorMsg.push(t("error.characterContinuationNotSelected"));
        }
        if (!characterData[0].crafting) {
            $(`#characterCrafting`).addClass("is-invalid");
            errorMsg.push(t("error.characterCraftingNotSelected"));
        }
        if (showPlayableCharOption && !characterData[0].playableCharacter) {
            $(`#characterPlayable`).addClass("is-invalid");
            errorMsg.push(t("error.playableCharacterNotSelected"));
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            // console.log(characterData);
            this.insertNewCharacter();
        }
    };

    insertNewCharacter = async () => {
        const { fetchURL, t, onShowAlert, activeEvent, charLink, modalView, onAddedNewChar } = this.props;
        const passData = { activeEvent: activeEvent, characterData: this.state.characterData[0] };
        onShowAlert({ variant: "info", message: t("info.addingNewChar"), fading: null });
        try {
            const response = await fetch(`${fetchURL}/insert-new-character`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        onShowAlert({ variant: "success", message: t(`success.characterAdded`), fading: 2000 });
                        if (modalView) onAddedNewChar();
                        else this.props.history.push(charLink);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    //pobierz i sprawdź czy uczestnik ma już inne postacie przypisane do tego eventu
    getPartCharacters = async () => {
        const { fetchURL, t, onShowAlert, activeEvent } = this.props;
        const passData = { activeEvent: activeEvent };
        try {
            const response = await fetch(`${fetchURL}/part-characters`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else
                        this.setState({
                            showView: "form",
                            partHasActiveChars: fetchedData.partHasActiveChars,
                            showPlayableCharOption: fetchedData.ifCanUserSelectThisChar && activeEvent !== "noActiveEvent" && activeEvent.allow_users_characters && fetchedData.userHasPart,
                        });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        this.getPartCharacters();
    }

    render() {
        const { showView, characterData, disabledForm, changedValues, partHasActiveChars, showPlayableCharOption } = this.state;
        const { t, modalView } = this.props;

        return (
            <Fragment>
                {!showView ? (
                    <LoadingProgressBar t={t} />
                ) : (
                    <div className="my-account-show">
                        {!modalView && (
                            <Row className="no-gutters">
                                <Col>
                                    <Button variant="link" onClick={() => this.props.history.goBack()}>
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                        {t("myAccount.goBack")}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        {showView === "form" ? (
                            <Fragment>
                                <Row className="no-gutters">
                                    <Col lg={{ span: 8, offset: 2 }} xs={12} className="text-center my-3">
                                        <Alert variant="primary" className="mb-3">
                                            <FontAwesomeIcon icon={faExclamationCircle} /> {t("myAccount.characters.form.alert1")}
                                        </Alert>
                                    </Col>
                                </Row>
                                <Row className="no-gutters">
                                    <Col xl={{ span: 8, offset: 2 }} xs={12} className="fill-form mt-3 mb-5 px-4 py-3">
                                        <Form onSubmit={this.handleCheckValidation}>
                                            <Row className="no-gutters">
                                                <Col xs={12}>
                                                    <p className="h4 text-uppercase text-center my-3">{t("myAccount.characters.form.title1")}</p>
                                                </Col>
                                                <Col xs={12} className="mt-4">
                                                    <TextForm
                                                        value={characterData[0].characterName}
                                                        onChangeValue={(e) => {
                                                            this.handleChangeTextFormValues(e);
                                                            $("#charName").removeClass("is-invalid");
                                                        }}
                                                        req
                                                        labelTxt={t("myAccount.characters.form.characterName.label")}
                                                        placeholderTxt={t("myAccount.characters.form.characterName.placeholder")}
                                                        name="characterName"
                                                        id="characterName"
                                                        disabled={disabledForm}
                                                    />
                                                </Col>
                                                <Col xs={12} className="mt-3">
                                                    <TextForm
                                                        value={characterData[0].characterFraction}
                                                        onChangeValue={(e) => {
                                                            this.handleChangeTextFormValues(e);
                                                            $("#charFraction").removeClass("is-invalid");
                                                        }}
                                                        labelTxt={t("myAccount.characters.form.characterFraction.label")}
                                                        placeholderTxt={t("myAccount.characters.form.characterFraction.placeholder")}
                                                        name="characterFraction"
                                                        id="characterFraction"
                                                        subtext={t("myAccount.characters.form.characterFraction.subtext")}
                                                        disabled={disabledForm}
                                                    />
                                                </Col>

                                                <Col xs={12} className="mt-3">
                                                    <TextareaForm
                                                        value={characterData[0].characterDescription}
                                                        onChangeValue={this.handleChangeTextFormValues}
                                                        labelTxt={t("myAccount.characters.form.characterDescr.label")}
                                                        placeholderTxt={t("myAccount.characters.form.characterDescr.placeholder")}
                                                        name="characterDescription"
                                                        id="characterDescription"
                                                        disabled={disabledForm}
                                                        subtext={t("myAccount.characters.form.characterDescr.subtext")}
                                                        req
                                                    />
                                                </Col>
                                                <Col xs={12} className="mt-3">
                                                    <TextareaForm
                                                        value={characterData[0].characterExpectiption}
                                                        onChangeValue={this.handleChangeTextFormValues}
                                                        labelTxt={t("myAccount.characters.form.characterExpect.label")}
                                                        placeholderTxt={t("myAccount.characters.form.characterExpect.placeholder")}
                                                        name="characterExpectiption"
                                                        id="characterExpectiption"
                                                        disabled={disabledForm}
                                                        subtext={t("myAccount.characters.form.characterExpect.subtext")}
                                                    />
                                                </Col>

                                                <Col xs={12} className="mt-4">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {t("myAccount.characters.form.characterCrafting")} <span className="required">*</span>
                                                        </Form.Label>

                                                        <CharacterCraftingRadios
                                                            disabled={disabledForm}
                                                            onChangeValue={(value, name) => {
                                                                const { characterData } = this.state;
                                                                const list = [...characterData];
                                                                list[0][name] = value;
                                                                this.setState({ characterData: list, changedValues: true });
                                                            }}
                                                            t={t}
                                                            radioValue={characterData[0].crafting}
                                                            id="characterCrafting"
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={12} className="mt-4">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {t("myAccount.characters.form.characterContinuation")} <span className="required">*</span>
                                                        </Form.Label>

                                                        <CharacterContinuationRadios
                                                            disabled={disabledForm}
                                                            onChangeValue={(value, name) => {
                                                                const { characterData } = this.state;
                                                                const list = [...characterData];
                                                                list[0][name] = value;
                                                                this.setState({ characterData: list, changedValues: true }, () => {
                                                                    $("#characterContinuation").removeClass("is-invalid");
                                                                    if (value === "N") {
                                                                        const { characterData } = this.state;
                                                                        const list = [...characterData];
                                                                        list[0]["characterAssets"] = "";
                                                                        list[0]["characterSkills"] = "";
                                                                        this.setState({ characterData: list });
                                                                    }
                                                                });
                                                            }}
                                                            t={t}
                                                            radioValue={characterData[0].continuation}
                                                            id="characterContinuation"
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                {characterData[0].continuation === "Y" && (
                                                    <Fragment>
                                                        <Col xs={12}>
                                                            <p className="h4 text-uppercase text-center my-3">{t("myAccount.characters.form.title2")}</p>
                                                        </Col>
                                                        <Col xs={12} className="mt-3">
                                                            <TextareaForm
                                                                value={characterData[0].characterSkills}
                                                                onChangeValue={this.handleChangeTextFormValues}
                                                                labelTxt={t("myAccount.characters.form.characterSkills.label")}
                                                                placeholderTxt={t("myAccount.characters.form.characterSkills.placeholder")}
                                                                name="characterSkills"
                                                                id="characterSkills"
                                                                disabled={disabledForm}
                                                                subtext={t("myAccount.characters.form.characterSkills.subtext")}
                                                            />
                                                        </Col>
                                                        <Col xs={12} className="mt-3">
                                                            <TextareaForm
                                                                value={characterData[0].characterAssets}
                                                                onChangeValue={this.handleChangeTextFormValues}
                                                                labelTxt={t("myAccount.characters.form.characterAssets.label")}
                                                                placeholderTxt={t("myAccount.characters.form.characterAssets.placeholder")}
                                                                name="characterAssets"
                                                                id="characterAssets"
                                                                disabled={disabledForm}
                                                                subtext={t("myAccount.characters.form.characterAssets.subtext")}
                                                            />
                                                        </Col>
                                                    </Fragment>
                                                )}

                                                {showPlayableCharOption && (
                                                    <Fragment>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                {t("myAccount.characters.form.characterPlayable")} <span className="required">*</span>
                                                            </Form.Label>
                                                            <PlayableCharacterRadios
                                                                disabled={disabledForm}
                                                                onChangeValue={(value, name) => {
                                                                    const { characterData } = this.state;
                                                                    const list = [...characterData];
                                                                    list[0][name] = value;
                                                                    this.setState({ characterData: list, changedValues: true }, () => {
                                                                        $("#characterPlayable").removeClass("is-invalid");
                                                                    });
                                                                }}
                                                                t={t}
                                                                radioValue={characterData[0].playableCharacter}
                                                                id="characterPlayable"
                                                            />
                                                        </Form.Group>

                                                        {partHasActiveChars && characterData[0].playableCharacter === "Y" && (
                                                            <Alert variant="warning" className="alert-small">
                                                                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                                                                {t("warning.partHasActiveChars")}
                                                            </Alert>
                                                        )}
                                                    </Fragment>
                                                )}

                                                <Col xs={12} className="mt-3 text-center">
                                                    <Button className="forms-btn" variant="primary" type="submit" disabled={!changedValues || disabledForm} size="lg">
                                                        {disabledForm ? <FontAwesomeIcon icon={faSpinner} className="btn-spinner" /> : t("myAccount.formSubmitBtn")}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Fragment>
                        ) : (
                            <Row className="no-gutters">
                                <Col lg={{ span: 8, offset: 2 }} xs={12}>
                                    <Alert className="my-5 text-center" variant={showView.variant}>
                                        <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /> {t(`info.${showView.message}`)}
                                    </Alert>
                                </Col>
                            </Row>
                        )}
                    </div>
                )}
            </Fragment>
        );
    }
}

export default withRouter(AddNewCharacter);

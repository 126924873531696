import React, { Fragment } from "react";
import { Col, Form } from "react-bootstrap";
import TextForm from "../../../forms/text-inputs/TextForm";
import TextareaForm from "../../../forms/TextareaForm";
import $ from "jquery";
//import CharacterContinuationRadios from "../../../forms/check-radio-boxes/CharacterContinuationRadios";
import CharacterCraftingRadios from "../../../forms/check-radio-boxes/CharacterCraftingRadios";

const EditCharacterForm = (props) => {
    const { characterData, onChangeTextFormValues, t, onChangeCraftingRadio, upgradeCharacter } = props;
    return (
        <Fragment>
            <Col xs={12} className="mt-3">
                <TextForm
                    value={characterData[0].char_fraction}
                    onChangeValue={(e) => {
                        onChangeTextFormValues(e);
                        $("#charFraction").removeClass("is-invalid");
                    }}
                    labelTxt={t("myAccount.characters.form.characterFraction.label")}
                    placeholderTxt={t("myAccount.characters.form.characterFraction.placeholder")}
                    name="char_fraction"
                    id="characterFraction"
                />
            </Col>
            <Col xs={12} className="mt-3">
                <TextareaForm
                    value={characterData[0].char_description}
                    onChangeValue={onChangeTextFormValues}
                    labelTxt={t("myAccount.characters.form.characterDescr.label")}
                    placeholderTxt={t("myAccount.characters.form.characterDescr.placeholder")}
                    name="char_description"
                    id="characterDescription"
                    req
                    minHeight="14rem"
                />
            </Col>
            {/* {!upgradeCharacter && (
                <Col xs={12} className="mt-3">
                    <Form.Group>
                        <Form.Label>
                            {t("myAccount.characters.form.characterContinuation")} <span className="required">*</span>
                        </Form.Label>
                        <CharacterContinuationRadios
                            onChangeValue={(value, name) => onChangeCharContinuation(value)}
                            t={t}
                            radioValue={characterData[0].char_is_continued}
                            id="characterContinuation"
                        />
                    </Form.Group>
                </Col>
            )} */}
            {((!upgradeCharacter && characterData[0].char_is_continued === "Y") || upgradeCharacter) && (
                <Fragment>
                    <Col xs={12} className="mt-3">
                        <TextareaForm
                            value={characterData[0].char_skills}
                            onChangeValue={onChangeTextFormValues}
                            labelTxt={t("myAccount.characters.form.characterSkills.label")}
                            placeholderTxt={t("myAccount.characters.form.characterSkills.placeholder")}
                            name="char_skills"
                            id="characterSkills"
                        />
                    </Col>
                    <Col xs={12} className="mt-3">
                        <TextareaForm
                            value={characterData[0].char_assets}
                            onChangeValue={onChangeTextFormValues}
                            labelTxt={t("myAccount.characters.form.characterAssets.label")}
                            placeholderTxt={t("myAccount.characters.form.characterAssets.placeholder")}
                            name="char_assets"
                            id="characterAssets"
                        />
                    </Col>
                </Fragment>
            )}

            <Col xs={12} className="mt-4">
                <Form.Group>
                    <Form.Label>
                        {t("myAccount.characters.form.characterCrafting")} <span className="required">*</span>
                    </Form.Label>

                    <CharacterCraftingRadios onChangeValue={(value, name) => onChangeCraftingRadio(value)} t={t} radioValue={characterData[0].char_is_crafter} id="characterCrafting" />
                </Form.Group>
            </Col>

            <Col xs={12} className="mt-3">
                <TextareaForm
                    value={characterData[0].char_expectation}
                    onChangeValue={onChangeTextFormValues}
                    labelTxt={t("myAccount.characters.form.characterExpectForNearestLarp")}
                    placeholderTxt={t("myAccount.characters.form.characterExpect.placeholder")}
                    name="char_expectation"
                    id="characterExpectiption"
                />
            </Col>
        </Fragment>
    );
};

export default EditCharacterForm;

import React, { Fragment } from "react";
import { Table } from "react-bootstrap";

const CharacterDetails = (props) => {
    const { t, details } = props;
    return (
        <Fragment>
            <Table responsive>
                <tbody>
                    <tr>
                        <td width="20%">{t("myAccount.characters.form.characterFraction.label")}</td>
                        <td>{details.char_fraction ? details.char_fraction : <span className="opacity-4">{t("info.none")}</span>}</td>
                    </tr>
                    <tr>
                        <td>{t("myAccount.characters.form.characterDescr.label")}</td>
                        <td>{details.char_description ? details.char_description : <span className="opacity-4">{t("info.none")}</span>}</td>
                    </tr>
                    <tr>
                        <td>{t("myAccount.characters.characterCrafting")}</td>
                        <td>{details.char_is_crafter === "Y" ? t("yes") : t("no")}</td>
                    </tr>
                    <tr>
                        <td>{t("myAccount.characters.characterContinuation")}</td>
                        <td>{details.char_is_continued === "Y" ? t("yes") : t("no")}</td>
                    </tr>
                    <tr>
                        <td>{t("myAccount.characters.form.characterAssets.label")}</td>
                        <td>{details.char_assets ? details.char_assets : <span className="opacity-4">{t("info.none")}</span>}</td>
                    </tr>
                    <tr>
                        <td>{t("myAccount.characters.form.characterSkills.label")}</td>
                        <td>{details.char_skills ? details.char_skills : <span className="opacity-4">{t("info.none")}</span>}</td>
                    </tr>
                    <tr>
                        <td>{t("myAccount.characters.form.characterExpect.label")}</td>
                        <td>{details.char_expectation ? details.char_expectation : <span className="opacity-4">{t("info.none")}</span>}</td>
                    </tr>
                </tbody>
            </Table>
        </Fragment>
    );
};

export default CharacterDetails;

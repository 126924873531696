import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import VolunteeringMain from "./VolunteeringMain";
import NoMatchMyAccount from "../../../other/NoMatchMyAccount";
/* import ConstructionForm from "./ConstructionForm";
import AccrAndBarForm from "./AccrAndBarForm"; */
import { Col, Row } from "react-bootstrap";
import VolunteeringForm from "./VolunteeringForm";

const Volunteering = (props) => {
    const { fetchURL, onShowAlert, t, volLink, location, activeEvent, eventsList, accrLink, onRefreshNotification, onChangeActiveKey, onNotLogged } = props;
    const skillsStart = [
            {
                value: false,
                label: t("myAccount.volunteering.constr.form.skills.build"),
            },
            {
                value: false,
                label: t("myAccount.volunteering.constr.form.skills.electric"),
            },
            {
                value: false,
                label: t("myAccount.volunteering.constr.form.skills.strength"),
            },
            {
                value: false,
                label: t("myAccount.volunteering.constr.form.skills.driver"),
            },
            {
                value: false,
                label: t("myAccount.volunteering.constr.form.skills.nothing"),
            },
            {
                value: false,
                label: t("myAccount.volunteering.constr.form.skills.other"),
            },
        ],
        toolsStart = [
            {
                value: false,
                label: t("myAccount.volunteering.constr.form.tools.hand"),
            },
            {
                value: false,
                label: t("myAccount.volunteering.constr.form.tools.screwdriver"),
            },
            {
                value: false,
                label: t("myAccount.volunteering.constr.form.tools.shovel"),
            },
            {
                value: false,
                label: t("myAccount.volunteering.constr.form.tools.other"),
            },
        ];
    return (
        <Fragment>
            <Row className="no-gutters">
                <Col className="text-center">
                    <p className="h3 my-4 text-uppercase">{t("myAccount.volunteering.title")}</p>
                </Col>
            </Row>
            {location.pathname !== `${volLink}` &&
                location.pathname !== `${volLink}/${t("url.link.constrForm")}` &&
                location.pathname !== `${volLink}/${t("url.link.volForm")}` && <Route render={() => <NoMatchMyAccount t={t} />} />}
            <Route
                exact
                path={`${volLink}`}
                render={() => (
                    <VolunteeringMain
                        onChangeActiveKey={onChangeActiveKey}
                        t={t}
                        fetchURL={fetchURL}
                        onShowAlert={onShowAlert}
                        volLink={volLink}
                        eventsList={eventsList}
                        activeEvent={activeEvent}
                        accrLink={accrLink}
                        skillsStart={skillsStart}
                        toolsStart={toolsStart}
                        onRefreshNotification={onRefreshNotification}
                        onNotLogged={onNotLogged}
                    />
                )}
            />
            <Route
                exact
                path={`${volLink}/${t("url.link.volForm")}`}
                render={() => (
                    <VolunteeringForm
                        t={t}
                        fetchURL={fetchURL}
                        onShowAlert={onShowAlert}
                        volLink={volLink}
                        eventsList={eventsList}
                        activeEvent={activeEvent}
                        onRefreshNotification={onRefreshNotification}
                        onChangeActiveKey={onChangeActiveKey}
                        skillsStart={skillsStart}
                        toolsStart={toolsStart}
                    />
                )}
            />
            {/* <Route
                exact
                path={`${volLink}/${t("url.link.constrForm")}`}
                render={() => <ConstructionForm t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} volLink={volLink} eventsList={eventsList} activeEvent={activeEvent} />}
            />
            <Route
                exact
                path={`${volLink}/${t("url.link.barForm")}`}
                render={() => <AccrAndBarForm t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} volLink={volLink} eventsList={eventsList} activeEvent={activeEvent} appType="BAR" />}
            />
            <Route
                exact
                path={`${volLink}/${t("url.link.accrForm")}`}
                render={() => <AccrAndBarForm t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} volLink={volLink} eventsList={eventsList} activeEvent={activeEvent} appType="ACCR" />}
            />
            <Route
                exact
                path={`${volLink}/${t("url.link.npcForm")}`}
                render={() => <NPCForm t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} volLink={volLink} eventsList={eventsList} activeEvent={activeEvent} />}
            /> */}
        </Fragment>
    );
};

export default Volunteering;

import React, { Fragment, Component } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import MoneyTransferData from "../pages/myAccount/accreditation/MoneyTransferData";
import $ from "jquery";
import CalendarDate from "../forms/datetimes/CalendarDate";
import { Checkbox } from "semantic-ui-react";

class EditMoneyTransferDeclaration extends Component {
    state = {
        declaration: this.props.accrData.part_declare_money_transfer === "Y" ? true : false,
        declarationDate: this.props.accrData.part_declare_money_transfer_date ? this.props.accrData.part_declare_money_transfer_date : new Date(),
        changedValues: false,
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { onShowAlert, t } = this.props;

        onShowAlert("");
        let errorMsg = [];

        if (!this.state.declaration) {
            errorMsg.push(t("error.moneyTransferDeclarationNotChecked"));
            $("#declaration").addClass("is-invalid");
        } else if (!this.state.declarationDate) {
            errorMsg.push(t("error.moneyTransferDateEmpty"));
            $("#declarationDate").addClass("is-invalid");
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            this.handleEdit();
        }
    };

    handleEdit = async () => {
        const { fetchURL, onShowAlert, t, onRefreshNotification, accrData } = this.props;
        onShowAlert({ variant: "info", message: t("info.editingData"), fading: false });
        const passData = {
            declaration: this.state.declaration,
            declarationDate: this.state.declarationDate,
            partID: accrData.part_id,
        };
        //console.log(passData);
        try {
            const response = await fetch(`${fetchURL}/update-money-transfer-declaration`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    } else {
                        onShowAlert({ variant: "success", message: t(`success.dataUpdatedSuccessfully`), fading: 2000 });
                        onRefreshNotification();
                        this.props.onCloseAndRefresh();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    render() {
        const { declaration, changedValues, declarationDate } = this.state;
        const { show, onClose, t, onShowAlert, activeEvent } = this.props;
        return (
            <Fragment>
                <Modal show={show} onHide={onClose} backdrop="static" keyboard={false} size="lg">
                    <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                        <Modal.Header closeButton>
                            <Modal.Title>{t("myAccount.accreditation.editMoneyTransferDeclaration.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="no-gutters">
                                <Col xs={12}>
                                    <p className="mb-3 h6" style={{ lineHeight: "1.2" }}>
                                        {t("myAccount.accreditation.editMoneyTransferDeclaration.subtitle")}
                                    </p>
                                </Col>

                                <Col xs={12}>
                                    <MoneyTransferData
                                        t={t}
                                        transferAmount={activeEvent.event_price}
                                        onShowAlert={onShowAlert}
                                        transferTitle={`${activeEvent.event_name} - ${t("myAccount.accreditation.transfer.accreditation")}`}
                                    />

                                    <Checkbox
                                        label={t("myAccount.accreditation.transfer.transferDoneBtn")}
                                        checked={declaration}
                                        onChange={(e, data) => this.setState({ declaration: data.checked, changedValues: true })}
                                        className="mr-3 mb-3"
                                        id="declaration"
                                    />
                                    <div className="d-inline-block">
                                        <CalendarDate
                                            onChangeCalendarDate={(date) => this.setState({ declarationDate: date, changedValues: true })}
                                            value={declarationDate}
                                            id="declarationDate"
                                            t={t}
                                            disabled={declaration ? false : true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={onClose}>
                                {t("info.cancel")}
                            </Button>
                            <Button variant="primary" disabled={!changedValues || !declaration ? true : false} type="submit">
                                {t("info.saveChanges")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default EditMoneyTransferDeclaration;

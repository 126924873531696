import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import $ from "jquery";
const TextareaForm = (props) => {
    const { value, onChangeValue, labelTxt, name, req, placeholderTxt, minHeight, id, subtext, disabled, rows } = props;
    return (
        <Fragment>
            <Form.Group>
                {labelTxt && (
                    <Form.Label>
                        {labelTxt} {req && <span className="required">*</span>}
                    </Form.Label>
                )}
                {subtext && <p className="text-small">{subtext}</p>}
                <Form.Control
                    id={id}
                    as="textarea"
                    rows={rows ? rows : 3}
                    placeholder={placeholderTxt}
                    name={name}
                    value={value}
                    onChange={(e) => {
                        $(`#${id}`).removeClass("is-invalid");
                        onChangeValue(e);
                    }}
                    className={req ? "req" : ""}
                    style={{ minHeight: minHeight ? minHeight : "auto" }}
                    disabled={disabled}
                />
            </Form.Group>
        </Fragment>
    );
};

export default TextareaForm;

import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import TransactionsMain from "./TransactionsMain";
import NoMatchMyAccount from "../../../other/NoMatchMyAccount";

const Transactions = (props) => {
    const { fetchURL, onShowAlert, t, accrLink, transactionsLink, location, activeEvent, eventsList, onRefreshNotification, myAccountLink, onShowSpoonLoader, onNotLogged } = props;

    return (
        <Fragment>
            {location.pathname !== `${transactionsLink}` && <Route render={() => <NoMatchMyAccount t={t} />} />}
            <Route
                exact
                path={`${transactionsLink}`}
                render={() => (
                    <TransactionsMain
                        t={t}
                        fetchURL={fetchURL}
                        onShowAlert={onShowAlert}
                        transactionsLink={transactionsLink}
                        accrLink={accrLink}
                        eventsList={eventsList}
                        activeEvent={activeEvent}
                        onRefreshNotification={onRefreshNotification}
                        myAccountLink={myAccountLink}
                        onShowSpoonLoader={onShowSpoonLoader}
                        onNotLogged={onNotLogged}
                    />
                )}
            />
        </Fragment>
    );
};

export default Transactions;

import React, { Component, Fragment } from "react";
import SmallBanner from "../../other/SmallBanner";
import { Row, Col, Container } from "react-bootstrap";
import Regulations from "./../../other/Regulations";
import Footer from "../../other/Footer";

class RegulationsPage extends Component {
    componentDidMount() {
        this.props.onHideSpinner(200);
    }
    render() {
        const { t, frontURL, fetchURL, onShowAlert, onShowSpoonLoader } = this.props;
        return (
            <Fragment>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <SmallBanner
                            frontURL={frontURL}
                            bgBottom={`${frontURL}/images/small-banners/b1-bottom.webp`}
                            bgDesktop={`${frontURL}/images/small-banners/b1.webp`}
                            title={t("regulations.title")}
                            subtitle={t("regulations.subtitle")}
                        />
                    </Col>
                </Row>
                <Container className="my-5" id="gallery">
                    <Row>
                        <Col>
                            <Regulations t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} />
                        </Col>
                    </Row>
                </Container>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default RegulationsPage;

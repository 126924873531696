import React, { Fragment } from "react";
import { Form, Radio } from "semantic-ui-react";

const CharacterCraftingRadios = (props) => {
    const { onChangeValue, id, radioValue, t, disabled } = props;
    return (
        <Fragment>
            <p className="text-small">{t("myAccount.characters.form.characterCraftingSubtext")}</p>
            <div id={id} className="ui form">
                <Form.Field>
                    <Radio label={t("yes")} name="characterCraftingRadio" value="Y" checked={radioValue === "Y"} onChange={(e, { value }) => onChangeValue(value, "crafting")} disabled={disabled} />
                </Form.Field>
                <Form.Field>
                    <Radio label={t("no")} name="characterCraftingRadio" value="N" checked={radioValue === "N"} onChange={(e, { value }) => onChangeValue(value, "crafting")} disabled={disabled} />
                </Form.Field>
            </div>
        </Fragment>
    );
};

export default CharacterCraftingRadios;

import React, { Component, Fragment } from "react";
import SmallBanner from "../../other/SmallBanner";
import { Row, Col, Container /* , Button */ } from "react-bootstrap";
import Footer from "../../other/Footer";
/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"; */
import { withRouter } from "react-router-dom";

class AdvancedMechanics extends Component {
    componentDidMount() {
        this.props.onHideSpinner(200);
    }

    handleRedirect = (e, url) => {
        e.preventDefault();
        this.props.onShowSpoonLoader();
        setTimeout(() => this.props.history.push(url), 700);
    };

    render() {
        const { frontURL, t, onShowSpoonLoader } = this.props;
        return (
            <Fragment>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <SmallBanner
                            frontURL={frontURL}
                            bgBottom={`${frontURL}/images/small-banners/b1-bottom.webp`}
                            bgDesktop={`${frontURL}/images/small-banners/b3.webp`}
                            title={t("advancedMechanics.title")}
                            subtitle={t("advancedMechanics.subtitle")}
                        />
                    </Col>
                </Row>
                <Container id="advancedMechanics" className="py-5">
                    <Row>
                        <Col className="my-5 white border py-3 bg-white-opacity">
                            <h1 className="h1 text-center mt-0" style={{ marginBottom: "2rem" }}>
                                {t("mechanics.inProgressTitle")}
                            </h1>
                            <Row>
                                <Col xs={12} lg={{ span: 8, offset: 2 }}>
                                    <p className="h4 mb-0 text-center">{t("mechanics.inProgressTxt")}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col className="mb-4">
                            <Button onClick={(e) => this.handleRedirect(e, `${t("url.link.basicMechanics")}`)} variant="link" className="d-block mb-2">
                                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                {t("advancedMechanics.goBack")}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-justify mb-4">
                            <div dangerouslySetInnerHTML={{ __html: t("advancedMechanics.text") }}></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-2">
                            <Button onClick={(e) => this.handleRedirect(e, `${t("url.link.basicMechanics")}`)} variant="link" className="d-block mb-2">
                                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                {t("advancedMechanics.goBack")}
                            </Button>
                        </Col>
                    </Row> */}
                </Container>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withRouter(AdvancedMechanics);

import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import $ from "jquery";
import DescPopover from "../../other/DescPopover";

const TextForm = (props) => {
    const { value, onChangeValue, req, labelTxt, placeholderTxt, name, helper, id, readOnly, subtext, disabled } = props;
    return (
        <Fragment>
            <Form.Group>
                {labelTxt && (
                    <Form.Label>
                        {labelTxt} {req && <span className="required">*</span>}
                        {helper && <DescPopover popoverTxt={<p>{helper}</p>} />}
                    </Form.Label>
                )}
                {subtext && <p className="text-small">{subtext}</p>}
                <Form.Control
                    type="text"
                    placeholder={placeholderTxt}
                    name={name}
                    id={id}
                    value={value}
                    onChange={(e) => {
                        $(`#${id}`).removeClass("is-invalid");
                        onChangeValue(e);
                    }}
                    className={req ? "req" : ""}
                    readOnly={readOnly}
                    disabled={disabled}
                />
            </Form.Group>
        </Fragment>
    );
};

export default TextForm;

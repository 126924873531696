import React, { Fragment } from "react";
import { Form, Radio } from "semantic-ui-react";

const NumberOfDutiesRadios = (props) => {
    const { onCheckNumberOfDuties, id, radioValue } = props;
    return (
        <Fragment>
            <div id={id} className="ui form">
                <Form.Field>
                    <Radio label={1} name="numberOfDutiesRadio" value="1" checked={radioValue === "1"} onChange={(e, { value }) => onCheckNumberOfDuties(value)} />
                </Form.Field>
                <Form.Field>
                    <Radio label={2} name="numberOfDutiesRadio" value="2" checked={radioValue === "2"} onChange={(e, { value }) => onCheckNumberOfDuties(value)} />
                </Form.Field>
                <Form.Field>
                    <Radio label={3} name="numberOfDutiesRadio" value="3" checked={radioValue === "3"} onChange={(e, { value }) => onCheckNumberOfDuties(value)} />
                </Form.Field>
                <Form.Field>
                    <Radio label={4} name="numberOfDutiesRadio" value="4" checked={radioValue === "4"} onChange={(e, { value }) => onCheckNumberOfDuties(value)} />
                </Form.Field>
                <Form.Field>
                    <Radio label="5+" name="numberOfDutiesRadio" value="5+" checked={radioValue === "5+"} onChange={(e, { value }) => onCheckNumberOfDuties(value)} />
                </Form.Field>
            </div>
        </Fragment>
    );
};

export default NumberOfDutiesRadios;

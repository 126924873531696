import React, { Component, Fragment } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import TextForm from "./../forms/text-inputs/TextForm";
import Email from "./../forms/Email";
import { Checkbox } from "semantic-ui-react";
import Password from "./../forms/Password";
import Footer from "../other/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

class Register extends Component {
    state = {
        email: "",
        password: "",
        repeatPassword: "",
        userName: "",
        userNickname: "",
        userSurname: "",
        rodo: false,
        token: null,
        loadingBtn: false,
    };
    validateEmail = (email) => {
        const { t } = this.props;
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            $("#userEmail").addClass("danger");
            return t("error.invalidEmail");
        } else return "";
    };

    validateName = (name) => {
        const regExp = /^([A-Z]|\u0141)([a-z]|\u0142|\u0144|\u00f3|\u0107|\u0105|\u017A|\u017C|\u0119|\u015B)+$/;
        if (regExp.test(name)) return true;
        else return false;
    };

    validateSurname = (name) => {
        const regExp =
            /^([A-Z]|\u0141|\u0106|\u017B|\u0179|\u00D3|\u015A)([a-z]|\u0142|\u0144|\u00f3|\u0107|\u0105|\u017A|\u017C|\u0119|\u015B)+(-)?([A-Z]|\u0141|\u0106|\u017B|\u0179|\u00D3|\u015A)?([a-z]|\u0142|\u0144|\u00f3|\u0107|\u0105|\u017A|\u017C|\u0119|\u015B)*$/;
        if (regExp.test(name)) return true;
        else return false;
    };

    validatePassword = (pass) => {
        const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~!@#$%^&*()_\-{}[\]:;'"<> ?`/]).{8,}$/;
        //console.log(pass, re.test(pass));
        if (!re.test(pass)) {
            return false;
        } else return true;
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { onShowAlert, t } = this.props;
        onShowAlert("");

        this.setState({ email: this.state.email.replace(/\s/g, ""), userName: this.state.userName.replace(/\s/g, ""), userSurname: this.state.userSurname.replace(/\s/g, "") }, () => {
            const { email, password, repeatPassword, userName, userSurname, rodo, token, userNickname } = this.state;
            let errorMsg = [];
            if (!email) {
                $("#userEmail").addClass("is-invalid");
                errorMsg.push(t("error.emptyEmail"));
            } else {
                const validate = this.validateEmail(email);
                if (validate) {
                    $("#userEmail").addClass("is-invalid");
                    errorMsg.push(validate);
                }
            }

            if (!userName) {
                errorMsg.push(t("error.emptyUserName"));
                $("#userName").addClass("is-invalid");
            } else {
                if (!this.validateName(userName)) {
                    errorMsg.push(t("error.invalidName"));
                    $("#userName").addClass("is-invalid");
                } else if (userName.length > 50) {
                    errorMsg.push(t("error.userNameTooLong"));
                    $("#userName").addClass("is-invalid");
                }
            }

            if (userNickname && userNickname.length > 50) {
                errorMsg.push(t("error.userNicknameTooLong"));
                $("#userName").addClass("is-invalid");
            }

            if (!userSurname) {
                errorMsg.push(t("error.emptyUserSurname"));
                $("#userSurname").addClass("is-invalid");
            } else {
                if (!this.validateSurname(userSurname)) {
                    errorMsg.push(t("error.invalidSurname"));
                    $("#userSurname").addClass("is-invalid");
                } else if (userSurname.length > 50) {
                    errorMsg.push(t("error.userSurnameTooLong"));
                    $("#userSurname").addClass("is-invalid");
                }
            }

            if (!password) {
                errorMsg.push(t("error.emptyPassword"));
                $("#password").addClass("is-invalid");
            } else if (!this.validatePassword(password)) {
                errorMsg.push(t("error.errIncorrectPass"));
                $("#password").addClass("is-invalid");
            }

            if (!repeatPassword) {
                errorMsg.push(t("error.emptyRepeatPassword"));
                $("#repeatPassword").addClass("is-invalid");
            } else if (!this.validatePassword(repeatPassword)) {
                errorMsg.push(t("error.errIncorrectRepeatPass"));
                $("#repeatPassword").addClass("is-invalid");
            }

            if (password !== repeatPassword) {
                errorMsg.push(t("error.diffPassword"));
                $("#password").addClass("is-invalid");
                $("#repeatPassword").addClass("is-invalid");
            }

            if (!rodo) {
                errorMsg.push(t("error.rodoNotChecked"));
                $(`#dataCheckbox`).addClass("is-invalid");
            }

            if (!token) errorMsg.push(t("error.recaptchaEmpty"));

            if (errorMsg.length > 0) {
                onShowAlert({
                    variant: "danger",
                    message: (
                        <Fragment>
                            <p className="mb-1">{t("error.errorsInForm")}</p>
                            <ul className="mb-1">
                                {errorMsg.map((e, i) => (
                                    <Fragment key={i}>
                                        <li>{e}</li>
                                    </Fragment>
                                ))}
                            </ul>
                        </Fragment>
                    ),
                });
            } else {
                this.setState({ loadingBtn: true }, () => this.handleRegister());
            }
        });
    };

    handleRegister = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.registering"), fading: false });
        const passData = {
            email: this.state.email,
            password: this.state.password,
            repeatPassword: this.state.repeatPassword,
            userName: this.state.userName,
            userNickname: this.state.userNickname,
            userSurname: this.state.userSurname,
            activateAccountURL: t("url.link.activateAccount"),
            rodo: this.state.rodo,
            token: this.state.token,
        };
        try {
            const response = await fetch(`${fetchURL}/register`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                        this.setState({ loadingBtn: false });
                    } else {
                        onShowAlert({ variant: "success", message: t(`success.registerSuccess`), fading: 3000 });
                        this.setState({
                            email: "",
                            password: "",
                            userPassAlert: "",
                            repeatPassword: "",
                            userRepeatPassAlert: "",
                            userName: "",
                            userNickname: "",
                            userSurname: "",
                            rodo: false,
                            token: null,
                            loadingBtn: false,
                        });
                    }
                });
            }
        } catch (err) {
            this.onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    checkIfLogged = async () => {
        const { fetchURL, t, onShowAlert } = this.props;
        try {
            const response = await fetch(`${fetchURL}/check-if-logged`, {
                credentials: "include",
                method: "POST",
                headers: { "Content-Type": "application/json" },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        onShowAlert({ variant: "info", message: t("info.alreadyLogged"), fading: 4500 });
                        this.props.history.push(`/${t("url.link.myAccount")}`);
                    }
                });
            }
        } catch (err) {
            this.handleShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleRedirect = (e, url) => {
        e.preventDefault();
        this.props.onShowSpoonLoader();
        setTimeout(() => this.props.history.push(url), 700);
    };

    componentDidMount() {
        this.checkIfLogged();
        this.props.onHideSpinner(200);
        $(document).ready(function () {
            $("#registerContainer").css("min-height", `${$(window).outerHeight() - $("#footer").outerHeight()}px`);
            $("#dataCheckbox + label").append('<span class="required ml-1">*</span>');
        });
    }

    render() {
        const { email, password, repeatPassword, userName, userSurname, rodo, loadingBtn, userNickname } = this.state;
        const { t, frontURL, onShowSpoonLoader } = this.props;

        return (
            <Fragment>
                <Container className="pt-lg-5" id="registerContainer">
                    <Row className=" mb-3 mb-lg-0">
                        <Col className="border shadow my-lg-5">
                            <Row className="">
                                <Col lg={6} xs={12} className="p-4">
                                    <h1 className="h3 my-3 text-center">{t("register.title")}</h1>
                                    <Row className="">
                                        <Col>
                                            <Form onSubmit={this.handleCheckValidation} autoComplete="off" className="my-3" noValidate>
                                                <Row className="">
                                                    <Col lg={6} xs={12}>
                                                        <TextForm
                                                            value={userName}
                                                            onChangeValue={(e) => {
                                                                this.setState({ [e.target.name]: e.target.value });
                                                            }}
                                                            labelTxt={t("register.userNameInput.label")}
                                                            placeholderTxt={t("register.userNameInput.placeholder")}
                                                            name="userName"
                                                            id="userName"
                                                            req
                                                        />
                                                    </Col>
                                                    <Col lg={6} xs={12}>
                                                        <TextForm
                                                            value={userNickname}
                                                            onChangeValue={(e) => {
                                                                this.setState({ [e.target.name]: e.target.value });
                                                            }}
                                                            labelTxt={t("register.userNicknameInput.label")}
                                                            placeholderTxt={t("register.userNicknameInput.placeholder")}
                                                            name="userNickname"
                                                            id="userNickname"
                                                        />
                                                    </Col>
                                                    <Col xs={12}>
                                                        <TextForm
                                                            value={userSurname}
                                                            onChangeValue={(e) => {
                                                                this.setState({ [e.target.name]: e.target.value });
                                                            }}
                                                            labelTxt={t("register.userSurnameInput.label")}
                                                            placeholderTxt={t("register.userSurnameInput.placeholder")}
                                                            name="userSurname"
                                                            id="userSurname"
                                                            req
                                                        />
                                                    </Col>

                                                    <Col xs={12}>
                                                        <Email value={email} onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })} id="userEmail" name="email" t={t} />
                                                    </Col>
                                                    <Col xs={12}>
                                                        <Password
                                                            value1={password}
                                                            value2={repeatPassword}
                                                            onChangeValue={(e) => {
                                                                this.setState({ [e.target.name]: e.target.value });
                                                            }}
                                                            option="double"
                                                            t={t}
                                                            onValidatePassword={this.validatePassword}
                                                        />
                                                    </Col>
                                                    <Col xs={12}>
                                                        <Checkbox
                                                            label={t("register.rodoLabel")}
                                                            onChange={(event, data) => {
                                                                $(`#dataCheckbox`).removeClass("is-invalid");
                                                                this.setState({ rodo: data.checked });
                                                            }}
                                                            id="dataCheckbox"
                                                            checked={rodo ? true : false}
                                                        />
                                                    </Col>
                                                    <Col xs={12} className="text-center my-3" id="recaptcha">
                                                        <ReCAPTCHA sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY} onChange={(value) => this.setState({ token: value })} theme="dark" />
                                                    </Col>
                                                </Row>
                                                <Form.Group className="text-center">
                                                    <Button type="submit" variant="primary" size="lg" className="my-3" disabled={loadingBtn}>
                                                        {loadingBtn ? <FontAwesomeIcon icon={faSpinner} className="rotate-btn-spinner" /> : t("register.registerBtn")}
                                                    </Button>
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6} xs={12} className="p-3 bg-darker">
                                    <div className="text-center">
                                        <p className="h6 mt-3">{t("register.login.subtitle1")}</p>
                                        <h2 className="h3 my-5">{t("register.login.title")}</h2>
                                    </div>
                                    <div>
                                        <p className="mb-2">{t("register.login.prosTitle")}</p>
                                        <ul>
                                            <li>{t("register.login.prosPoint1")}</li>
                                            <li>{t("register.login.prosPoint2")}</li>
                                            <li>{t("register.login.prosPoint3")}</li>
                                        </ul>
                                    </div>
                                    <div className="text-center">
                                        <Button
                                            variant="outline-primary"
                                            size="lg"
                                            className="my-3"
                                            onClick={() => {
                                                this.props.onShowSpinner();
                                                setTimeout(() => {
                                                    this.props.history.push(`/${t("url.link.login")}`);
                                                }, 200);
                                            }}
                                        >
                                            {t("url.text.login")}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Row className="">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withRouter(Register);

import React, { Component, Fragment } from "react";
import SmallBanner from "../../other/SmallBanner";
import { Row, Col, Container, Image } from "react-bootstrap";
import $ from "jquery";
import Footer from "../../other/Footer";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

var ww;

function importAll(r) {
    return r.keys().map(r);
}

class Gallery extends Component {
    state = {
        images: null,
    };
    componentDidMount() {
        ww = window.innerWidth < window.outerWidth ? window.innerWidth : window.outerWidth;
        this.setState({
            images: ww > 992 ? importAll(require.context(`./gallery/desktop`, false, /\.(png|jpe?g|svg|webp)$/)) : importAll(require.context(`./gallery/mobile`, false, /\.(png|jpe?g|svg|webp)$/)),
        });
        this.props.onHideSpinner(200);
        if ($(".loading-spoon").hasClass("show")) {
            $(".gallery-img:first-child").delay(1500).fadeTo(400, 1);
        } else {
            $(".gallery-img:first-child").delay(500).fadeTo(400, 1);
        }

        window.addEventListener("scroll", this.scroll);
        window.addEventListener("resize", this.resize);
    }

    resize = () => {
        ww = window.innerWidth < window.outerWidth ? window.innerWidth : window.outerWidth;
        this.setState({
            images: ww > 992 ? importAll(require.context(`./gallery/desktop`, false, /\.(png|jpe?g|svg|webp)$/)) : importAll(require.context(`./gallery/mobile`, false, /\.(png|jpe?g|svg|webp)$/)),
        });
    };

    showGallery = () => {
        const distance = window.scrollY;
        const wh = $(window).outerHeight();
        $(".gallery-img").each(function () {
            const posOffset = $(this).offset();
            if (distance + wh - 200 >= posOffset.top) $(this).fadeTo(400, 1);
        });
    };

    scroll = () => {
        if (document.getElementById("gallery")) {
            this.showGallery();
        }
    };

    componentDidUpdate() {
        if (this.state.images) this.showGallery();
    }

    render() {
        const { images } = this.state;
        const { frontURL, t, onShowSpoonLoader } = this.props;

        return (
            <Fragment>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <SmallBanner
                            frontURL={frontURL}
                            bgBottom={`${frontURL}/images/small-banners/b5-bottom.webp`}
                            bgDesktop={`${frontURL}/images/small-banners/b6.webp`}
                            title={t("gallery.title")}
                            subtitle={t("gallery.subtitle")}
                        />
                    </Col>
                </Row>
                <Container className="my-5" id="gallery">
                    <Row>
                        {!images ? (
                            <LoadingProgressBar t={t} />
                        ) : (
                            <Fragment>
                                {images.map((i, key) => (
                                    <Col xs={12} className="text-center" key={key}>
                                        <Image src={i} alt={`lyzkon_image_${key}`} fluid className="my-3 gallery-img" style={{ opacity: 0 }} />
                                    </Col>
                                ))}
                            </Fragment>
                        )}
                    </Row>
                    <Row>
                        <Col className="text-center my-4">
                            <p>{t("gallery.authors")}:</p>
                            <p className="mb-1">
                                <a href="https://www.facebook.com/ZlodziejeDusz" target="_blank" rel="noopener noreferrer">
                                    Złodzieje Dusz
                                </a>
                            </p>
                            <p className="mb-1">
                                <a href="https://www.facebook.com/JuliaMatuszakFotografia" target="_blank" rel="noopener noreferrer">
                                    Julia Matuszak
                                </a>
                            </p>
                            <p className="mb-1">
                                <a href="https://www.facebook.com/KDkingablog" target="_blank" rel="noopener noreferrer">
                                    Kinga Dziwota
                                </a>
                            </p>
                            <p className="mb-1">
                                <a href="https://www.facebook.com/okiem.kruka" target="_blank" rel="noopener noreferrer">
                                    Okiem Kruka
                                </a>
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default Gallery;

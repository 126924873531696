import { faEdit, faEraser, faEye, faLongArrowAltLeft, faLongArrowAltRight, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, Component } from "react";
import { Button, Col, Form, InputGroup, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import i18n from "../../../../../../i18n";
import DeleteNewsModal from "../../../../modals/DeleteNewsModal";
import NoPermission from "../../../../other/NoPermission";
import LoadingProgressBar from "../../../../spinners/LoadingProgressBar";

class NewsList extends Component {
    state = {
        noPermission: false,
        newsList: null,
        originalNewsList: null,
        loading: true,
        showNOR: 5,
        displayNOR: 5,
        searchedPhrase: "",
        selectedNews: null,
        showDeleteNewsModal: false,
    };

    getNewsList = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/admin-news-list`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onNotLogged();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else this.setState({ loading: false, newsList: fetchedData.newsList, originalNewsList: fetchedData.newsList });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };
    deleteNews = async () => {
        const { selectedNews } = this.state;
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = { newsID: selectedNews.newsID, newsName: selectedNews.newsName };

        try {
            const response = await fetch(`${fetchURL}/delete-news`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false)
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        onShowAlert({ variant: "success", message: t("success.newsDeleted"), fading: 2000 });
                        this.setState({ selectedNews: null, showDeleteNewsModal: false, loading: true }, () => {
                            this.getNewsList();
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleSearch = (e) => {
        const searchedPhrase = e.target.value.toLowerCase();
        const { originalNewsList } = this.state;
        this.setState({ searchedPhrase: searchedPhrase });
        if (searchedPhrase !== "") {
            let filteredParts = originalNewsList;
            //console.log(filteredParts)
            filteredParts = filteredParts.filter((el) => el.news_title_pl.toString().toLowerCase().includes(searchedPhrase));
            this.setState({ newsList: filteredParts });
        } else {
            this.setState({ newsList: originalNewsList });
        }
    };

    componentDidMount() {
        this.getNewsList();
    }

    render() {
        const { noPermission, loading, newsList, searchedPhrase, displayNOR, showNOR, showDeleteNewsModal } = this.state;
        const { t, newsLink, frontURL, onShowAlert, fetchURL } = this.props;
        const filteredNewsList = newsList;
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : (
                    <Fragment>
                        <Row>
                            <Col xs={12}>
                                <p className="my-4 h3 text-uppercase text-center">{t("myAccount.adminPanel.news.newsList")}</p>
                            </Col>
                        </Row>
                        <div className="my-account-show">
                            <Row>
                                <Col lg={3} xs={8}>
                                    <Form.Label>{t("form.searching")}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="text" placeholder={t("form.search")} onChange={this.handleSearch} value={searchedPhrase} />
                                        {searchedPhrase && (
                                            <InputGroup.Append>
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() =>
                                                        this.setState({
                                                            searchedPhrase: "",
                                                            newsList: this.state.originalNewsList,
                                                        })
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faEraser} />
                                                </Button>
                                            </InputGroup.Append>
                                        )}
                                    </InputGroup>
                                </Col>
                                <Col lg={9} xs={4} className="text-left text-lg-right">
                                    <Link className="btn btn-outline-primary mt-lg-4 mt-0" to={`${newsLink}/${t("url.link.addNews")}`}>
                                        {t("myAccount.adminPanel.news.addNewsBtn")}
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                {loading ? (
                                    <Col xs={12} className="text-center">
                                        <LoadingProgressBar t={t} />
                                    </Col>
                                ) : newsList.length === 0 ? (
                                    <Col className="text-center my-3">
                                        <p className="opacity-4">{t("myAccount.adminPanel.news.noNews")}</p>
                                    </Col>
                                ) : (
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Table responsive id="newsTable" hover>
                                                    <thead>
                                                        <tr>
                                                            <th>{t("myAccount.adminPanel.news.publicationDate")}</th>
                                                            <th>{t("myAccount.adminPanel.news.newsTitle")}</th>
                                                            <th>{t("myAccount.adminPanel.news.editor")}</th>
                                                            <th>{t("myAccount.adminPanel.news.status")}</th>
                                                            <th>{t("myAccount.adminPanel.news.actions")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {newsList
                                                            .map((e, i) => (
                                                                <tr key={i}>
                                                                    <td>{e.news_date}</td>
                                                                    <td>
                                                                        {e.langs.map((el, j) => (
                                                                            <Fragment key={j}>{el.lang_code === i18n.language && el.news_title}</Fragment>
                                                                        ))}
                                                                    </td>
                                                                    <td>{e.user_fullname}</td>
                                                                    <td>{t(`myAccount.adminPanel.news.stat.${e.news_stat_code}`)}</td>
                                                                    <td className="text-center" width="180px">
                                                                        {e.news_stat_code !== "DELETED" && (
                                                                            <Fragment>
                                                                                {e.news_stat_code === "PUBLISHED" && (
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={
                                                                                            <Tooltip id={`newsDetails${i}`}>{t("myAccount.adminPanel.news.newsDetails")}</Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <span className="d-inline-block">
                                                                                            <a
                                                                                                href={`${frontURL}/${t("url.link.news")}/${e.news_link}`}
                                                                                                className="btn btn-outline-primary mx-1"
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                <FontAwesomeIcon icon={faEye} />
                                                                                            </a>
                                                                                        </span>
                                                                                    </OverlayTrigger>
                                                                                )}
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip id={`newsEdit${i}`}>{t("myAccount.adminPanel.news.newsEdit")}</Tooltip>}
                                                                                >
                                                                                    <span className="d-inline-block">
                                                                                        <Link to={`${newsLink}/${e.news_link}`} className="btn btn-outline-primary mx-1">
                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                        </Link>
                                                                                    </span>
                                                                                </OverlayTrigger>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip id={`newsDelete${i}`}>{t("myAccount.adminPanel.news.newsDelete")}</Tooltip>}
                                                                                >
                                                                                    <span className="d-inline-block">
                                                                                        <Button
                                                                                            variant="outline-primary"
                                                                                            className="mx-1"
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    showDeleteNewsModal: true,
                                                                                                    selectedNews: {
                                                                                                        newsID: e.news_id,
                                                                                                        newsName: e.langs.length > 0 ? e.langs[0].news_title : "",
                                                                                                    },
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                                        </Button>
                                                                                    </span>
                                                                                </OverlayTrigger>
                                                                            </Fragment>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            .slice(0, displayNOR)}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                {t("loaded")} {displayNOR > filteredNewsList.length ? filteredNewsList.length : displayNOR} {t("from")} {filteredNewsList.length}{" "}
                                                {t("records")}
                                            </Col>
                                            <Col className="text-center">
                                                {displayNOR >= filteredNewsList.length ? (
                                                    <p className="opacity-4">
                                                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                        <span className="px-3">{t("thatsAll")}</span>
                                                        <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                    </p>
                                                ) : (
                                                    <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                        {t("loadMore")}
                                                    </Button>
                                                )}
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                )}
                            </Row>
                        </div>
                    </Fragment>
                )}

                {showDeleteNewsModal && (
                    <DeleteNewsModal
                        show={showDeleteNewsModal}
                        t={t}
                        onShowAlert={onShowAlert}
                        fetchURL={fetchURL}
                        onClose={() => this.setState({ showDeleteNewsModal: false, selectedNews: null })}
                        onDeleteNews={this.deleteNews}
                    />
                )}
            </Fragment>
        );
    }
}

export default NewsList;

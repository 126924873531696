import React, { Fragment } from "react";
import { Form } from "react-bootstrap";

const Phone = (props) => {
    const { value, onChangeValue, name, id, req, t } = props;

    return (
        <Fragment>
            <Form.Group>
                <Form.Label>
                    {t("myAccount.dataAccount.editPhone.label")}
                    {req && <span className="required"> *</span>}
                </Form.Label>
                <Form.Control
                    id={id}
                    type="text"
                    placeholder={t("myAccount.dataAccount.editPhone.placeholder")}
                    name={name}
                    value={value}
                    onChange={(e) => onChangeValue(e)}
                    className={req ? "req" : ""}
                />
            </Form.Group>
        </Fragment>
    );
};

export default Phone;

import React, { Fragment, Component } from "react";
import { Container } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import Program from "./components/pages/convention/Program";
import About from "./components/pages/convention/About";
import Accreditation from "./components/pages/convention/Accreditation";
import Gallery from "./components/pages/convention/Gallery";
import RegulationsPage from "./components/pages/convention/RegulationsPage";
import Intro from "./components/pages/larp/Intro";
//import Mechanics from "./components/pages/larp/Mechanics";
import Login from "./components/pages/Login";
import Register from "./components/pages/Register";
import ResetPassword from "./components/pages/ResetPassword";
import SetPassword from "./components/pages/SetPassword";
import MyAccount from "./components/pages/myAccount/MyAccount";
import News from "./components/pages/news/News";
import PreviousEditions from "./components/pages/previousEditions/PreviousEditions";
import AlertBasic from "./components/alerts/AlertBasic";
import NoMatch from "./components/other/NoMatch";
import NoPermission from "./components/other/NoPermission";
import MainNavbar from "./components/navigation/MainNavbar";
import ActivateAccount from "./components/pages/myAccount/ActivateAccount";
import { withTranslation } from "react-i18next";
import LoadingSpinner from "./components/spinners/LoadingSpinner";
import LoadingSpoon from "./components/spinners/LoadingSpoon";
import $ from "jquery";
import MetaTags from "react-meta-tags";
import AlertUsingCookies from "./components/alerts/AlertUsingCookies";
import Contact from "./components/pages/Contact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import SendActivationLink from "./components/pages/SendActivationLink";
import WhereWePlay from "./components/pages/area/WhereWePlay";
import WhereWeSleep from "./components/pages/area/WhereWeSleep";
import AccessAndParking from "./components/pages/area/AccessAndParking";
import FoodAndHygiene from "./components/pages/area/FoodAndHygiene";
import AdvancedMechanics from "./components/pages/larp/AdvancedMechanics";
import AccreditationRequirements from "./components/pages/larp/AccreditationRequirements";

var url = process.env.REACT_APP_API_URL; //"http://localhost:4002";
var moduleName = process.env.REACT_APP_MODULE_NAME; //"WEBPAGE_LOGIN";
var frontURL = process.env.PUBLIC_URL; //"http://localhost:3000";

function milisecondsToDays(t) {
    var cd = 24 * 60 * 60 * 1000,
        ch = 60 * 60 * 1000,
        d = Math.floor(t / cd),
        h = Math.floor((t - d * cd) / ch),
        m = Math.round((t - d * cd - h * ch) / 60000);
    if (m === 60) {
        h++;
        m = 0;
    }
    if (h === 24) {
        d++;
        h = 0;
    }
    return d;
}

class App extends Component {
    state = {
        alert: "",
        loggedUserEmail: null,
        loggedUserPrivilages: [],
        resetPassError: "",
        loggedIn: false,
        cookieMaxAge: null,
        sendOnThisEmail: "",
        showContactModal: false,
        contactFormData: null,
        showCookieAlert: false,
        noPermission: false,
        myAccountNotification: null,
        activeEvent: null,
        reqDataNotification: null,
        showSpinner: true,
        showingSpinner: false,
        infoEvent: null,
        showEventCancelModal: false,
    };

    handlePassLoggedUserData = (userPrivilages, expDate) => {
        const { t } = this.props;
        this.setState({ loggedIn: true, loggedUserPrivilages: userPrivilages, cookieMaxAge: expDate });
        this.handleShowAlert({ variant: "success", message: t(`success.loggedSuccess`), fading: 1500 });
    };

    handleCheckUserPrivilage = (priv) => {
        const { loggedUserPrivilages } = this.state;
        for (let i = 0; i < loggedUserPrivilages.length; i++) if (loggedUserPrivilages[i] === priv) return true;
        return false;
    };

    resetCookieAlertText = async () => {
        const { t } = this.props;
        try {
            await fetch(`${url}/reset-cookie-alert-text`, {
                credentials: "include",
            });
        } catch (err) {
            this.handleShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err}).` });
        }
    };

    handleShowAlert = (alert) => {
        this.setState({ alert: alert });
    };

    fetchMainData = async () => {
        const { t } = this.props;
        try {
            const response = await fetch(`${url}/`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status && fetchedData.loginSuccess) {
                        this.setState(
                            {
                                loggedIn: true,
                                loggedUserPrivilages: fetchedData.userPrivilages,
                            },
                            () => this.getEventData()
                        );
                    } else if (fetchedData.status && !fetchedData.loginSuccess) {
                        this.setState({ loggedIn: false, activeEvent: "noActiveEvent" });
                    } else {
                        this.setState({ noPermission: true, loggedIn: false });
                    }
                    this.getOnlyInfoEvent();
                });
            }
        } catch (err) {
            this.handleShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err}).` });
        }
    };

    getOnlyInfoEvent = async () => {
        const { t } = this.props;
        try {
            const response = await fetch(`${url}/get-only-info-event`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) this.handleShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ infoEvent: fetchedData.event });
                });
            }
        } catch (err) {
            this.handleShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err}).` });
        }
    };

    getEventData = async () => {
        const { t } = this.props;
        try {
            const response = await fetch(`${url}/get-event-data`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noActiveEvent") {
                        this.setState({ activeEvent: fetchedData.status });
                        if (this.state.loggedIn) this.checkRequiredData(this.state.activeEvent.event_code);
                    } else if (fetchedData.status === false) this.handleShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else
                        this.setState({ activeEvent: fetchedData.event }, () => {
                            const { activeEvent } = this.state;
                            let applyDeadline = activeEvent.allow_users_apply_deadline;
                            let orderDeadline = activeEvent.allow_users_order_deadline;
                            const d = new Date();
                            const now = d.getTime();
                            applyDeadline = new Date(applyDeadline);
                            orderDeadline = new Date(orderDeadline);
                            applyDeadline = applyDeadline.getTime();
                            orderDeadline = orderDeadline.getTime();

                            const applyDays = milisecondsToDays(applyDeadline - now);
                            const orderDays = milisecondsToDays(orderDeadline - now);
                            let infoMessage = null;
                            if ((applyDays <= 5 && applyDays >= 0 && !activeEvent.filledAccrForm) || (orderDays <= 5 && orderDays >= 0)) infoMessage = t("info.attention");
                            if (applyDays <= 5 && applyDays >= 0 && !activeEvent.filledAccrForm)
                                infoMessage +=
                                    applyDays === 0
                                        ? ` ${t("info.openedAppliesOnlyToday")}`
                                        : ` ${t("info.openedApplies")} ${applyDays} ${applyDays > 1 ? t("manyDays") : t("oneDay")}. `;
                            if (orderDays <= 5 && orderDays >= 0)
                                infoMessage +=
                                    orderDays === 0
                                        ? ` ${t("info.openedOrdersOnlyToday")}`
                                        : ` ${t("info.openedOrders")} ${orderDays} ${orderDays > 1 ? t("manyDays") : t("oneDay")}. `;

                            if (infoMessage)
                                this.handleShowAlert({
                                    variant: "info",
                                    message: (
                                        <Fragment>
                                            <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                                            {infoMessage}
                                        </Fragment>
                                    ),
                                    fading: false,
                                });
                            if (this.state.activeEvent && this.state.activeEvent !== "noActiveEvent" && this.state.loggedIn)
                                this.checkRequiredData(this.state.activeEvent.event_code);
                        });
                });
            }
        } catch (err) {
            this.handleShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err}).` });
        }
    };

    checkRequiredData = async (eventCode) => {
        const { t } = this.props;
        const passData = { eventCode: eventCode };
        try {
            const response = await fetch(`${url}/check-required-data`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) this.handleShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else this.setState({ reqDataNotification: fetchedData.requiredData, myAccountNotification: fetchedData.requiredData.length });
                });
            }
        } catch (err) {
            this.handleShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleHideSpinner = (delay = 0) => {
        //console.log(delay);
        $("#mainSpinner").delay(delay).fadeOut(400);
        setTimeout(() => {
            //console.log("hide main spinner");
            this.setState({ showSpinner: false });
        }, delay + 200);
    };

    handleShowSpoonLoader = () => {
        if (!this.state.showingSpinner) {
            this.setState({ showingSpinner: true }, () => {
                $(".loading-spoon").addClass("show");
                setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 600); //koniecznie, aby przed zmianą podstrony, scroll to top odbył się odrobinę wcześniej
                setTimeout(() => {
                    $(".loading-spoon").removeClass("show");
                    $(".loading-spoon").addClass("hide");
                }, 1500);
                setTimeout(() => {
                    $(".loading-spoon").removeClass("hide");
                    this.setState({ showingSpinner: false });
                }, 2000);
            });
        }
    };

    componentDidMount() {
        this.fetchMainData();

        if (!localStorage.getItem("acceptCookies") || localStorage.getItem("acceptCookies") === false) {
            this.setState({ showCookieAlert: true });
        }

        /* if (!localStorage.getItem("eventCancelledModal") || localStorage.getItem("eventCancelledModal") === false) {
            this.setState({ showEventCancelModal: true });
        } */
    }

    componentWillUnmount() {
        this.setState({
            alert: "",
            loggedUserEmail: null,
            loggedUserPrivilages: [],
            resetPassError: "",
            loggedIn: false,
            cookieMaxAge: null,
            sendOnThisEmail: "",
            showContactModal: false,
            contactFormData: null,
            showCookieAlert: false,
            noPermission: false,
            myAccountNotification: null,
            activeEvent: null,
            reqDataNotification: null,
            showSpinner: true,
            showingSpinner: false,
        });
    }

    testMail = async () => {
        const { t } = this.props;
        try {
            const response = await fetch(`${url}/test-mail`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                });
            }
        } catch (err) {
            this.handleShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    render() {
        const { alert, noPermission, loggedIn, activeEvent, showCookieAlert, infoEvent } = this.state;
        const { t } = this.props;
        return (
            <Fragment>
                {/* <button onClick={this.testMail} style={{ position: "absolute", top: "20px", left: "20px", zIndex: 20000 }}>
                    test mail
                </button> */}
                <MetaTags>
                    <title>{t("document.title")}</title>
                    <meta name="description" content={t("document.description")} />
                    <meta property="og:title" content={t("document.title")} />
                    <meta property="og:image" content={`${frontURL}/images/icons/favicon-32x32.png`} />
                </MetaTags>
                <Router>
                    {alert && (
                        <AlertBasic
                            className="alert-basic"
                            variant={alert.variant}
                            onCloseAlert={() => this.setState({ alert: "" })}
                            dismissible
                            mssg={alert.message}
                            contactBtn={alert.contactBtn}
                            fading={alert.fading}
                            frontURL={frontURL}
                            onShowSpoonLoader={this.handleShowSpoonLoader}
                            t={t}
                            onShowAlert={this.handleShowAlert}
                        />
                    )}
                    {noPermission ? (
                        <NoPermission t={t} />
                    ) : (
                        <Fragment>
                            <LoadingSpinner />
                            <LoadingSpoon frontURL={frontURL} />
                            {activeEvent && infoEvent && (
                                <Fragment>
                                    <Container fluid className="px-0">
                                        <MainNavbar
                                            t={t}
                                            loggedIn={loggedIn}
                                            frontURL={frontURL}
                                            onShowAlert={this.handleShowAlert}
                                            myAccountNotification={this.state.myAccountNotification}
                                            onShowSpoonLoader={this.handleShowSpoonLoader}
                                        />

                                        <Switch>
                                            <Route
                                                exact
                                                path="/"
                                                render={() => (
                                                    <Home
                                                        fetchURL={url}
                                                        t={t}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                        frontURL={frontURL}
                                                        onShowAlert={this.handleShowAlert}
                                                    />
                                                )}
                                            />

                                            <Route
                                                exact
                                                path={`/${t("url.link.contact")}`}
                                                render={() => (
                                                    <Contact
                                                        fetchURL={url}
                                                        t={t}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                        frontURL={frontURL}
                                                        onShowAlert={this.handleShowAlert}
                                                    />
                                                )}
                                            />

                                            <Route
                                                path={`/${t("url.link.news")}`}
                                                render={(routeProps) => (
                                                    <News
                                                        fetchURL={url}
                                                        t={t}
                                                        frontURL={frontURL}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        onShowAlert={this.handleShowAlert}
                                                        {...routeProps}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                    />
                                                )}
                                            />
                                            <Route
                                                path={`/${t("url.link.previousEditions")}`}
                                                render={(routeProps) => (
                                                    <PreviousEditions
                                                        fetchURL={url}
                                                        t={t}
                                                        frontURL={frontURL}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        onShowAlert={this.handleShowAlert}
                                                        {...routeProps}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                    />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={`/${t("url.link.accreditation")}`}
                                                render={() => (
                                                    <Accreditation
                                                        t={t}
                                                        frontURL={frontURL}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                    />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={`/${t("url.link.whereWePlay")}`}
                                                render={() => (
                                                    <WhereWePlay
                                                        t={t}
                                                        frontURL={frontURL}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                    />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={`/${t("url.link.whereWeSleep")}`}
                                                render={() => (
                                                    <WhereWeSleep
                                                        t={t}
                                                        frontURL={frontURL}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                    />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={`/${t("url.link.foodAndHygiene")}`}
                                                render={() => (
                                                    <FoodAndHygiene
                                                        t={t}
                                                        frontURL={frontURL}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                    />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={`/${t("url.link.accessAndParking")}`}
                                                render={() => (
                                                    <AccessAndParking
                                                        t={t}
                                                        frontURL={frontURL}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                    />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={`/${t("url.link.about")}`}
                                                render={() => (
                                                    <About
                                                        t={t}
                                                        frontURL={frontURL}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                    />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={`/${t("url.link.program")}`}
                                                render={() => (
                                                    <Program
                                                        fetchURL={url}
                                                        t={t}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                        frontURL={frontURL}
                                                        infoEvent={infoEvent}
                                                    />
                                                )}
                                            />

                                            {/* <Route
                                                exact
                                                path={`/${t("url.link.accomodation")}`}
                                                render={() => (
                                                    <Accomodation
                                                        fetchURL={url}
                                                        t={t}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        frontURL={frontURL}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                    />
                                                )}
                                            /> */}
                                            <Route
                                                exact
                                                path={`/${t("url.link.gallery")}`}
                                                render={() => (
                                                    <Gallery
                                                        fetchURL={url}
                                                        t={t}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        frontURL={frontURL}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                    />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={`/${t("url.link.regulations")}`}
                                                render={() => (
                                                    <RegulationsPage
                                                        fetchURL={url}
                                                        t={t}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        frontURL={frontURL}
                                                        onShowAlert={this.handleShowAlert}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                    />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={`/${t("url.link.intro")}`}
                                                render={() => (
                                                    <Intro
                                                        fetchURL={url}
                                                        t={t}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        frontURL={frontURL}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                    />
                                                )}
                                            />
                                            {/* <Route
                                                exact
                                                path={`/${t("url.link.basicMechanics")}`}
                                                render={() => (
                                                    <Mechanics
                                                        fetchURL={url}
                                                        t={t}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        frontURL={frontURL}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                    />
                                                )}
                                            /> */}
                                            <Route
                                                exact
                                                path={`/${t("url.link.advancedMechanics")}`}
                                                render={() => (
                                                    <AdvancedMechanics
                                                        fetchURL={url}
                                                        t={t}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        frontURL={frontURL}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                    />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={`/${t("url.link.accreditationReq")}`}
                                                render={() => (
                                                    <AccreditationRequirements
                                                        fetchURL={url}
                                                        t={t}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        frontURL={frontURL}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                    />
                                                )}
                                            />

                                            <Route
                                                exact
                                                path={`/${t("url.link.login")}`}
                                                render={(routeProps) => (
                                                    <Login
                                                        fetchURL={url}
                                                        t={t}
                                                        onShowAlert={this.handleShowAlert}
                                                        onPassLoggedUserData={this.handlePassLoggedUserData}
                                                        moduleName={moduleName}
                                                        {...routeProps}
                                                        loggedIn={loggedIn}
                                                        onGetEventData={() => this.getEventData()}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        onShowSpinner={() => $(".loading-spinner").fadeIn(200)}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                        frontURL={frontURL}
                                                    />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={`/${t("url.link.register")}`}
                                                render={() => (
                                                    <Register
                                                        fetchURL={url}
                                                        t={t}
                                                        onShowAlert={this.handleShowAlert}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        onShowSpinner={() => $(".loading-spinner").fadeIn(200)}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                        frontURL={frontURL}
                                                    />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={`/${t("url.link.forgotPassword")}`}
                                                render={() => (
                                                    <ResetPassword
                                                        fetchURL={url}
                                                        t={t}
                                                        onShowAlert={this.handleShowAlert}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                        frontURL={frontURL}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                    />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={`/${t("url.link.sendActivationLink")}`}
                                                render={() => (
                                                    <SendActivationLink
                                                        fetchURL={url}
                                                        t={t}
                                                        onShowAlert={this.handleShowAlert}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                        frontURL={frontURL}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                    />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={`/${t("url.link.resetPassword")}`}
                                                render={(routeProps) => (
                                                    <SetPassword
                                                        fetchURL={url}
                                                        t={t}
                                                        onShowAlert={this.handleShowAlert}
                                                        onLogout={() => this.setState({ loggedIn: false })}
                                                        {...routeProps}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                        frontURL={frontURL}
                                                    />
                                                )}
                                            />
                                            <Route
                                                path={`/${t("url.link.myAccount")}`}
                                                render={(routeProps) => (
                                                    <MyAccount
                                                        fetchURL={url}
                                                        t={t}
                                                        onShowAlert={this.handleShowAlert}
                                                        onLogout={() => this.setState({ loggedIn: false })}
                                                        {...routeProps}
                                                        onPassLoggedUserData={this.handlePassLoggedUserData}
                                                        moduleName={moduleName}
                                                        frontURL={frontURL}
                                                        activeEvent={activeEvent}
                                                        onRefreshNotification={() => this.checkRequiredData(activeEvent.event_code)}
                                                        reqDataNotification={this.state.reqDataNotification}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        onGetEventData={() => this.getEventData()}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                    />
                                                )}
                                            />
                                            <Route
                                                path={`/${t("url.link.activateAccount")}`}
                                                render={(routeProps) => (
                                                    <ActivateAccount
                                                        fetchURL={url}
                                                        t={t}
                                                        onShowAlert={this.handleShowAlert}
                                                        {...routeProps}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        onShowSpinner={() => $(".loading-spinner").fadeIn(200)}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                        frontURL={frontURL}
                                                        onLogout={() => this.setState({ loggedIn: false })}
                                                    />
                                                )}
                                            />

                                            <Route
                                                render={() => (
                                                    <NoMatch
                                                        t={t}
                                                        onShowSpoonLoader={this.handleShowSpoonLoader}
                                                        onHideSpinner={(delay) => this.handleHideSpinner(delay)}
                                                        frontURL={frontURL}
                                                    />
                                                )}
                                            />
                                        </Switch>
                                    </Container>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </Router>
                {showCookieAlert && (
                    <AlertUsingCookies
                        onAcceptCookies={() => {
                            this.setState({ showCookieAlert: false });
                            localStorage.setItem("acceptCookies", true);
                        }}
                        t={t}
                    />
                )}

                {/* showEventCancelModal && (
                    <Modal show={true} onHide={() => this.setState({ showEventCancelModal: false })} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>{t("eventCancelledModal.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Alert variant="info">
                                <div dangerouslySetInnerHTML={{ __html: t("eventCancelledModal.text") }}></div>
                            </Alert>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="outline-primary"
                                onClick={() => {
                                    this.setState({ showEventCancelModal: false });
                                    localStorage.setItem("eventCancelledModal", true);
                                }}
                                type="button"
                            >
                                {t("ok")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                ) */}
            </Fragment>
        );
    }
}

export default withTranslation()(App);

import React, { Fragment, Component } from "react";
import { Alert, Button, Form, Modal, Row, Col } from "react-bootstrap";
import AlertNoPermission from "../alerts/AlertNoPermission";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import NPCForm from "../pages/myAccount/volunteering/NPCForm";
import LoadingProgressBar from "../spinners/LoadingProgressBar";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class EditNPCFormModal extends Component {
    state = { noPermission: false, loading: true, alert: null, changedValues: false, npcComment: null };

    getNpcData = async () => {
        const { fetchURL, t, onShowAlert, activeEvent } = this.props;
        const passData = {
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/get-npc-data`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status !== true)
                        this.setState({ alert: { variant: fetchedData.status, message: t(`${fetchedData.status}.${fetchedData.code}`) }, loading: false });
                    else {
                        const basicData = fetchedData.basicData[0];
                        this.setState({
                            npcComment: basicData.pan_comment ? basicData.pan_comment : "",
                            loading: false,
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    insertOrUpdateForm = async () => {
        const { fetchURL, t, onShowAlert, activeEvent, onRefreshData } = this.props;
        const passData = {
            activeEvent: activeEvent,
            npcComment: this.state.npcComment,
        };
        onShowAlert({ variant: "info", message: t(this.props.option === "edit" ? "info.editingData" : "info.submittingForm"), fading: false });
        try {
            const response = await fetch(`${fetchURL}/${this.props.option === "edit" ? "update-npc-data" : "insert-npc-data"}`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status !== true)
                        this.setState({ alert: { variant: fetchedData.status, message: t(`${fetchedData.status}.${fetchedData.code}`) }, loading: false });
                    else {
                        onShowAlert({
                            variant: "success",
                            message: t(`success.${this.props.option === "edit" ? "formEditedSuccessfully" : "formSubmittedSuccessfully"}`),
                            fading: 2000,
                        });
                        onRefreshData();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleChangeNpcData = (value, name, enableBtn) => {
        const { npcComment } = this.state;
        const list = [...npcComment];
        list[0][name] = value;
        this.setState({ npcComment: list, changedValues: enableBtn });
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { npcComment } = this.state;
        const { onShowAlert, t } = this.props;
        onShowAlert("");
        let errorMsg = [];
        const regExpWhiteSpace = /^\s*$/;

        if (!npcComment) {
            $("#npcComment").addClass("is-invalid");
            errorMsg.push(t("error.npcCommentEmpty"));
        } else if (npcComment.length > 255) {
            $("#npcComment").addClass("is-invalid");
            errorMsg.push(t("error.npcCommentTooLong"));
        } else if (npcComment && regExpWhiteSpace.test(npcComment)) {
            $("#npcComment").addClass("is-invalid");
            errorMsg.push(t("error.npcCommentInvalid"));
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            this.insertOrUpdateForm();
        }
    };

    handleChangeNpcData = (value, name, enableBtn) => {
        const { npcComment } = this.state;
        const list = [...npcComment];
        list[0][name] = value;
        this.setState({ npcComment: list, changedValues: enableBtn });
    };

    componentDidMount() {
        if (this.props.activeEvent && this.props.activeEvent !== "noActiveEvent")
            if (this.props.option === "edit") this.getNpcData();
            else
                this.setState({
                    npcComment: "",
                    loading: false,
                });
        else this.setState({ showView: "noActiveEvent" });
    }

    render() {
        const { noPermission, loading, alert, changedValues, npcComment } = this.state;
        const { t, show, onClose, onShowAlert } = this.props;
        return (
            <Fragment>
                <Modal show={show} onHide={onClose} size="lg" backdrop="static" keyboard={false}>
                    <Form onSubmit={this.handleCheckValidation}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t(`myAccount.volunteering.${this.props.option === "edit" ? "editForm" : "fillForm"}`)}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {noPermission ? (
                                <AlertNoPermission t={t} />
                            ) : alert ? (
                                <Alert className="my-5 text-center" variant={alert.variant}>
                                    <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /> {alert.message}
                                </Alert>
                            ) : loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <div className="my-account-show" id="volunteeringForm">
                                    <Row className="no-gutters">
                                        <Col xs={12} className="fill-form mt-3 mb-5 px-4 py-3">
                                            <NPCForm
                                                npcComment={npcComment}
                                                onChangeValue={(e) => this.setState({ npcComment: e.target.value, changedValues: true })}
                                                t={t}
                                                onShowAlert={onShowAlert}
                                                changedValues={changedValues}
                                                onChangeView={(view) => this.setState({ showView: view })}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary" type="button" onClick={onClose}>
                                {t("info.cancel")}
                            </Button>
                            <Button variant="primary" type="submit" disabled={!changedValues}>
                                {t(this.props.option === "edit" ? "info.save" : "myAccount.formSubmitBtn")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default withRouter(EditNPCFormModal);

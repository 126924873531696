import React, { Fragment, Component } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import EditCharacterForm from "../pages/myAccount/characters/EditCharacterForm";
import LoadingProgressBar from "../spinners/LoadingProgressBar";
import $ from "jquery";

class EditChosenCharacter extends Component {
    state = {
        changedValues: false,
        characterData: [
            {
                char_name: "",
                char_fraction: "",
                char_description: "",
                char_assets: "",
                char_skills: "",
                char_expectation: "",
                char_version: null,
            },
        ],
    };

    getCharacterData = async () => {
        const { fetchURL, t, onShowAlert, charID } = this.props;
        try {
            const response = await fetch(`${fetchURL}/get-char-data-to-edit`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ charID: charID }),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        this.setState({ characterData: fetchedData.characterData, loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    editCharacter = async () => {
        const { characterData } = this.state;
        const { fetchURL, t, onShowAlert, charID, onCloseAndRefresh, onRefreshNotification, activeEvent, partID } = this.props;
        try {
            const response = await fetch(`${fetchURL}/edit-character`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ charID: charID, characterData: characterData[0], activeEvent: activeEvent, partID: partID }),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        onShowAlert({ variant: "success", message: t(`success.dataUpdatedSuccessfully`), fading: 2000 });
                        onRefreshNotification();
                        onCloseAndRefresh();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { characterData } = this.state;
        const { onShowAlert, t } = this.props;

        onShowAlert("");
        let errorMsg = [];

        if (characterData[0].char_expectation.length > 4000) {
            $(`#characterExpectiption`).addClass("is-invalid");
            errorMsg.push(t("error.tooLongCharacterExpectiption"));
        }

        if (!characterData[0].char_description) {
            $(`#characterDescription`).addClass("is-invalid");
            errorMsg.push(t("error.emptyCharacterDescription"));
        } else if (characterData[0].char_description.length > 4000) {
            $(`#characterDescription`).addClass("is-invalid");
            errorMsg.push(t("error.tooLongCharacterDescription"));
        }

        if (characterData[0].char_skills.length > 4000) {
            $(`#characterSkills`).addClass("is-invalid");
            errorMsg.push(t("error.tooLongCharacterSkills"));
        }
        if (characterData[0].char_assets.length > 4000) {
            $(`#characterAssets`).addClass("is-invalid");
            errorMsg.push(t("error.tooLongCharacterAssets"));
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            //console.log(characterData[0]);
            this.editCharacter();
        }
    };

    handleChangeTextFormValues = (e) => {
        const { characterData } = this.state;
        const name = e.target.name;
        const list = [...characterData];
        list[0][name] = e.target.value;
        this.setState({ characterData: list, changedValues: true });
    };

    handleChangeCharContinuation = (value) => {
        const { characterData } = this.state;
        const list = [...characterData];
        list[0].char_is_continued = value;
        this.setState({ characterData: list, changedValues: true }, () => {
            $("#characterContinuation").removeClass("is-invalid");
            if (value === "N") {
                const { characterData } = this.state;
                const list = [...characterData];
                list[0].char_assets = "";
                list[0].char_skills = "";
                this.setState({ characterData: list });
            }
        });
    };

    handleChangeCraftingRadio = (value) => {
        const { characterData } = this.state;
        const list = [...characterData];
        list[0].char_is_crafter = value;
        this.setState({ characterData: list, changedValues: true });
    };

    componentDidMount() {
        this.props.onShowAlert("");
        this.getCharacterData();
    }

    render() {
        const { changedValues, characterData, loading } = this.state;
        const { t, show, onClose, charComment } = this.props;

        return (
            <Fragment>
                <Modal show={show} onHide={onClose} backdrop="static" keyboard={false} size="lg">
                    <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                        <Modal.Header closeButton>
                            <Modal.Title>{t("myAccount.characters.editYourCharacter")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {loading ? (
                                <Row className="no-gutters">
                                    <Col className="text-center">
                                        <LoadingProgressBar t={t} />
                                    </Col>
                                </Row>
                            ) : (
                                <Row className="no-gutters">
                                    {charComment && (
                                        <Col xs={12} className="h6 mb-4">
                                            <p>{t("myAccount.characters.characterComment")} </p>
                                            <p>
                                                <i>{charComment}</i>
                                            </p>
                                        </Col>
                                    )}
                                    <p className="text-uppercase h5">
                                        {t("myAccount.characters.characterHistory.chosenVersion")}: {characterData[0].char_version}
                                    </p>
                                    <EditCharacterForm
                                        t={t}
                                        characterData={characterData}
                                        onChangeTextFormValues={this.handleChangeTextFormValues}
                                        newChar={characterData[0].char_is_continued === "N" ? true : false}
                                        onChangeCraftingRadio={this.handleChangeCraftingRadio}
                                        onChangeCharContinuation={this.handleChangeCharContinuation}
                                    />
                                </Row>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={onClose}>
                                {t("close")}
                            </Button>
                            <Button variant="primary" disabled={!changedValues} type="submit">
                                {t("info.saveChanges")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default EditChosenCharacter;

import React from "react";
import { Button, Col, Row } from "react-bootstrap";

const BestLARP = (props) => {
    const { t, frontURL, onChangeRoute } = props;

    return (
        <Row id="bestLARP" className="no-gutters">
            <div
                className="bg-img"
                style={{
                    backgroundImage: `url(${frontURL}/images/DSC_7522.webp)`,
                }}
                id="wastelandImg"
            ></div>
            <p className="p-title">{t("home.p3.title")}</p>
            <Col xs={12} lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }} id="bestLARPCol">
                <div className="p-text" style={{ opacity: 0 }} id="bestLARPText">
                    <div>
                        <p dangerouslySetInnerHTML={{ __html: t("home.p3.text") }}></p>
                        <div className="text-center">
                            <Button variant="primary" size="lg" onClick={(e) => onChangeRoute(e, `/${t("url.link.intro")}`)}>
                                {t("url.text.intro")}
                            </Button>
                        </div>
                    </div>
                </div>
            </Col>
            <div id="bestLarpBg"></div>
        </Row>
    );
};

export default BestLARP;

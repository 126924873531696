import React, { Component, Fragment } from "react";
import { Row, Col, Button, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import AccreditationTransfer from "./AccreditationTransfer";
import AccrForm from "./AccrForm";
import { Link, withRouter } from "react-router-dom";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";

class SubmitForm extends Component {
    state = {
        showView: null,
        partID: null,
        accrData: [
            {
                partID: null,
                participation: null,
                partIdentNickname: "",
                larpCharacter: null,
                partCommentForm: "",
                regulations: false,
                legalAge: false,
                moneyTransferDeclaration: false,
            },
        ],
        disabledForm: false,
        warning: null,
        allowSelectCharacter: false,
    };

    checkPartExistence = async () => {
        const { fetchURL, t, onShowAlert } = this.props;

        try {
            const response = await fetch(`${fetchURL}/get-participant-data`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status !== true) this.setState({ showView: { variant: fetchedData.status, message: fetchedData.code } });
                    else {
                        if (fetchedData.allowSelectCharacter) this.setState({ allowSelectCharacter: true, showView: "form" });
                        else this.setState({ showView: "form" });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    insertParticipant = async () => {
        const { accrData, accrApplications } = this.state;
        const { fetchURL, t, onShowAlert, onRefreshNotification } = this.props;
        const passData = {
            accrData: accrData[0],
            accrApplications: accrApplications,
        };
        // console.log(passData);
        try {
            const response = await fetch(`${fetchURL}/insert-participant`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                        this.setState({ disabledForm: false });
                    } else if (fetchedData.status !== true) {
                        onShowAlert({ variant: "warning", message: t(`warning.${fetchedData.status}`), fading: false });
                        this.props.onFetchAgainEventsList();
                    } else {
                        if (this.state.accrData[0].participation === "ABORTED") {
                            onShowAlert({ variant: "success", message: t(`success.formSubmittedSuccessfully`), fading: 2000 });
                            onRefreshNotification();
                            this.props.history.push(`/${t("url.link.myAccount")}/${t("url.link.accreditation")}`);
                        } else this.setState({ showView: "transfer", partID: fetchedData.partID });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    insertPayment = async () => {
        const { accrData, partID } = this.state;
        const { fetchURL, t, onShowAlert, onRefreshNotification } = this.props;
        onShowAlert({ variant: "info", message: t("info.submittingForm"), fading: false });
        const passData = {
            accrData: accrData[0],
            partID: partID,
        };
        //console.log(passData);
        try {
            const response = await fetch(`${fetchURL}/insert-payment`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                        this.setState({ disabledForm: false });
                    } else {
                        onShowAlert({ variant: "success", message: t(`success.formSubmittedSuccessfully`), fading: 2000 });
                        onRefreshNotification();
                        this.props.history.push(`/${t("url.link.myAccount")}/${t("url.link.accreditation")}`);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleSendData = (mt, date) => {
        this.setState({ disabledForm: true }, () => {
            const { accrData } = this.state;
            const list = [...accrData];
            list[0].moneyTransferDeclaration = mt;
            list[0].moneyTransferDeclarationDate = list[0].moneyTransferDeclaration ? date : null;
            this.setState({ accrData: list }, () => this.insertPayment());
        });
    };

    handleDataProcessing = () => {
        this.insertParticipant();
    };

    componentDidMount() {
        if (this.props.activeEvent && this.props.activeEvent !== "noActiveEvent") this.checkPartExistence();
        else this.setState({ showView: "noActiveEvent" });
    }

    render() {
        const { showView, partID, disabledForm, accrData, allowSelectCharacter } = this.state;
        const { t, activeEvent, onShowAlert, fetchURL, accrLink, myAccountLink } = this.props;

        return (
            <Fragment>
                <Row className="no-gutters">
                    <Col className="text-center">
                        <p className="h3 my-4 text-uppercase">{t("myAccount.accreditation.title")}</p>
                    </Col>
                </Row>
                {!showView ? (
                    <LoadingProgressBar t={t} />
                ) : (
                    <div className="my-account-show">
                        {showView === "transfer" ? (
                            <Fragment>
                                <Row className="no-gutters">
                                    <Col>
                                        <Button variant="link" onClick={() => this.props.history.goBack()}>
                                            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                            {t("myAccount.goBack")}
                                        </Button>
                                    </Col>
                                </Row>
                                <AccreditationTransfer
                                    t={t}
                                    activeEvent={activeEvent}
                                    partID={partID}
                                    onShowAlert={onShowAlert}
                                    fetchURL={fetchURL}
                                    onSendData={this.handleSendData}
                                    disabledForm={disabledForm}
                                />
                            </Fragment>
                        ) : showView === "form" ? (
                            <Fragment>
                                <Row className="no-gutters">
                                    <Col>
                                        <Button variant="link" onClick={() => this.props.history.goBack()}>
                                            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                            {t("myAccount.goBack")}
                                        </Button>
                                    </Col>
                                </Row>
                                <AccrForm
                                    t={t}
                                    onDataProcessing={this.handleDataProcessing}
                                    onShowAlert={onShowAlert}
                                    fetchURL={fetchURL}
                                    disabledForm={disabledForm}
                                    onUpdateAccrData={(data) => this.setState({ accrData: data })}
                                    onUpdateAccrApp={(data) => this.setState({ accrApplications: data })}
                                    accrData={accrData}
                                    allowSelectCharacter={allowSelectCharacter}
                                    activeEvent={activeEvent}
                                    myAccountLink={myAccountLink}
                                />
                            </Fragment>
                        ) : (
                            <Row className="no-gutters">
                                <Col lg={{ span: 8, offset: 2 }} xs={12}>
                                    <Alert className="my-5 text-center" variant={showView.variant}>
                                        <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /> {t(`info.${showView.message}`)}
                                    </Alert>
                                </Col>
                                <Col xs={12} className="text-center">
                                    <Link to={accrLink} className="btn btn-link">
                                        {t("myAccount.goBack")}
                                    </Link>
                                </Col>
                            </Row>
                        )}
                    </div>
                )}
            </Fragment>
        );
    }
}

export default withRouter(SubmitForm);

import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";

const Attractions = (props) => {
    const { t, frontURL, onChangeRoute, dirSize } = props;

    return (
        <Row id="attractions" className="no-gutters">
            <div id="attractionsBg"></div>
            <Col xs={12}>
                <p className="p-title">{t("home.p2.title")}</p>
            </Col>
            <div id="attractionsTxt"></div>

            <Col xs={12} lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }} id="attractionsCol">
                <div className="p-text" style={{ opacity: 0 }} id="attractionsTxtContent">
                    <p dangerouslySetInnerHTML={{ __html: t("home.p2.text") }}></p>
                    <div className="text-center">
                        <Button variant="outline-light" size="lg" onClick={(e) => onChangeRoute(e, `/${t("url.link.program")}`)}>
                            {t("url.text.program")}
                        </Button>
                    </div>
                </div>
            </Col>

            <Col xs={12}>
                <div id="barBanner">
                    {dirSize.includes("xs") || dirSize.includes("sm") || dirSize.includes("md") ? (
                        <Image src={`${frontURL}/images/bar-banner/${dirSize}/bar_banner.jpg`} alt="main_banner" />
                    ) : (
                        <>
                            <img className="barimg" src={`${frontURL}/images/bar-banner/${dirSize}/5.webp`} data-modifier="0" alt="bar banner layer 1" />
                            <img className="barimg" id="barWallRight" src={`${frontURL}/images/bar-banner/${dirSize}/4.webp`} data-modifier="0" alt="bar banner layer 2" />
                            <img className="barimg" id="barWallLeft" src={`${frontURL}/images/bar-banner/${dirSize}/3.webp`} data-modifier="0" alt="bar banner layer 3" />
                            <img
                                className="barimg"
                                src={`${frontURL}/images/bar-banner/${dirSize}/2.webp`}
                                data-modifier={dirSize.includes("xl") ? "40" : dirSize.includes("sm_horizontal") || dirSize.includes("lg") ? "15" : "25"}
                                alt="bar banner layer 4"
                            />
                            <img
                                className="barimg"
                                src={`${frontURL}/images/bar-banner/${dirSize}/1.webp`}
                                data-modifier={dirSize.includes("xl") ? "50" : dirSize.includes("sm_horizontal") || dirSize.includes("lg") ? "20" : "25"}
                                alt="bar banner layer 5"
                            />
                            <div
                                className="barimg"
                                id="bgCover"
                                data-modifier={dirSize.includes("xl") ? "10" : dirSize.includes("sm_horizontal") || dirSize.includes("lg") ? "2" : "5"}
                            />
                        </>
                    )}
                </div>
            </Col>

            <div id="attractionsAfterBanner"></div>
        </Row>
    );
};

export default Attractions;

import React, { Component, Fragment } from "react";
import { Row, Col, Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import NoPermission from "../../other/NoPermission";
import { faEdit, faExclamation, faPlusSquare, faUser } from "@fortawesome/free-solid-svg-icons";
import EditNameAndSurname from "../../modals/EditNameAndSurname";
import EditNickname from "../../modals/EditNickname";
import EditPhone from "../../modals/EditPhone";
import EditFbLink from "../../modals/EditFbLink";
import EditMedInfo from "../../modals/EditMedInfo";
import EditAvatar from "../../modals/EditAvatar";

class DataAccount extends Component {
    state = {
        loading: true,
        noPermission: false,
        userData: null,
        showModal: null,
    };

    getPersonalData = async () => {
        const { fetchURL, t, onShowAlert } = this.props;
        try {
            const response = await fetch(`${fetchURL}/personal-data`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") {
                        this.props.onNotLogged();
                    } else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        this.setState({
                            userData: [fetchedData.userData],
                            loading: false,
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        this.getPersonalData();
    }

    render() {
        const { loading, noPermission, userData, showModal } = this.state;
        const { t, fetchURL, onShowAlert, avatar, onRefreshNotification } = this.props;
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : (
                    <Fragment>
                        <Row className="no-gutters">
                            <Col className="text-center">
                                <p className="h3 my-4 text-uppercase">{t("myAccount.dataAccount.title")}</p>
                            </Col>
                        </Row>
                        {loading ? (
                            <LoadingProgressBar t={t} />
                        ) : (
                            <div className="my-account-show">
                                <Row className="no-gutters">
                                    <Col xs={12} className="text-center mt-4">
                                        <div className="account-data-table">
                                            <Table responsive>
                                                <tbody>
                                                    <tr>
                                                        <td>{t("myAccount.dataAccount.profilePic")}</td>
                                                        <td className="p-2">
                                                            {avatar && avatar !== "noFile" ? (
                                                                <div className="avatar">
                                                                    <img src={`data:${avatar.type};base64,${avatar.data}`} width="200" alt={avatar.name} />
                                                                </div>
                                                            ) : (
                                                                <div className="avatar border">
                                                                    <FontAwesomeIcon icon={faUser} />
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <Button
                                                                variant="outline-primary"
                                                                size="sm"
                                                                onClick={() => this.setState({ showModal: "avatar" })}
                                                                className="align-baselign p-2 action-btn"
                                                            >
                                                                <FontAwesomeIcon icon={userData[0].blob_id ? faEdit : faPlusSquare} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("myAccount.dataAccount.nameAndSurname")}</td>
                                                        <td className="editable-data">
                                                            {userData[0].user_name} {userData[0].user_surname}
                                                        </td>
                                                        <td>
                                                            <Button
                                                                variant="outline-primary"
                                                                size="sm"
                                                                className="align-baselign p-2 action-btn"
                                                                onClick={() => this.setState({ showModal: "nameAndSurname" })}
                                                            >
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("myAccount.dataAccount.nickname")}</td>
                                                        <td className="editable-data">
                                                            {userData[0].user_nickname ? (
                                                                userData[0].user_nickname
                                                            ) : (
                                                                <span className="opacity-4 text-lowercase">{t("info.none")}</span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <Button
                                                                variant="outline-primary"
                                                                size="sm"
                                                                className="align-baselign p-2 action-btn"
                                                                onClick={() => this.setState({ showModal: "nickname" })}
                                                            >
                                                                <FontAwesomeIcon icon={userData[0].user_nickname ? faEdit : faPlusSquare} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("myAccount.dataAccount.email")}</td>
                                                        <td>{userData[0].user_email}</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {t("myAccount.dataAccount.phone")}
                                                            {!userData[0].user_phone && (
                                                                <OverlayTrigger
                                                                    placement="right"
                                                                    overlay={(props) => <Tooltip {...props}>{t("myAccount.dataAccount.noUserPhone")}</Tooltip>}
                                                                >
                                                                    <Button variant="link" size="sm">
                                                                        <span className="badge badge-pill badge-danger ml-2 align-text-bottom">
                                                                            <FontAwesomeIcon icon={faExclamation} />
                                                                        </span>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            )}
                                                        </td>
                                                        <td className="editable-data">
                                                            {userData[0].user_phone ? userData[0].user_phone : <span className="opacity-4 text-lowercase">{t("info.none")}</span>}
                                                        </td>
                                                        <td>
                                                            <Button
                                                                variant="outline-primary"
                                                                size="sm"
                                                                className="align-baselign p-2 action-btn"
                                                                onClick={() => this.setState({ showModal: "phone" })}
                                                            >
                                                                <FontAwesomeIcon icon={userData[0].user_phone ? faEdit : faPlusSquare} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("myAccount.dataAccount.fbLink")}</td>
                                                        <td className="editable-data">
                                                            {userData[0].user_facebook_link ? userData[0].user_facebook_link : <span className="opacity-4">{t("info.none")}</span>}
                                                        </td>
                                                        <td>
                                                            <Button
                                                                variant="outline-primary"
                                                                size="sm"
                                                                className="align-baselign p-2 action-btn"
                                                                onClick={() => this.setState({ showModal: "fbLink" })}
                                                            >
                                                                <FontAwesomeIcon icon={userData[0].user_facebook_link ? faEdit : faPlusSquare} />
                                                            </Button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>{t("myAccount.dataAccount.medInfo")}</td>
                                                        <td className="editable-data">
                                                            {userData[0].user_medical_info ? userData[0].user_medical_info : <span className="opacity-4">{t("info.none")}</span>}
                                                        </td>
                                                        <td>
                                                            <Button
                                                                variant="outline-primary"
                                                                size="sm"
                                                                className="align-baselign p-2 action-btn"
                                                                onClick={() => this.setState({ showModal: "medInfo" })}
                                                            >
                                                                <FontAwesomeIcon icon={userData[0].user_medical_info ? faEdit : faPlusSquare} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                                {showModal === "avatar" && (
                                    <EditAvatar
                                        fetchURL={fetchURL}
                                        t={t}
                                        onShowAlert={onShowAlert}
                                        show={showModal ? true : false}
                                        onClose={() => this.setState({ showModal: null })}
                                        userData={userData}
                                        onCloseAndRefresh={() => {
                                            this.setState({ showModal: null, loading: true }, () => {
                                                this.getPersonalData();
                                                this.props.onRefreshAvatar();
                                            });
                                        }}
                                        avatar={avatar}
                                    />
                                )}
                                {showModal === "nameAndSurname" && (
                                    <EditNameAndSurname
                                        fetchURL={fetchURL}
                                        t={t}
                                        onShowAlert={onShowAlert}
                                        show={showModal ? true : false}
                                        onClose={() => this.setState({ showModal: null })}
                                        userData={userData}
                                        onCloseAndRefresh={() => {
                                            this.setState({ showModal: null, loading: true }, () => this.getPersonalData());
                                        }}
                                    />
                                )}
                                {showModal === "nickname" && (
                                    <EditNickname
                                        fetchURL={fetchURL}
                                        t={t}
                                        onShowAlert={onShowAlert}
                                        show={showModal ? true : false}
                                        onClose={() => this.setState({ showModal: null })}
                                        userData={userData}
                                        onCloseAndRefresh={() => {
                                            this.setState({ showModal: null, loading: true }, () => {
                                                this.getPersonalData();
                                            });
                                        }}
                                    />
                                )}
                                {showModal === "phone" && (
                                    <EditPhone
                                        fetchURL={fetchURL}
                                        t={t}
                                        onShowAlert={onShowAlert}
                                        show={showModal ? true : false}
                                        onClose={() => this.setState({ showModal: null })}
                                        userData={userData}
                                        onCloseAndRefresh={() => {
                                            this.setState({ showModal: null, loading: true }, () => this.getPersonalData());
                                        }}
                                        onRefreshNotification={onRefreshNotification}
                                    />
                                )}
                                {showModal === "fbLink" && (
                                    <EditFbLink
                                        fetchURL={fetchURL}
                                        t={t}
                                        onShowAlert={onShowAlert}
                                        show={showModal ? true : false}
                                        onClose={() => this.setState({ showModal: null })}
                                        userData={userData}
                                        onCloseAndRefresh={() => {
                                            this.setState({ showModal: null, loading: true }, () => this.getPersonalData());
                                        }}
                                    />
                                )}
                                {showModal === "medInfo" && (
                                    <EditMedInfo
                                        fetchURL={fetchURL}
                                        t={t}
                                        onShowAlert={onShowAlert}
                                        show={showModal ? true : false}
                                        onClose={() => this.setState({ showModal: null })}
                                        userData={userData}
                                        onCloseAndRefresh={() => {
                                            this.setState({ showModal: null, loading: true }, () => this.getPersonalData());
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default DataAccount;

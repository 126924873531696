import React, { Fragment } from "react";
import TextForm from "../text-inputs/TextForm";
import { Checkbox } from "semantic-ui-react";

const ToolsCheckboxes = (props) => {
    const { t, tools, toolsOther, onCheckTool, onChangeOtherTools } = props;
    return (
        <Fragment>
            {tools.map((e, i) => (
                <Fragment key={i}>
                    <Checkbox label={e.label} checked={e.value} onChange={(e, d) => onCheckTool(i, d.checked)} className="d-block" />
                    {i === tools.length - 1 && e.value && (
                        <TextForm
                            value={toolsOther}
                            onChangeValue={onChangeOtherTools}
                            placeholderTxt={t("myAccount.volunteering.constr.form.tools.otherToolPlaceholder")}
                            name="otherTools"
                            id="otherTools"
                        />
                    )}
                </Fragment>
            ))}
        </Fragment>
    );
};

export default ToolsCheckboxes;

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import LoadingProgressBar from "../spinners/LoadingProgressBar";
import $ from "jquery";
import EditCharacterForm from "../pages/myAccount/characters/EditCharacterForm";

class UpgradeCharacter extends Component {
    state = {
        loading: true,
        characterData: [
            {
                char_fraction: "",
                char_description: "",
                char_assets: "",
                char_skills: "",
            },
        ],
        changedValues: false,
        modalAlert: null,
    };

    getCharData = async () => {
        const { fetchURL, t, onShowAlert, charID } = this.props;

        try {
            const response = await fetch(`${fetchURL}/get-char-data-to-upgrade`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ charID: charID }),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status !== true)
                        this.setState({ modalAlert: { variant: fetchedData.status, message: t(`${fetchedData.status}.${fetchedData.code}`) }, loading: false });
                    else this.setState({ characterData: fetchedData.characterData, loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    upgradeCharacter = async () => {
        const { characterData } = this.state;
        const { fetchURL, t, onShowAlert, charID, onCloseAndRefresh } = this.props;
        onShowAlert({ variant: "info", message: "editingCharacter", fading: null });
        try {
            const response = await fetch(`${fetchURL}/upgrade-character`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ charID: charID, characterData: characterData[0] }),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        onShowAlert({ variant: "success", message: t(`success.charEdited`), fading: 3000 });
                        onCloseAndRefresh();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { characterData } = this.state;
        const { onShowAlert, t } = this.props;

        onShowAlert("");
        let errorMsg = [];

        if (characterData[0].char_expectation.length > 4000) {
            $(`#characterExpectiption`).addClass("is-invalid");
            errorMsg.push(t("error.tooLongCharacterExpectiption"));
        }

        if (!characterData[0].char_description) {
            $(`#characterDescription`).addClass("is-invalid");
            errorMsg.push(t("error.emptyCharacterDescription"));
        } else if (characterData[0].char_description.length > 4000) {
            $(`#characterDescription`).addClass("is-invalid");
            errorMsg.push(t("error.tooLongCharacterDescription"));
        }

        if (characterData[0].char_skills.length > 4000) {
            $(`#characterSkills`).addClass("is-invalid");
            errorMsg.push(t("error.tooLongCharacterSkills"));
        }
        if (characterData[0].char_assets.length > 4000) {
            $(`#characterAssets`).addClass("is-invalid");
            errorMsg.push(t("error.tooLongCharacterAssets"));
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            // console.log(characterData);
            this.setState({ changedValues: false });
            this.upgradeCharacter();
        }
    };

    handleChangeTextFormValues = (e) => {
        const { characterData } = this.state;
        const name = e.target.name;
        const list = [...characterData];
        list[0][name] = e.target.value;
        this.setState({ characterData: list, changedValues: true });
    };

    componentDidMount() {
        this.getCharData();
    }

    render() {
        const { characterData, loading, changedValues, modalAlert } = this.state;
        const { t, onClose, show } = this.props;

        return (
            <Fragment>
                <Modal show={show} onHide={onClose} backdrop="static" keyboard={false} size="lg">
                    <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                        <Modal.Header closeButton>
                            <Modal.Title>{t("myAccount.characters.upgradeCharacter.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="no-gutters">
                                {loading ? (
                                    <Col xs={12}>
                                        <LoadingProgressBar t={t} />
                                    </Col>
                                ) : modalAlert ? (
                                    <Col xs={12}>
                                        <Alert variant={modalAlert.variant}>
                                            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                            {modalAlert.message}
                                        </Alert>
                                    </Col>
                                ) : (
                                    <>
                                        <Col xs={12}>
                                            <p className="mb-4 h6" style={{ lineHeight: "1.2" }}>
                                                {t("myAccount.characters.upgradeCharacter.subtitle")}
                                            </p>
                                            <Alert variant="primary">
                                                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                                {t("myAccount.characters.upgradeCharacter.important")}
                                            </Alert>
                                        </Col>
                                        <EditCharacterForm t={t} characterData={characterData} onChangeTextFormValues={this.handleChangeTextFormValues} upgradeCharacter />
                                    </>
                                )}
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={onClose}>
                                {t("info.cancel")}
                            </Button>
                            {!modalAlert && (
                                <Button variant="primary" disabled={!changedValues} type="submit">
                                    {t("info.saveChanges")}
                                </Button>
                            )}
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default UpgradeCharacter;

import React, { Fragment } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";
import { Form } from "react-bootstrap";
import $ from "jquery";
registerLocale("pl", pl);

const CalendarDate = (props) => {
    const { txtLabel, onChangeCalendarDate, value, id, t, disabled, className, popperPlacement } = props;
    $(`#${id}`).on("click", function () {
        $(this).removeClass("is-invalid");
    });
    return (
        <Fragment>
            {txtLabel && <Form.Label>{txtLabel}</Form.Label>}
            <DatePicker
                placeholderText={t("calendarPlaceholder")}
                selected={value}
                onChange={(date) => onChangeCalendarDate(date)}
                dateFormat="yyyy-MM-dd"
                locale="pl"
                id={id}
                disabled={disabled}
                className={className}
                popperPlacement={popperPlacement}
            />
        </Fragment>
    );
};

export default CalendarDate;

import React, { Fragment } from "react";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";

const NoMatch = (props) => {
    const { t } = props;
    $(document).ready(function () {
        $("#page404").css("min-height", `${$(window).outerHeight() - $("#footer").outerHeight()}px`);
    });
    props.onHideSpinner(200);
    return (
        <Fragment>
            <div id="page404">
                <p>{t("noMatch.title")}</p>
                <Button
                    variant="primary"
                    onClick={(e) => {
                        e.preventDefault();
                        props.onShowSpoonLoader();
                        setTimeout(() => {
                            props.history.push(`/`);
                        }, 500);
                    }}
                >
                    {t("noMatch.button")}
                </Button>
            </div>
        </Fragment>
    );
};

export default withRouter(NoMatch);

import React, { Fragment, Component } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class Footer extends Component {
    state = {};

    handleChangeRoute = (e, url) => {
        e.preventDefault();

        this.props.onShowSpoonLoader();

        setTimeout(() => {
            this.props.history.push(url);
        }, 700);
    };

    render() {
        const { t, frontURL } = this.props;
        return (
            <Fragment>
                <footer id="footer">
                    <Row className="no-gutters">
                        <Col>
                            <div className="d-lg-none">
                                <Row className="no-gutters">
                                    <Col xs={12} id="fbLinks" className="text-center">
                                        <Image src={`${frontURL}/images/lyzkon_logo_white.svg`} alt="Łyżkon logo white" id="footerLogo" />
                                        <p id="alkoLogo">
                                            <a href="https://www.facebook.com/alkochemicy" target="_blank" rel="noopener noreferrer">
                                                <Image src={`${frontURL}/images/alkochemicy.svg`} alt="Alkochemicy logo" />
                                                <span>facebook/alkochemicy</span>
                                            </a>
                                        </p>
                                        <p id="lyzkonIcon">
                                            <a href="https://www.facebook.com/lyzkon" target="_blank" rel="noopener noreferrer">
                                                <Image src={`${frontURL}/images/lyzkon_icon_white.svg`} alt="Łyżkon icon logo" />
                                                <span>facebook/lyzkon</span>
                                            </a>
                                        </p>
                                    </Col>
                                    <Col xs={12} className="my-3 text-center" id="fastLinks">
                                        <p className="my-4 h5 text-uppercase">{t("footer.fastLinks")}</p>
                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.news")}`)} className="my-3 d-block mx-auto">
                                            {t("url.text.news")}
                                        </Button>
                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.about")}`)} className="my-3 d-block mx-auto">
                                            {t("url.text.about")}
                                        </Button>
                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.intro")}`)} className="my-3 d-block mx-auto">
                                            {t("url.text.intro")}
                                        </Button>
                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.basicMechanics")}`)} className="my-3 d-block mx-auto">
                                            {t("url.text.basicMechanics")}
                                        </Button>
                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.regulations")}`)} className="my-3 d-block mx-auto">
                                            {t("url.text.regulations")}
                                        </Button>

                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.contact")}`)} className="my-3 d-block mx-auto">
                                            {t("url.text.contact")}
                                        </Button>
                                    </Col>
                                    <Col className="text-center py-2 text-small" xs={12}>
                                        Copyright by Łyżkon
                                    </Col>
                                </Row>
                            </div>

                            <div className="d-lg-block d-none">
                                <Row className="no-gutters">
                                    <Col xs={12} xl={{ span: 3, offset: 1 }} id="fbLinks" className="text-center text-xl-left mb-4 mb-xl-0">
                                        <Image src={`${frontURL}/images/lyzkon_logo_white.svg`} alt="Łyżkon logo white" id="footerLogo" />
                                        <p id="alkoLogo">
                                            <a href="https://www.facebook.com/alkochemicy" target="_blank" rel="noopener noreferrer">
                                                <Image src={`${frontURL}/images/alkochemicy.svg`} alt="Alkochemicy logo" />
                                                <span>facebook/alkochemicy</span>
                                            </a>
                                        </p>
                                        <p id="lyzkonIcon">
                                            <a href="https://www.facebook.com/lyzkon" target="_blank" rel="noopener noreferrer">
                                                <Image src={`${frontURL}/images/lyzkon_icon_white.svg`} alt="Łyżkon icon logo" />
                                                <span>facebook/lyzkon</span>
                                            </a>
                                        </p>
                                    </Col>
                                    <Col md={4} xl={2} xs={12} className="text-center">
                                        <p className="mb-4 h5 text-uppercase">Łyżkon</p>
                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.news")}`)} className="mb-3 d-block mx-auto">
                                            {t("url.text.news")}
                                        </Button>

                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.about")}`)} className="mb-3 d-block mx-auto">
                                            {t("url.text.about")}
                                        </Button>
                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.whereWePlay")}`)} className="mb-3 d-block mx-auto">
                                            {t("url.text.area")}
                                        </Button>
                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.contact")}`)} className="mb-3 d-block mx-auto">
                                            {t("url.text.contact")}
                                        </Button>
                                    </Col>

                                    <Col md={4} xl={2} xs={12} className="text-center">
                                        <p className="mb-4 h5 text-uppercase">{t("url.text.convention")}</p>
                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.program")}`)} className="mb-3 d-block mx-auto">
                                            {t("url.text.program")}
                                        </Button>

                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.gallery")}`)} className="mb-3 d-block mx-auto">
                                            {t("url.text.gallery")}
                                        </Button>
                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.previousEditions")}`)} className="mb-3 d-block mx-auto">
                                            {t("url.text.previousEditions")}
                                        </Button>
                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.regulations")}`)} className="mb-3 d-block mx-auto">
                                            {t("url.text.regulations")}
                                        </Button>
                                    </Col>
                                    <Col md={4} xl={2} xs={12} className="text-center">
                                        <p className="mb-4 h5 text-uppercase">{t("url.text.larp")}</p>
                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.intro")}`)} className="mb-3 d-block mx-auto">
                                            {t("url.text.intro")}
                                        </Button>
                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.basicMechanics")}`)} className="mb-3 d-block mx-auto">
                                            {t("url.text.basicMechanics")}
                                        </Button>
                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.accreditation")}`)} className="mb-3 d-block mx-auto">
                                            {t("url.text.accreditation")}
                                        </Button>
                                        <Button variant="link" onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.submit")}`)} className="mb-3 d-block mx-auto">
                                            {t("url.text.submit")}
                                        </Button>
                                    </Col>
                                    <Col className="text-center py-2 text-small" xs={12}>
                                        Copyright by Łyżkon
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </footer>
            </Fragment>
        );
    }
}

export default withRouter(Footer);

import React, { Component, Fragment } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import $ from "jquery";
import Email from "../forms/Email";
import TextForm from "../forms/text-inputs/TextForm";
import TextareaForm from "../forms/TextareaForm";
import Footer from "../other/Footer";
import ReCAPTCHA from "react-google-recaptcha";

class Contact extends Component {
    state = {
        email: "",
        title: "",
        text: "",
        name: "",
        token: null,
    };

    validateEmail = (email) => {
        const { t } = this.props;
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            $("#login").addClass("danger");
            return t("error.invalidEmail");
        } else return "";
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { email, title, text, name } = this.state;
        const { onShowAlert, t } = this.props,
            regExpWhiteSpace = /^\s*$/;

        onShowAlert("");
        let errorMsg = [];
        if (!email) {
            $("#contactEmail").addClass("is-invalid");
            errorMsg.push(t("error.emptyEmail"));
        } else {
            const validate = this.validateEmail(email);
            if (validate) {
                $("#contactEmail").addClass("is-invalid");
                errorMsg.push(validate);
            }
        }

        if (!title) {
            $("#contactTitle").addClass("is-invalid");
            errorMsg.push(t("error.emptyTitleMessage"));
        } else if (regExpWhiteSpace.test(title)) {
            $("#contactTitle").addClass("is-invalid");
            errorMsg.push(t("error.titleMessageInvalid"));
        } else if (title.length > 50) {
            $("#contactTitle").addClass("is-invalid");
            errorMsg.push(t("error.titleMessageTooLong"));
        }

        if (!text) {
            $("#contactText").addClass("is-invalid");
            errorMsg.push(t("error.emptyMailMessage"));
        } else if (regExpWhiteSpace.test(text)) {
            $("#contactText").addClass("is-invalid");
            errorMsg.push(t("error.mailMessageInvalid"));
        } else if (text.length > 4000) {
            $("#contactText").addClass("is-invalid");
            errorMsg.push(t("error.mailMessageTooLong"));
        }

        if (!name) {
            $("#contactName").addClass("is-invalid");
            errorMsg.push(t("error.emptyMailName"));
        } else if (regExpWhiteSpace.test(name)) {
            $("#contactName").addClass("is-invalid");
            errorMsg.push(t("error.userNameInvalid"));
        } else if (name.length > 50) {
            $("#contactName").addClass("is-invalid");
            errorMsg.push(t("error.userNameTooLong"));
        }

        if (!this.state.token) errorMsg.push(t("error.recaptchaEmpty"));

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            this.sendEmail();
        }
    };

    sendEmail = async () => {
        const { fetchURL, t, onShowAlert } = this.props;
        onShowAlert({ variant: "info", message: t(`info.sendingMail`), fading: false });
        const passData = {
            email: this.state.email,
            title: this.state.title,
            name: this.state.name,
            message: this.state.text,
            token: this.state.token,
        };
        try {
            const response = await fetch(`${fetchURL}/send-email-by-contact-form`, {
                credentials: "include",
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === true) {
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 3000 });
                        this.setState({ email: "", title: "", text: "", name: "", token: null });
                    } else onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        this.props.onHideSpinner(200);
        $("#contact").css({ minHeight: `${$(window).outerHeight() - $("footer").outerHeight()}px` });
    }

    render() {
        const { t, onShowSpoonLoader, frontURL } = this.props;
        return (
            <Fragment>
                <Container id="contact" className="mt-5">
                    <Row>
                        <Col className="border shadow mt-5 p-4" xs={12} lg={{ span: 6, offset: 3 }} xl={{ span: 8, offset: 2 }}>
                            <h1 className="h3 text-center">{t("contact.title")}</h1>
                            <Form onSubmit={this.handleCheckValidation} autoComplete="off" noValidate>
                                <Row>
                                    <Col xs={12}>
                                        <Email value={this.state.email} onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })} id="contactEmail" name="email" t={t} />
                                    </Col>
                                    <Col xs={12}>
                                        <TextForm
                                            value={this.state.title}
                                            onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })}
                                            req
                                            labelTxt={t("contact.mailTitle")}
                                            placeholderTxt={t("contact.mailTitlePlaceholder")}
                                            name="title"
                                            id="contactTitle"
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <TextForm
                                            value={this.state.name}
                                            onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })}
                                            req
                                            labelTxt={t("contact.mailName")}
                                            placeholderTxt={t("contact.mailNamePlaceholder")}
                                            name="name"
                                            id="contactName"
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <TextareaForm
                                            value={this.state.text}
                                            onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })}
                                            req
                                            labelTxt={t("contact.mailText")}
                                            placeholderTxt={t("contact.mailTextPlaceholder")}
                                            name="text"
                                            id="contactText"
                                            minHeight="5"
                                        />
                                    </Col>
                                    <Col xs={12} className="text-center my-3" id="recaptcha">
                                        <ReCAPTCHA sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY} onChange={(value) => this.setState({ token: value })} theme="dark" />
                                    </Col>
                                    <Col xs={12} className="text-center">
                                        <Button variant="primary" type="submit">
                                            {t("contact.sendBtn")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default Contact;

import React, { Fragment, Component } from "react";
import { Alert, Button, Form, Modal, Row, Col } from "react-bootstrap";
import AlertNoPermission from "../alerts/AlertNoPermission";
import ConstructionForm from "../pages/myAccount/volunteering/ConstructionForm";
import LoadingProgressBar from "../spinners/LoadingProgressBar";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class EditConstrFormModal extends Component {
    state = { noPermission: false, loading: true, alert: null, changedValues: false, appTypes: null, constrFormData: null };

    getConstrData = async () => {
        const { fetchURL, t, onShowAlert, skillsStart, toolsStart, activeEvent } = this.props;
        const passData = {
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/get-contruction-data`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status !== true)
                        this.setState({ alert: { variant: fetchedData.status, message: t(`${fetchedData.status}.${fetchedData.code}`) }, loading: false });
                    else {
                        const basicData = fetchedData.basicData[0],
                            avalOptions = fetchedData.avalOptions,
                            regExp = /^,? ?,? ?,? ?,? ?,? ?/; //wyrażenie regularne na stringa po wycięciu z niego zaznaczonych opcji - mogą wtedy zostać samotne przecinki lub też spacje
                        let skills = [],
                            tools = [],
                            skillsOther = basicData.pac_skills ? basicData.pac_skills : "",
                            toolsOther = basicData.pac_tools ? basicData.pac_tools : "";
                        skillsStart.forEach((e, i) => {
                            //console.log(e.label, e.label.includes(basicData.pac_skills));
                            if (basicData.pac_skills.includes(e.label)) {
                                skills.push({ value: true, label: e.label });
                                skillsOther = skillsOther.replace(e.label, "");
                            } else {
                                if (i + 1 === skillsStart.length && skillsOther.length > 0 && skillsOther.replace(regExp, "").length > 0)
                                    skills.push({ value: true, label: e.label });
                                else skills.push({ value: false, label: e.label });
                            }
                        });
                        toolsStart.forEach((e, i) => {
                            if (basicData.pac_tools.includes(e.label)) {
                                tools.push({ value: true, label: e.label });
                                toolsOther = toolsOther.replace(e.label, "");
                            } else {
                                if (i + 1 === toolsStart.length && toolsOther.length > 0 && toolsOther.replace(regExp, "").length > 0) tools.push({ value: true, label: e.label });
                                else tools.push({ value: false, label: e.label });
                            }
                        });

                        this.setState({
                            constrFormData: [
                                {
                                    avalOptions: avalOptions,
                                    skills: skills,
                                    tools: tools,
                                    skillsOther: skillsOther.length > 0 && skillsOther.replace(regExp, "").length > 0 ? skillsOther.replace(regExp, "") : "",
                                    toolsOther: toolsOther.length > 0 && toolsOther.replace(regExp, "").length > 0 ? toolsOther.replace(regExp, "") : "",
                                    arrivalDate: new Date(basicData.pac_arrival_date),
                                    constrComment: basicData.pac_comment ? basicData.pac_comment : "",
                                },
                            ],
                            loading: false,
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    insertOrUpdateForm = async () => {
        const { fetchURL, t, onShowAlert, activeEvent, onRefreshData } = this.props;
        const passData = {
            activeEvent: activeEvent,
            constrFormData: this.state.constrFormData,
        };
        onShowAlert({ variant: "info", message: t(this.props.option === "edit" ? "info.editingData" : "info.submittingForm"), fading: false });
        try {
            const response = await fetch(`${fetchURL}/${this.props.option === "edit" ? "update-construction-data" : "insert-construction-data"}`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status !== true)
                        this.setState({ alert: { variant: fetchedData.status, message: t(`${fetchedData.status}.${fetchedData.code}`) }, loading: false });
                    else {
                        onShowAlert({
                            variant: "success",
                            message: t(`success.${this.props.option === "edit" ? "formEditedSuccessfully" : "formSubmittedSuccessfully"}`),
                            fading: 2000,
                        });
                        onRefreshData();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleChangeConstrData = (value, name, enableBtn) => {
        const { constrFormData } = this.state;
        const list = [...constrFormData];
        list[0][name] = value;
        this.setState({ constrFormData: list, changedValues: enableBtn });
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { constrFormData } = this.state;
        const { onShowAlert, t } = this.props;
        onShowAlert("");
        let errorMsg = [];

        if (!constrFormData[0].arrivalDate) {
            $("#arrivalDate").addClass("is-invalid");
            errorMsg.push(t("error.emptyArrivalDate"));
        }

        let avalError = false;
        let allAvalsAreNo = true;
        constrFormData[0].avalOptions.forEach((e, i) => {
            if (!e.answer) {
                $(`#CONSTRavalAnswer${i}`).addClass("is-invalid");
                avalError = t("error.avalAnswersNotFilled");
            } else if (e.answer !== "N") allAvalsAreNo = false;
        });

        if (avalError) errorMsg.push(avalError);
        if (allAvalsAreNo) errorMsg.push(t("error.allAvalsAreCheckedForNo"));

        const regExpWhiteSpace = /^\s*$/;

        if (constrFormData[0].constrComment && regExpWhiteSpace.test(constrFormData[0].constrComment)) {
            $("#constrComment").addClass("is-invalid");
            errorMsg.push(t("error.commentFormInvalid"));
        } else if (constrFormData[0].constrComment.length > 255) {
            $("#constrComment").addClass("is-invalid");
            errorMsg.push(t("error.commentFormToLong"));
        }

        if (constrFormData[0].skillsOther.length > 0 && constrFormData[0].skillsOther.length > 50) {
            $("#otherSkills").addClass("is-invalid");
            errorMsg.push(t("error.skillsCommentTooLong"));
        } else if (constrFormData[0].skillsOther.length > 0 && regExpWhiteSpace.test(constrFormData[0].skillsOther)) {
            $("#otherSkills").addClass("is-invalid");
            errorMsg.push(t("error.skillsCommentInvalid"));
        }
        if (constrFormData[0].toolsOther.length > 0 && constrFormData[0].toolsOther.length > 50) {
            $("#otherSkills").addClass("is-invalid");
            errorMsg.push(t("error.toolsCommentTooLong"));
        } else if (constrFormData[0].toolsOther.length > 0 && regExpWhiteSpace.test(constrFormData[0].toolsOther)) {
            $("#otherSkills").addClass("is-invalid");
            errorMsg.push(t("error.toolsCommentInvalid"));
        }

        if (avalError) errorMsg.push(avalError);

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            this.insertOrUpdateForm();
        }
    };

    componentDidMount() {
        if (this.props.activeEvent && this.props.activeEvent !== "noActiveEvent") {
            if (this.props.option === "edit") this.getConstrData();
            else
                this.setState({
                    constrFormData: [
                        {
                            avalOptions: null,
                            skills: this.props.skillsStart,
                            tools: this.props.toolsStart,
                            skillsOther: "",
                            toolsOther: "",
                            arrivalDate: null,
                            constrComment: "",
                        },
                    ],
                    loading: false,
                });
        } else this.setState({ showView: "noActiveEvent" });
    }

    render() {
        const { noPermission, loading, alert, changedValues, constrFormData, appTypes } = this.state;
        const { t, show, onClose, fetchURL, onShowAlert, activeEvent } = this.props;
        return (
            <Fragment>
                <Modal show={show} onHide={onClose} size="lg" backdrop="static" keyboard={false}>
                    <Form onSubmit={this.handleCheckValidation}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t(`myAccount.volunteering.${this.props.option === "edit" ? "editForm" : "fillForm"}`)}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {noPermission ? (
                                <AlertNoPermission t={t} />
                            ) : alert ? (
                                <Alert className="my-5 text-center" variant={alert.variant}>
                                    <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /> {alert.message}
                                </Alert>
                            ) : loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <div className="my-account-show" id="volunteeringForm">
                                    <Row className="no-gutters">
                                        <Col xs={12} className="fill-form mt-3 mb-5 px-4 py-3">
                                            <ConstructionForm
                                                t={t}
                                                fetchURL={fetchURL}
                                                onShowAlert={onShowAlert}
                                                activeEvent={activeEvent}
                                                onChangeView={(view) => this.setState({ showView: view })}
                                                appTypes={appTypes}
                                                changedValues={changedValues}
                                                onChangeConstrData={(value, name, enableBtn) => this.handleChangeConstrData(value, name, enableBtn)}
                                                constrFormData={constrFormData}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary" type="button" onClick={onClose}>
                                {t("info.cancel")}
                            </Button>
                            <Button variant="primary" type="submit" disabled={!changedValues}>
                                {t(this.props.option === "edit" ? "info.save" : "myAccount.formSubmitBtn")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default withRouter(EditConstrFormModal);

import React, { Component, Fragment } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import CalendarDate from "../../../forms/datetimes/CalendarDate";
import { Checkbox } from "semantic-ui-react";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import MoneyTransferData from "./MoneyTransferData";

class AccreditationTransfer extends Component {
    state = {
        makeTransfer: false,
        transferDate: new Date(),
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { makeTransfer, transferDate } = this.state;
        const { onShowAlert, t } = this.props;

        onShowAlert("");
        let errorMsg = [];

        if (makeTransfer && !transferDate) {
            $("#transferDate").addClass("is-invalid");
            errorMsg.push(t("error.noTransferData"));
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            this.props.onSendData(makeTransfer, transferDate);
        }
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { makeTransfer, transferDate, disabledForm } = this.state;
        const { t, activeEvent, onShowAlert } = this.props;

        return (
            <Fragment>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <p className="my-4 h5 text-center">{t("myAccount.accreditation.transfer.subtitle")}</p>
                    </Col>
                    <Col lg={{ span: 8, offset: 2 }} xs={12}>
                        <MoneyTransferData
                            t={t}
                            transferAmount={activeEvent.event_price}
                            onShowAlert={onShowAlert}
                            transferTitle={`${activeEvent.event_name} - ${t("myAccount.accreditation.transfer.accreditation")}`}
                        />
                    </Col>
                    <Col lg={{ span: 8, offset: 2 }} xs={12}>
                        <Form onSubmit={this.handleCheckValidation}>
                            <Checkbox
                                label={t("myAccount.accreditation.transfer.transferDoneBtn")}
                                checked={makeTransfer}
                                onChange={(e, data) => this.setState({ makeTransfer: data.checked })}
                                className="mr-3 mb-3"
                            />
                            <div className="d-inline-block">
                                <CalendarDate
                                    onChangeCalendarDate={(date) => this.setState({ transferDate: date })}
                                    value={transferDate}
                                    id="transferDate"
                                    t={t}
                                    disabled={makeTransfer ? false : true}
                                    className="mb-3"
                                    popperPlacement="top-end"
                                />
                            </div>
                            <div>
                                <p className="text-small">{t("myAccount.accreditation.transfer.helper")}</p>
                            </div>
                            <div className="text-center">
                                <Button variant="primary" type="submit" className="my-3" size="lg" disabled={disabledForm}>
                                    {disabledForm ? <FontAwesomeIcon icon={faSpinner} className="btn-spinner" /> : t("myAccount.accreditation.transfer.sendBtn")}
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default AccreditationTransfer;

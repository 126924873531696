import { faArrowLeft, faExclamationCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, Component } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { Checkbox } from "semantic-ui-react";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import ConstructionForm from "./ConstructionForm";
import BarForm from "./BarForm";
import AccrForm from "./AccrForm";
import Phone from "../../../forms/text-inputs/Phone";
import TextareaForm from "../../../forms/TextareaForm";
import TextForm from "../../../forms/text-inputs/TextForm";
import $ from "jquery";
import NPCForm from "./NPCForm";

var regExpWhiteSpace = /^\s*$/;

class VolunteeringForm extends Component {
    state = {
        showView: null,
        appTypes: null,
        disabledForm: false,
        changedValues: false,
        showConfirmModal: false,
        userFbLink: null,
        userPhone: null,
        npcComment: "",
        programComment: "",
        sponsorComment: "",
        gastroComment: "",
        mediaComment: "",
        medicComment: "",
        barFormData: [{ avalOptions: null, numberOfDuties: null, barComment: "" }],
        accrFormData: [{ avalOptions: null, numberOfDuties: null, accrComment: "" }],
        constrFormData: [
            {
                avalOptions: null,
                skills: this.props.skillsStart,
                tools: this.props.toolsStart,
                skillsOther: "",
                toolsOther: "",
                arrivalDate: null,
                constrComment: "",
            },
        ],
    };

    getApplicationTypes = async () => {
        const { fetchURL, t, onShowAlert } = this.props;

        try {
            const response = await fetch(`${fetchURL}/get-application-types`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else this.setState({ appTypes: fetchedData.partApplications, showView: "appFunctions" });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    checkVolunteeringFilledFormExistence = async () => {
        const { fetchURL, t, onShowAlert } = this.props;
        const passData = {
            activeEvent: this.props.activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/check-help-form-existence`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status !== true) this.setState({ showView: { variant: fetchedData.status, message: t(`${fetchedData.status}.${fetchedData.code}`) } });
                    else {
                        this.setState({
                            userFbLink: fetchedData.userFbLink ? { show: false } : { show: true, value: "" },
                            userPhone: fetchedData.userPhone ? { show: false } : { show: true, value: "" },
                        });
                        this.getApplicationTypes();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    submitVolunteeringForm = async () => {
        const { npcComment, barFormData, constrFormData, accrFormData, userFbLink, userPhone, appTypes, programComment, sponsorComment, gastroComment, mediaComment, medicComment } = this.state;
        const { fetchURL, t, onShowAlert, volLink, onRefreshNotification } = this.props;
        const passData = {
            activeEvent: this.props.activeEvent,
            npcComment: npcComment,
            barFormData: barFormData,
            constrFormData: constrFormData,
            accrFormData: accrFormData,
            userFbLink: userFbLink.value,
            userPhone: userPhone.value,
            appTypes: appTypes,
            programComment: programComment,
            sponsorComment: sponsorComment,
            gastroComment: gastroComment,
            mediaComment: mediaComment,
            medicComment: medicComment,
        };
        try {
            const response = await fetch(`${fetchURL}/submit-volunteering-form`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                        this.setState({ disabledForm: false });
                    } else if (fetchedData.status !== true) this.setState({ showView: { variant: fetchedData.status, message: t(`${fetchedData.status}.${fetchedData.code}`) }, disabledForm: false });
                    else {
                        onShowAlert({ variant: "success", message: t("success.formSubmittedSuccessfully"), fading: 2000 });
                        onRefreshNotification();
                        this.props.history.push(volLink);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleChangeAppTypesValues = (value, index) => {
        const { appTypes } = this.state;
        const list = [...appTypes];
        list[index].value = value;
        this.setState({ appTypes: list, changedValues: true });
    };

    handleChangeConstrData = (value, name, enableBtn) => {
        const { constrFormData } = this.state;
        const list = [...constrFormData];
        list[0][name] = value;
        this.setState({ constrFormData: list, changedValues: enableBtn });
    };
    handleChangeBarData = (value, name, enableBtn) => {
        const { barFormData } = this.state;
        const list = [...barFormData];
        list[0][name] = value;
        this.setState({ barFormData: list, changedValues: enableBtn });
    };
    handleChangeAccrData = (value, name, enableBtn) => {
        const { accrFormData } = this.state;
        const list = [...accrFormData];
        list[0][name] = value;
        this.setState({ accrFormData: list, changedValues: enableBtn });
    };

    validateFbLink = (fb) => {
        const regExp = /^https:\/\/www.facebook.com\/.+/;
        const regExp2 = /^https:\/\/facebook.com\/.+/;

        if (regExp.test(fb) || regExp2.test(fb) || !fb) {
            $("#userFbLink").removeClass("is-invalid");
            return true;
        } else {
            $("#userFbLink").addClass("is-invalid");
            return false;
        }
    };

    validatePhone = (number) => {
        const regExp = /^\(?\+?[0-9]{1,3}\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{3,4}( ?-?[0-9]{3})? ?(\w{1,10}\s?\d{1,6})?$/;
        if (regExp.test(number)) return true;
        else return false;
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { onShowAlert, t } = this.props;

        onShowAlert({ variant: "info", message: t("info.sendingData") });
        this.setState({ disabledForm: true }, () => this.submitVolunteeringForm());
    };

    handleCheckAppFunctionsValidation = (e) => {
        e.preventDefault();
        const { userPhone, userFbLink, appTypes, gastroComment, programComment, sponsorComment, mediaComment, medicComment } = this.state;
        const { onShowAlert, t } = this.props;
        onShowAlert("");
        let errorMsg = [];
        if (userPhone.show && !userPhone.value) {
            $("#userPhone").addClass("is-invalid");
            errorMsg.push(t("error.emptyUserPhone"));
        } else if (userPhone.show && !this.validatePhone(userPhone.value)) {
            $("#userPhone").addClass("is-invalid");
            errorMsg.push(t("error.invalidPhoneNumber"));
        }

        if (userFbLink.show && userFbLink.value && !this.validateFbLink(userFbLink.value)) {
            $("#userFbLink").addClass("is-invalid");
            errorMsg.push(t("error.invalidFbLink"));
        }

        let sthIsChecked = false;
        appTypes.forEach((e) => {
            if (e.value) sthIsChecked = true;

            if (e.name === "PROGRAM" && e.value) {
                if (!programComment) {
                    $(`#programComment`).addClass("is-invalid");
                    errorMsg.push(t(`error.programCommentEmpty`));
                } else if (programComment.length > 255) {
                    $(`#programComment`).addClass("is-invalid");
                    errorMsg.push(t(`error.programCommentIsTooLong`));
                } else if (regExpWhiteSpace.test(programComment)) {
                    $(`#programComment`).addClass("is-invalid");
                    errorMsg.push(t(`error.programCommentIsInvalid`));
                }
            } else if (e.name === "SPONSOR" && e.value) {
                if (!sponsorComment) {
                    $(`#sponsorComment`).addClass("is-invalid");
                    errorMsg.push(t(`error.sponsorCommentEmpty`));
                } else if (sponsorComment.length > 255) {
                    $(`#sponsorComment`).addClass("is-invalid");
                    errorMsg.push(t(`error.sponsorCommentIsTooLong`));
                } else if (regExpWhiteSpace.test(sponsorComment)) {
                    $(`#sponsorComment`).addClass("is-invalid");
                    errorMsg.push(t(`error.sponsorCommentIsInvalid`));
                }
            } else if (e.name === "GASTRO" && e.value) {
                if (!gastroComment) {
                    $(`#gastroComment`).addClass("is-invalid");
                    errorMsg.push(t(`error.gastroCommentEmpty`));
                } else if (gastroComment.length > 255) {
                    $(`#gastroComment`).addClass("is-invalid");
                    errorMsg.push(t(`error.gastroCommentIsTooLong`));
                } else if (regExpWhiteSpace.test(gastroComment)) {
                    $(`#gastroComment`).addClass("is-invalid");
                    errorMsg.push(t(`error.gastroCommentIsInvalid`));
                }
            } else if (e.name === "MEDIA" && e.value) {
                if (!mediaComment) {
                    $(`#mediaComment`).addClass("is-invalid");
                    errorMsg.push(t(`error.mediaCommentEmpty`));
                } else if (mediaComment.length > 255) {
                    $(`#mediaComment`).addClass("is-invalid");
                    errorMsg.push(t(`error.mediaCommentIsTooLong`));
                } else if (regExpWhiteSpace.test(mediaComment)) {
                    $(`#mediaComment`).addClass("is-invalid");
                    errorMsg.push(t(`error.mediaCommentIsInvalid`));
                }
            } else if (e.name === "MEDIC" && e.value) {
                if (medicComment && medicComment.length > 255) {
                    $(`#medicComment`).addClass("is-invalid");
                    errorMsg.push(t(`error.medicCommentIsTooLong`));
                } else if (medicComment && regExpWhiteSpace.test(medicComment)) {
                    $(`#medicComment`).addClass("is-invalid");
                    errorMsg.push(t(`error.medicCommentIsInvalid`));
                }
            }
        });

        if (!sthIsChecked) {
            errorMsg.push(t("error.noneOfAppTypesIsChecked"));
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            let showView = "summary";
            const constr = appTypes.filter((e) => e.name === "CONSTR"),
                bar = appTypes.filter((e) => e.name === "BAR"),
                npc = appTypes.filter((e) => e.name === "NPC"),
                accr = appTypes.filter((e) => e.name === "ACCR");

            if (constr[0].value) showView = constr[0].name;
            else if (bar[0].value) showView = bar[0].name;
            else if (accr[0].value) showView = accr[0].name;
            else if (npc[0].value) showView = npc[0].name;

            window.scrollTo(0, 0);
            this.setState({ showView: showView, changedValues: false });
        }
    };

    componentDidMount() {
        if (this.props.activeEvent && this.props.activeEvent !== "noActiveEvent") this.checkVolunteeringFilledFormExistence();
        else this.setState({ showView: "noActiveEvent" });
    }

    componentDidUpdate() {
        const { appTypes } = this.state;
        //dodawanie czerwonej gwiazdki jeśli dany checkbox z appTypes jest zaznaczony
        if (appTypes && appTypes.length > 0) {
            $("#appTypesCheckboxes .checkbox input").each(function (i) {
                let getID = $(this).attr("id").toUpperCase();
                appTypes.forEach((e) => {
                    if (e.name === "GASTRO" || e.name === "PROGRAM" || e.name === "MEDIA" || e.name === "SPONSOR") {
                        if (e.name === getID && e.value) {
                            $("#appTypesCheckboxes .checkbox label").each(function (j) {
                                if (i === j && !$(this).hasClass("appended")) {
                                    $(this).append('<span class="required ml-1">*</span>');
                                    $(this).addClass("appended");
                                }
                            });
                        } else if (e.name === getID && !e.value) {
                            $("#appTypesCheckboxes .checkbox label").each(function (j) {
                                if (i === j && $(this).hasClass("appended")) {
                                    $(this).find("span").remove();
                                    $(this).removeClass("appended");
                                }
                            });
                        }
                    }
                });
            });
        }
    }

    render() {
        const {
            showView,
            appTypes,
            disabledForm,
            changedValues,
            userFbLink,
            userPhone,
            constrFormData,
            barFormData,
            accrFormData,
            showConfirmModal,
            npcComment,
            /* programComment,
            sponsorComment, */
            gastroComment,
            mediaComment,
            medicComment,
        } = this.state;
        const { t, volLink, fetchURL, onShowAlert, activeEvent } = this.props;
        return (
            <Fragment>
                {!showView ? (
                    <LoadingProgressBar t={t} />
                ) : showView.variant && showView.message ? (
                    <Row className="no-gutters">
                        <Col lg={{ span: 8, offset: 2 }} xs={12}>
                            <Alert className="my-5 text-center" variant={showView.variant}>
                                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /> {showView.message}
                            </Alert>
                        </Col>
                        <Col xs={12} className="text-center">
                            <Button variant="link" onClick={() => this.props.history.goBack()}>
                                {t("myAccount.goBack")}
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    <div className="my-account-show" id="volunteeringForm">
                        <Fragment>
                            <Row className="no-gutters">
                                <Col>
                                    <Button variant="link" onClick={() => this.setState({ showConfirmModal: true })}>
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                        {t("myAccount.goBack")}
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="no-gutters">
                                <Col xl={{ span: 8, offset: 2 }} xs={12} className="fill-form mt-3 mb-5 px-4 py-3">
                                    <Form onSubmit={this.handleCheckValidation}>
                                        {showView === "appFunctions" && (
                                            <Row className="no-gutters">
                                                <Col xs={12}>
                                                    <p className="h4 text-uppercase text-center my-3">{t("myAccount.volunteering.form.title")}</p>
                                                </Col>
                                                <Col xs={12} className="mt-3">
                                                    <Form.Group id="appTypesCheckboxes">
                                                        <Form.Label>{t("myAccount.accreditation.form.labelsLabel")}</Form.Label>
                                                        <p className="text-small">{t("myAccount.accreditation.form.labelsSubtext")}</p>
                                                        {appTypes.map((e, i) => (
                                                            <Fragment key={i}>
                                                                {e.name === "PROGRAM" || e.name === "SPONSOR" || e.name === "NPC" ? null : (
                                                                    <Checkbox
                                                                        label={t(`myAccount.accreditation.form.${e.name.toLowerCase()}`)}
                                                                        onChange={(event, data) => this.handleChangeAppTypesValues(data.checked, i)}
                                                                        id={e.name.toLowerCase()}
                                                                        checked={e.value}
                                                                        disabled={disabledForm}
                                                                        className="d-block"
                                                                    />
                                                                )}
                                                                {e.name === "MEDIC" && e.value && (
                                                                    <TextareaForm
                                                                        value={medicComment}
                                                                        onChangeValue={(e) => {
                                                                            $("#medicComment").removeClass("is-invalid");
                                                                            this.setState({ medicComment: e.target.value, changedValues: true });
                                                                        }}
                                                                        name="medicComment"
                                                                        placeholderTxt={t("myAccount.volunteering.medicCommentPlaceholder")}
                                                                        id="medicComment"
                                                                        rows="2"
                                                                    />
                                                                )}
                                                                {/* e.name === "PROGRAM" && e.value && (
                                                                    <TextareaForm
                                                                        value={programComment}
                                                                        onChangeValue={(e) => {
                                                                            $("#programComment").removeClass("is-invalid");
                                                                            this.setState({ programComment: e.target.value, changedValues: true });
                                                                        }}
                                                                        name="programComment"
                                                                        placeholderTxt={t("myAccount.volunteering.programCommentPlaceholder")}
                                                                        id="programComment"
                                                                        rows="2"
                                                                    />
                                                                ) */}
                                                                {/* e.name === "SPONSOR" && e.value && (
                                                                    <TextareaForm
                                                                        value={sponsorComment}
                                                                        onChangeValue={(e) => {
                                                                            $("#sponsorComment").removeClass("is-invalid");
                                                                            this.setState({ sponsorComment: e.target.value, changedValues: true });
                                                                        }}
                                                                        name="sponsorComment"
                                                                        placeholderTxt={t("myAccount.volunteering.sponsorCommentPlaceholder")}
                                                                        id="sponsorComment"
                                                                        rows="2"
                                                                    />
                                                                ) */}
                                                                {e.name === "GASTRO" && e.value && (
                                                                    <TextareaForm
                                                                        value={gastroComment}
                                                                        onChangeValue={(e) => {
                                                                            $("#gastroComment").removeClass("is-invalid");
                                                                            this.setState({ gastroComment: e.target.value, changedValues: true });
                                                                        }}
                                                                        name="gastroComment"
                                                                        placeholderTxt={t("myAccount.volunteering.gastroCommentPlaceholder")}
                                                                        id="gastroComment"
                                                                        rows="2"
                                                                    />
                                                                )}
                                                                {e.name === "MEDIA" && e.value && (
                                                                    <TextareaForm
                                                                        value={mediaComment}
                                                                        onChangeValue={(e) => {
                                                                            $("#mediaComment").removeClass("is-invalid");
                                                                            this.setState({ mediaComment: e.target.value, changedValues: true });
                                                                        }}
                                                                        name="mediaComment"
                                                                        placeholderTxt={t("myAccount.volunteering.mediaCommentPlaceholder")}
                                                                        id="mediaComment"
                                                                        rows="2"
                                                                    />
                                                                )}
                                                            </Fragment>
                                                        ))}
                                                    </Form.Group>
                                                </Col>
                                                {this.state.userFbLink.show && (
                                                    <Col xs={12} className="mt-4">
                                                        <TextForm
                                                            value={userFbLink.value}
                                                            onChangeValue={(e) => {
                                                                this.setState({ userFbLink: { show: true, value: e.target.value }, changedValues: true });
                                                                if (e.target.value === "") $(`#userFbLink`).addClass("is-invalid");
                                                                else if (!this.validateFbLink(e.target.value)) $(`#userFbLink`).addClass("is-invalid");
                                                                else $(`#userFbLink`).removeClass("is-invalid");
                                                            }}
                                                            name="userFbLink"
                                                            id="userFbLink"
                                                            labelTxt={t("myAccount.dataAccount.editFbLink.title")}
                                                            subtext={t("myAccount.volunteering.fbLinkPlaceholder")}
                                                            placeholderTxt={t("myAccount.dataAccount.editFbLink.placeholder")}
                                                            t={t}
                                                        />
                                                        {!this.validateFbLink(userFbLink.value) && (
                                                            <Alert variant="danger" className="alert-small">
                                                                {t("error.invalidFbLink")}
                                                            </Alert>
                                                        )}
                                                    </Col>
                                                )}
                                                {this.state.userPhone.show && (
                                                    <Col xs={12} className="mt-4">
                                                        <Phone
                                                            value={userPhone.value}
                                                            onChangeValue={(e) => {
                                                                $("#userPhone").removeClass("is-invalid");
                                                                this.setState({ userPhone: { show: true, value: e.target.value }, changedValues: true });
                                                            }}
                                                            name="userPhone"
                                                            id="userPhone"
                                                            req
                                                            t={t}
                                                        />
                                                    </Col>
                                                )}
                                                <Col xs={12} className="mt-3 text-center">
                                                    <Button className="forms-btn" variant="primary" type="button" onClick={this.handleCheckAppFunctionsValidation} disabled={!changedValues}>
                                                        {t("myAccount.nextBtn")}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}

                                        {showView === "CONSTR" && (
                                            <ConstructionForm
                                                t={t}
                                                fetchURL={fetchURL}
                                                onShowAlert={onShowAlert}
                                                activeEvent={activeEvent}
                                                onChangeView={(view) => this.setState({ showView: view })}
                                                appTypes={appTypes}
                                                changedValues={changedValues}
                                                onChangeConstrData={(value, name, enableBtn) => this.handleChangeConstrData(value, name, enableBtn)}
                                                constrFormData={constrFormData}
                                                addView
                                            />
                                        )}
                                        {showView === "BAR" && (
                                            <BarForm
                                                appType="BAR"
                                                t={t}
                                                fetchURL={fetchURL}
                                                onShowAlert={onShowAlert}
                                                activeEvent={activeEvent}
                                                onChangeView={(view) => this.setState({ showView: view })}
                                                appTypes={appTypes}
                                                changedValues={changedValues}
                                                onChangeBarData={(value, name, enableBtn) => this.handleChangeBarData(value, name, enableBtn)}
                                                barFormData={barFormData}
                                                addView
                                            />
                                        )}
                                        {showView === "ACCR" && (
                                            <AccrForm
                                                appType="ACCR"
                                                t={t}
                                                fetchURL={fetchURL}
                                                onShowAlert={onShowAlert}
                                                activeEvent={activeEvent}
                                                onChangeView={(view) => this.setState({ showView: view })}
                                                appTypes={appTypes}
                                                changedValues={changedValues}
                                                onChangeAccrData={(value, name, enableBtn) => this.handleChangeAccrData(value, name, enableBtn)}
                                                accrFormData={accrFormData}
                                                addView
                                            />
                                        )}
                                        {showView === "NPC" && (
                                            <NPCForm
                                                npcComment={npcComment}
                                                onChangeValue={(e) => this.setState({ npcComment: e.target.value, changedValues: true })}
                                                t={t}
                                                addView
                                                onShowAlert={onShowAlert}
                                                appTypes={appTypes}
                                                changedValues={changedValues}
                                                onChangeView={(view) => this.setState({ showView: view })}
                                            />
                                        )}

                                        {showView === "summary" && (
                                            <Row className="no-gutters">
                                                <Col xs={12} className="mt-4">
                                                    <p>{t("myAccount.volunteering.appCommentSubtext")}</p>
                                                </Col>
                                                <Col xs={12} className="mt-3 text-center">
                                                    <Button
                                                        className="forms-btn mr-1"
                                                        variant="outline-primary"
                                                        type="button"
                                                        onClick={() => {
                                                            let showView = "appFunctions";
                                                            const constr = appTypes.filter((e) => e.name === "CONSTR"),
                                                                bar = appTypes.filter((e) => e.name === "BAR"),
                                                                npc = appTypes.filter((e) => e.name === "NPC"),
                                                                accr = appTypes.filter((e) => e.name === "ACCR");

                                                            if (npc[0].value) showView = npc[0].name;
                                                            else if (accr[0].value) showView = accr[0].name;
                                                            else if (bar[0].value) showView = bar[0].name;
                                                            else if (constr[0].value) showView = constr[0].name;

                                                            window.scrollTo(0, 0);
                                                            this.setState({ showView: showView, changedValues: false });
                                                        }}
                                                    >
                                                        {t("myAccount.prevBtn")}
                                                    </Button>
                                                    <Button className="forms-btn ml-1" variant="primary" type="submit" disabled={disabledForm} size="lg">
                                                        {disabledForm ? <FontAwesomeIcon icon={faSpinner} className="btn-spinner" /> : t("myAccount.formSubmitBtn")}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                    </Form>
                                </Col>
                            </Row>
                        </Fragment>
                    </div>
                )}
                {showConfirmModal && (
                    <Modal show={showConfirmModal} onHide={() => this.setState({ showConfirmModal: false })}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t("confrimModal.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{t("confrimModal.text")}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => this.setState({ showConfirmModal: false })}>
                                {t("no")}
                            </Button>
                            <Link to={volLink} className="btn btn-outline-primary">
                                {t("yes")}
                            </Link>
                        </Modal.Footer>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default withRouter(VolunteeringForm);

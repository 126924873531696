import { faAngleDoubleUp, faAngleDown, faAngleUp, faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, Component } from "react";
import { Accordion, Button, Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import CharactersHistory from "../../../modals/CharactersHistory";
import UpgradeCharacter from "../../../modals/UpgradeCharacter";
import NoPermission from "../../../other/NoPermission";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import CharacterDetails from "./CharacterDetails";

class CharactersMain extends Component {
    state = { loading: true, noPermission: false, charsList: null, activeEventKey: null, showModal: null };

    getCharsList = async () => {
        const { fetchURL, t, onShowAlert } = this.props;

        try {
            const response = await fetch(`${fetchURL}/get-user-chars-list`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onNotLogged();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        this.setState({ charsList: fetchedData.charsList, loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleCloseAndRefresh = () => {
        this.setState({ showModal: null, loading: true }, () => {
            this.getCharsList();
        });
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getCharsList();
    }

    render() {
        const { noPermission, loading, charsList, activeEventKey, showModal } = this.state;
        const { t, charLink, fetchURL, onShowAlert } = this.props;
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : (
                    <Fragment>
                        {loading ? (
                            <LoadingProgressBar t={t} />
                        ) : (
                            <div className="my-account-show">
                                <Row className="no-gutters">
                                    <Col xs={12} className="mt-4">
                                        <Link to={`${charLink}/${t("url.link.addNewCharacter")}`} className="btn btn-outline-primary mb-3">
                                            {t("myAccount.characters.addNewCharacter")}
                                        </Link>
                                    </Col>
                                </Row>
                                <Row className="no-gutters">
                                    <Col xs={12} className="my-3">
                                        {charsList.length === 0 ? (
                                            <p className="opacity-4">{t("myAccount.characters.noCharacters")}</p>
                                        ) : (
                                            <Fragment>
                                                <Accordion onSelect={(e) => this.setState({ activeEventKey: e })} className="characters-accordion">
                                                    {charsList.map((e, i) => (
                                                        <Card key={i + 1}>
                                                            <Card.Header>
                                                                <Accordion.Toggle as={Button} variant="link" eventKey={i + 1} className="align-baseline-middle">
                                                                    <b className="h5">{e.char_name}</b>
                                                                </Accordion.Toggle>
                                                                <div className="float-right">
                                                                    <Accordion.Toggle as={Button} variant="link" eventKey={i + 1} className="align-middle px-3">
                                                                        <FontAwesomeIcon
                                                                            icon={activeEventKey === i + 1 ? faAngleUp : faAngleDown}
                                                                            style={{ fontSize: "1.25rem" }}
                                                                        />
                                                                    </Accordion.Toggle>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={(props) => <Tooltip {...props}>{t("myAccount.characters.charHistoryTooltip")}</Tooltip>}
                                                                    >
                                                                        <Button
                                                                            variant="outline-primary"
                                                                            className="mr-2"
                                                                            onClick={() => this.setState({ showModal: { modalName: "charHistory", charID: e.char_unique_id } })}
                                                                        >
                                                                            <FontAwesomeIcon icon={faHistory} />
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={(props) => <Tooltip {...props}>{t("myAccount.characters.charUpgradeTooltip")}</Tooltip>}
                                                                    >
                                                                        <Button
                                                                            variant="outline-primary"
                                                                            onClick={() => this.setState({ showModal: { modalName: "upgradeChar", charID: e.char_unique_id } })}
                                                                        >
                                                                            <FontAwesomeIcon icon={faAngleDoubleUp} />
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey={i + 1}>
                                                                <Card.Body>
                                                                    <CharacterDetails t={t} details={e} />
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    ))}
                                                </Accordion>
                                            </Fragment>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Fragment>
                )}
                {showModal && (
                    <Fragment>
                        {showModal.modalName === "charHistory" && (
                            <CharactersHistory
                                fetchURL={fetchURL}
                                t={t}
                                charID={showModal.charID}
                                onClose={() => this.setState({ showModal: null })}
                                show={true}
                                onShowAlert={onShowAlert}
                            />
                        )}
                        {showModal.modalName === "upgradeChar" && (
                            <UpgradeCharacter
                                fetchURL={fetchURL}
                                t={t}
                                charID={showModal.charID}
                                onClose={() => this.setState({ showModal: null })}
                                show={true}
                                onShowAlert={onShowAlert}
                                onCloseAndRefresh={this.handleCloseAndRefresh}
                            />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default CharactersMain;

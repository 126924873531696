import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Image, Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

class PreviousEditionsSummary extends Component {
    state = {
        existingFile: null,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        const { location, t } = this.props;
        const pathname = location.pathname;
        const year = pathname.replace(`/${t("url.link.previousEditions")}/${t("url.link.summary")}/`, "");
        this.getPreviousEditionSummary(year);
    }

    getPreviousEditionSummary = async (year) => {
        const { fetchURL, t, onShowAlert } = this.props;
        const passData = {
            fileName: `infografika_${year}.webp`,
        };
        try {
            const response = await fetch(`${fetchURL}/check-if-file-exists`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.fileExists) this.setState({ existingFile: year });
                    else this.setState({ existingFile: "N" });
                });
            } else this.setState({ existingFile: "N" });
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    render() {
        //tymczasowo, docelowo ma być to pobierane z bazy
        const { t, frontURL } = this.props;

        const { existingFile } = this.state;
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col xs={12} className="my-3">
                            <Link to={`/${t("url.link.previousEditions")}`}>
                                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                {t("previousEditions.backToSummary")}
                            </Link>
                        </Col>
                        {existingFile ? (
                            <Fragment>
                                <Col className="text-center py-5" xs={12}>
                                    {existingFile !== "N" ? (
                                        <Image src={`${frontURL}/images/previous-editions/infografika_${existingFile}.webp`} alt={`infografika Łyżkon edycja z roku ${existingFile}`} fluid />
                                    ) : (
                                        <p className="h3 opacity-4">{t("previousEditions.notExistingFile")}</p>
                                    )}
                                </Col>
                                <Col xs={12} className="my-3 text-center">
                                    <Link to={`/${t("url.link.previousEditions")}`} className="btn btn-outline-primary mb-5">
                                        {t("previousEditions.backToSummary")}
                                    </Link>
                                </Col>
                            </Fragment>
                        ) : (
                            <Col className="text-center mb-5">
                                <LoadingProgressBar t={t} />
                            </Col>
                        )}
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default PreviousEditionsSummary;

import React, { Fragment } from "react";
import { Form, Radio } from "semantic-ui-react";

const CharacterContinuationRadios = (props) => {
    const { onChangeValue, id, radioValue, t, disabled } = props;
    return (
        <Fragment>
            <div id={id} className="ui form">
                <Form.Field>
                    <Radio
                        label={t("yes")}
                        name="characterContinuationRadio"
                        value="Y"
                        checked={radioValue === "Y"}
                        onChange={(e, { value }) => onChangeValue(value, "continuation")}
                        disabled={disabled}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        label={t("no")}
                        name="characterContinuationRadio"
                        value="N"
                        checked={radioValue === "N"}
                        onChange={(e, { value }) => onChangeValue(value, "continuation")}
                        disabled={disabled}
                    />
                </Form.Field>
            </div>
        </Fragment>
    );
};

export default CharacterContinuationRadios;

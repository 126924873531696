import React, { Fragment, Component } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import TextForm from "../forms/text-inputs/TextForm";

class EditNickname extends Component {
    state = {
        newUserNickname: this.props.userData[0].user_nickname ? this.props.userData[0].user_nickname : "",
        changedValues: false,
    };

    handleEdit = async (e) => {
        e.preventDefault();
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.editingData"), fading: false });
        const passData = {
            newNickname: this.state.newUserNickname,
        };
        try {
            const response = await fetch(`${fetchURL}/update-nickname`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    } else {
                        onShowAlert({ variant: "success", message: t(`success.dataUpdatedSuccessfully`), fading: 2000 });
                        this.props.onCloseAndRefresh();
                    }
                });
            }
        } catch (err) {
            this.onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    render() {
        const { newUserNickname, changedValues } = this.state;
        const { show, onClose, t } = this.props;
        return (
            <Fragment>
                <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
                    <Form onSubmit={this.handleEdit} autoComplete="off">
                        <Modal.Header closeButton>
                            <Modal.Title>{t("myAccount.dataAccount.editNickname.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="no-gutters">
                                <Col xs={12}>
                                    <p className="mb-4 h6" style={{ lineHeight: "1.2" }}>
                                        {t("myAccount.dataAccount.editNickname.subtitle")}
                                    </p>
                                </Col>

                                <Col xs={12}>
                                    <TextForm
                                        value={newUserNickname}
                                        onChangeValue={(e) => {
                                            this.setState({ [e.target.name]: e.target.value, changedValues: true });
                                        }}
                                        labelTxt={t("myAccount.dataAccount.editNickname.label")}
                                        placeholderTxt={t("myAccount.dataAccount.editNickname.placeholder")}
                                        name="newUserNickname"
                                        id="userNickame"
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={onClose}>
                                {t("info.cancel")}
                            </Button>
                            <Button variant="primary" disabled={!changedValues ? true : false} type="submit">
                                {t("info.saveChanges")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default EditNickname;

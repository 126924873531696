import React, { Fragment } from "react";
import { Modal, Button, Image, Col } from "react-bootstrap";
import Moment from "react-moment";
import "moment/locale/pl";

const NewsPreviewModal = (props) => {
    const { show, onClose, t, langs, imageFile, imageFileURL, selectedLang, frontURL, newsDate } = props;

    return (
        <Fragment>
            <Modal show={show} onHide={onClose} size="lg">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Col xs={12} className="text-center">
                        <div className="langs-image mt-3 mb-5 text-center">
                            {imageFile ? (
                                imageFileURL ? (
                                    <img src={imageFileURL} alt="add-img" style={{ width: "500px", height: "500px" }} />
                                ) : (
                                    <img src={`data:${imageFile.type};base64,${imageFile.data}`} alt={imageFile.name} style={{ width: "500px", height: "500px" }} />
                                )
                            ) : (
                                <Image src={`${frontURL}/images/placeholder_img.webp`} alt="placeholder-img" style={{ width: "500px", height: "500px" }} />
                            )}
                        </div>
                    </Col>
                    {langs.map((e, i) => (
                        <Fragment key={i}>
                            {e.lang_code === selectedLang && (
                                <Fragment>
                                    <Col xs={12} className="langs-titles">
                                        <h1 className="h1 mb-5 text-center font-weight-bold">{e.news_title}</h1>
                                        <p className="h3 mb-4">{e.news_subtitle}</p>
                                    </Col>
                                    <Col xs={12} className="langs-date my-2 opacity-4">
                                        <Moment date={newsDate} format="YYYY-MM-DD HH:mm" locale="pl" element="span" />
                                    </Col>
                                    <Col xs={12} className="langs-text mb-4 mt-3">
                                        <div dangerouslySetInnerHTML={{ __html: e.news_content }}></div>
                                    </Col>
                                </Fragment>
                            )}
                        </Fragment>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={onClose}>
                        {t("close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default NewsPreviewModal;

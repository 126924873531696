import React from "react";

const LoadingProgressBar = (props) => {
    const { t } = props;
    return (
        <div className="text-center">
            <p className="loading-data">{t("loadingData")}</p>
            <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default LoadingProgressBar;

import React, { Component, Fragment } from "react";
import { Accordion, Alert, Button, Card, Col, Modal, Row } from "react-bootstrap";
import CharacterDetails from "../pages/myAccount/characters/CharacterDetails";
import LoadingProgressBar from "../spinners/LoadingProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

class CharactersHistory extends Component {
    state = { loading: true, charsHistoryList: null, newestVersion: null, activeEventKey: null };

    getCharVersions = async () => {
        const { fetchURL, t, onShowAlert, charID } = this.props;

        try {
            const response = await fetch(`${fetchURL}/get-char-versions`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ charID: charID }),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        //console.log(fetchedData.charsList);
                        const version = fetchedData.charsList.length,
                            charsList = fetchedData.charsList;
                        charsList.shift();

                        this.setState({ charsHistoryList: charsList, loading: false, newestVersion: version });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        this.getCharVersions();
    }

    render() {
        const { newestVersion, charsHistoryList, loading, activeEventKey } = this.state;
        const { t, show, onClose } = this.props;

        return (
            <Fragment>
                <Modal show={show} onHide={onClose} backdrop="static" keyboard={false} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{t("myAccount.characters.characterHistory.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {loading || !charsHistoryList ? (
                            <LoadingProgressBar t={t} />
                        ) : charsHistoryList.length === 0 ? (
                            <Row className="no-gutters">
                                <Col xs={12}>
                                    <Alert variant="info" className="alert-small">
                                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                        {t("myAccount.characters.characterHistory.noPrevVersions")}
                                    </Alert>
                                </Col>
                            </Row>
                        ) : (
                            <Row className="no-gutters">
                                <Col xs={12}>
                                    <p className="mb-4 h5" style={{ lineHeight: "1.2" }}>
                                        {t("myAccount.characters.characterHistory.currentVersion")}: {newestVersion}
                                    </p>
                                    <p className="mb-4 h6">{t("myAccount.characters.characterHistory.prevVersions")}:</p>
                                </Col>
                                <Col xs={12}>
                                    <Accordion onSelect={(e) => this.setState({ activeEventKey: e })} className="w-100 characters-accordion">
                                        {charsHistoryList.map((e, i) => (
                                            <Card key={i}>
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey={i + 1} className="align-middle">
                                                        {t("myAccount.characters.characterHistory.version")} {charsHistoryList.length - i}
                                                    </Accordion.Toggle>

                                                    <div className="float-right">
                                                        <Accordion.Toggle as={Button} variant="link" eventKey={i + 1} className="align-middle px-3">
                                                            <FontAwesomeIcon icon={activeEventKey === i + 1 ? faAngleUp : faAngleDown} style={{ fontSize: "1.25rem" }} />
                                                        </Accordion.Toggle>
                                                    </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={i + 1}>
                                                    <Card.Body>
                                                        <CharacterDetails t={t} details={e} />
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                    </Accordion>
                                </Col>
                            </Row>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={onClose}>
                            {t("close")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

export default CharactersHistory;

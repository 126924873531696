import React, { Component, Fragment } from "react";
import { Row, Col, Container } from "react-bootstrap";
import SmallBanner from "./../../other/SmallBanner";
import { withRouter } from "react-router-dom";
import Footer from "../../other/Footer";
import $ from "jquery";
/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBus, faCar, faTrain } from "@fortawesome/free-solid-svg-icons"; */

/* function fadeToObjects(object) {
    const objectOffset = $(`${object}`).offset();
    $(window).on("scroll", function () {
        const distance = window.scrollY;
        const wh = $(window).outerHeight();
        if (distance + wh - 200 >= objectOffset.top) $(`${object}`).fadeTo(600, 1);
    });
} */

class WhereWePlay extends Component {
    componentDidMount() {
        this.props.onHideSpinner(200);
        if ($(".loading-spoon").hasClass("show")) $("#centerRooms").delay(1200).fadeTo(400, 1);
        else $("#centerRooms").delay(400).fadeTo(400, 1);
    }

    /* componentDidUpdate() {
        fadeToObjects("#car");
        fadeToObjects("#bus");
        fadeToObjects("#train");
        fadeToObjects("#another");
        fadeToObjects("#accessImg");
        fadeToObjects("#centerBar");
        fadeToObjects("#centerGarage");
    } */

    handleRedirect = (e, url) => {
        e.preventDefault();
        this.props.onShowSpoonLoader();
        setTimeout(() => this.props.history.push(url), 700);
    };

    render() {
        const { t, frontURL, onShowSpoonLoader } = this.props;
        return (
            <Fragment>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <SmallBanner
                            frontURL={frontURL}
                            bgBottom={`${frontURL}/images/small-banners/b1-bottom.webp`}
                            bgDesktop={`${frontURL}/images/small-banners/b3.webp`}
                            title={t("whereWePlay.title")}
                            subtitle={t("whereWePlay.subtitle")}
                        />
                    </Col>
                </Row>
                <div id="whereWePlay">
                    <Container>
                        <Row className="my-5">
                            <Col xs={12} lg={{ span: 6, offset: 3 }} className="mb-4">
                                <p className="h2 text-uppercase mb-4 text-center">{t("whereWePlay.title1")}</p>
                                <div dangerouslySetInnerHTML={{ __html: t("whereWePlay.text1") }}></div>
                                <p>
                                    {t("whereWePlay.link")}
                                    <a href="https://battleground.de/" target="_blank" rel="noopener noreferrer">
                                        https://battleground.de/
                                    </a>
                                </p>
                            </Col>
                            {/* <Col lg={6} xs={12} className="px-3">
                                <Image fluid src={`${frontURL}/images/mapka2.jpg`} alt="map terrain" />
                            </Col>

                            <Col xs={12} className="mb-4 d-lg-none">
                                <p className="h2 text-uppercase my-4">{t("whereWePlay.title2")}</p>
                                <div dangerouslySetInnerHTML={{ __html: t("whereWePlay.text2") }}></div>
                            </Col> */}
                        </Row>
                    </Container>
                </div>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withRouter(WhereWePlay);

import { faCheckCircle, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const PersonalData = (props) => {
    const { t, userData, link } = props;
    return (
        <div className="dashboard-box mx-2" id="personalDataBox">
            <div className="p-4">
                <p className="dashboard-box-title">{t("myAccount.dataAccount.title")}</p>
                <Row className="no-gutters">
                    <Col xs={8}>
                        {userData.user_phone ? (
                            <p className="dashboard-box-text">{t("myAccount.dashboard.personalDataText.filled")}</p>
                        ) : (
                            <p className="dashboard-box-text">{t("myAccount.dashboard.personalDataText.notFilled")}</p>
                        )}
                    </Col>
                    <Col xs={4} className="text-center" id="dashboardBoxIcon">
                        {userData.user_phone ? <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> : <FontAwesomeIcon icon={faEdit} />}
                    </Col>
                    {!userData.user_phone && (
                        <Col xs={12}>
                            <Link className="btn btn-primary w-100 mt-4" to={`/${link}/${t("url.link.accountData")}`} id={t("url.link.accountData")}>
                                {t("myAccount.dashboard.fillPersonalDataBtn")}
                            </Link>
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    );
};

export default PersonalData;

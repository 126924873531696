import React, { Component, Fragment } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import NoPermission from "../../../other/NoPermission";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import i18n from "../../../../../i18n";

class ShopItemsList extends Component {
    state = {
        noPermission: false,
        loading: true,
        noActiveEventInfo: false,
        itemsList: [],
        warning: false,
    };

    getShopItemsList = async () => {
        const { fetchURL, t, onShowAlert, activeEvent } = this.props;
        const passData = {
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/shop-items-list`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status !== true) {
                        this.setState({ warning: t(`warning.${fetchedData.status}`), loading: false });
                        this.props.onRefreshCartData();
                    } else {
                        const itemsList = fetchedData.itemsList;
                        for (let i = 0; i < itemsList.length; i++) {
                            if (itemsList[i].blob_data) {
                                let blob = itemsList[i].blob_data;
                                let buffer = Buffer.from(blob);
                                let bufferBase64 = buffer.toString("base64");
                                itemsList[i].blob_data = bufferBase64;
                            }
                        }
                        this.setState({
                            loading: false,
                            itemsList: itemsList,
                            info: fetchedData.partStatus === "ABORTED" ? t("info.shopAbortedPart") : "",
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    handleRedirectTo = (e, url) => {
        e.preventDefault();
        this.props.history.push(url);
    };

    componentDidMount() {
        this.getShopItemsList();
    }

    componentDidUpdate() {
        const getImgWidth = $(".shop-item img").outerWidth();
        $(".shop-item img").css({ height: `${getImgWidth}px` });
    }

    render() {
        const { noPermission, loading, itemsList, warning, info } = this.state;
        const { t, frontURL, shopLink } = this.props;

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : (
                    <Fragment>
                        <Col xs={12}>
                            <p className="my-4 h4 text-uppercase text-center">{t("myAccount.shop.itemsList.list")}</p>
                        </Col>
                        {loading ? (
                            <Col xs={12} className="text-center">
                                <LoadingProgressBar t={t} />
                            </Col>
                        ) : warning ? (
                            <Col xs={12}>
                                <Col xs={12}>
                                    <Alert variant="warning" className="w-100 text-center my-3">
                                        <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                                        {warning}
                                    </Alert>
                                </Col>
                            </Col>
                        ) : (
                            <Fragment>
                                {info && (
                                    <Col xs={12}>
                                        <Alert variant="info" className="w-100 text-center my-3">
                                            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                                            {info}
                                        </Alert>
                                    </Col>
                                )}

                                <Col xs={12} id="shopItemsList">
                                    {itemsList.length === 0 ? (
                                        <p className="text-center opacity-4">{t("myAccount.shop.itemsList.emptyList")}</p>
                                    ) : (
                                        <Row>
                                            {itemsList.map((e, i) => (
                                                <Fragment key={i}>
                                                    <Col
                                                        xs={6}
                                                        lg={4}
                                                        onClick={(event) =>
                                                            e.shit_available === "N" ? {} : this.handleRedirectTo(event, `${shopLink}/${t("url.link.shopItem")}/${e.shit_code}`)
                                                        }
                                                        className={
                                                            e.shit_quantity !== null && (e.shit_available === "N" || e.shit_quantity <= 0 || e.shit_quantity === "0")
                                                                ? "shop-item py-3 disabled"
                                                                : "shop-item py-3"
                                                        }
                                                    >
                                                        <div
                                                            className="not-available"
                                                            style={{
                                                                display:
                                                                    e.shit_quantity !== null && (e.shit_available === "N" || e.shit_quantity <= 0 || e.shit_quantity === "0")
                                                                        ? "block"
                                                                        : "none",
                                                            }}
                                                        >
                                                            <p>{t("myAccount.shop.itemNotAvailable")}</p>
                                                        </div>
                                                        <Row className="no-gutters">
                                                            <Col xs={12}>
                                                                {e.blob_data ? (
                                                                    <img src={`data:${e.blob_file_type};base64,${e.blob_data}`} className="w-100" alt={e.blob_name} />
                                                                ) : (
                                                                    <img src={`${frontURL}/images/placeholder_img_dark.webp`} alt="placeholder_img" className="w-100" />
                                                                )}
                                                            </Col>
                                                            <Col xs={12}>
                                                                {e.langs.map((l, j) => (
                                                                    <Fragment key={j}>
                                                                        {l.lang_code === i18n.language && <p className="h6 text-center text-uppercase my-3">{l.shit_name}</p>}
                                                                    </Fragment>
                                                                ))}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Fragment>
                                            ))}
                                        </Row>
                                    )}
                                </Col>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default withRouter(ShopItemsList);

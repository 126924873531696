import React from "react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import "./core/Core.css";
import "./website/Website.css";
import Main from "./website/Main";
import "./i18n";

ReactDOM.render(<Main />, document.getElementById("root"));

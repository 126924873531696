import React, { Fragment } from "react";
import { Form, Radio } from "semantic-ui-react";

const ParticipationRadios = (props) => {
    const { onChangeValue, id, radioValue, t, disabled } = props;
    return (
        <Fragment>
            <div id={id} className="ui form">
                <Form.Field>
                    <Radio
                        label={t("myAccount.accreditation.form.participation.yes")}
                        name="participationRadio"
                        value="ABSENT"
                        checked={radioValue === "ABSENT"}
                        onChange={(e, { value }) => onChangeValue(value, "participation")}
                        disabled={disabled}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        label={t("myAccount.accreditation.form.participation.no")}
                        name="participationRadio"
                        value="ABORTED"
                        checked={radioValue === "ABORTED"}
                        onChange={(e, { value }) => onChangeValue(value, "participation")}
                        disabled={disabled}
                    />
                </Form.Field>
            </div>
        </Fragment>
    );
};

export default ParticipationRadios;

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, Component } from "react";
import { Modal, Button, Row, Col, Form, Alert } from "react-bootstrap";
import MoneyTransferData from "../pages/myAccount/accreditation/MoneyTransferData";

class PayBarCreditModal extends Component {
    state = {
        changedValues: false,
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        this.setState({ changedValues: true }, () => {
            this.sendEmail();
        });
    };

    sendEmail = async () => {
        const { fetchURL, onShowAlert, t, onClose, amount, transferTitle } = this.props;
        onShowAlert({ variant: "info", message: t("info.sendingMail"), fading: false });
        const passData = {
            amount: amount,
            transferTitle: transferTitle,
        };
        try {
            const response = await fetch(`${fetchURL}/send-email-for-paying-bar-credit`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                        this.setState({ changedValues: false });
                    } else {
                        onShowAlert({ variant: "success", message: t(`success.sendEmail`), fading: 2000 });
                        onClose();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
            this.setState({ changedValues: false });
        }
    };

    render() {
        const { changedValues } = this.state;
        const { onClose, t, onShowAlert, transferTitle, amount } = this.props;
        return (
            <Fragment>
                <Modal show={true} onHide={onClose} backdrop="static" keyboard={false} size="lg">
                    <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                        <Modal.Header closeButton>
                            <Modal.Title>{t("myAccount.barClient.payBarCredit.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="no-gutters">
                                <Col xs={12}>
                                    <p className="mb-3 h6">{t("myAccount.barClient.payBarCredit.subtitle")}</p>
                                </Col>

                                <Col xs={12}>
                                    <MoneyTransferData t={t} transferAmount={amount} onShowAlert={onShowAlert} transferTitle={transferTitle} />
                                </Col>
                                <Col xs={12}>
                                    <Alert variant="info" className="my-3">
                                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                        {t("myAccount.barClient.payBarCredit.alert")}
                                    </Alert>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={onClose}>
                                {t("info.cancel")}
                            </Button>
                            <Button variant="primary" disabled={changedValues} type="submit">
                                {t("contact.sendBtn")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default PayBarCreditModal;

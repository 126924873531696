import React, { Fragment } from "react";
import NewsList from "./NewsList";
import AddNews from "./AddNews";
import EditNews from "./EditNews";

const News = (props) => {
    const { fetchURL, onShowAlert, t, newsLink, location, frontURL, onNotLogged } = props;

    return (
        <Fragment>
            {location.pathname === `${newsLink}` ? (
                <NewsList t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} newsLink={newsLink} frontURL={frontURL} onNotLogged={onNotLogged} />
            ) : location.pathname === `${newsLink}/${t("url.link.addNews")}` ? (
                <AddNews t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} newsLink={newsLink} frontURL={frontURL} />
            ) : (
                <EditNews t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} newsLink={newsLink} frontURL={frontURL} />
            )}
        </Fragment>
    );
};

export default News;

import React, { Component, Fragment } from "react";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-bootstrap";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import NoPermission from "../../../other/NoPermission";
import PersonalData from "./PersonalData";
import AccreditationData from "./AccreditationData";

class Dashboard extends Component {
    state = {
        fullName: null,
        email: null,
        loading: true,
        noPermission: false,
        userData: null,
        accrData: null,
        accomodationData: null,
        charactersData: null,
    };

    dashboardData = async () => {
        const { fetchURL, t, onShowAlert } = this.props;
        try {
            const response = await fetch(`${fetchURL}/dashboard-data`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onNotLogged();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        this.setState({
                            userData: fetchedData.userData,
                            accomodationData: fetchedData.accomodationData,
                            charactersData: fetchedData.charactersData,
                            accrData: fetchedData.accrData,
                            loading: false,
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        this.dashboardData();
    }

    render() {
        const { loading, noPermission, userData, accrData } = this.state;
        const { t, avatar, activeEvent } = this.props;
        const link = t("url.link.myAccount");
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : (
                    <Fragment>
                        <Row className="no-gutters">
                            <Col className="text-center">
                                <p className="h3 my-4 text-uppercase">{t("myAccount.dashboard.title")}</p>
                            </Col>
                        </Row>
                        {loading ? (
                            <LoadingProgressBar t={t} />
                        ) : (
                            <div className="my-account-show">
                                <Row className="no-gutters">
                                    <Col xs={12} className="text-center  mb-5">
                                        <div className="my-3 m-auto">
                                            {avatar && avatar !== "noFile" ? (
                                                <div className="avatar mx-auto d-inline-block">
                                                    <img src={`data:${avatar.type};base64,${avatar.data}`} width="200" alt={avatar.name} />
                                                </div>
                                            ) : (
                                                <div className="avatar mx-auto d-inline-block border">
                                                    <FontAwesomeIcon icon={faUser} />
                                                </div>
                                            )}
                                            <div className="d-inline-block text-left ml-3 align-middle">
                                                <p className="h4">{userData.user_fullname}</p>
                                                <p className="h6">{userData.user_email}</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} xs={12} className="py-3">
                                        <PersonalData t={t} userData={userData} link={link} />
                                    </Col>
                                    <Col lg={6} xs={12} className="py-3">
                                        <AccreditationData t={t} accrData={accrData} link={link} activeEvent={activeEvent} />
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default Dashboard;

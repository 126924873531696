import React, { Component, Fragment } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Password from "../../forms/Password";
import $ from "jquery";

class ChangePassword extends Component {
    state = {
        oldPass: "",
        password: "",
        repeatPassword: "",
    };
    validatePassword = (pass) => {
        const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~!@#$%^&*()_\-{}[\]:;'"<> ?`/]).{8,}$/;
        if (!re.test(pass)) {
            return false;
        } else return true;
    };

    handleValidation = (e) => {
        e.preventDefault();
        const { oldPass, password, repeatPassword } = this.state;
        const { onShowAlert, t } = this.props;

        onShowAlert("");
        let errorMsg = [];

        if (!oldPass) $("#oldPass").addClass("is-invalid");
        if (!password) $("#password").addClass("is-invalid");
        if (!repeatPassword) $("#repeatPassword").addClass("is-invalid");
        if (!oldPass || !password || !repeatPassword) errorMsg.push(t("error.emptyOneOfAllPassword"));
        else if (!this.validatePassword(password) || !this.validatePassword(repeatPassword)) errorMsg.push(t("error.passRegExp"));

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            this.handleChangePassword();
        }
    };

    handleChangePassword = async () => {
        const { oldPass, password, repeatPassword } = this.state;
        const { fetchURL, t, onShowAlert } = this.props;
        const passData = { oldPass: oldPass, password: password, repeatPassword: repeatPassword };
        onShowAlert({ variant: "info", message: t("info.changingPassword"), fading: false });
        try {
            const response = await fetch(`${fetchURL}/change-password`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData)
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onNotLogged();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        onShowAlert({ variant: "success", message: t("success.passChangedSuccessfully"), fading: 2000 });
                        this.props.history.push(`/${t("url.link.login")}`);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    render() {
        const { oldPass, password, repeatPassword } = this.state;
        const { t } = this.props;
        return (
            <Fragment>
                <Row className="no-gutters">
                    <Col className="text-center" xs={12}>
                        <p className="h3 my-4 text-uppercase">{t("myAccount.changePassword.title")}</p>
                    </Col>
                    <Col className="text-center" xs={12}>
                        <p className="h6 my-4">{t("myAccount.changePassword.subtitle")}</p>
                    </Col>
                </Row>
                <div className="my-account-show">
                    <Form onSubmit={this.handleValidation} className="mt-5" autoComplete="off">
                        <Row className="no-gutters">
                            <Col lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1 }}>
                                <Form.Group>
                                    <Form.Label>
                                        {t("myAccount.changePassword.oldPasswordLabel")} <span className="required"> *</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        id="oldPass"
                                        placeholder={t("myAccount.changePassword.oldPasswordPlaceholder")}
                                        name="oldPass"
                                        value={oldPass}
                                        onChange={(e) => {
                                            this.setState({ [e.target.name]: e.target.value });
                                        }}
                                        autoComplete={"nope"}
                                        className="mb-3 req"
                                    />
                                </Form.Group>
                                <Password
                                    value1={password}
                                    value2={repeatPassword}
                                    onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })}
                                    option="double"
                                    t={t}
                                    onValidatePassword={this.validatePassword}
                                    arrangement="block"
                                />
                            </Col>
                        </Row>
                        <Row className="no-gutters">
                            <Col lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1 }} className="text-center">
                                <Button type="submit" variant="primary" className="mt-2" disabled={oldPass && password && repeatPassword ? false : true}>
                                    {t("myAccount.changePassword.submitBtn")}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(ChangePassword);

import React, { Fragment } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Route } from "react-router";
import ShopItemDetails from "./ShopItemDetails";
import ShopItemsCart from "./ShopItemsCart";
import ShopItemsList from "./ShopItemsList";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

const ShopItem = (props) => {
    const { fetchURL, t, onShowAlert, shopLink, activeEvent, location, frontURL, loading, cart, onRefreshCartData } = props;
    return (
        <Fragment>
            <Row className="no-gutters">
                <Col className="text-center">
                    <p className="h3 my-4 text-uppercase">{t("myAccount.shop.title")}</p>
                </Col>
            </Row>

            <Row>
                <Col xs={12} className="d-lg-none text-right">
                    <Button variant="outline-primary" size="sm" className="" onClick={() => props.history.push(`${shopLink}/${t("url.link.shopCart")}`)}>
                        <FontAwesomeIcon icon={faShoppingCart} />
                    </Button>
                </Col>
                <Col xs={12} lg={8} id="shopItem">
                    <Row>
                        <Route
                            exact
                            path={`${shopLink}`}
                            render={() => (
                                <ShopItemsList
                                    t={t}
                                    fetchURL={fetchURL}
                                    onShowAlert={onShowAlert}
                                    shopLink={shopLink}
                                    activeEvent={activeEvent}
                                    frontURL={frontURL}
                                    onRefreshCartData={onRefreshCartData}
                                />
                            )}
                        />

                        <Route
                            path={`${shopLink}/${t("url.link.shopItem")}`}
                            render={() => (
                                <ShopItemDetails
                                    t={t}
                                    fetchURL={fetchURL}
                                    onShowAlert={onShowAlert}
                                    shopLink={shopLink}
                                    activeEvent={activeEvent}
                                    location={location}
                                    frontURL={frontURL}
                                    onRefreshCartData={onRefreshCartData}
                                />
                            )}
                        />
                    </Row>
                </Col>
                <Col xs={6} lg={4} className="d-none d-lg-block">
                    <ShopItemsCart t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} activeEvent={activeEvent} loading={loading} cart={cart} shopLink={shopLink} />
                </Col>
            </Row>
        </Fragment>
    );
};

export default withRouter(ShopItem);

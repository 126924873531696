import { faCheck, faQuestion, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Button, Col, Table, OverlayTrigger, Tooltip } from "react-bootstrap";

const AvailabilityOptions = (props) => {
    const { avalOptions, t, appType, onSelectAnswer } = props;
    const avalColumnCount = []; //pierwszy element w tablicy - nazwa labelki, drugi el. - ile razy występuje (rowSpan), trzeci el. - w którym momencie występuje

    if (avalOptions) {
        avalOptions.forEach((aval, i) => {
            const label = aval.aval_period_label;
            if (avalColumnCount.filter((e) => e[0] === label).length === 0) avalColumnCount.push([label, 1, i]);
            else
                avalColumnCount.forEach((el) => {
                    if (el[0] === label) el[1] += 1;
                });
        });
    }
    var labelIndex = 0;

    function insertRowSpanTD(e, i) {
        //i = current avalType index
        if (avalColumnCount[labelIndex]) {
            if (i === avalColumnCount[labelIndex][2]) {
                const insert = (
                    <td rowSpan={avalColumnCount[labelIndex][1]} className="py-2">
                        <p className="mb-1">{e.aval_period_label}</p>
                        <p className="mb-0">{e.aval_period_date}</p>
                    </td>
                );
                labelIndex++;
                return insert;
            }
        }
    }

    return (
        <Fragment>
            {avalOptions && (
                <Col xs={12}>
                    <Table size="sm" className="mb-3 aval-detail-table" bordered>
                        <tbody>
                            {avalOptions.map((e, i) => (
                                <Fragment key={i}>
                                    <tr>
                                        {appType === "BAR" || appType === "ACCR" ? (
                                            insertRowSpanTD(e, i)
                                        ) : (
                                            <td>
                                                {e.aval_period_label}
                                                <br />
                                                {e.aval_period_date}
                                            </td>
                                        )}
                                        {(appType === "BAR" || appType === "ACCR") && (
                                            <td className="text-center">
                                                {e.aval_period_start} - {e.aval_period_end}
                                            </td>
                                        )}
                                        <td className="text-center" style={{ minWidth: "130px" }} id={`${appType}avalAnswer${i}`}>
                                            <OverlayTrigger placement="top" overlay={(props) => <Tooltip {...props}>{t("myAccount.volunteering.availability.yesBtn")}</Tooltip>}>
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    style={{ width: "2rem" }}
                                                    className={e.answer === "Y" ? "active mx-1" : "mx-1"}
                                                    onClick={() => onSelectAnswer(i, "Y")}
                                                >
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="top" overlay={(props) => <Tooltip {...props}>{t("myAccount.volunteering.availability.maybeBtn")}</Tooltip>}>
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    style={{ width: "2rem" }}
                                                    className={e.answer === "M" ? "active mx-1" : "mx-1"}
                                                    onClick={() => onSelectAnswer(i, "M")}
                                                >
                                                    <FontAwesomeIcon icon={faQuestion} />
                                                </Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="top" overlay={(props) => <Tooltip {...props}>{t("myAccount.volunteering.availability.noBtn")}</Tooltip>}>
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    style={{ width: "2rem" }}
                                                    className={e.answer === "N" ? "active mx-1" : "mx-1"}
                                                    onClick={() => onSelectAnswer(i, "N")}
                                                >
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </Button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                </Fragment>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            )}
        </Fragment>
    );
};

export default AvailabilityOptions;

import React, { Fragment, Component } from "react";
import { Modal, Button, Row, Col, Alert, Form } from "react-bootstrap";
import TextForm from "./../forms/text-inputs/TextForm";
import $ from "jquery";

class EditNameAndSurname extends Component {
    state = {
        newUserName: this.props.userData[0].user_name,
        newUserSurname: this.props.userData[0].user_surname,
        changedValues: false,
    };

    validateName = (name) => {
        const regExp = /^([A-Z]|\u0141)([a-z]|\u0142|\u0144|\u00f3|\u0107|\u0105|\u017A|\u017C|\u0119|\u015B)+$/;
        if (regExp.test(name)) return true;
        else return false;
    };

    validateSurname = (name) => {
        const regExp =
            /^[A-Z\u0141\u0106\u017B\u0179\u00D3\u015A][a-z\u0142\u0144\u00f3\u0107\u0105\u017A\u017C\u0119\u015B]+(-[A-Z\u0141\u0106\u017B\u0179\u00D3\u015A][a-z\u0142\u0144\u00f3\u0107\u0105\u017A\u017C\u0119\u015B]+| [A-Z\u0141\u0106\u017B\u0179\u00D3\u015A][a-z\u0142\u0144\u00f3\u0107\u0105\u017A\u017C\u0119\u015B]+)?$/;
        if (regExp.test(name)) return true;
        else return false;
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { onShowAlert, t } = this.props;

        onShowAlert("");
        let errorMsg = [];

        if (!this.state.newUserName) {
            errorMsg.push(t("error.emptyUserName"));
            $("#userName").addClass("is-invalid");
        } else {
            if (!this.validateName(this.state.newUserName)) {
                errorMsg.push(t("error.invalidName"));
                $("#userName").addClass("is-invalid");
            } else if (this.state.newUserName.length > 50) {
                errorMsg.push(t("error.userNameTooLong"));
                $("#userName").addClass("is-invalid");
            }
        }

        if (!this.state.newUserSurname) {
            errorMsg.push(t("error.emptyUserSurname"));
            $("#userSurname").addClass("is-invalid");
        } else {
            if (!this.validateSurname(this.state.newUserSurname)) {
                errorMsg.push(t("error.invalidSurname"));
                $("#userSurname").addClass("is-invalid");
            } else if (this.state.newUserSurname.length > 50) {
                errorMsg.push(t("error.userSurnameTooLong"));
                $("#userSurname").addClass("is-invalid");
            }
        }

        if (errorMsg.length > 0) {
            onShowAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
            });
        } else {
            //console.log("edit");
            this.handleEdit();
        }
    };

    handleEdit = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.editingData"), fading: false });
        const passData = {
            oldName: this.props.userData[0].user_name,
            oldSurname: this.props.userData[0].user_surname,
            newName: this.state.newUserName,
            newSurname: this.state.newUserSurname,
        };
        try {
            const response = await fetch(`${fetchURL}/update-name-surname`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    } else {
                        onShowAlert({ variant: "success", message: t(`success.dataUpdatedSuccessfully`), fading: 2000 });
                        this.props.onCloseAndRefresh();
                    }
                });
            }
        } catch (err) {
            this.onShowAlert({ variant: "danger", message: `${t("error.cannotSendData")} (${err})`, fading: false });
        }
    };

    render() {
        const { newUserName, newUserSurname, changedValues } = this.state;
        const { show, onClose, t } = this.props;
        return (
            <Fragment>
                <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
                    <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                        <Modal.Header closeButton>
                            <Modal.Title>{t("myAccount.dataAccount.editNameAndSurname.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="no-gutters">
                                <Col xs={12}>
                                    <p className="mb-4 h6" style={{ lineHeight: "1.2" }}>
                                        {t("myAccount.dataAccount.editNameAndSurname.subtitle")}
                                    </p>
                                </Col>

                                <Col xs={12}>
                                    <TextForm
                                        value={newUserName}
                                        onChangeValue={(e) => {
                                            this.setState({ [e.target.name]: e.target.value, changedValues: true });
                                            if (!e.target.value) $("#userName").addClass("is-invalid");
                                            else if (!this.validateName(e.target.value)) $("#userName").addClass("is-invalid");
                                            else $("#userName").removeClass("is-invalid");
                                        }}
                                        labelTxt={t("register.userNameInput.label")}
                                        placeholderTxt={t("register.userNameInput.placeholder")}
                                        name="newUserName"
                                        id="userName"
                                        req
                                    />

                                    {!newUserName ? (
                                        <Alert variant="danger" className="alert-small">
                                            {t("error.emptyUserName")}
                                        </Alert>
                                    ) : (
                                        !this.validateName(newUserName) && (
                                            <Alert variant="danger" className="alert-small">
                                                {t("error.invalidName")}
                                            </Alert>
                                        )
                                    )}
                                </Col>
                                <Col xs={12}>
                                    <TextForm
                                        value={newUserSurname}
                                        onChangeValue={(e) => {
                                            this.setState({ [e.target.name]: e.target.value, changedValues: true });
                                            if (!e.target.value) $("#userSurame").addClass("is-invalid");
                                            else if (!this.validateName(e.target.value)) $("#userSurame").addClass("is-invalid");
                                            else $("#userSurame").removeClass("is-invalid");
                                        }}
                                        labelTxt={t("register.userSurnameInput.label")}
                                        placeholderTxt={t("register.userSurnameInput.placeholder")}
                                        name="newUserSurname"
                                        id="userSurname"
                                        req
                                    />

                                    {!newUserSurname ? (
                                        <Alert variant="danger" className="alert-small">
                                            {t("error.emptyUserSurname")}
                                        </Alert>
                                    ) : (
                                        !this.validateSurname(newUserSurname) && (
                                            <Alert variant="danger" className="alert-small">
                                                {t("error.invalidSurname")}
                                            </Alert>
                                        )
                                    )}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={onClose}>
                                {t("info.cancel")}
                            </Button>
                            <Button variant="primary" disabled={!changedValues ? true : false} type="submit">
                                {t("info.saveChanges")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default EditNameAndSurname;

import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const NoMatchMyAccount = (props) => {
    const { t } = props;

    return (
        <Fragment>
            <div className="text-center">
                <p className="h1 my-5 text-uppercase">{t("myAccount.noSubpageTitle")}</p>
                <p className="h5 my-5">{t("myAccount.noSubpageSubtitle")}</p>
                <Link to={`/${t("url.link.myAccount")}`} className="btn btn-primary btn-lg">
                    {t("myAccount.noSubpageBtn")}
                </Link>
            </div>
        </Fragment>
    );
};

export default NoMatchMyAccount;

import React, { Component, Fragment } from "react";
import { Alert, Button, Col, Modal, Row, Table } from "react-bootstrap";
import AlertNoPermission from "../alerts/AlertNoPermission";
import LoadingProgressBar from "../spinners/LoadingProgressBar";

class AvailabilityDetailsModal extends Component {
    state = {
        loading: true,
        avalDetails: null,
        noPermission: false,
        alert: null,
    };

    getAvailabilityData = async () => {
        const { fetchURL, onShowAlert, t, partAppType, activeEvent } = this.props;
        const passData = { partAppType: partAppType, eventCode: activeEvent.event_code };

        try {
            const response = await fetch(`${fetchURL}/availability-details`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status === "warning") this.setState({ loading: false, alert: { variant: "warning", message: t(`warning.${fetchedData.code}`) } });
                    else this.setState({ avalDetails: fetchedData.avalDetails, loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        this.getAvailabilityData();
    }

    render() {
        const { noPermission, alert, loading, avalDetails } = this.state;
        const { show, t, onClose, partAppType } = this.props;

        const avalColumnCount = []; //pierwszy element w tablicy - nazwa labelki, drugi el. - ile razy występuje (rowSpan), trzeci el. - w którym momencie występuje

        if (avalDetails) {
            avalDetails.forEach((aval, i) => {
                const label = aval.aval_period_label;
                if (avalColumnCount.filter((e) => e[0] === label).length === 0) avalColumnCount.push([label, 1, i]);
                else
                    avalColumnCount.forEach((el) => {
                        if (el[0] === label) el[1] += 1;
                    });
            });
        }
        var labelIndex = 0;

        function insertRowSpanTD(e, i) {
            //i = current avalType index
            if (avalColumnCount[labelIndex]) {
                if (i === avalColumnCount[labelIndex][2]) {
                    const insert = (
                        <td rowSpan={avalColumnCount[labelIndex][1]} className="text-center">
                            {e.aval_period_label}
                            <br />
                            {e.aval_period_date}
                        </td>
                    );
                    labelIndex++;
                    return insert;
                }
            }
        }

        return (
            <Fragment>
                <Modal show={show} onHide={onClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{t("myAccount.volunteering.seeAvalDetails")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {noPermission ? (
                            <AlertNoPermission t={t} />
                        ) : alert ? (
                            <Alert variant={alert.variant}>{alert.message}</Alert>
                        ) : loading ? (
                            <LoadingProgressBar t={t} />
                        ) : (
                            <Row>
                                {avalDetails && (
                                    <Col xs={12}>
                                        <Table size="sm" bordered className="mb-3 aval-detail-table">
                                            <tbody>
                                                {avalDetails.map((e, i) => (
                                                    <Fragment key={i}>
                                                        <tr>
                                                            {partAppType === "BAR" || partAppType === "ACCR" ? (
                                                                insertRowSpanTD(e, i)
                                                            ) : (
                                                                <td>
                                                                    {e.aval_period_label}
                                                                    <br />
                                                                    {e.aval_period_date}
                                                                </td>
                                                            )}
                                                            {(partAppType === "BAR" || partAppType === "ACCR") && (
                                                                <td className="text-center">
                                                                    {e.aval_period_start} - {e.aval_period_end}
                                                                </td>
                                                            )}
                                                            <td className="text-center">
                                                                {e.answer === "Y" ? (
                                                                    <span className="text-success">{t("info.yes")}</span>
                                                                ) : e.answer === "N" ? (
                                                                    <span className="text-danger">{t("info.no")}</span>
                                                                ) : (
                                                                    <span className="text-warning">{t("info.maybe")}</span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </Fragment>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                )}
                            </Row>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" type="button" onClick={onClose}>
                            {t("close")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

export default AvailabilityDetailsModal;
